import { SVGProps } from "react";
interface SvgTickProps extends SVGProps<SVGSVGElement> {
  /**
   * Width of icon, uses size if not given
   */
  width?: number;
  /**
   * Height of icon, uses size if not given
   */
  height?: number;
  /**
   * Size of icon, applies to width & height if not supplied
   * @default 24
   */
  size?: number;
}
export const SvgTick = (props: SvgTickProps) => (
  <svg
    {...props}
    width={props.width ?? props.size ?? 24}
    height={props.height ?? props.size ?? 24}
    viewBox="0 0 20 18"
    fill="currentColor"
  >
    <g id="surface1">
      <path d="M 17.863281 0.648438 L 6.125 12.167969 L 3.011719 8.785156 C 2.4375 8.234375 1.535156 8.199219 0.878906 8.667969 C 0.238281 9.148438 0.0585938 10 0.453125 10.683594 L 4.140625 16.785156 C 4.503906 17.351562 5.125 17.699219 5.832031 17.699219 C 6.503906 17.699219 7.140625 17.351562 7.503906 16.785156 C 8.09375 16 19.355469 2.351562 19.355469 2.351562 C 20.832031 0.816406 19.042969 -0.535156 17.863281 0.632812 Z M 17.863281 0.648438 " />
    </g>
  </svg>
);
export default SvgTick;
