import React from "react";

import { Filter } from "ui/forms";

import { useSubmissionCandenceQuery } from "API";

import useFilterParam from "./useFilterParam";

const Cadences: React.FC = () => {
  const { data = [], isSuccess, isError } = useSubmissionCandenceQuery();
  const [selected, setSelected] = useFilterParam("cadence", data, {
    defaultToFirst: false,
    multi: true,
    ready: isSuccess || isError,
  });

  return (
    <Filter
      items={{ options: data }}
      title="Cadence"
      label="Cadence"
      selected={!!selected.length}
      count={selected.length ? selected.length : void 0}
      onChange={setSelected}
      disabled={!data.length || !selected}
      value={selected}
      multi={true}
      showReset={true}
      showApply={true}
      searchable={true}
    />
  );
};

export default Cadences;
