import { SubmittedData } from "types/SubmittedData";

export const getNewSubmittedData = (categoryId: string): SubmittedData => {
  return {
    category: categoryId,
    source: null,
    status: "in_progress",
    data: {},
    files: [],
    showErrors: false,
    errors: {},
  };
};
