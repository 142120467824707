import { useCallback, useRef } from "react";
import ReactDOM from "react-dom";

import styled, { keyframes } from "styled-components";
import cssVar from "theme/vars";

import { useClickOutside, useRootElement, useScrollLock } from "../../hooks";

import { ModalContext } from "./Context";

export interface ModalWindowProps {
  open: boolean;
  onClose?: () => void;
  closeOnClickOutside?: boolean;
  className?: string;
  wide?: boolean;
}

const Window: React.FC<ModalWindowProps> = ({
  open,
  onClose,
  closeOnClickOutside = true,
  children,
  className,
  wide,
}) => {
  const portalTarget = useRootElement("modals");
  const modalRef = useRef<HTMLDivElement | null>(null);
  useScrollLock(open);

  const handleClickOutside = useCallback(() => {
    if (closeOnClickOutside) {
      onClose?.();
    }
  }, [closeOnClickOutside, onClose]);

  useClickOutside(modalRef, handleClickOutside);

  if (!open) {
    return null;
  }

  return ReactDOM.createPortal(
    <ModalContext.Provider value={{ open, onClose }}>
      <OuterWrapper>
        <ModalContainer ref={modalRef} className={className} $wide={wide}>
          {children}
        </ModalContainer>
      </OuterWrapper>
    </ModalContext.Provider>,
    portalTarget
  );
};

const revealWrapper = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;
const revealWindow = keyframes`
  0% {
    transform: translateY(40px);
  }
  100% {
    transform: translateY(0px);
  }
`;
const OuterWrapper = styled.div`
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(52, 64, 84, 0.6);
  display: grid;
  place-content: center;
  animation: 0.2s ${revealWrapper} backwards;
`;

const ModalContainer = styled.div<{ $wide?: boolean }>`
  --width: ${({ $wide }) => ($wide ? "1080px" : "480px")};
  min-width: min(var(--width), 90vw);
  max-width: min(var(--width), 100vw);
  max-height: 80vh;
  height: fit-content;
  background-color: ${cssVar("color/primary/white")};
  filter: drop-shadow(0px 8px 8px rgba(0, 0, 0, 0.08));
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  animation: 0.4s ${revealWindow} backwards;
`;

export default Window;
