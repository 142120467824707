export const scope3CategoryNames: Record<number, string> = {
  1: "Purchased Goods and Services",
  2: "Capital Goods",
  3: "Fuel- and Energy-Related Activities",
  4: "Upstream Transportation and Distribution",
  5: "Waste Generated in Operations",
  6: "Business Travel",
  7: "Employee Commuting",
  8: "Upstream Leased Assets",
  9: "Downstream Transportation and Distribution",
  10: "Processing of Sold Products",
  11: "Use of Sold Products",
  12: "End-of-Life Treatment of Sold Products",
  13: "Downstream Leased Assets",
  14: "Franchises",
  15: "Investments",
};
