import { useEffect, useState, RefObject, useCallback } from "react";

export function useScrollableTabs(
  rootContainer: RefObject<HTMLElement>,
  tabContainer: RefObject<HTMLElement>
) {
  const [maxScroll, setMaxScroll] = useState(0);
  const [currentScroll, setCurrentScroll] = useState(0);

  // Determine if the tabs need to scroll
  useEffect(() => {
    let wrapperWidth = 0,
      tabsWidth = 0;
    function processScrollableArea() {
      const allTabs = tabContainer.current,
        root = rootContainer.current;
      if (!allTabs || !root) {
        return;
      }

      const rootSize = root.offsetWidth,
        tabsSize = allTabs.offsetWidth;
      if (rootSize === wrapperWidth && tabsSize === tabsWidth) {
        // No change skip
        return;
      }

      wrapperWidth = rootSize;
      tabsWidth = tabsSize;

      const max = Math.max(0, tabsWidth - wrapperWidth);
      setMaxScroll(max);
      setCurrentScroll((s) => Math.min(s, max));
    }

    processScrollableArea();
    window.addEventListener("resize", processScrollableArea);
    return () => {
      window.removeEventListener("resize", processScrollableArea);
    };
  }, [rootContainer, tabContainer]);

  const canScrollNext = currentScroll < maxScroll;
  const canScrollPrev = currentScroll > 0;

  const setScroll = useCallback(
    (delta: number) => {
      setCurrentScroll((s) => Math.max(0, Math.min(s + delta, maxScroll)));
    },
    [maxScroll]
  );

  const onScrollNext = useCallback(() => {
    const delta = ((rootContainer?.current?.offsetWidth || 300) * 2) / 3;
    setScroll(delta);
  }, [setScroll, rootContainer]);
  const onScrollPrev = useCallback(() => {
    const delta = ((rootContainer?.current?.offsetWidth || 300) * 2) / 3;
    setScroll(-delta);
  }, [setScroll, rootContainer]);

  return {
    max: maxScroll,
    current: currentScroll,
    enabled: {
      next: canScrollNext,
      prev: canScrollPrev,
    },
    onScrollNext,
    onScrollPrev,
  };
}
