import { createContext, useContext } from "react";

export type SortDirection = "asc" | "desc";
export type ContextInfo = {
  onSort: (field: string) => void;
  sort: {
    field?: string;
    direction?: SortDirection;
  };
} | null;

export const TableContext = createContext<ContextInfo>(null);
export function useTableContext() {
  return useContext(TableContext);
}
