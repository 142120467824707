import { FetchArgs, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getToken } from "Authentication/token";
import fetch from "isomorphic-fetch";
import qs from "qs";

import config from "config";

// Circular reference would trigger if we load Root state from store/store
type RootState = {
  organisation?: {
    currentOrganisation?: {
      id: string;
    };
  };
} | void;

/**
 * Slighty poll for the active org to be populated, to avoid any race conditions
 */
const waitForOrganisation = async function (getState: () => unknown) {
  for (let i = 0; i < 10; i++) {
    const state = (getState() as RootState)?.organisation;
    if (state?.currentOrganisation) {
      return state?.currentOrganisation.id;
    }
    await new Promise((r) => setTimeout(r, 200));
  }

  return null;
};

const fetcher = fetchBaseQuery({
  fetchFn: fetch,
  baseUrl: config.api.endpoint,
  async prepareHeaders(headers) {
    headers.append("X-MIN-CAMEL-CASE", "true");
    const [, token] = await getToken();
    if (token) {
      headers.append("Authorization", `Bearer ${token}`);
    }
    return headers;
  },
  paramsSerializer: (params) =>
    qs.stringify(params, {
      encodeValuesOnly: true,
      arrayFormat: "repeat",
    }),
});

// Manipulate fetcher a little bit more to support intrinsic URL params
export const baseQuery: typeof fetcher = async (
  urlOrOptions: string | FetchArgs,
  api,
  extra
) => {
  let url = typeof urlOrOptions === "string" ? urlOrOptions : urlOrOptions.url;
  const args = typeof urlOrOptions === "string" ? {} : urlOrOptions;

  // Swap out :org variable in URL with the current org
  if (/:org(\/|$)/.test(url)) {
    const org = await waitForOrganisation(api.getState);
    if (org) {
      url = url.split(":org").join(org);
    }
  }

  // Append / to all endpoints (due to 301 redirect setup)
  if (!/\/$/.test(url)) {
    url = url + "/";
  }

  return await fetcher(
    {
      ...args,
      url: url,
    },
    api,
    extra
  );
};
