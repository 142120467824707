import { createAsyncThunk } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";

import { SnackNotification, actions } from "./slice";

const DEFAULT_TIMEOUT = 5000;
export const addNotification = createAsyncThunk(
  "snackbar/add",
  async (
    notification: Omit<SnackNotification, "id"> & { timeout?: number },
    thunkAPI
  ) => {
    const newNotification: SnackNotification = {
      id: uuidv4(),
      ...notification,
    };
    thunkAPI.dispatch(actions.addNotification(newNotification));

    await new Promise((res) => {
      setTimeout(res, notification.timeout || DEFAULT_TIMEOUT);
    });

    thunkAPI.dispatch(actions.removeNotification(newNotification.id));
  }
);
