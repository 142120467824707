import styled from "styled-components";
import cssVar from "theme/vars";
import { Spinner } from "./index";
import { H4M, H4R } from "typography/headers";
import { Close, Delete, Download } from "icons";

export interface FileInfoProps {
  loading?: boolean;
  onCancel?: () => void;
  onDelete?: null | (() => void);
  name: string;
  description?: string;
  onClick?: () => void;
  url?: string;
  showDownload?: boolean;
}

const FileInfo: React.FC<FileInfoProps> = ({
  loading,
  name,
  description,
  url,
  onCancel,
  onDelete,
  onClick,
  showDownload,
  children,
}) => {
  const handleCancel = () => {
    if (loading && onCancel) {
      onCancel();
    }
  };

  const handleDelete = () => {
    if (!loading && onDelete) {
      onDelete();
    }
  };

  const handleClick = () => {
    if (onClick) {
      onClick();
    } else if (url) {
      window.open(url);
    }
  };

  return (
    <Wrapper>
      {loading && <Spinner />}
      <FileDetails>
        <FileName onClick={handleClick}> {name}</FileName>
        {description && <FileDescription> {description}</FileDescription>}
      </FileDetails>
      {loading && onCancel ? (
        <ActionIcon as={Close} size={20} onClick={handleCancel} />
      ) : (
        children || (
          <>
            {onDelete && (
              <ActionIcon
                data-testid="delete-file"
                as={Delete}
                size={20}
                onClick={handleDelete}
              />
            )}
            {showDownload && (
              <ActionIcon
                data-testid="download-file"
                as={Download}
                size={20}
                onClick={handleClick}
              />
            )}
          </>
        )
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 16px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: row;
  gap: 16px;
  background-color: ${cssVar("color/gray/5")};
  border: 1px solid ${cssVar("color/gray/30")};
  border-radius: 12px;
`;

const FileDetails = styled.div`
  flex-grow: 1;
  margin-top: -4px;
  width: fit-content;
`;

const FileName = styled(H4M)`
  margin: 0;
  cursor: pointer;
`;

const FileDescription = styled(H4R)`
  color: ${cssVar(`color/gray/60`)};
`;

const ActionIcon = styled.div`
  color: ${cssVar("color/primary/blue")};
  cursor: pointer;
`;

export default FileInfo;
