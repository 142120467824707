import { GroupedOptionProps } from "ui/forms/Filter";

import { GetEmissionTags } from "../Emissions/payloads";

export function tagsToOptions(
  tags: GetEmissionTags
): GroupedOptionProps["groups"] {
  return Array.from(Object.entries(tags)).map(([group, tags]) => ({
    label: group,
    options: tags.map((tag) => ({
      value: JSON.stringify({ [group]: tag }),
      label: tag,
    })),
  }));
}
