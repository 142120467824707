import { TriangleArrow } from "icons";
import { rem } from "polished";
import React, { useCallback, useMemo, useRef, useState } from "react";
import styled, { css } from "styled-components";
import cssVar from "theme/vars";
import { H4M } from "typography/headers";

import * as Icons from "icons";
import { useElementBox } from "../../hooks";
import Menu, { GroupedOptionProps, StandardOptionProps } from "./Menu";

type IconName = keyof typeof Icons;
type CommonProps = {
  /**
   * Copy to display
   */
  label: string;
  /**
   * For re-styling
   */
  className?: string;
  /**
   * Display a count of active items as a badge
   */
  count?: number;
  /**
   * Optionally hide the expand arrow
   */
  showExpandArrow?: boolean;
  /**
   * Disable all interactions
   */
  disabled?: boolean;
  /**
   * Apply additional styles to indicate that the field is currently having an
   * effect on the current view
   */
  selected?: boolean;
  /**
   * Optional icon to display on the left-side of the chip
   */
  icon?: IconName;
  /**
   * Enable text search in filter list
   */
  searchable?: boolean;
  /**
   * Copy to display in filter list
   */
  title?: string;
  /**
   * Copy to display in filter list
   */
  subtitle?: string;
  /**
   * Show an Apply button in the menu to allow the user to pick options without
   * immediate effect
   */
  showApply?: boolean;
  /**
   * Show a reset button that allows the user to remove all filter options
   */
  showReset?: boolean;
  items: StandardOptionProps | GroupedOptionProps;
  /**
   * Position of dropdown menu
   */
  anchor?: "left" | "right";
};

type SingleValueCallback = (value: string) => void;
type SingleValueProps = CommonProps & {
  /**
   * Accept multiple selections?
   */
  multi?: false;
  /**
   * Current value
   */
  value?: string;
  /**
   * Change handler
   */
  onChange?: SingleValueCallback;
};

type MultiValueCallback = (value: string[]) => void;
type MultiValueProps = CommonProps & {
  multi: true;
  value?: string[];
  onChange?: MultiValueCallback;
};

export type FilterProps = SingleValueProps | MultiValueProps;

const Filter: React.FC<FilterProps> = ({
  label,
  className,
  count,
  showExpandArrow = true,
  disabled = false,
  selected = false,
  icon,
  multi = false,
  value,
  anchor = "left",
  onChange,
  ...menuProps
}) => {
  const [open, setOpen] = useState(false);
  const onToggle = useCallback(() => {
    if (disabled) {
      return;
    }
    setOpen((o) => !o);
  }, [disabled]);

  const onClose = useCallback(() => {
    setOpen(false);
  }, []);

  const onApply = useCallback(
    (values: string[]) => {
      if (multi) {
        (onChange as MultiValueCallback)(values);
      } else {
        (onChange as SingleValueCallback)(values[0] ?? "");
      }
    },
    [multi, onChange]
  );

  const chip = useRef<HTMLButtonElement>(null);
  const isOpen = open && !disabled;
  const position = useElementBox(chip, isOpen);

  // Keep value always an array to simplify change logic in <Menu />
  const normalisedValue = useMemo(
    () => (Array.isArray(value) ? value : value ? [value] : []),
    [value]
  );

  return (
    <>
      <Chip
        className={className}
        $disabled={disabled}
        $selected={selected}
        $open={isOpen}
        onClick={onToggle}
        ref={chip}
        aria-label={label}
      >
        {icon && <Icon as={Icons[icon]} />}
        <Label>{label}</Label>
        {count !== void 0 && <Badge>{count}</Badge>}
        {showExpandArrow && <Chevron $open={isOpen} />}
      </Chip>
      <Menu
        onClose={onClose}
        top={position?.bottom ?? 0}
        left={position?.left ?? 0}
        right={position?.right ?? 0}
        anchor={anchor}
        open={isOpen}
        multi={multi}
        value={normalisedValue}
        onApply={onApply}
        parent={chip}
        {...menuProps}
      />
    </>
  );
};

const Icon = styled.svg`
  width: 16px;
  height: 16px;
  color: var(--icon, ${cssVar("color/primary/darkGrey")});
`;
const Chevron = styled(TriangleArrow).attrs({ "aria-hidden": true })<{
  $open: boolean;
}>`
  display: block;
  width: 20px;
  height: 20px;
  color: ${cssVar("color/gray/100")};
  transform: rotate(${(props) => (props.$open ? 180 : 0)}deg);
  transition: transform 0.2s;
`;
const Badge = styled.span`
  display: grid;
  place-content: center;
  border-radius: 7px;
  background: var(--badge, ${cssVar("color/primary/blue")});
  color: ${cssVar("color/primary/white")};
  font-size: ${rem(11)};
  line-height: ${rem(11)};
  font-weight: 500;
  height: 14px;
  text-align: center;
  padding: 0 3px;
  min-width: 14px;
  transition: background-color 0.3s;
`;
const Label = styled(H4M).attrs({ as: "span" })`
  color: var(--text, ${cssVar("color/gray/100")});
  transition: color 0.3s;
`;

const Chip = styled.button.attrs({ type: "button" })<{
  $disabled: boolean;
  $selected: boolean;
  $open: boolean;
}>`
  display: inline-flex;
  align-items: center;
  background: var(--background, ${cssVar("color/primary/white")});
  border: 1px solid var(--border, ${cssVar("color/primary/disabledGrey")});
  border-radius: 20px;
  padding: 6px 12px;
  gap: 6px;

  transition: border-color 0.3s, background-color 0.3s;
  cursor: pointer;

  &:hover {
    --border: ${cssVar("color/gray/40")};
    --background: ${cssVar("color/gray/10")};
  }

  ${(props) =>
    props.$open &&
    css`
      --border: ${cssVar("color/gray/50")};
      --background: ${cssVar("color/gray/20")};
    `}

  ${(props) =>
    props.$selected &&
    css`
      &,
      &:hover,
      &:active {
        --border: ${cssVar("color/gray/100")};
        --background: ${cssVar("color/primary/lightBlue")};
      }
      &:active {
        --border: ${cssVar("color/gray/80")};
        --background: ${cssVar("color/primary/riverBlue")};
      }
    `}

  ${(props) =>
    props.$disabled &&
    css`
      --border: ${cssVar("color/primary/disabledGrey")} !important;
      --background: ${cssVar("color/primary/silverGrey")} !important;
      --badge: ${cssVar("color/primary/disabledGrey")} !important;
      --text: ${cssVar("color/primary/disabledGrey")} !important;
      --icon: ${cssVar("color/primary/disabledGrey")} !important;
      cursor: not-allowed;
    `}
`;

export default Filter;
