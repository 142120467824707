import React from "react";

import { Filter } from "ui/forms";

import { useReportingYearsQuery } from "API";

import useFilterParam from "./useFilterParam";

const MultiReportingYear: React.FC = () => {
  const {
    data = [],
    isSuccess,
    isError,
  } = useReportingYearsQuery(void 0, {
    selectFromResult: (result) => ({
      ...result,
      data: result.data?.map((item) => ({
        value: item.id,
        label: item.label ?? "Unknown",
        startDate: item.startDate ?? "Unknown",
        endDate: item.endDate ?? "Unknown",
      })),
    }),
  });
  const [selected, setSelected] = useFilterParam("reporting_years", data, {
    defaultToFirst: false,
    multi: true,
    ready: isSuccess || isError,
  });
  return (
    <Filter
      items={{ options: data }}
      title="Reporting year"
      label="Reporting year"
      selected={!!selected.length}
      count={selected.length ? selected.length : void 0}
      onChange={setSelected}
      disabled={!isSuccess || !selected}
      value={selected}
      multi={true}
      showReset={true}
      showApply={true}
    />
  );
};

export default MultiReportingYear;
