import { SVGProps } from "react";
interface SvgPlaceholderProps extends SVGProps<SVGSVGElement> {
  /**
   * Width of icon, uses size if not given
   */
  width?: number;
  /**
   * Height of icon, uses size if not given
   */
  height?: number;
  /**
   * Size of icon, applies to width & height if not supplied
   * @default 16
   */
  size?: number;
}
export const SvgPlaceholder = (props: SvgPlaceholderProps) => (
  <svg
    {...props}
    width={props.width ?? props.size ?? 16}
    height={props.height ?? props.size ?? 16}
    viewBox="0 0 16 16"
    fill="none"
  >
    <circle cx="8" cy="8" r="6" fill="#011009" />
  </svg>
);
export default SvgPlaceholder;
