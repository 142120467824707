import { css } from "styled-components";
import cssVar from "./vars";

export const scrollbars = () => css`
  scrollbar-width: thin;
  // Using a nested template string as there was intellisense weirdness otherwise
  scrollbar-color: ${`${cssVar("color/grayModern/obsidian")} ${cssVar(
    "color/gray/10"
  )}`};

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background: ${cssVar("color/gray/30")};
    border-radius: 3px;
  }

  &::-webkit-scrollbar-thumb {
    width: 6px;
    height: 6px;
    background: ${cssVar("color/grayModern/obsidian")};
    border-radius: 3px;
  }
`;
