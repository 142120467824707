import {
  SubmissionSlotData,
  SubmissionSlotDetails,
  SubmissionSlots,
} from "types/SubmissionSlots";

import api from "API";

import * as Params from "../../params";
import {
  GetSubmissionSlotParams,
  GetSubmissionSlots,
  RequestToReopenParams,
  UpdateSubmissionSlotDataParams,
} from "./payloads";

const SubmissionGroupsEndpoint = "/organisations/:org/submission-groups/";

const extendedEndpoints = api.injectEndpoints({
  endpoints: (builder) => ({
    submissionSlots: builder.query<
      GetSubmissionSlots,
      Params.GenericParamMap<GetSubmissionSlotParams>
    >({
      query: (query) => {
        return {
          url:
            SubmissionGroupsEndpoint +
            `${query.submission_group_id}` +
            "/submission-slots",
          method: "GET",
          params: {
            page: query.page,
            ordering: Params.toString(query.ordering),
            time_period: Params.toArray(query.timePeriod),
            status: Params.toArray(query.submission_slots_status),
          },
        };
      },
    }),
    submissionSlotDetails: builder.query<
      SubmissionSlotDetails,
      Params.GenericParamMap
    >({
      query: (query) => ({
        url:
          SubmissionGroupsEndpoint +
          `${query.submissionGroupId}` +
          "/submission-slots" +
          `/${query.slotId}`,
        method: "GET",
      }),
      keepUnusedDataFor: 0, //disable caching for this query
    }),
    actionRequiredSlot: builder.query<
      SubmissionSlots[],
      Params.GenericParamMap<GetSubmissionSlotParams>
    >({
      query: (query) => {
        return {
          url:
            SubmissionGroupsEndpoint +
            `${query.submission_group_id}` +
            "/submission-slots/action-required",
          method: "GET",
        };
      },
    }),
    updateSubmissionSlotData: builder.mutation<
      SubmissionSlotData,
      UpdateSubmissionSlotDataParams
    >({
      query: ({ groupId, slotId, ...params }) => ({
        url:
          SubmissionGroupsEndpoint +
          `${groupId}` +
          "/submission-slots" +
          `/${slotId}` +
          "/submit",
        method: "POST",
        body: params,
      }),
    }),
    requestToReopen: builder.mutation<
      SubmissionSlotData,
      RequestToReopenParams
    >({
      query: ({ groupId, slotId }) => ({
        url:
          SubmissionGroupsEndpoint +
          `${groupId}` +
          "/submission-slots" +
          `/${slotId}` +
          "/request-reopen",
        method: "POST",
      }),
    }),
  }),
});

export const {
  useSubmissionSlotsQuery,
  useSubmissionSlotDetailsQuery,
  useActionRequiredSlotQuery,
  useUpdateSubmissionSlotDataMutation,
  useRequestToReopenMutation,
} = extendedEndpoints;
