import * as React from "react";

type FeatureGroup = {
  [featureName: string]: boolean | FeatureGroup;
};

export type FeatureFlags = string[] | FeatureGroup;

const FeatureFlagsContext = React.createContext<FeatureGroup>({});

function transformFlags(features: FeatureFlags): FeatureGroup {
  if (!Array.isArray(features)) {
    return features;
  }
  return Object.fromEntries(
    features.map((feature) => [feature, true])
  ) as FeatureGroup;
}

function mergeFeatures(a: FeatureGroup, b: FeatureGroup): FeatureGroup {
  return { ...a, ...b };
}

export function UIFlagsProvider({
  features = {},
  children,
}: {
  features?: FeatureFlags;
  children: React.ReactNode;
}) {
  const currentFeatures = useUIFeatures();
  return (
    <FeatureFlagsContext.Provider
      value={mergeFeatures(
        transformFlags(currentFeatures),
        transformFlags(features)
      )}
    >
      {children}
    </FeatureFlagsContext.Provider>
  );
}

export function useUIFeatures(): FeatureGroup {
  return React.useContext(FeatureFlagsContext);
}

export function useUIFeature(name: string): boolean | FeatureGroup {
  const features = useUIFeatures();

  const currentFeatures: boolean | FeatureGroup = features;
  return currentFeatures[name] as FeatureGroup;
}
