import { Suspense, useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";

import { Navigation as HubNavigation } from "Hub/components/Navigation";

import { userSelectors } from "store/user/userSelectors";
import { userThunks } from "store/user/userThunks";

import { useAppDispatch, useAppSelector } from "utilities/hooks/general";
import urls from "utilities/urls";

import Navigation from "Layout/Navigation";
import PageLoader from "Layout/PageLoader";

let checking = false;
const Guard: React.FC<{ withNav?: boolean; withHubNav?: boolean }> = ({
  withNav = true,
  withHubNav = false,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const userID = useAppSelector(userSelectors.userId);

  useEffect(() => {
    if (!userID && !checking) {
      checking = true;
      dispatch(userThunks.fetchUserDetails())
        .unwrap()
        .then(
          () => {
            checking = false;
          },
          () => {
            checking = false;
            navigate(urls.login());
          }
        );
    }
  }, [dispatch, navigate, userID]);

  return userID ? (
    <>
      {withNav && <Navigation />}
      {withHubNav && <HubNavigation />}
      <Suspense fallback={<PageLoader />}>
        <Outlet />
      </Suspense>
    </>
  ) : (
    <PageLoader />
  );
};

export default Guard;
