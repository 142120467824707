import ReactDOM from "react-dom";
import { useSelector } from "react-redux";

import styled from "styled-components";

import { useRootElement } from "ui/hooks";

import Snack from "./Snack";
import { selectors } from "./slice";

const Snackbar = () => {
  const notifications = useSelector(selectors.notifications);
  const portalTarget = useRootElement("snacks");

  return ReactDOM.createPortal(
    <Wrapper>
      {notifications.map((notification) => (
        <Snack key={notification.id} notification={notification} />
      ))}
    </Wrapper>,
    portalTarget
  );
};

const Wrapper = styled.div`
  isolation: isolate;
  z-index: 100;
  position: fixed;
  inset: 64px 35px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
  pointer-events: none;
`;

export default Snackbar;
