import { rem } from "polished";
import styled from "styled-components";
import cssVar from "theme/vars";

const DL = styled.dl`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 8px 0;
`;

const Term = styled.dt`
  margin: 0;
  padding: 16px;
  font-size: ${rem(14)};
  line-height: ${rem(24)};
  font-weight: 400;
  background: ${cssVar("color/primary/superSilverGrey")};
  color: ${cssVar("color/gray/60")};
`;

const Desc = styled(Term).attrs({ as: "dd" })`
  color: ${cssVar("color/gray/100")};
  text-align: right;
  font-weight: 600;
`;

export { Desc, Term };
export default DL;
