import React from "react";

import styled from "styled-components";
import cssVar from "theme/vars";
import { Alert, Spinner } from "ui/core";

export interface ResponseProps {
  loading?: boolean;
  message: string;
  isError?: boolean;
}

const Response: React.FC<ResponseProps> = ({
  message,
  loading,
  isError,
  children,
}) => {
  return (
    <ResponseWrapper>
      {!!message && (
        <Alert variant={isError ? "error" : "shiny"}>{message}</Alert>
      )}
      {children}
      {loading && <Loader />}
    </ResponseWrapper>
  );
};

const Loader = styled(Spinner)`
  color: ${cssVar("color/primary/blue")};
  width: 64px;
  height: 64px;
`;
const ResponseWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 24px;
`;

export default Response;
