export type emissionOutputType = "market" | "location";

export enum FeatureFlag {
  HUB_VIEW = "HUB_VIEW",
  ATLAS_VIEW = "ATLAS_VIEW",
  DATA_TRACKER_VIEW = "DATA_TRACKER_VIEW",
  SUBMISSION_PROGRESS_VIEW = "SUBMISSION_PROGRESS_VIEW",
  EXPLO_DASHBOARDS_VIEW = "EXPLO_DASHBOARDS_VIEW", // to be deprecated
  BESPOKE_EXPLO_DASHBOARDS_VIEW = "BESPOKE_EXPLO_DASHBOARDS_VIEW",
  STANDARD_EXPLO_DASHBOARDS_VIEW = "STANDARD_EXPLO_DASHBOARDS_VIEW",
  SUBMISSIONS_VIEW = "SUBMISSIONS_VIEW",
  LEGACY_SUBMISSIONS_VIEW = "LEGACY_SUBMISSIONS_VIEW",
  REQUEST_REPORT_VIEW = "REQUEST_REPORT_VIEW",
  EMISSIONS_VIEW = "EMISSIONS_VIEW",
  ACTIVITY_DATA_VIEW = "ACTIVITY_DATA_VIEW",
  TRANSACTIONS_VIEW = "TRANSACTIONS_VIEW",
  EMBEDDED_REPORTS = "EMBEDDED_REPORTS",
  METHODOLOGIES_LIST_VIEW = "METHODOLOGIES_LIST_VIEW",
  SHOW_HTML_METHODOLOGY_DESCRIPTIONS = "SHOW_HTML_METHODOLOGY_DESCRIPTIONS",
  OUTPUT_API_ACCESS = "OUTPUT_API_ACCESS",
  DATA_COLLECTION_VIEW = "DATA_COLLECTION_VIEW",
  DOCUMENTS_VIEW = "DOCUMENTS_VIEW",
}

export interface Entity {
  id: string;
  name: string;
  role: string;
  featureFlags: Partial<Record<FeatureFlag, boolean>>;
  defaultEmissionOutputType?: emissionOutputType;
  minimumLogoUrl: string;
  outputApiClientId: string;
  outputApiSecretShown: boolean | null;
  scope3Visible: boolean;
}
