import { SVGProps } from "react";
interface SvgMoreProps extends SVGProps<SVGSVGElement> {
  /**
   * Width of icon, uses size if not given
   */
  width?: number;
  /**
   * Height of icon, uses size if not given
   */
  height?: number;
  /**
   * Size of icon, applies to width & height if not supplied
   * @default 24
   */
  size?: number;
}
export const SvgMore = (props: SvgMoreProps) => (
  <svg
    {...props}
    width={props.width ?? props.size ?? 17}
    height={props.height ?? props.size ?? 16}
    viewBox="0 0 17 16"
    fill="currentColor"
  >
    <g id="More">
      <mask
        id="mask0_2358_11524"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width={props.width ?? props.size ?? 17}
        height={props.height ?? props.size ?? 16}
      >
        <rect
          id="Bounding box"
          x="0.5"
          width={props.width ?? props.size ?? 17}
          height={props.height ?? props.size ?? 16}
          fill="#D9D9D9"
        />
      </mask>
      <g mask="url(#mask0_2358_11524)">
        <path
          id="more_vert"
          d="M8.49984 13.3333C8.13317 13.3333 7.81939 13.2028 7.5585 12.942C7.29717 12.6806 7.1665 12.3666 7.1665 12C7.1665 11.6333 7.29717 11.3193 7.5585 11.058C7.81939 10.7971 8.13317 10.6666 8.49984 10.6666C8.8665 10.6666 9.1805 10.7971 9.44184 11.058C9.70273 11.3193 9.83317 11.6333 9.83317 12C9.83317 12.3666 9.70273 12.6806 9.44184 12.942C9.1805 13.2028 8.8665 13.3333 8.49984 13.3333ZM8.49984 9.33329C8.13317 9.33329 7.81939 9.20263 7.5585 8.94129C7.29717 8.6804 7.1665 8.36663 7.1665 7.99996C7.1665 7.63329 7.29717 7.31929 7.5585 7.05796C7.81939 6.79707 8.13317 6.66663 8.49984 6.66663C8.8665 6.66663 9.1805 6.79707 9.44184 7.05796C9.70273 7.31929 9.83317 7.63329 9.83317 7.99996C9.83317 8.36663 9.70273 8.6804 9.44184 8.94129C9.1805 9.20263 8.8665 9.33329 8.49984 9.33329ZM8.49984 5.33329C8.13317 5.33329 7.81939 5.20263 7.5585 4.94129C7.29717 4.6804 7.1665 4.36663 7.1665 3.99996C7.1665 3.63329 7.29717 3.31951 7.5585 3.05863C7.81939 2.79729 8.13317 2.66663 8.49984 2.66663C8.8665 2.66663 9.1805 2.79729 9.44184 3.05863C9.70273 3.31951 9.83317 3.63329 9.83317 3.99996C9.83317 4.36663 9.70273 4.6804 9.44184 4.94129C9.1805 5.20263 8.8665 5.33329 8.49984 5.33329Z"
          fill="#1961E7"
        />
      </g>
    </g>
  </svg>
);
export default SvgMore;
