import React, { useCallback, useState, CSSProperties, useEffect } from "react";
import { createPortal } from "react-dom";
import ReactDatePicker from "react-datepicker";
import styled from "styled-components";
import { useRootElement } from "ui/hooks";
import DatePickerStyles from "./Styles";
import Header from "./Header";
import { MenuWrapper } from "../Filter/Menu";
import { Button } from "ui/core";
import { scrollbars } from "theme/mixins";

export interface MenuProps {
  open: boolean;
  onClose: () => void;
  onApply: (dates: [Date | null, Date | null]) => void;
  top: number;
  left: number;
  right: number;
  rightAligned: boolean;
  startDate: Date | null | undefined;
  endDate: Date | null | undefined;
}

const Menu: React.FC<MenuProps> = ({
  open,
  onClose,
  onApply,
  top,
  left,
  right,
  rightAligned = false,
  startDate = null,
  endDate = null,
}) => {
  const portal = useRootElement("date-picker");

  const [startValue, setStartValue] = useState<Date | null>(startDate);
  const [endValue, setEndValue] = useState<Date | null>(endDate);
  const [datesChanged, setDatesChanged] = useState(false);

  // Keep our state in-sync with provided value
  useEffect(() => {
    setStartValue(startDate);
    setEndValue(endDate);
    setDatesChanged(false);
  }, [startDate, endDate]);

  const handleChange = useCallback((dates: [Date | null, Date | null]) => {
    setStartValue(dates[0]);
    setEndValue(dates[1]);
    if (dates[0] && dates[1]) {
      setDatesChanged(true);
    }
  }, []);

  const onCancel = useCallback(() => {
    onApply([null, null]);
    setStartValue(null);
    setEndValue(null);
    setDatesChanged(false);
    onClose();
  }, [onApply, onClose]);

  const handleApply = useCallback(() => {
    onApply([startValue, endValue]);
    onClose();
  }, [onApply, startValue, endValue, onClose]);

  return createPortal(
    <>
      {open && <Backdrop onClick={onClose} />}
      <DatePicker
        $open={open}
        $right={rightAligned}
        $showReset={false}
        $showApply={false}
        role="dialog"
        style={
          {
            "--top": `${top}px`,
            "--left": `${left}px`,
            "--right": `${right}px`,
          } as CSSProperties
        }
      >
        <DatePickerStyles />
        <ReactDatePicker
          onChange={handleChange}
          selectsRange={true}
          monthsShown={2}
          inline={true}
          startDate={startValue}
          endDate={endValue}
          calendarStartDay={1}
          fixedHeight={true}
          renderCustomHeader={Header}
        />
        <ButtonContainer>
          <DatePickerButton
            size="large"
            variant="outlined-primary"
            onClick={onCancel}
            disabled={!startValue && !endValue}
          >
            Reset
          </DatePickerButton>
          <DatePickerButton
            size="large"
            variant="primary"
            disabled={!datesChanged}
            onClick={handleApply}
          >
            Apply
          </DatePickerButton>
        </ButtonContainer>
      </DatePicker>
    </>,
    portal
  );
};

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 100;
`;

const DatePicker = styled(MenuWrapper)`
  width: 656px;
  padding: 0;
  overflow-y: auto;
  ${scrollbars()}
`;

const ButtonContainer = styled.div`
  margin: 16px 16px 16px auto;
`;

const DatePickerButton = styled(Button)`
  margin-left: 16px;
`;

export default Menu;
