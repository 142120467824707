import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import * as Icons from "icons";

export type SnackVariant = `success` | `info` | `warning` | `error`;

export type SnackNotification = {
  id: string;
  message: string;
  headerMessage?: string;
  icon?: keyof typeof Icons;
  variant?: SnackVariant;
};

export type State = {
  notifications: SnackNotification[];
};

const initialState: State = {
  notifications: [],
};

const slice = createSlice({
  name: "snackbar",
  initialState,
  reducers: {
    addNotification: (state, action: PayloadAction<SnackNotification>) => {
      state.notifications.push(action.payload);
    },
    removeNotification: (state, action: PayloadAction<string>) => {
      state.notifications = state.notifications.filter(
        (notification) => notification.id !== action.payload
      );
    },
  },
});

export const selectors = {
  notifications: (state: { [slice.name]: State }) =>
    state.snackbar.notifications,
};

export const { reducer, actions } = slice;
export { slice };
