import { useCallback, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { useAuthClient, useLogout } from "Authentication/react";
import { Button, Modal } from "ui/core";

import urls from "utilities/urls";

const SESSION_TIMEOUT = 2 * 60 * 60 * 1000; // 2 hours in milliseconds
const MODAL_SHOW_TIME = 90 * 60 * 1000; // 90 minutes in milliseconds

const TimeoutProvider = ({ children }: { children: any }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const client = useAuthClient();
  const logout = useLogout();
  const timeoutRef = useRef<NodeJS.Timeout>();
  const sessionTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const [showInActiveModal, setShowInActiveModal] = useState<boolean>(false);

  const handleLogout = useCallback(() => {
    setShowInActiveModal(false);
    async function run() {
      await logout();
      navigate(urls.login());
    }

    void run();
  }, [navigate, logout]);

  const handleContinue = () => {
    setShowInActiveModal(false);
    resetTimers();
  };

  const clearExistingTimers = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    if (sessionTimeoutRef.current) {
      clearTimeout(sessionTimeoutRef.current);
    }
  };

  const resetTimers = () => {
    const isAuthenticated = client?.isAuthenticated();
    clearExistingTimers();
    if (isAuthenticated) {
      // Set new idle time modal timeout
      timeoutRef.current = setTimeout(() => {
        setShowInActiveModal(true);

        // Set session timeout after modal is displayed
        sessionTimeoutRef.current = setTimeout(() => {
          handleLogout();
        }, SESSION_TIMEOUT - MODAL_SHOW_TIME);
      }, MODAL_SHOW_TIME);
    }
  };

  useEffect(() => {
    const handleWindowEvents = () => {
      resetTimers();
    };

    // listen for specific window events to ensure user is still active
    window.addEventListener("mousemove", handleWindowEvents);
    window.addEventListener("keydown", handleWindowEvents);
    window.addEventListener("click", handleWindowEvents);
    window.addEventListener("scroll", handleWindowEvents);

    resetTimers();

    //cleanup function
    return () => {
      window.removeEventListener("mousemove", handleWindowEvents);
      window.removeEventListener("keydown", handleWindowEvents);
      window.removeEventListener("click", handleWindowEvents);
      window.removeEventListener("scroll", handleWindowEvents);

      clearExistingTimers();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, location.pathname]);

  return (
    <>
      {children}
      {showInActiveModal && (
        <Modal.Window
          open={showInActiveModal}
          onClose={() => setShowInActiveModal(false)}
          closeOnClickOutside={false}
        >
          <Modal.Header title="Session timeout" noBorder />
          <Modal.Body>
            Your session will expire in 30 minutes due to inactivity. To keep
            your session active, please click “Continue Session”. If no action
            is taken, you will be logged out automatically.
          </Modal.Body>
          <Modal.FooterWithActions aligned="right">
            <Button variant="text-primary-underline" onClick={handleLogout}>
              Log out now
            </Button>
            <Button onClick={handleContinue}>Continue session</Button>
          </Modal.FooterWithActions>
        </Modal.Window>
      )}
    </>
  );
};

export default TimeoutProvider;
