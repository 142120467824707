import React from "react";
import styled from "styled-components";
import cssVar from "theme/vars";
import { H4R } from "typography/headers";

const SubHeader: React.FC = ({ children }) => {
  return <SubHeaderWrapper>{children}</SubHeaderWrapper>;
};

const SubHeaderWrapper = styled(H4R)`
  padding-top: 4px;
  color: ${cssVar("color/gray/60")};

  em {
    font-style: normal;
    color: ${cssVar("color/gray/100")};
  }
`;

export default SubHeader;
