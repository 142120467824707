import { SVGProps } from "react";

interface SvgEditProps extends SVGProps<SVGSVGElement> {
  /**
   * Width of icon, uses size if not given
   */
  width?: number;
  /**
   * Height of icon, uses size if not given
   */
  height?: number;
  /**
   * Size of icon, applies to width & height if not supplied
   * @default 13
   */
  size?: number;
}
export const SvgEdit = (props: SvgEditProps) => (
  <svg
    {...props}
    width={props.width ?? props.size ?? 13}
    height={props.height ?? props.size ?? 13}
    viewBox="0 0 13 13"
    fill="currentColor"
  >
    <path
      d="M1.33333 11.6667H2.26667L8.01667 5.91671L7.08333 4.98337L1.33333 10.7334V11.6667ZM10.8667 4.95004L8.03333 2.15004L8.96667 1.21671C9.22222 0.961152 9.53622 0.833374 9.90867 0.833374C10.2807 0.833374 10.5944 0.961152 10.85 1.21671L11.7833 2.15004C12.0389 2.4056 12.1722 2.71404 12.1833 3.07537C12.1944 3.43626 12.0722 3.74449 11.8167 4.00004L10.8667 4.95004ZM0.666667 13C0.477778 13 0.319556 12.936 0.192 12.808C0.0640001 12.6805 0 12.5223 0 12.3334V10.45C0 10.3612 0.0166667 10.2752 0.05 10.192C0.0833334 10.1085 0.133333 10.0334 0.2 9.96671L7.06667 3.10004L9.9 5.93337L3.03333 12.8C2.96667 12.8667 2.89178 12.9167 2.80867 12.95C2.72511 12.9834 2.63889 13 2.55 13H0.666667Z"
      fill="#B54708"
    />
  </svg>
);
export default SvgEdit;
