const Typography = {
  family: {
    regular: "InterVariable",
  },
  legacy: {
    xxl: {
      size: "3rem",
      lineHeight: "125%",
      letterSpacing: "0.03rem",
    },
    xl: {
      size: "2.375rem",
      lineHeight: "130%",
      letterSpacing: "0.03rem",
    },
    l: {
      size: "2rem",
      lineHeight: "140%",
      letterSpacing: "0.03rem",
    },
    mll: {
      size: "1.5rem",
      lineHeight: "140%",
      letterSpacing: "0.03rem",
    },
    ml: {
      size: "1.25rem",
      lineHeight: "140%",
      letterSpacing: "0.03rem",
    },
    m: {
      size: "1rem",
      lineHeight: "165%",
      letterSpacing: "0.03rem",
    },
    ms: {
      size: "0.875rem",
      lineHeight: "175%",
      letterSpacing: "0.03rem",
    },
    s: {
      size: "0.75rem",
      lineHeight: "165%",
      letterSpacing: "0.03rem",
    },
    xs: {
      size: "0.625rem",
      lineHeight: "170%",
      letterSpacing: "0.03rem",
    },
  },
};

import { extract, Leaves } from "./utilities";

export type TypographyKey = Leaves<typeof Typography>;
export default function getTextStyle(key: TypographyKey): string {
  return extract(Typography, key);
}

export { Typography };
