import { Auth0Client } from "@auth0/auth0-spa-js";

import config from "config";

// TODO: Generate this within the react application flow, rather than statically.
const client = new Auth0Client({
  domain: config.auth0.domain,
  client_id: config.auth0.clientId,
  redirect_uri: config.auth0.redirectUrl,
  useRefreshTokens: true,
  scope: "offline_access",
  cacheLocation: "localstorage",
});

export default client;
