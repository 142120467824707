import {
  LegacySubmissionSlot,
  LegacySubmissionSlotData,
} from "types/LegacySubmissionSlot";

import api from "API";

import {
  LegacySubmissionSlotEntity,
  UpdateLegacySubmissionSlotParams,
} from "./payloads";

const extendedEndpoints = api.injectEndpoints({
  endpoints: (builder) => ({
    legacySubmissionSlot: builder.query<LegacySubmissionSlot, string>({
      query: (slotId) => ({
        url: `/organisations/:org/legacy-submission-slots/${slotId}`,
      }),
      providesTags: (result) => [{ type: "Submission", id: result?.id }],
    }),
    resetLegacySubmissionSlotData: builder.mutation<
      LegacySubmissionSlotData,
      string
    >({
      query: (id) => ({
        url: `/organisations/:org/legacy-submission-slots/${id}/reset-submission/`,
        method: "DELETE",
      }),
      invalidatesTags: (_result, _error, id) => [
        { type: "SubmissionData", id },
        { type: "Submission", Id: "LIST" },
      ],
    }),
    updateLegacySubmissionSlotData: builder.mutation<
      LegacySubmissionSlotData,
      UpdateLegacySubmissionSlotParams & { id: string }
    >({
      query: ({ id, ...params }) => ({
        url: `/organisations/:org/legacy-submission-slots/${id}/create-submission/`,
        method: "POST",
        body: params,
      }),
      invalidatesTags: (_result, _error, { id }) => [
        { type: "SubmissionData", id },
        { type: "Submission", Id: "LIST" },
      ],
    }),
    legacySubmissionSlotEntities: builder.query<
      LegacySubmissionSlotEntity[],
      string
    >({
      query: (rootEntityId) => ({
        url: `/organisations/:org/entities/`,
        params: {
          root_entity: rootEntityId,
        },
      }),
    }),
    legacySubmissionSlotCSVTemplateExport: builder.query<Blob, string>({
      query: (methodologyId) => ({
        url: `/organisations/:org/methodologies/${methodologyId}/download-csv-template/`,
        responseHandler: (response: Response) => response.blob(),
      }),
    }),
    legacySubmissionSlotClose: builder.mutation<LegacySubmissionSlot, string>({
      query: (slotId) => ({
        url: `/organisations/:org/legacy-submission-slots/${slotId}/close/`,
        method: "POST",
      }),
      invalidatesTags: (_result, _error, id) => [
        { type: "SubmissionData", id },
        { type: "Submission", Id: "LIST" },
      ],
    }),
  }),
});

export const {
  useLazyLegacySubmissionSlotQuery,
  useResetLegacySubmissionSlotDataMutation,
  useUpdateLegacySubmissionSlotDataMutation,
  useLazyLegacySubmissionSlotEntitiesQuery,
  useLazyLegacySubmissionSlotCSVTemplateExportQuery,
  useLegacySubmissionSlotCloseMutation,
} = extendedEndpoints;
