import api from "API";

import * as Params from "../../params";
import * as User from "./payloads";

export const usersEndpoint = `/users`;

const extendedEndpoints = api.injectEndpoints({
  endpoints: (builder) => ({
    userMe: builder.query<User.GetUser, void>({
      query: () => {
        return {
          url: `${usersEndpoint}/me/`,
        };
      },
    }),
    userMeUpdate: builder.mutation<Document, Params.GenericParamMap>({
      query: (preferredUnits) => {
        return {
          url: `${usersEndpoint}/me/`,
          method: "PATCH",
          body: {
            preferredUnits,
          },
        };
      },
    }),
  }),
});

export const { useUserMeQuery, useUserMeUpdateMutation } = extendedEndpoints;
