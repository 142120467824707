import React from "react";

import { Filter } from "ui/forms";
import { Option } from "ui/forms/Filter/Menu";

import useFilterParam from "./useFilterParam";

interface StatusProps {
  options: Option[];
  disabled?: boolean;
}

const Status: React.FC<StatusProps> = ({ options, disabled = false }) => {
  const [selected, setSelected] = useFilterParam("status", options, {
    defaultToFirst: false,
    multi: true,
  });
  return (
    <Filter
      items={{ options }}
      title="Status"
      label="Status"
      selected={!!selected.length}
      count={selected.length ? selected.length : void 0}
      disabled={options.length === 0 || disabled}
      onChange={setSelected}
      value={selected}
      multi={true}
      showReset={true}
      showApply={true}
      searchable={true}
    />
  );
};

export default Status;
