import api from "API";

import * as Params from "../../params";
import * as EntityCustomAttributes from "./payloads";

export const entityCustomAttributesEndpoint = `/organisations/:org/entity-custom-attributes`;

const extendedEndpoints = api.injectEndpoints({
  endpoints: (builder) => ({
    entityCustomAttributes: builder.query<
      EntityCustomAttributes.GetEntityCustomAttributes,
      Params.GenericParamMap
    >({
      query: () => ({
        url: entityCustomAttributesEndpoint,
      }),
    }),
  }),
});

export const { useEntityCustomAttributesQuery } = extendedEndpoints;
