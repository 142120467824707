import { useMemo } from "react";

import { FeatureFlag } from "types/Entity";

export type Navlink = {
  label: string;
  getUrl: () => string;
  parentUrl?: () => string;
  flags?: FeatureFlag[];
  hide?: boolean;
};

export const useNavigationLinks = (
  linkList: Navlink[],
  flags: Partial<Record<FeatureFlag, boolean>>
) => {
  const filteredAndMappedArray = useMemo(() => {
    return linkList
      .filter((item) => {
        return !item.flags || item.flags.some((flag) => flag && flags[flag]);
      })
      .map((link) => {
        return {
          label: link.label,
          url: link.getUrl(),
          parentUrl: link.parentUrl ? link.parentUrl() : undefined,
        };
      });
  }, [linkList, flags]);

  return filteredAndMappedArray;
};
