import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { skipToken } from "@reduxjs/toolkit/dist/query";
import { ChevronLeft } from "icons";
import styled from "styled-components";
import cssVar from "theme/vars";
import { Headers } from "typography";
import { H4M } from "typography/headers";
import { Button } from "ui/core";

import {
  useExternalRegistrationInvitationQuery,
  useExternalRegistrationInviteMutation,
} from "API/Endpoints/ExternalRegistration/queries";

import urls from "utilities/urls";

import Page from "Layout/Page/Auth";
import PageLoader from "Layout/PageLoader";

import AccountCreationErrorMessage from "./AccountCreationErrorMessage";
import RegistrationFormCompanyInfo from "./RegistrationFormCompanyInfo";
import RegistrationFormPersonalInfo from "./RegistrationFormPersonalInfo";

export type RegistrationFormData = {
  email: string;
  password: string;
  company_name: string;
  city: string;
  country: string;
  invitedUsers: string[];
};

const initialFormData: RegistrationFormData = {
  email: "",
  password: "",
  company_name: "",
  city: "",
  country: "United Kingdom",
  invitedUsers: [],
};

export type RegistrationFormGetHandler = (
  param: keyof RegistrationFormData
) => (e: React.ChangeEvent<HTMLInputElement>) => void;

export type AccountCreationError = {
  status?: number;
  data?: {
    [key: string]: string[] | undefined;
  };
};

const ExternalRegistration = () => {
  const navigate = useNavigate();
  const { organisationId, linkId } = useParams();
  const [currentStep, setCurrentStep] = useState<number>(1);
  const [formData, setFormData] =
    useState<RegistrationFormData>(initialFormData);
  const [continueDisabled, setContinueDisabled] = useState<boolean>(true);
  const [createAccountDisabled, setCreateAccountDisabled] =
    useState<boolean>(true);
  const [accountCreationError, setAccountCreationError] =
    useState<AccountCreationError | null>(null);

  const { data: getInvitationResponse, error: inviteLinkError } =
    useExternalRegistrationInvitationQuery(
      organisationId && linkId ? { organisationId, linkId } : skipToken
    );

  useEffect(() => {
    setContinueDisabled(
      !(formData.email.length > 0 && formData.password.length > 0)
    );
  }, [formData.email, formData.password]);

  useEffect(() => {
    setCreateAccountDisabled(
      !!accountCreationError ||
        !(formData.company_name.length > 0 && formData.city.length > 0)
    );
  }, [accountCreationError, formData.company_name, formData.city]);

  const registrationFormOnChangeHandler = useCallback(
    (param: keyof RegistrationFormData, val: string) => {
      setFormData({ ...formData, [param]: val });
    },
    [formData]
  );

  const getOnChangeHandler = useCallback(
    (param: keyof RegistrationFormData) => {
      return (e: React.ChangeEvent<HTMLInputElement>) => {
        registrationFormOnChangeHandler(param, e.target.value);
        setAccountCreationError(null);
      };
    },
    [registrationFormOnChangeHandler]
  );

  const [submitForm, { isLoading }] = useExternalRegistrationInviteMutation();

  const handleSubmit = useCallback(async () => {
    if (organisationId && linkId) {
      try {
        await submitForm({ ...formData, organisationId, linkId }).unwrap();
        navigate(urls.login());
      } catch (error) {
        error && setAccountCreationError(error);
      }
    }
  }, [formData, organisationId, linkId, submitForm, navigate]);

  if (inviteLinkError) {
    return (
      <Page name="Supplier Invitation">
        <Header>Registering to Minimum</Header>
        <ErrorMessage>This link is invalid</ErrorMessage>
      </Page>
    );
  }

  if (!getInvitationResponse) {
    return <PageLoader />;
  }

  return (
    <Page name="Supplier Invitation">
      <Form onSubmit={handleSubmit}>
        <Header>Registering to Minimum</Header>
        {getInvitationResponse?.organisationName && (
          <Message>
            On behalf of {getInvitationResponse?.organisationName}
          </Message>
        )}
        {currentStep === 1 && (
          <>
            <RegistrationFormPersonalInfo
              registrationFormData={formData}
              getOnChangeHandler={getOnChangeHandler}
            />
            <Submit
              disabled={continueDisabled}
              onClick={() => {
                setCurrentStep(2);
              }}
            >
              Continue
            </Submit>
          </>
        )}
        {currentStep === 2 && (
          <>
            <Back variant="text-primary" onClick={() => setCurrentStep(1)}>
              <ChevronLeft size={16} />
              Back
            </Back>
            <RegistrationFormCompanyInfo
              registrationFormData={formData}
              countries={getInvitationResponse.countries}
              getOnChangeHandler={getOnChangeHandler}
              updateInviteEmails={(emails) => {
                setFormData({ ...formData, invitedUsers: emails });
                setAccountCreationError(null);
              }}
              updateCountry={(country) => {
                setFormData({ ...formData, country: country });
                setAccountCreationError(null);
              }}
            />
            {accountCreationError && (
              <AccountCreationErrorMessage
                accountCreationError={accountCreationError}
              />
            )}
            <Submit
              onClick={handleSubmit}
              disabled={createAccountDisabled}
              loading={isLoading}
            >
              Create account
            </Submit>
            <AdditionalInfo>
              To invite additional users after creating your account, please
              contact your Minimum consultant directly.
            </AdditionalInfo>
          </>
        )}
        <Login variant="text-primary" onClick={() => navigate(urls.login())}>
          Log in
        </Login>
      </Form>
    </Page>
  );
};

const Form = styled.form.attrs({
  method: "post",
})`
  height: 100%;
  display: flex;
  flex-direction: column;

  > * {
    width: 100%;
  }
`;

const Header = styled(Headers.H1SB)`
  margin: 0 0 12px;
  text-align: center;
`;

const Message = styled(Headers.H2R)`
  text-align: center;
  margin: 0 0 16px;
  color: ${cssVar("color/gray/60")};
`;

const ErrorMessage = styled(Message)`
  padding-top: 24px;
  color: ${cssVar("color/action/red")};
`;

const Back = styled(Button)`
  width: 20%;
  margin: 0 0 -16px auto;
  --color: ${cssVar("color/primary/grey")};
`;

const Submit = styled(Button).attrs({
  variant: "primary",
  submit: true,
  size: "large",
})`
  margin: 16px 0 0;
`;

export const Login = styled(Button)`
  width: 20%;
  margin: 8px 0 -28px auto;
`;

const AdditionalInfo = styled(H4M)`
  text-align: center;
  margin: 8px 0;
  color: ${cssVar("color/gray/60")};
`;

export default ExternalRegistration;
