import { useCallback } from "react";
import { useSearchParams } from "react-router-dom";

import { SortDirection } from "ui/core/Table/Context";

import { QUERY_PARAM as PAGINATION_QUERY_PARAM } from "Common/Pagination";

export const QUERY_PARAM = "sort";

function useSort(
  allowedFields: string[]
): [
  string | void,
  { field: string; direction: SortDirection },
  (field: string, dir: SortDirection) => void
] {
  const [query, setQuery] = useSearchParams();
  // Means we don't need to memoize or hoist allowedFields up above
  const serialised = allowedFields.join(",");
  const handleSort = useCallback(
    (field: string, direction: SortDirection) => {
      // Undoing the sort operation
      if (!field) {
        query.delete(QUERY_PARAM);
        query.delete(PAGINATION_QUERY_PARAM);
        setQuery(query);
        return;
      }

      // Not allowed
      if (!allowedFields.includes(field)) {
        return;
      }

      query.set(QUERY_PARAM, `${field}:${direction}`);
      query.delete(PAGINATION_QUERY_PARAM);
      setQuery(query);
    },
    // We can safely ignore as serialised's value is dependant on allowedFields
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [query, serialised, setQuery]
  );

  const current = query.get(QUERY_PARAM) ?? "";
  const [field, dir = "asc"] = current.split(":");

  const direction = dir === "desc" ? "desc" : "asc";
  if (!field || !allowedFields.includes(field)) {
    return [void 0, { field: "", direction: "asc" }, handleSort];
  }

  return [
    `${dir === "desc" ? "-" : ""}${field}`,
    { field, direction },
    handleSort,
  ];
}

export default useSort;
