import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Entity } from "types/Entity";
import { ReportingYear } from "types/ReportingYear";

import { organisationThunks } from "store/organisation/organisationThunks";
import { userThunks } from "store/user/userThunks";

export type OrganisationSliceState = {
  currentOrganisation: Entity | null;
  selectedReportingYear: ReportingYear | null;
  reportingYears: ReportingYear[];
};

const initialState: OrganisationSliceState = {
  currentOrganisation: null,
  selectedReportingYear: null,
  reportingYears: [],
};

export const organisationSlice = createSlice({
  name: "organisation",
  initialState,
  reducers: {
    setCurrentOrganisation: (state, action: PayloadAction<Entity>) => {
      state.currentOrganisation = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      organisationThunks.getOrganisation.fulfilled,
      (state, { payload }) => {
        state.currentOrganisation = payload;
      }
    );
    builder.addCase(
      userThunks.fetchUserDetails.fulfilled,
      (state, { payload }) => {
        state.currentOrganisation = payload.currentOrganisation;
      }
    );

    builder.addCase(
      organisationThunks.getReportingYears.fulfilled,
      (state, { payload }) => {
        state.reportingYears = payload;

        if (!state.selectedReportingYear) {
          // Current reporting year priority order
          // 1. latest reporting year with emissions data
          // 2. or else just the latest reporting year

          const reportingYearsWithEmissionsData = payload.filter(
            (reportingYear: ReportingYear) =>
              Boolean(reportingYear.hasEmissions)
          );

          // if there are reporting years with emissions data
          if (reportingYearsWithEmissionsData[0]) {
            // select the first (latest) one from the list
            state.selectedReportingYear = reportingYearsWithEmissionsData[0];
          }
          // if there are NO reporting years with emissions data
          if (!reportingYearsWithEmissionsData[0] && payload[0]) {
            // default and select the first (latest) one from the payload
            state.selectedReportingYear = payload[0];
          }
        }
      }
    );
  },
});
export default organisationSlice.reducer;
