type Coercable = unknown[] | number | string | void | null;

function asStringOrNull(
  value: Record<string, Coercable>
): Record<string, string | null>;
function asStringOrNull(value: Coercable): string | null;
function asStringOrNull(value: Record<string, Coercable> | unknown) {
  if (value && typeof value === "object") {
    return Object.entries(value).reduce<Record<string, Coercable>>(
      (converted, [key, value]) => ({
        ...converted,
        [key]: typeof value === "string" ? value : null,
      }),
      {}
    );
  }
  return typeof value === "string" ? value : null;
}

function asArray<T>(value: T | T[] | null | undefined): T[] {
  if (!value) {
    return [];
  }
  return Array.isArray(value) ? value : [value];
}

export { asStringOrNull, asArray };
