const elements: Record<string, HTMLDivElement> = {};
/**
 * Hook that allows us to grab a top level element for use with react portals
 */
function useRootElement(name = "default"): HTMLDivElement {
  const existing = elements[name];
  if (existing) {
    return existing;
  }

  const div = document.createElement("div");
  document.body.appendChild(div);
  return (elements[name] = div);
}

export default useRootElement;
