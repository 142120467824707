import { rem } from "polished";
import styled, { css } from "styled-components";
import cssVar from "theme/vars";

interface RadioProps {
  active?: boolean;
}
const Radio: React.FC<RadioProps> = ({ active = false }) => {
  return <RadioWrapper $selected={active} />;
};

const RadioWrapper = styled.span.attrs({ "aria-hidden": true })<{
  $selected: boolean;
}>`
  cursor: pointer;
  display: inline-grid;
  place-content: center;
  border-radius: 50%;
  min-width: 20px;
  height: 20px;
  background: ${cssVar("color/primary/white")};
  color: ${cssVar("color/primary/blue")};
  border: 1px solid ${cssVar("color/primary/blue")};
  font-size: ${rem(14)};
  font-family: ${cssVar("text/family/regular")};
  transition: border-color 0.3s ease, background-color 0.3s ease,
    box-shadow 0.3s ease;
  outline: 0;

  box-shadow: 0px 0px 0px 4px rgba(124, 130, 135, 0);

  &[disabled] {
    background: ${cssVar("color/primary/superSilverGrey")};
    border: 1px solid ${cssVar("color/primary/disabledGrey")};
  }

  *:hover > & {
    box-shadow: 0px 0px 0px 4px rgba(44, 113, 241, 0.1);
  }

  :focus {
    box-shadow: 0px 0px 0px 4px rgba(44, 113, 241, 0.2) !important;
  }

  :active {
    box-shadow: 0px 0px 0px 4px rgba(44, 113, 241, 0.35) !important;
  }

  ${(props) =>
    props.$selected &&
    css`
      background: ${cssVar("color/primary/blue")};
      &::after {
        content: "";
        display: block;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: ${cssVar("color/primary/white")};
      }
    `}
`;

export default Radio;
