export const transformFileDownloadResponse = async (response: Response) => {
  const contentDisposition = response.headers.get("Content-Disposition");
  let filename = undefined;
  // Regular expression to match the filename pattern
  const regex = /filename="([^"]+)/;
  // Execute the regex on the string
  const match = contentDisposition?.match(regex);
  // Check if a match was found and extract the filename
  if (match) {
    filename = match[1];
    // Remove the file extension
    filename = filename?.replace(/\.\w+$/, "");
  }

  const file = await response.blob();

  return {
    filename,
    file,
  };
};
