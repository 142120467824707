import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";

import styled from "styled-components";
import cssVar from "theme/vars";
import { Spinner } from "ui/core";

const PageLoader: React.FC = () => {
  // Only show loader when we really need to
  const [showLoader, setShowLoader] = useState(false);
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowLoader(true);
    }, 300);
    return () => clearTimeout(timer);
  }, []);
  return (
    <PageLoaderWrapper>
      <Helmet title="Loading..." />
      {showLoader && <Loader />}
    </PageLoaderWrapper>
  );
};

const PageLoaderWrapper = styled.div`
  display: grid;
  min-height: 100vh;
  place-content: center;
  background-color: ${cssVar("color/background/mouse")};
`;

const Loader = styled(Spinner)`
  width: 96px;
  height: 96px;
`;

export default PageLoader;
