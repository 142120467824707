import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import { DatePicker } from "ui/forms";

import { formattedDate } from "utilities/general";

import { QUERY_PARAM as PAGINATION_QUERY_PARAM } from "../Pagination";
import { useFilterUpdate } from "./slice";

const DocumentValidDateRange = () => {
  const [query, setQuery] = useSearchParams();
  const updateFilters = useFilterUpdate();

  const dateFrom = query.get("valid_from");
  const dateTo = query.get("valid_to");

  useEffect(() => {
    dateFrom && updateFilters("valid_from", dateFrom);
    dateTo && updateFilters("valid_to", dateTo);
    return () => {
      updateFilters("valid_from", null);
      updateFilters("valid_to", null);
    };
  }, [dateFrom, dateTo, updateFilters]);

  const onChange = (dates: [Date | null, Date | null]) => {
    const start = formattedDate(dates[0]);
    const end = formattedDate(dates[1]);
    updateFilters("valid_from", start);
    updateFilters("valid_to", end);
    query.delete("valid_from");
    query.delete("valid_to");
    start && query.set("valid_from", start);
    end && query.set("valid_to", end);
    // A change in filter means we should reset pagination
    query.delete(PAGINATION_QUERY_PARAM);
    setQuery(query, { replace: true });
  };

  return (
    <DatePicker
      placeholderText="Validity"
      startDate={dateFrom ? new Date(dateFrom) : null}
      endDate={dateTo ? new Date(dateTo) : null}
      onChange={onChange}
    />
  );
};

export default DocumentValidDateRange;
