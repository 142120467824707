import { Filter } from "ui/forms";

import { useUserMeQuery } from "API";

import useFilterParam from "./useFilterParam";

const Organisation: React.FC = () => {
  const { data: userData, isSuccess, isError } = useUserMeQuery();
  const data = userData
    ? userData.organisations
        .filter((user) => user.featureFlags.HUB_VIEW)
        .map(({ id, name }) => ({ value: id, label: name }))
    : [];
  const [selected, setSelected] = useFilterParam("organisation", data, {
    defaultToFirst: false,
    ready: isSuccess || isError,
  });

  const selectedLabel =
    data.find((o) => o.value === selected)?.label ?? "Organisation";

  return (
    <Filter
      items={{ options: data }}
      title="Organisation"
      label={selectedLabel}
      onChange={setSelected}
      selected={!!selected}
      disabled={!isSuccess}
      value={selected ?? ""}
      showReset={true}
      showApply={true}
      searchable={true}
    />
  );
};

export default Organisation;
