import React, { ReactNode, useEffect, useState } from "react";

import { RadioGroup as HeadlessRadioGroup } from "@headlessui/react";

import "./radio-group.scss";

export interface RadioOption {
  value: any;
  label: ReactNode;
}

const RadioGroup: React.FC<{
  disabled?: boolean;
  inline?: boolean;
  onChange?: (newVal: any) => void;
  options: RadioOption[];
  value?: any;
}> = ({ onChange, options, disabled, inline, value }) => {
  const [selected, setSelected] = useState(value);

  useEffect(() => {
    setSelected(value);
  }, [value]);

  const handleChange = (newVal: any) => {
    setSelected(newVal);
    if (onChange) {
      onChange(newVal);
    }
  };

  return (
    <div
      className={`min-ui__radio-group  ${
        inline ? "min-ui__radio-group--inline" : ""
      }`}
    >
      <HeadlessRadioGroup
        disabled={disabled}
        value={selected}
        onChange={handleChange}
      >
        {options.map((option) => {
          return (
            <HeadlessRadioGroup.Option key={option.value} value={option.value}>
              {({ checked }) => {
                return (
                  <div
                    className={`min-ui__radio-group__option ${
                      disabled ? "min-ui__radio-group__option--disabled" : ""
                    }`}
                  >
                    <span
                      className={`min-ui__radio-group__option__box 
										${checked ? "min-ui__radio-group__option__box--selected" : ""}`}
                    />
                    <span className="min-ui__radio-group__option__label">
                      {" "}
                      {option.label}{" "}
                    </span>
                  </div>
                );
              }}
            </HeadlessRadioGroup.Option>
          );
        })}
      </HeadlessRadioGroup>
    </div>
  );
};

export default RadioGroup;
