import { StandardOptionProps } from "ui/forms/Filter";

import api from "API";
import * as Params from "API/params";

import {
  GetSubmissionGroupParams,
  GetSubmissionGroups,
  GetSubmissionSlotParams,
  GetSubmissionSlots,
} from "../DataCollection/payloads";
import * as Tasks from "./payloads";
import { transformFileDownloadResponse } from "./transformers";

export const tasksEndpoint = `/hub/data-tasks/`;
export const taskFilesEndpoint = `/hub/data-tasks/`;

const extendedEndpoints = api.injectEndpoints({
  endpoints: (builder) => ({
    tasks: builder.query<
      Tasks.GetTasks,
      Params.GenericParamMap<Tasks.GetTasksParams>
    >({
      query: (query) => {
        const params = {
          page: query.page,
          ordering: Params.toString(query.ordering),
          reporting_year: Params.toString(query.task_reporting_years),
          status: Params.toArray(query.status),
          submission_group: Params.toArray(query.submissionGroup),
          submission_slot: Params.toArray(query.submissionSlot),
          assignee: Params.toArray(query.assignee),
          organisation: Params.toArray(query.organisation),
          show_completed: query.show_completed === "show_complete",
        };

        return {
          url: tasksEndpoint,
          params,
        };
      },
    }),
    taskDetails: builder.query<Tasks.GetTask, Params.GenericParamMap>({
      query: (query) => {
        return {
          url: `/organisations/${query.organisationId}/data-tasks/${query.taskId}/`,
        };
      },
      keepUnusedDataFor: 0,
    }),
    taskFiles: builder.query<
      Tasks.GetClassificationTaskFiles,
      Params.GenericParamMap
    >({
      query: (query) => {
        return {
          url: `/organisations/${query.organisationId}/data-tasks/${query.taskId}/task-files/`,
        };
      },
      keepUnusedDataFor: 0,
    }),
    cleaningTaskFiles: builder.query<
      Tasks.GetTaskFiles,
      Params.GenericParamMap
    >({
      query: (query) => {
        return {
          url: `/organisations/${query.organisationId}/data-tasks/${query.taskId}/submitted-data/`,
        };
      },
      keepUnusedDataFor: 0,
    }),
    taskFileDownload: builder.query<Tasks.SavedFile, Params.GenericParamMap>({
      query: ({ organisationId, fileId }) => {
        return {
          url: `/organisations/${organisationId}/files/${fileId}`,
        };
      },
    }),
    taskEntityMappingRows: builder.query<
      Tasks.EntityMappingRow[],
      Params.GenericParamMap
    >({
      query: ({ organisationId, taskId }) => {
        return {
          url: `/organisations/${organisationId}/data-tasks/${taskId}/entity-mappings`,
        };
      },
    }),
    taskParameterMappingOptions: builder.query<
      Tasks.ParameterMappingOption[],
      Params.GenericParamMap
    >({
      query: ({ organisationId, taskId }) => {
        return {
          url: `/organisations/${organisationId}/data-tasks/${taskId}/parameter-mapping-options`,
        };
      },
    }),
    taskParameterMappingComplete: builder.mutation<
      Tasks.RequestTaskUpdate,
      Tasks.RequestTaskParametersSubmitParams
    >({
      query: ({ organisationId, taskId, activities, autoProcess }) => {
        let params = {};

        if (autoProcess) {
          params = {
            auto: true,
          };
        }

        return {
          url: `/organisations/${organisationId}/data-tasks/${taskId}/map-parameters`,
          method: "POST",
          body: [...activities],
          params,
        };
      },
    }),
    taskMissingDataCheckCells: builder.query<
      Tasks.MissingDataCheckCellsData,
      Params.GenericParamMap
    >({
      query: ({ organisationId, taskId, activityId }) => {
        return {
          url: `/organisations/${organisationId}/data-tasks/${taskId}/missing-data-check-cells`,
          params: {
            activity: activityId,
          },
        };
      },
    }),
    taskMissingDataCheckCellsUpdate: builder.mutation<
      Tasks.MissingDataCheckCellsData,
      Tasks.RequestTaskMissingDataCheckCellsUpdateParams
    >({
      query: ({
        organisationId,
        taskId,
        activityId,
        rowNumber,
        note,
        actions,
      }) => {
        return {
          url: `/organisations/${organisationId}/data-tasks/${taskId}/missing-data-check-cells`,
          method: "PATCH",
          params: {
            activity: activityId,
          },
          body: {
            rowNumber,
            note,
            actions,
          },
        };
      },
    }),
    taskMissingDataCheckEntities: builder.query<
      Tasks.MissingDataCheckEntitiesData,
      Params.GenericParamMap
    >({
      query: ({ organisationId, taskId, activityId }) => {
        return {
          url: `/organisations/${organisationId}/data-tasks/${taskId}/missing-data-check-entities`,
          params: {
            activity: activityId,
          },
        };
      },
    }),
    taskMissingDataCheckEntitiesUpdate: builder.mutation<
      Tasks.MissingDataCheckCellsData,
      Tasks.RequestTaskMissingDataCheckEntitysUpdateParams
    >({
      query: ({
        organisationId,
        taskId,
        activityId,
        entities,
        entity,
        action,
        note,
        values,
      }) => {
        return {
          url: `/organisations/${organisationId}/data-tasks/${taskId}/missing-data-check-entities`,
          method: "PATCH",
          params: {
            activity: activityId,
          },
          body: {
            entities,
            action,
            note,
            values,
            entity,
          },
        };
      },
    }),
    taskUpdate: builder.mutation<
      Tasks.RequestTaskUpdate,
      Tasks.RequestTaskUpdateParams
    >({
      query: ({
        organisationId,
        taskId,
        task,
        files,
        classifiedData,
        userId,
        autoProcess,
      }) => {
        let body = {};
        let params = {};

        if (files) {
          body = {
            files,
          };
        } else if (classifiedData) {
          body = classifiedData;
        } else if (userId) {
          body = {
            assignee: userId,
          };
        }

        if (autoProcess) {
          params = {
            auto: true,
          };
        }

        return {
          url: `/organisations/${organisationId}/data-tasks/${taskId}/${task}/`,
          method: "POST",
          body,
          params,
        };
      },
    }),
    taskVarianceChecks: builder.query<
      Tasks.VariaceChecksList,
      Params.GenericParamMap
    >({
      query: ({ organisationId, taskId }) => {
        return {
          url: `/organisations/${organisationId}/data-tasks/${taskId}/variance-checks`,
        };
      },
    }),
    taskVarianceChecksUpdate: builder.mutation<
      Tasks.VariaceChecksList,
      Params.GenericParamMap
    >({
      query: ({
        organisationId,
        taskId,
        activityId,
        action,
        entity,
        note,
        value,
        row_id,
        file_id,
        estimation_approach,
      }) => {
        return {
          url: `/organisations/${organisationId}/data-tasks/${taskId}/variance-checks`,
          method: "PATCH",
          params: {
            activity: activityId,
          },
          body: {
            action,
            entity,
            note,
            value,
            row_id,
            file_id,
            estimation_approach,
          },
        };
      },
    }),
    taskDownloadTemplate: builder.query<
      Tasks.GetDownloadFileData,
      Params.GenericParamMap
    >({
      query: ({ organisationId, taskId, activityId }) => ({
        url: `/organisations/${organisationId}/data-tasks/${taskId}/data-extraction-schema/`,
        params: {
          activity: activityId,
        },
        method: "GET",
        responseHandler: async (response: Response) => {
          return transformFileDownloadResponse(response);
        },
      }),
    }),
    taskUploadFile: builder.mutation<
      Tasks.SavedFile,
      { organisationId: string; file: File }
    >({
      query: ({ organisationId, file }) => {
        const formData = new FormData();
        formData.append("file", file);
        return {
          url: `/organisations/${organisationId}/files/`,
          method: "POST",
          body: formData,
        };
      },
    }),
    taskDownloadInputData: builder.query<
      Tasks.GetDownloadFileData,
      Params.GenericParamMap
    >({
      query: ({ organisationId, taskId, activityId }) => ({
        url: `/organisations/${organisationId}/data-tasks/${taskId}/dry-run-input/`,
        params: {
          activity: activityId,
        },
        method: "GET",
        responseHandler: async (response: Response) => {
          return transformFileDownloadResponse(response);
        },
      }),
    }),
    taskRunCalculationTest: builder.query<
      Tasks.GetCalculationTest,
      Params.GenericParamMap
    >({
      query: ({ organisationId, taskId, activityId }) => ({
        url: `/organisations/${organisationId}/data-tasks/${taskId}/trigger-dry-run/`,
        params: {
          activity: activityId,
        },
      }),
    }),
    taskAmendCalculationFile: builder.mutation<
      Tasks.SavedFile,
      {
        organisationId?: string;
        taskId?: string;
        activityId?: string;
        file: File;
      }
    >({
      query: ({ organisationId, taskId, activityId, file }) => {
        const formData = new FormData();
        formData.append("file", file);
        return {
          url: `/organisations/${organisationId}/data-tasks/${taskId}/dry-run-input/`,
          method: "PATCH",
          params: {
            activity: activityId,
          },
          body: formData,
        };
      },
    }),
    classifySubmissionSlots: builder.query<
      GetSubmissionSlots,
      Params.GenericParamMap<GetSubmissionSlotParams>
    >({
      query: ({ organisationId, submission_group_id }) => ({
        url: `/organisations/${organisationId}/submission-groups/${submission_group_id}/submission-slots`,
        method: "GET",
      }),
    }),
    taskDownloadEmissionTemplate: builder.query<
      Tasks.GetDownloadFileData,
      Params.GenericParamMap
    >({
      query: ({ organisationId, taskId, activityId }) => ({
        url: `/organisations/${organisationId}/data-tasks/${taskId}/offline-emissions-template/`,
        params: {
          activity: activityId,
        },
        method: "GET",
        responseHandler: async (response: Response) => {
          return transformFileDownloadResponse(response);
        },
      }),
    }),
    taskUploadEmission: builder.mutation<
      Tasks.TaskUploadEmission,
      Params.GenericParamMap
    >({
      query: ({ organisationId, taskId, activityId, fileId }) => ({
        url: `/organisations/${organisationId}/data-tasks/${taskId}/offline-emissions/`,
        params: {
          activity: activityId,
        },
        method: "PATCH",
        body: {
          file_id: fileId,
        },
      }),
    }),
    taskReset: builder.mutation<Tasks.TaskBaseParams, Params.GenericParamMap>({
      query: ({ organisationId, taskId }) => ({
        url: `/organisations/${organisationId}/data-tasks/${taskId}/reset/`,
        method: "POST",
      }),
    }),
    submissionGroupsList: builder.query<
      GetSubmissionGroups,
      Params.GenericParamMap<GetSubmissionGroupParams>
    >({
      query: ({ organisationId }) => ({
        url: `/organisations/${organisationId}/submission-groups/`,
        method: "GET",
      }),
    }),
    submissionSlotsList: builder.query<
      GetSubmissionSlots,
      Params.GenericParamMap<GetSubmissionSlotParams>
    >({
      query: ({ organisationId, submission_group_id }) => {
        return {
          url: `/organisations/${organisationId}/submission-groups/${submission_group_id}/submission-slots`,
          method: "GET",
        };
      },
    }),
    taskTransferFile: builder.mutation<
      Tasks.RequestTaskUpdateMutation,
      Tasks.RequestTaskTransferFile
    >({
      query: ({ organisationId, taskId, fileDetails }) => ({
        url: `/organisations/${organisationId}/data-tasks/${taskId}/transfer-file`,
        method: "POST",
        body: fileDetails,
      }),
    }),
    taskRecalculate: builder.mutation<
      Tasks.RecalculateTask,
      Params.GenericParamMap
    >({
      query: ({ organisationId, taskId, operation }) => ({
        url: `/organisations/${organisationId}/data-tasks/${taskId}/return-to/`,
        method: "POST",
        body: {
          operation,
        },
      }),
    }),
    taskSubmissionGroupsFilter: builder.query<
      StandardOptionProps["options"],
      Params.GenericParamMap
    >({
      query: (query) => ({
        url: `/organisations/${query.organisationId}/submission-groups/filter-options/`,
        params: {
          reporting_year: query.reporting_year,
        },
      }),
      keepUnusedDataFor: 60,
      transformResponse(original: Tasks.TaskSubmissionGroup) {
        return original.map(({ label, value }) => {
          return { label: label, value: value };
        });
      },
    }),
  }),
});

export const {
  useTasksQuery,
  useTaskDetailsQuery,
  useTaskFilesQuery,
  useCleaningTaskFilesQuery,
  useTaskEntityMappingRowsQuery,
  useTaskParameterMappingOptionsQuery,
  useTaskParameterMappingCompleteMutation,
  useTaskMissingDataCheckCellsQuery,
  useTaskMissingDataCheckCellsUpdateMutation,
  useTaskMissingDataCheckEntitiesQuery,
  useTaskMissingDataCheckEntitiesUpdateMutation,
  useTaskFileDownloadQuery,
  useLazyTaskFileDownloadQuery,
  useTaskUpdateMutation,
  useTaskVarianceChecksQuery,
  useTaskVarianceChecksUpdateMutation,
  useTaskUploadFileMutation,
  useLazyTaskDownloadTemplateQuery,
  useLazyTaskDownloadInputDataQuery,
  useLazyTaskRunCalculationTestQuery,
  useTaskAmendCalculationFileMutation,
  useClassifySubmissionSlotsQuery,
  useLazyTaskDownloadEmissionTemplateQuery,
  useTaskUploadEmissionMutation,
  useTaskResetMutation,
  useTaskRecalculateMutation,
  useSubmissionGroupsListQuery,
  useSubmissionSlotsListQuery,
  useTaskTransferFileMutation,
  useTaskSubmissionGroupsFilterQuery,
} = extendedEndpoints;
