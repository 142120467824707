import { format } from "date-fns";
import { SubmissionSlotData } from "types/SubmissionSlots";

export const noop = () => void 0;

export const deepClone = <T>(toCopy: T): T => {
  return JSON.parse(JSON.stringify(toCopy)) as T;
};

export const getFirstError = (errors: {
  [key: string]: string | string[];
}): string | undefined => {
  const [error] = Object.values(errors);
  if (error) {
    return Array.isArray(error) ? error[0] : error;
  }

  return undefined;
};

export const clearDecimalZeros = (num: string | number): string => {
  const float = parseFloat(num.toString());
  return isNaN(float) ? num.toString() : float.toString();
};

export const ObjectArrayMatcher = (
  array: { id: string; files?: any; role?: string }[],
  idToFind: string
) => {
  const matchedObject = array.find((object) => object?.id === idToFind);
  return matchedObject;
};

export const checkKeysHaveValues = (array: SubmissionSlotData) => {
  return array?.every((obj) => {
    return Object.keys(obj).every(() => {
      return (
        obj["files"] !== null &&
        obj["files"] !== undefined &&
        obj["files"].length !== 0
      );
    });
  });
};

export const checkActivitiesHaveValues = (activities: SubmissionSlotData) => {
  let allSurveysValid = false;

  const hasValidFiles = activities.some(
    (activity) =>
      activity.expectedFormat === "file" &&
      activity.files &&
      activity.files.length > 0
  );

  const filterSurveys = activities.filter(
    (activity) => activity.expectedFormat === "survey"
  );
  if (filterSurveys.length > 0) {
    allSurveysValid = filterSurveys.every(
      (activity) => activity.survey && activity.survey.isValid
    );
  }

  const hasSurveyAndFile =
    activities.some((activity) => activity.expectedFormat === "survey") &&
    activities.some((activity) => activity.expectedFormat === "file");

  const isSubmitEnabled = hasSurveyAndFile
    ? allSurveysValid
    : hasValidFiles || allSurveysValid;
  return isSubmitEnabled;
};

export const formattedDate = (date: Date | null) =>
  date ? format(date, "yyyy-MM-dd") : null;

export const formatNumber = (value?: number): string => {
  if (value === undefined) {
    return "";
  }
  // For values >= 1000 or <= -1000
  if (Math.abs(value) >= 1000) {
    return Math.round(value).toLocaleString();
  }

  // For values between 10 and 1000 (or -10 and -1000)
  if (Math.abs(value) >= 10) {
    return Math.round(value).toString();
  }

  // For values < 10 (and > -10)
  if (Math.abs(value) >= 1) {
    const rounded = value.toFixed(2); // Round to 2 decimal places
    // If both decimal places are zero, return as integer
    return parseFloat(rounded).toString();
  }

  // For values less than 1 (but not zero), use toPrecision to get significant figures and round trailing number
  return parseFloat(value.toPrecision(2)).toString(); // Round to 2 significant digits and remove trailing zeroes
};

export const formatStringNumber = (value?: string): string => {
  if (value === undefined) {
    return "";
  }
  const numberValue = parseFloat(value);

  // If the string is not a valid number, return the original string
  if (isNaN(numberValue)) {
    return value;
  }

  // For values >= 1000 or <= -1000
  if (Math.abs(numberValue) >= 1000) {
    return Math.round(numberValue).toLocaleString();
  }

  // For values between 10 and 1000 (or -10 and -1000)
  if (Math.abs(numberValue) >= 10) {
    return Math.round(numberValue).toString();
  }

  // For values < 10 (and > -10)
  if (Math.abs(numberValue) >= 1) {
    const rounded = numberValue.toFixed(2); // Round to 2 decimal places
    // If both decimal places are zero, return as integer
    return parseFloat(rounded).toString();
  }

  // For values less than 1 (but not zero), use toPrecision to get significant figures and round trailing number
  return parseFloat(numberValue.toPrecision(2)).toString(); // Round to 2 significant digits and remove trailing zeroes
};

export const formatString = (value: string) => {
  // Capitalize the first character and lowercase the rest
  const formattedStr =
    value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();

  // Replace underscores with spaces
  return formattedStr.replace(/_/g, " ");
};
