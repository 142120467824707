import React, { useEffect, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { ChevronLeft, ChevronRight } from "icons";
import styled, { css } from "styled-components";
import cssVar from "theme/vars";
import { H4M } from "typography/headers";

import { useScrollableTabs } from "./useScrollableTabs";
import useScrubberAnimation from "./useScrubberAnimation";

export interface TabsProps {
  tabs: {
    label: string;
    url: string;
    parentUrl?: string;
  }[];
  /**
   * If true, the page will redirect to the first URL in the tabs array on mount
   * if no tabs are currently active
   */
  redirectToFirst?: boolean;
  className?: string;
  onClickCallback?: (to: string, from: string) => void;
}

const Tabs: React.FC<TabsProps> = ({
  tabs,
  redirectToFirst,
  className,
  onClickCallback,
}) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const activeIndex = tabs.findIndex((tab) => {
    if (tab.parentUrl) {
      return pathname.startsWith(tab.parentUrl);
    }

    return pathname.startsWith(tab.url);
  });
  const firstUrl = tabs[0]?.url;
  const rootContainer = useRef<HTMLElement>(null);
  const tabContainer = useRef<HTMLDivElement>(null);

  const scroll = useScrollableTabs(rootContainer, tabContainer);
  const scrubber = useScrubberAnimation(activeIndex, tabContainer);

  useEffect(() => {
    if (redirectToFirst && firstUrl && activeIndex === -1) {
      // Select first tab
      navigate(firstUrl, { replace: true });
    }
  }, [redirectToFirst, activeIndex, firstUrl, navigate]);

  return (
    <TabsWrapper ref={rootContainer} className={className}>
      {/* aria-hidden set as users can tab into scrollable tabs */}
      <Scroller
        onClick={scroll.onScrollPrev}
        $active={scroll.enabled.prev}
        aria-hidden="true"
      >
        <ChevronLeft />
      </Scroller>
      <Scroller
        onClick={scroll.onScrollNext}
        $active={scroll.enabled.next}
        aria-hidden="true"
      >
        <ChevronRight />
      </Scroller>
      <Container
        // Typing mismatch MutableRefObject -> RefObject
        ref={tabContainer}
        style={
          {
            "--scroll": `-${scroll.current}px`,
            ...(activeIndex !== -1 && scrubber
              ? {
                  "--left": `${scrubber.left}px`,
                  "--size": `${scrubber.width}`,
                }
              : {}),
          } as React.CSSProperties
        }
        $matched={activeIndex !== -1 && !!scrubber}
      >
        {tabs.map(({ label, url }, index) => (
          <Tab
            to={url}
            key={url}
            $active={index === activeIndex}
            onClick={() =>
              onClickCallback &&
              onClickCallback(label, tabs[activeIndex]?.label || "External")
            }
          >
            <H4M as="span">{label}</H4M>
          </Tab>
        ))}
      </Container>
    </TabsWrapper>
  );
};

export const Scroller = styled.button.attrs({ type: "button" })<{
  $active: boolean;
}>`
  position: absolute;
  right: 0;
  top: 0;
  width: 40px;
  bottom: 1px; // Allow for border
  background: ${cssVar("color/background/mouse")};
  z-index: 1;
  color: ${cssVar("color/primary/blue")};
  border: 0;
  cursor: pointer;
  display: grid;
  place-content: center;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease;

  &:first-of-type {
    left: 0;
    right: auto;
  }

  ${(props) =>
    props.$active &&
    css`
      opacity: 1;
      pointer-events: auto;
    `}
  svg {
    width: 24px;
    height: 24px;
    display: block;
  }
`;

const TabsWrapper = styled.nav`
  background: ${cssVar("color/background/mouse")};
  max-width: 100%;
  display: flex;
  overflow: hidden;
  position: relative;
  min-height: 32px;
`;
// Used to shrink to contents
const Container = styled.div<{ $matched: boolean }>`
  position: relative;
  display: flex;
  gap: 24px;
  transform: translateX(var(--scroll, 0px));
  transition: transform 0.5s ease;

  // Prevent an initial animation on redirect
  ${(props) =>
    props.$matched &&
    css`
      &::after {
        content: "";
        position: absolute;
        bottom: -1px;
        height: 3px;
        width: 1px;
        background: ${cssVar("color/gray/80")};
        left: 0;
        transform-origin: left;
        transform: translateX(var(--left)) scaleX(var(--size));
        transition: transform 0.4s ease;
      }
    `}
`;

const Tab = styled(Link)<{ $active?: boolean }>`
  display: grid;
  place-content: center;
  padding: 4px 4px 12px;
  color: ${cssVar("color/gray/60")};
  text-decoration: none;
  transition: color 0.2s ease;
  text-align: center;
  white-space: nowrap;
  flex-shrink: 0;
  min-width: 0;

  &:hover {
    color: ${cssVar("color/gray/80")};
  }

  ${H4M} {
    color: inherit;
  }

  ${(props) =>
    props.$active &&
    css`
      color: ${cssVar("color/gray/80")};
    `}
`;

export default Tabs;
