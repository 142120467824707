import { RefObject, useLayoutEffect, useState } from "react";
type ScrubberState = {
  width: number;
  left: number;
};

// NOTE: This is incredibly extra.
// Determine the width of the currently selected tab and resize the scrubber
export default function useScrubberAnimation(
  activeIndex: number,
  tabContainer: RefObject<HTMLElement>
) {
  const [scrubber, setScrubber] = useState<ScrubberState | null>(null);

  useLayoutEffect(() => {
    if (activeIndex === -1) {
      setScrubber(null);
      return;
    }

    const children = Array.from(
      tabContainer.current?.children ?? []
    ) as HTMLElement[];
    const active = children[activeIndex];
    if (!active) {
      setScrubber(null);
      return;
    }

    const { width } = active.getBoundingClientRect();
    setScrubber({ width, left: active.offsetLeft });
  }, [activeIndex, tabContainer]);

  return scrubber;
}
