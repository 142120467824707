import React from "react";

import styled, { css } from "styled-components";

type FooterActionsAligned = "center" | "right";

interface ModalFooterProps {
  shadowed?: boolean;
  className?: string;
}
const Footer: React.FC<ModalFooterProps> = ({
  children,
  shadowed,
  className,
}) => {
  return (
    <Wrapper $shadowed={shadowed} className={className}>
      {children}
    </Wrapper>
  );
};

const Wrapper = styled.div<{ $shadowed?: boolean }>`
  padding: 24px 32px 32px 32px;
  ${({ $shadowed }) =>
    $shadowed &&
    css`
      box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.08);
    `}
`;

interface ModalFooterAlignedProps extends ModalFooterProps {
  aligned?: FooterActionsAligned;
}

export const FooterWithActions: React.FC<ModalFooterAlignedProps> = (props) => {
  const { children, aligned = "right", ...rest } = props;
  return (
    <Footer {...rest}>
      <Actions $aligned={aligned}>{children}</Actions>
    </Footer>
  );
};

const Actions = styled.div<{ $aligned?: FooterActionsAligned }>`
  display: flex;
  justify-content: flex-end;
  gap: 16px;

  ${({ $aligned }) =>
    $aligned === "center" &&
    css`
      justify-content: center;
    `}
`;

export default Footer;
