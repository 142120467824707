import { createAsyncThunk } from "@reduxjs/toolkit";
import { APIGet } from "net/api";
import { organisationEndpoints } from "net/endpoints/organisations";
import { AtlasGraphOrganisation } from "types/Atlas";

const fetchGraph = createAsyncThunk(
  "atlas/graph",
  async (
    {
      orgId,
      selectedReportingYearID,
    }: { orgId: string; selectedReportingYearID: string },
    thunkApi
  ) => {
    try {
      const response = await APIGet<AtlasGraphOrganisation>(
        organisationEndpoints.graph(orgId),
        {
          params: {
            reporting_year: selectedReportingYearID,
          },
        }
      );
      return response.data;
    } catch (err: any) {
      return thunkApi.rejectWithValue(err.response.data);
    }
  }
);

export const thunks = {
  fetchGraph,
};
