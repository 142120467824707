import React, { ChangeEvent } from "react";
import styled from "styled-components";
import { H4R2 } from "typography/headers";
import RadioAtom from "../atoms/Radio";

export interface RadioProps {
  label: string;
  name?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  checked: boolean;
}
const Radio: React.FC<RadioProps> = ({ name, label, checked, onChange }) => {
  return (
    <Wrapper>
      <RadioAtom active={checked} />
      <Control
        type="radio"
        role="radio"
        name={name || label}
        onChange={onChange}
        checked={checked}
        aria-checked={checked}
      />
      <H4R2 as="span">{label}</H4R2>
    </Wrapper>
  );
};

const Control = styled.input`
  position: absolute;
  opacity: 0;
  left: -200px;
  height: -200px;
`;
const Wrapper = styled.label`
  cursor: pointer;
  overflow: hidden;
  position: relative;
  display: flex;
  height: 28px;
  align-items: center;
  gap: 12px;
  padding: 0 4px;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export default Radio;
