import { Close as XClose } from "icons";
import React from "react";
import styled, { css } from "styled-components";
import cssVar from "theme/vars";
import { H4SB2 } from "typography/headers";
import * as SmallAlert from "./Alert";
import Button from "./Button";

type Action = {
  label: string;
  onClick?: () => void;
};
export interface BigAlertProps {
  variant?: SmallAlert.Variant;
  onClose?: () => void;
  title?: string;
  primaryAction?: Action;
  secondaryAction?: Action;
  className?: string;
}

/**
 * Expands basic alert with onClose button and action items
 */
const BigAlert: React.FC<BigAlertProps> = ({
  variant = "info",
  children,
  title,
  onClose,
  primaryAction,
  secondaryAction,
  className,
}) => {
  const icon = SmallAlert.VariantIcons[variant];
  return (
    <BigAlertWrapper $variant={variant} className={className}>
      <SmallAlert.Icon as={icon} />
      <div>
        <Title>{title}</Title>
        <SmallAlert.Copy>{children}</SmallAlert.Copy>
        <Actions>
          {secondaryAction && (
            <Button variant="secondary" onClick={secondaryAction.onClick}>
              {secondaryAction.label}
            </Button>
          )}
          {primaryAction && (
            <Button variant="primary" onClick={primaryAction.onClick}>
              {primaryAction.label}
            </Button>
          )}
        </Actions>
      </div>
      {onClose && (
        <Close onClick={onClose}>
          <XClose aria-hidden="true" />
        </Close>
      )}
    </BigAlertWrapper>
  );
};

const Actions = styled.div`
  &:empty {
    display: none;
  }

  display: flex;
  gap: 12px;
  padding-top: 16px;
`;
const Close = styled.button.attrs({ type: "button", "aria-label": "Close" })`
  border: 0;
  background: transparent;
  width: 20px;
  height: 20px;
  padding: 0;
  cursor: pointer;
  svg {
    display: block;
    width: 100%;
    height: 100%;
    color: ${cssVar("color/gray/70")};
  }
`;
const Title = styled(H4SB2)`
  color: var(--icon);
  padding-bottom: 4px;
`;
const BigAlertWrapper = styled(SmallAlert.AlertWrapper)`
  ${(props) =>
    props.$variant === "info" &&
    css`
      background-color: ${cssVar("color/primary/white")};
    `}
`;

export default BigAlert;
