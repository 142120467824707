import styled from "styled-components";
import cssVar from "theme/vars";

import Page, { PageProps } from "../Page";

const Auth: React.FC<PageProps> = ({ children, ...props }) => {
  return (
    <AuthPage {...props}>
      <Content>
        <Logo src="https://assets.minimum.com/logo__minimum.svg" />
        {children}
      </Content>
    </AuthPage>
  );
};

const AuthPage = styled(Page)`
  display: flex;
  flex-direction: column;
`;
const Content = styled.main`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  width: 100%;
  max-width: 454px;
  padding: 48px;
  background-color: ${cssVar("color/primary/superSilverGrey")};
`;

const Logo = styled.img`
  height: 24px;
  margin-bottom: 24px;
`;

export default Auth;
