import { DataTrackerEntity } from "types/DataTracker";
import { SubmissionProgress } from "types/SubmittedData";

import { RootState } from "store/store";

export const selectors = {
  loading: (state: RootState): boolean => state.dataTracker.loading,
  categoriesProgress: (state: RootState): SubmissionProgress =>
    state.dataTracker.categoriesProgress ?? {
      total: 0,
      completed: 0,
    },
  entitiesProgress: (state: RootState): SubmissionProgress =>
    state.dataTracker.entitiesProgress ?? {
      total: 0,
      completed: 0,
    },
  entities: (state: RootState): DataTrackerEntity[] =>
    state.dataTracker.entities,
  selectedEntity: (state: RootState): DataTrackerEntity | null =>
    state.dataTracker.selectedEntity,
};
