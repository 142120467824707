import { SVGProps } from "react";
interface SvgChevronSelectorVerticalDownProps extends SVGProps<SVGSVGElement> {
  /**
   * Width of icon, uses size if not given
   */
  width?: number;
  /**
   * Height of icon, uses size if not given
   */
  height?: number;
  /**
   * Size of icon, applies to width & height if not supplied
   * @default 24
   */
  size?: number;
}
export const SvgChevronSelectorVerticalDown = (
  props: SvgChevronSelectorVerticalDownProps
) => (
  <svg
    {...props}
    width={props.width ?? props.size ?? 24}
    height={props.height ?? props.size ?? 24}
    viewBox="0 0 17 16"
    fill="currentColor"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.76749 9.43432C5.07991 9.1219 5.58644 9.1219 5.89886 9.43432L8.66651 12.202L11.4342 9.43432C11.7466 9.1219 12.2531 9.1219 12.5655 9.43432C12.8779 9.74674 12.8779 10.2533 12.5655 10.5657L9.23219 13.899C8.91977 14.2114 8.41324 14.2114 8.10082 13.899L4.76749 10.5657C4.45507 10.2533 4.45507 9.74674 4.76749 9.43432Z"
    />
  </svg>
);
export default SvgChevronSelectorVerticalDown;
