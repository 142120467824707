import { SVGProps } from "react";
interface SvgCloudUploadProps extends SVGProps<SVGSVGElement> {
  /**
   * Width of icon, uses size if not given
   */
  width?: number;
  /**
   * Height of icon, uses size if not given
   */
  height?: number;
  /**
   * Size of icon, applies to width & height if not supplied
   * @default 24
   */
  size?: number;
}
export const SvgCloudUpload = (props: SvgCloudUploadProps) => (
  <svg
    {...props}
    width={props.width ?? props.size ?? 24}
    height={props.height ?? props.size ?? 24}
    viewBox="0 0 48 48"
    fill="currentColor"
  >
    <path d="M12.5 40q-4.3 0-7.4-3.1Q2 33.8 2 29.5q0-3.9 2.475-6.875t6.375-3.575q1-4.85 4.7-7.925T24.1 8.05q5.65 0 9.475 4.075Q37.4 16.2 37.4 21.9v1.2q3.6-.1 6.1 2.325Q46 27.85 46 31.55q0 3.45-2.5 5.95T37.55 40H25.5q-1.2 0-2.1-.9-.9-.9-.9-2.1V24.1l-3.1 3.1q-.45.45-1.05.425-.6-.025-1.05-.475-.45-.45-.45-1.075T17.3 25l5.65-5.65q.25-.25.5-.35.25-.1.55-.1.3 0 .55.1.25.1.5.35l5.7 5.7q.45.45.45 1.05 0 .6-.45 1.05-.45.45-1.075.45t-1.075-.45l-3.1-3.05V37h12.05q2.25 0 3.85-1.6t1.6-3.85q0-2.25-1.6-3.85t-3.85-1.6H34.4v-4.2q0-4.45-3.025-7.65t-7.475-3.2q-4.45 0-7.5 3.2t-3.05 7.65h-.95q-3.1 0-5.25 2.175T5 29.45q0 3.1 2.2 5.325T12.5 37H18q.65 0 1.075.425.425.425.425 1.075 0 .65-.425 1.075Q18.65 40 18 40ZM24 25.5Z" />
  </svg>
);
export default SvgCloudUpload;
