import { rem } from "polished";
import styled, { css } from "styled-components";
import cssVar from "theme/vars";

const Cell = styled.div<{
  $sticky?: boolean;
  $stickyOffset?: number;
  $right?: boolean;
  colSpan?: number;
}>`
  display: flex;
  padding: 16px 24px;
  font-size: ${rem(14)};
  line-height: ${rem(20)};
  color: ${cssVar("color/gray/60")};
  align-items: center;
  border-bottom: 1px solid ${cssVar("color/gray/20")};

  ${(props) =>
    props.colSpan &&
    css`
      grid-column: span ${props.colSpan};
    `}

  ${(props) =>
    props.$sticky &&
    css`
      background: ${cssVar("color/primary/white")};
      border-left: 1px solid ${cssVar("color/gray/20")};
      position: sticky;
      right: 0;
      box-shadow: -5px 0 10px -1px ${cssVar("color/gray/20")};
    `}

    ${(props) =>
    props.$stickyOffset &&
    css`
      right: ${props.$stickyOffset}px;
    `}

  ${(props) =>
    props.$right &&
    css`
      text-align: right;
      justify-content: flex-end;
    `}
`;

export const CondensedCell = styled(Cell)`
  padding: 12px 24px;
  background-color: ${cssVar("color/primary/white")};
`;

export const FooterCell = styled(CondensedCell)<{
  $sticky?: boolean;
  $stickyOffset?: number;
  $noShadow?: boolean;
}>`
  background-color: ${cssVar("color/primary/silverGrey")};
  border-top: 1px solid ${cssVar("color/gray/40")};
  position: sticky;
  bottom: 0;

  ${(props) =>
    props.$sticky &&
    css`
      background-color: ${cssVar("color/primary/silverGrey")};
      border-left: 1px solid ${cssVar("color/gray/20")};
      position: sticky;
      right: 0;
      box-shadow: -5px 0 10px -1px ${cssVar("color/gray/40")};
    `}

  ${(props) =>
    props.$stickyOffset &&
    css`
      right: ${props.$stickyOffset}px;
    `}
  
    ${(props) =>
    props.$noShadow &&
    css`
      box-shadow: none;
    `}
`;

export default Cell;
