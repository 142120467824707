import { Config } from "./base";

const config: Config = {
  environment: "demo",
  api: {
    endpoint: "https://api.demo.minimum.com",
    disableCache: false,
  },
  sentry: {
    dsn: "https://62a06f6f0487f7001b22d480540d0a13@o346449.ingest.sentry.io/4506196432781312",
    project: "atlas-dashboard-demo",
  },
  auth0: {
    clientId: "NbgdjaxYZmHiupaXKtf1iqZQStAZ7Izb",
    domain: "demo-auth.minimum.com",
    redirectUrl: "https://demo.minimum.com/auth/callback",
  },
  app: {
    sem_ver: process.env.APP_VERSION,
    name: process.env.APP_NAME,
  },
  segment: {
    writeKey: "iLem2HcXDRMLnuKUAKD9uDUCXKODbDUT",
    cdn: "https://analytics-a.minimum.eco",
  },
  supportEmail: "support@minimum.com",
  features: {
    TESTING_FEATURES: false,
  },
};

export type { Config };
export default config;
