import React from "react";

import { Filter } from "ui/forms";

import { useSubmissionPeriodsQuery } from "API";

import useFilterParam from "./useFilterParam";

const SubmissionPeriod: React.FC = () => {
  const { data = [], isSuccess, isError } = useSubmissionPeriodsQuery();
  const [selected, setSelected] = useFilterParam("submissionPeriod", data, {
    defaultToFirst: false,
    multi: true,
    ready: isSuccess || isError,
  });
  return (
    <Filter
      items={{ options: data }}
      title="Period"
      label="Period"
      selected={!!selected.length}
      count={selected.length ? selected.length : void 0}
      onChange={setSelected}
      disabled={!isSuccess}
      value={selected}
      multi={true}
      showReset={true}
      showApply={true}
      searchable={true}
    />
  );
};

export default SubmissionPeriod;
