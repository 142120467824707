import { createAsyncThunk } from "@reduxjs/toolkit";
import { APIGet } from "net/api";
import { organisationEndpoints } from "net/endpoints/organisations";
import { Entity } from "types/Entity";
import { ReportingYear } from "types/ReportingYear";

const getOrganisation = createAsyncThunk(
  "organisation/fetch",
  async (params: { orgId: string }, thunkApi) => {
    try {
      const response = await APIGet<Entity>(
        organisationEndpoints.organisation(params.orgId)
      );
      return response.data;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.response.data);
    }
  }
);

const getReportingYears = createAsyncThunk(
  "organisation/reportingYears",
  async (params: { orgId: string }, thunkApi) => {
    try {
      const response = await APIGet<ReportingYear[]>(
        organisationEndpoints.reportingYears(params.orgId)
      );
      return response.data;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.response.data);
    }
  }
);

export const organisationThunks = {
  getOrganisation,
  getReportingYears,
};
