import { Leaves, extract } from "./utilities";

const Colors = {
  primary: {
    black: "hsl(150, 85%, 3%)",
    white: "hsl(0, 0%, 100%)",
    grey: "hsl(210, 7%, 64%)",
    disabledGrey: "hsl(210, 5%, 83%)",
    darkGrey: "hsl(207, 4%, 51%)",
    silverGrey: "hsl(240, 4%, 95%)",
    superSilverGrey: "hsl(240, 11%, 98%)",
    blue: "hsl(219, 81%, 50%)",
    lightOcean: "hsl(222, 28%, 93%)",
    riverBlue: "hsl(217,79%,91%)",
    lightBlue: "hsl(229,100%,96%)",
    darkBlue: "hsl(221,71%,28%)",
    skinnyBlue: "hsla(220, 26%, 93%, 1)",
  },
  brand: {
    vividGreen: "hsla(153, 100%, 44%, 1)",
    nightGreen: "hsla(150, 8%, 14%, 1)",
    pastelGreen: "hsla(151, 84%, 39%, 1)",
    acidGreen: "hsla(164, 92%, 61%, 1)",
    eveningGreen: "hsla(153, 100%, 11%, 1)",
  },
  action: {
    green: "hsla(120, 100%, 33%, 1)",
    lightGreen: "hsla(133, 53%, 97%, 1)",
    orange: "hsla(32, 99%, 49%, 1)",
    lightOrange: "hsla(30, 100%, 98%, 1)",
    darkRed: "hsla(0, 100%, 38%, 1)",
    red: "hsla(358, 100%, 45%, 1)",
    mediumRed: "hsla(0, 80%, 81%, 1)",
    lightRed: "hsla(3, 80%, 88%, 1)",
    superLightRed: "hsla(3, 79%, 94%, 1)",
    duskBlue: "hsla(224, 73%, 53%, 1)",
    activeBlue: "hsla(223, 100%, 33%, 1)",
    lightActiveBlue: "hsla(219, 83%, 89%, 1)",
    superLightBlue: "hsla(213, 82%, 98%, 1)",
  },
  border: {
    default: "#D0D5DD",
  },
  background: {
    mouse: "hsla(0, 0%, 98%, 1)",
    platinum: "hsla(240, 20%, 99%, 1)",
    default: "#F9FAFB",
    info: "#E0EAFC",
    warning: "#FEF0C7",
  },
  foreground: {
    default: "##101828",
    info: "#1961E7",
    warning: "#B54708",
  },
  grayModern: {
    carbon: "hsla(207, 95%, 7%, 1)",
    coal: "hsla(202, 22%, 40%, 1)",
    charcoal: "hsla(208, 93%, 12%, 1)",
    marble: "hsla(199, 18%, 46%, 1)",
    zinc: "hsla(208, 45%, 29%, 1)",
    obsidian: "hsla(201, 16%, 63%, 1)",
    nickel: "hsla(207, 30%, 35%, 1)",
    steel: "hsla(191, 13%, 83%, 1)",
  },
  gray: {
    5: "hsla(180, 20%, 99%, 1)",
    10: "hsla(210, 20%, 98%, 1)",
    20: "hsla(216, 24%, 96%, 1)",
    30: "hsla(220, 17%, 93%, 1)",
    40: "hsla(217, 16%, 84%, 1)",
    50: "hsla(218, 15%, 65%, 1)",
    60: "hsla(221, 13%, 46%, 1)",
    70: "hsla(216, 18%, 34%, 1)",
    80: "hsla(218, 24%, 27%, 1)",
    90: "hsla(214, 33%, 17%, 1)",
    100: "hsla(220, 43%, 11%, 1)",
  },
  error: {
    5: "hsla(12, 100%, 99%, 1)",
    10: "hsla(5, 86%, 97%, 1)",
    20: "hsla(4, 93%, 94%, 1)",
    30: "hsla(3, 100%, 90%, 1)",
    40: "hsla(4, 96%, 80%, 1)",
    50: "hsla(4, 94%, 69%, 1)",
    60: "hsla(4, 86%, 58%, 1)",
    70: "hsla(4, 74%, 49%, 1)",
    80: "hsla(4, 76%, 40%, 1)",
    90: "hsla(4, 72%, 33%, 1)",
    100: "hsla(8, 65%, 29%, 1)",
  },
  warning: {
    5: "hsla(42, 100%, 98%, 1)",
    10: "hsla(45, 100%, 96%, 1)",
    20: "hsla(45, 96%, 89%, 1)",
    30: "hsla(44, 98%, 77%, 1)",
    40: "hsla(42, 99%, 65%, 1)",
    50: "hsla(39, 98%, 56%, 1)",
    60: "hsla(34, 94%, 50%, 1)",
    70: "hsla(28, 97%, 44%, 1)",
    80: "hsla(22, 92%, 37%, 1)",
    90: "hsla(19, 84%, 31%, 1)",
    100: "hsla(18, 79%, 27%, 1)",
  },
  success: {
    5: "hsla(143, 80%, 98%, 1)",
    10: "hsla(145, 81%, 96%, 1)",
    20: "hsla(140, 80%, 90%, 1)",
    30: "hsla(144, 78%, 80%, 1)",
    40: "hsla(148, 74%, 67%, 1)",
    50: "hsla(150, 66%, 52%, 1)",
    60: "hsla(152, 82%, 39%, 1)",
    70: "hsla(153, 96%, 30%, 1)",
    80: "hsla(155, 97%, 24%, 1)",
    90: "hsla(155, 90%, 20%, 1)",
    100: "hsla(156, 88%, 16%, 1)",
  },
  blue: {
    "02": "#7DA6F2",
  },
  yellow: {
    "02": "#FEC84B",
  },
  neutral: {
    "04": "#707783",
  },
  purple: {
    5: "hsla(264, 100%, 99%, 1)",
    10: "hsla(264, 100%, 98%, 1)",
    20: "hsla(267, 100%, 96%, 1)",
    30: "hsla(268, 95%, 92%, 1)",
    40: "hsla(265, 89%, 86%, 1)",
    50: "hsla(262, 85%, 77%, 1)",
    60: "hsla(260, 77%, 70%, 1)",
    70: "hsla(259, 63%, 59%, 1)",
    80: "hsla(258, 54%, 52%, 1)",
    90: "hsla(256, 48%, 42%, 1)",
    100: "hsla(254, 45%, 34%, 1)",
  },
  emerald: {
    50: "hsl(157, 79%, 94%)",
    200: "hsl(153, 79%, 78%)",
    500: "hsl(153, 100%, 44%)",
    600: "hsl(153, 100%, 33%)",
    700: "hsl(153, 100%, 22%)",
    800: "hsl(153, 100%, 11%)",
  },
  slate: {
    basic: "hsl(210, 7%, 71%)",
    0: "hsl(0, 0%, 100%)",
    25: "hsl(120, 20%, 98%)",
    50: "hsl(120, 5%, 96%)",
    100: "hsl(150, 5%, 91%)",
    200: "hsl(120, 3%, 89%)",
    300: "hsl(140, 4%, 86%)",
    400: "hsl(156, 3%, 71%)",
    500: "hsl(146, 3%, 57%)",
    600: "hsl(153, 4%, 43%)",
    700: "hsl(150, 8%, 28%)",
    800: "hsl(150, 8%, 14%)",
    900: "hsl(0, 0%, 0%)",
  },

  positive: {
    basic: "hsl(73, 73%, 38%)",
    bg: "hsl(93, 43%, 96%)",
    text: "hsl(73, 73%, 19%)",
  },
  caution: {
    basic: "hsl(35, 100%, 68%)",
    bg: "hsl(34, 100%, 97%)",
    text: "hsl(35, 48%, 34%)",
  },

  danger: {
    basic: "hsl(356, 76%, 59%)",
    bg: "hsl(356, 73%, 96%)",
    text: "hsl(356, 53%, 29%)",
  },

  accents: {
    rose: "hsl(341,85%,41%)",
    roseBg: "hsl(351,100%,97%)",
    orange: "hsl(13,80%,40%)",
    orangeBg: "hsl(23,100%,96%)",
    pink: "hsl(327,80%,42%)",
    pinkBg: "hsl(316,73%,97%)",
    purple: "hsl(258, 54%, 52%)",
    purpleBg: "hsl(264, 100%, 98%)",
    darkPurple: "hsl(257, 72%, 50%)",
    darkPurpleBg: "hsl(245, 100%, 98%)",
  },
};

export type ColorKey = Leaves<typeof Colors>;
export default function getColor(key: ColorKey): string {
  return extract(Colors, key);
}

export { Colors };
