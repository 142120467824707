import { ComponentProps, MouseEvent, useMemo } from "react";
import { Link, LinkProps } from "react-router-dom";

import styled, { css } from "styled-components";
import cssVar from "theme/vars";
import * as Text from "typography/buttons";

import Spinner from "./Spinner";

export type Variant =
  | "primary"
  | "secondary"
  | "error"
  | "error-secondary"
  | "outlined-primary"
  | "outlined-error"
  | "text-primary"
  | "text-primary-underline"
  | "text-error"
  | "text-white";

type Size = "large" | "medium" | "small";

export interface ButtonProps {
  /**
   * Pass in a string to behave as an HTML a tag, otherwise button is used
   */
  onClick?: string | ((e: MouseEvent<HTMLElement>) => void);
  /**
   * Affects padding and text size
   */
  size?: Size;
  /**
   * Controls color and border styles
   */
  variant?: Variant;
  /**
   * Is this button acting as a submit button for a form element?
   */
  submit?: boolean;
  /**
   * Passthrough for restyling
   */
  className?: string;
  disabled?: boolean;
  /**
   * Show loading spinner?
   */
  loading?: boolean;
  download?: string;
  /**
   * Open link in a new tab (only for string onClick values)
   */
  newTab?: boolean;
  /**
   * Optionally pass a state object to Link
   */
  state?: Record<string, any>;
  /**
   * Optionally pass a data test id for testing library selectors
   */
  dataTestId?: string;
}

type HTMLButtonProps = Omit<ComponentProps<"button">, "ref">;
type HTMLAnchorProps = Omit<ComponentProps<"a">, "ref">;
type ComponentAndProps =
  | [typeof Link, LinkProps]
  | ["button", HTMLButtonProps]
  | ["a", HTMLAnchorProps];

const Button: React.FC<ButtonProps> = ({
  onClick,
  size = "medium",
  variant = "primary",
  className,
  submit = false,
  children,
  disabled,
  loading,
  download,
  newTab = false,
  state,
  dataTestId = "",
}) => {
  const Text = TextStyles[size ?? "medium"];
  const [BaseComponent, baseProps] = useMemo<ComponentAndProps>(() => {
    if (typeof onClick === "string") {
      if (download || /^blob:/.test(onClick) || newTab) {
        return [
          "a",
          {
            download,
            href: onClick,
            onClick: function (e: MouseEvent) {
              if (disabled || loading) {
                e.preventDefault();
              }
            },
            ...(newTab ? { target: "_blank" } : {}),
          },
        ];
      }

      return [
        Link,
        {
          to: onClick,
          state: state,
          onClick: function (e: MouseEvent) {
            if (disabled || loading) {
              e.preventDefault();
            }
          },
        },
      ];
    } else {
      return [
        "button",
        {
          type: submit ? "submit" : "button",
          disabled: disabled || loading,
          onClick,
        },
      ];
    }
  }, [submit, onClick, disabled, loading, download, newTab, state]);

  return (
    <ButtonWrapper
      as={BaseComponent}
      {...baseProps}
      className={className}
      data-testid={dataTestId}
      $variant={variant}
      $size={size}
      $disabled={disabled}
      $loading={loading}
    >
      <Label as={Text}>{children}</Label>
      {loading && (
        <Loader>
          <LoaderAnimation />
        </Loader>
      )}
    </ButtonWrapper>
  );
};

const ButtonWrapper = styled.button<{
  $variant: Variant;
  $size: Size;
  $disabled?: boolean;
  $loading?: boolean;
}>`
  display: inline-flex;
  place-content: center;
  text-decoration: none;
  border-radius: 4px;
  border: var(--border, 0px) solid var(--color);
  padding: var(--padding, 0px);
  background: var(--background, transparent);
  color: var(--color);
  margin: 0;
  cursor: pointer;
  transition: background 0.3s ease, color 0.2s ease;
  position: relative;
  overflow: hidden;

  // Default colors
  --disabled-background: ${cssVar("color/primary/disabledGrey")};
  --disabled-color: ${cssVar("color/primary/white")};

  // Initial state
  --background: var(--initial-background);
  --color: var(--initial-color);

  &:hover {
    --background: var(--hover-background, var(--initial-background));
    --color: var(--hover-color, var(--initial-color));
  }

  &:focus-visible {
    --background: var(
      --focus-background,
      var(--hover-background, var(--initial-background))
    );
    --color: var(--focus-color, var(--hover-color, var(--initial-color)));
  }

  &:active {
    --background: var(--active-background, var(--initial-background));
    --color: var(--active-color, var(--initial-color));
  }

  ${(props) =>
    props.$loading &&
    css`
      cursor: wait;
      --background: var(
        --loading-background,
        var(--active-background, var(--initial-background))
      ) !important;
      --color: var(
        --loading-color,
        var(--active-color, var(--initial-color))
      ) !important;
    `}

  ${(props) =>
    props.$disabled &&
    css`
      cursor: not-allowed;
      --background: var(
        --disabled-background,
        var(--initial-background)
      ) !important;
      --color: var(--disabled-color, var(--initial-color)) !important;
    `}


  ${(props) => VariantStyles[props.$variant]}
  ${(props) => SizeStyles[props.$size]}
`;
const Label = styled.span`
  color: var(--color);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--gap, 8px);
`;
const Loader = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--background);
  display: grid;
  place-content: center;
`;
const LoaderAnimation = styled(Spinner)`
  width: var(--loader-size, 24px);
  height: var(--loader-size, 24px);
`;

const TextStyles: Record<Size, typeof Text[keyof typeof Text]> = {
  large: Text.LargeM,
  medium: Text.SmallM,
  small: Text.SmallM,
};

const SizeStyles: Record<Size, Record<string, string>> = {
  large: {
    "--padding": "12px 16px",
    "--loader-padding": "8px 36px",
    "--gap": "12px",
  },
  medium: {
    "--padding": "8px 16px",
    "--loader-size": "20px",
    "--loader-padding": "6px 26px",
    "--gap": "8px",
  },
  small: {
    "--padding": "4px 8px",
    "--loader-size": "16px",
    "--loader-padding": "4px 20px",
    "--gap": "4px",
  },
};

const VariantStyles: Record<Variant, Record<string, string>> = {
  primary: {
    "--initial-background": cssVar("color/primary/blue"),
    "--initial-color": cssVar("color/primary/white"),
    "--hover-background": cssVar("color/action/duskBlue"),
    "--active-background": cssVar("color/action/activeBlue"),
  },
  secondary: {
    "--initial-background": cssVar("color/gray/20"),
    "--initial-color": cssVar("color/primary/blue"),
    "--hover-background": cssVar("color/gray/30"),
    "--active-background": cssVar("color/gray/40"),
  },
  error: {
    "--initial-background": cssVar("color/error/60"),
    "--initial-color": cssVar("color/primary/white"),
    "--hover-background": cssVar("color/error/70"),
    "--active-background": cssVar("color/error/80"),
  },
  "error-secondary": {
    "--initial-background": cssVar("color/error/20"),
    "--initial-color": cssVar("color/error/60"),
    "--hover-background": cssVar("color/error/30"),
    "--hover-color": cssVar("color/error/70"),
    "--active-background": cssVar("color/error/40"),
    "--active-color": cssVar("color/error/90"),
    "--loading-background": cssVar("color/error/20"),
    "--loading-color": cssVar("color/error/60"),
  },
  "outlined-primary": {
    "--border": "1px",
    "--initial-background": "transparent",
    "--initial-color": cssVar("color/primary/blue"),
    "--hover-background": cssVar("color/primary/lightBlue"),
    "--active-background": cssVar("color/primary/riverBlue"),
    "--disabled-color": cssVar("color/primary/grey"),
    "--disabled-background": "transparent",
  },
  "outlined-error": {
    "--border": "1px",
    "--initial-background": "transparent",
    "--initial-color": cssVar("color/error/60"),
    "--hover-background": cssVar("color/error/30"),
    "--hover-color": cssVar("color/error/70"),
    "--active-background": cssVar("color/error/40"),
    "--active-color": cssVar("color/error/90"),
    "--disabled-color": cssVar("color/primary/grey"),
    "--disabled-background": "transparent",
    "--loading-background": cssVar("color/error/20"),
    "--loading-color": cssVar("color/error/60"),
  },
  "text-primary": {
    "--initial-background": "transparent",
    "--initial-color": cssVar("color/primary/blue"),
    "--hover-background": cssVar("color/primary/lightBlue"),
    "--active-background": cssVar("color/primary/riverBlue"),
    "--disabled-color": cssVar("color/primary/grey"),
    "--disabled-background": "transparent",
  },
  "text-primary-underline": {
    "--initial-background": "transparent",
    "--initial-color": cssVar("color/primary/blue"),
    "--hover-background": cssVar("color/primary/lightBlue"),
    "--active-background": cssVar("color/primary/riverBlue"),
    "--disabled-color": cssVar("color/primary/grey"),
    "--disabled-background": "transparent",
    "text-decoration": "underline",
  },
  "text-error": {
    "--initial-background": "transparent",
    "--initial-color": cssVar("color/error/60"),
    "--hover-background": cssVar("color/error/30"),
    "--hover-color": cssVar("color/error/70"),
    "--active-background": cssVar("color/error/40"),
    "--active-color": cssVar("color/error/90"),
    "--disabled-color": cssVar("color/primary/grey"),
    "--disabled-background": "transparent",
    "--loading-background": cssVar("color/error/20"),
    "--loading-color": cssVar("color/error/60"),
  },
  "text-white": {
    "--initial-background": "transparent",
    "--initial-color": cssVar("color/primary/white"),
    "--hover-color": cssVar("color/primary/blue"),
    "--active-color": cssVar("color/primary/blue"),
    "--hover-background": cssVar("color/primary/lightBlue"),
    "--active-background": cssVar("color/primary/riverBlue"),
    "--disabled-color": cssVar("color/primary/grey"),
    "--disabled-background": "transparent",
    "--loading-background": "transparent",
    "--loading-color": cssVar("color/primary/white"),
  },
};

export default Button;
