/**
 * Helper utility to add an event listener that triggers only when an element
 * outside the given parent is clicked
 */

import { useEffect } from "react";

function useClickOutside(
  parent: React.RefObject<Element | null>,
  handler?: (e: MouseEvent) => void,
  relatedElement?: React.RefObject<HTMLElement | null>
) {
  useEffect(() => {
    const listener = function (e: MouseEvent) {
      if (
        !e.target ||
        !parent.current ||
        parent?.current?.contains(e.target as HTMLElement) ||
        relatedElement?.current?.contains(e.target as HTMLElement)
      ) {
        return;
      }

      handler?.(e);
    };

    window.addEventListener("click", listener);

    return () => {
      window.removeEventListener("click", listener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parent, handler]);
}

export default useClickOutside;
