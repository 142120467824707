import React, { ReactNode, useEffect, useState } from "react";

import { useDebouncedCallback } from "use-debounce";
import { v4 as uuidv4 } from "uuid";

import { ReactComponent as IconCheck } from "../../assets/icon-check.svg";
import { ReactComponent as IconCross } from "../../assets/icon-cross.svg";

import "./input.scss";

export type InputProps = {
  valid?: boolean;
  moreInfo?: string;
  label?: string;
  monoSpace?: boolean;
  value?: string;
  error?: string;
  headerContent?: ReactNode;
  debounce?: boolean;
  debounceTime?: number;
};

type fullInputProps = InputProps &
  Omit<React.HTMLProps<HTMLInputElement>, "css" | "ref" | "value">;

export const Input = React.forwardRef<HTMLInputElement, fullInputProps>(
  (
    {
      valid,
      value,
      className,
      moreInfo,
      error,
      label,
      monoSpace,
      headerContent,
      onChange,
      debounce,
      debounceTime,
      ...props
    },
    ref
  ) => {
    const validClassName =
      valid === true
        ? "min-ui__input--valid"
        : valid === false || error
        ? "min-ui__input--invalid"
        : "";
    const monoClassName = monoSpace ? "min-ui__input__target--mono" : "";
    const id = "min-ui__input--" + uuidv4();

    const [internalValue, setInternalValue] = useState<string>(value || "");

    useEffect(() => {
      setInternalValue(value || "");
    }, [value]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setInternalValue(e.target.value);
      //if the input doesn't have a debounce then we should call onchange instantly
      if (onChange && !debounce) {
        onChange(e);
      }
    };

    const debouncedOnChange = useDebouncedCallback(() => {
      if (onChange && internalValue !== value) {
        // @ts-ignore
        onChange({ target: { value: internalValue } });
      }
    }, debounceTime || 500);

    useEffect(() => {
      if (debounce) {
        debouncedOnChange();
      }
    }, [debounce, debouncedOnChange, internalValue]);

    return (
      <div className={`min-ui__input ${validClassName} ${className || ""}`}>
        <div className="min-ui__input__row min-ui__input__row--header">
          <label htmlFor={id} className="min-ui__input__label">
            {label}
          </label>
          {headerContent}
        </div>
        <div className="min-ui__input__target-container">
          <input
            ref={ref}
            id={id}
            value={internalValue}
            className={`min-ui__input__target ${monoClassName}`}
            onWheel={(e) => (e.target as HTMLElement).blur()}
            onChange={handleChange}
            {...props}
          />
          <div className="validity-icon">
            {valid === false && <IconCross />}
            {valid && <IconCheck />}
          </div>
        </div>
        <div className="min-ui__input__row min-ui__input__row--footer">
          {moreInfo}
          {error}
        </div>
      </div>
    );
  }
);

export default Input;
