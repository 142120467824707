import React from "react";
import { Helmet } from "react-helmet";

import styled from "styled-components";

const Application: React.FC = ({ children }) => {
  return (
    <ApplicationWrapper>
      <Helmet titleTemplate="%s | Minimum" title="Dashboard" />
      {children}
    </ApplicationWrapper>
  );
};

const ApplicationWrapper = styled.main`
  min-height: 100vh;
  display: grid;
  grid-template-rows: 52px minmax(0, 1fr);
  grid-template-columns: 100vw;
  max-width: 100%;

  // When only a single element is rendered (i.e auth routes), take up the whole page
  > *:first-child:last-child {
    grid-column: 1 / -1;
  }
`;

export default Application;
