import React from "react";

import { Tooltip } from "ui/core";
import { Filter } from "ui/forms";

import { skipToken, useTaskReportingYearsQuery } from "API";

import useFilterParam from "./useFilterParam";

const TaskReportingYear: React.FC<{ organisationId: string }> = ({
  organisationId,
}) => {
  const {
    data = [],
    isSuccess,
    isError,
  } = useTaskReportingYearsQuery(
    organisationId ? { organisationId } : skipToken,
    {
      selectFromResult: (result) => ({
        ...result,
        data: result.data?.map((item) => ({
          value: item.id,
          label: item.label ?? "Unknown",
        })),
      }),
    }
  );

  const [selected, setSelected] = useFilterParam("task_reporting_years", data, {
    defaultToFirst: false,
    ready: isSuccess || isError,
  });

  const selectedLabel =
    data.find((o) => o.value === selected)?.label ?? "Reporting year";

  return !isSuccess ? (
    <Tooltip
      content="You must select an Organisation before filtering by Reporting Year"
      initialPosition="bottom"
    >
      <Filter
        items={{ options: data }}
        title="Reporting year"
        label={selectedLabel}
        selected={!!selected}
        onChange={setSelected}
        disabled={!isSuccess}
        value={selected ?? ""}
        showReset={true}
        showApply={true}
      />
    </Tooltip>
  ) : (
    <Filter
      items={{ options: data }}
      title="Reporting year"
      label={selectedLabel}
      selected={!!selected}
      onChange={setSelected}
      disabled={!isSuccess}
      value={selected ?? ""}
      showReset={true}
      showApply={true}
    />
  );
};

export default TaskReportingYear;
