import { SVGProps } from "react";
interface SvgHelpProps extends SVGProps<SVGSVGElement> {
  /**
   * Width of icon, uses size if not given
   */
  width?: number;
  /**
   * Height of icon, uses size if not given
   */
  height?: number;
  /**
   * Size of icon, applies to width & height if not supplied
   * @default 24
   */
  size?: number;
}
export const SvgHelp = (props: SvgHelpProps) => (
  <svg
    {...props}
    width={props.width ?? props.size ?? 24}
    height={props.height ?? props.size ?? 24}
    viewBox="0 0 48 48"
    fill="currentColor"
  >
    <path d="M24.2 35.65q.8 0 1.35-.55t.55-1.35q0-.8-.55-1.35t-1.35-.55q-.8 0-1.35.55t-.55 1.35q0 .8.55 1.35t1.35.55ZM24.15 15q1.7 0 2.75.925t1.05 2.375q0 1-.6 1.975-.6.975-1.95 2.125-1.3 1.15-2.075 2.425-.775 1.275-.775 2.325 0 .55.425.875.425.325.975.325.6 0 1-.4t.5-1q.15-1 .675-1.775.525-.775 1.625-1.675 1.5-1.25 2.175-2.5.675-1.25.675-2.8 0-2.65-1.725-4.25t-4.575-1.6q-1.9 0-3.5.75t-2.65 2.2q-.4.55-.325 1.125.075.575.475.875.55.4 1.175.25.625-.15 1.025-.7.65-.9 1.575-1.375Q23 15 24.15 15ZM24 44q-4.2 0-7.85-1.525Q12.5 40.95 9.8 38.25q-2.7-2.7-4.25-6.35Q4 28.25 4 24q0-4.2 1.55-7.85Q7.1 12.5 9.8 9.8q2.7-2.7 6.35-4.25Q19.8 4 24 4q4.15 0 7.8 1.55 3.65 1.55 6.35 4.25 2.7 2.7 4.275 6.35Q44 19.8 44 24q0 4.25-1.575 7.9-1.575 3.65-4.275 6.35-2.7 2.7-6.35 4.225Q28.15 44 24 44Zm0-20.55ZM24 41q7.05 0 12.025-5Q41 31 41 24t-4.975-12Q31.05 7 24 7q-7.15 0-12.075 5Q7 17 7 24t4.925 12Q16.85 41 24 41Z" />
  </svg>
);
export default SvgHelp;
