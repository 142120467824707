import { Entity, FeatureFlag, emissionOutputType } from "types/Entity";

import { RootState } from "store/store";

import { HUB_FEATURE_FLAG } from "utilities/consts";

export const userSelectors = {
  userId: (state: RootState): string | null => state.user.id,
  name: (state: RootState): string | undefined => state.user.name,
  email: (state: RootState): string | undefined => state.user.email,
  isInternalUser: (state: RootState): boolean | undefined =>
    state.user.isInternalUser,
  featureFlags: (state: RootState): Partial<Record<FeatureFlag, boolean>> =>
    state.user.currentOrganisation?.featureFlags || {},
  someOrganisationsContainHubFeatureFlag: (state: RootState): boolean =>
    Boolean(
      state.user.organisations?.some(
        (org) => org.featureFlags[HUB_FEATURE_FLAG]
      )
    ) || false,
  loginInvalid: (state: RootState) => state.user.invalidLogin,
  defaultEmissionOutputType: (state: RootState): emissionOutputType | null =>
    state.user.currentOrganisation?.defaultEmissionOutputType || null,
  currentOrganisation: (state: RootState): Entity | null =>
    state.user.currentOrganisation || null,
  organisations: (state: RootState): Entity[] | undefined =>
    state.user.organisations,
};
