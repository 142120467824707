import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Entity } from "types/Entity";

import { userThunks } from "store/user/userThunks";

export type UserSliceState = {
  id: string | null;
  currentOrganisation: Entity | null;
  invalidLogin: boolean;
  isInternalUser?: boolean;
  name?: string;
  email?: string;
  organisations?: Entity[];
};

const initialState: UserSliceState = {
  id: null,
  currentOrganisation: null,
  invalidLogin: false,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setCurrentOrganisation: (state, action: PayloadAction<Entity>) => {
      state.currentOrganisation = action.payload;
    },
    setInvalidLogin: (state) => {
      state.invalidLogin = true;
    },
    resetOrganisation: () => {
      void 0;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      userThunks.fetchUserDetails.fulfilled,
      (state, { payload }) => {
        return { ...state, ...payload };
      }
    );
  },
});

export default userSlice.reducer;
