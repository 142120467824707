import api from "API";

import * as Files from "./payloads";

export const filesEndpoint = `/organisations/:org/files/`;

const extendedEndpoints = api.injectEndpoints({
  endpoints: (builder) => ({
    File: builder.query<Files.SavedFile, string>({
      query: (fileId) => ({
        url: filesEndpoint + `${fileId}`,
      }),
    }),
    uploadFile: builder.mutation<Files.SavedFile, { file: File }>({
      query: ({ file }) => {
        const formData = new FormData();
        formData.append("file", file);
        return {
          url: filesEndpoint,
          method: "POST",
          body: formData,
        };
      },
    }),
    uploadMultipleFile: builder.mutation<Files.SavedFile[], { files: File[] }>({
      query: ({ files }) => {
        const formData = new FormData();
        files.forEach((file) => {
          formData.append("files", file);
        });
        return {
          url: filesEndpoint + `bulk`,
          method: "POST",
          body: formData,
        };
      },
    }),
  }),
});

export const {
  useUploadFileMutation,
  useUploadMultipleFileMutation,
  useLazyFileQuery,
} = extendedEndpoints;
