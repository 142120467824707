import React from "react";
import styled from "styled-components";
import { H2SB } from "typography/headers";
import Badge, { BadgeColor } from "../Badge";

export interface HeaderProps {
  title: string;
  badge?: {
    color?: BadgeColor;
    content: string;
  };
}

const Header: React.FC<HeaderProps> = ({ badge, title, children }) => {
  return (
    <HeaderWrapper>
      <Title>
        {title}
        {badge && (
          <Badge size="small" color={badge.color || "initial"}>
            {badge.content}
          </Badge>
        )}
      </Title>
      {children}
    </HeaderWrapper>
  );
};

const HeaderWrapper = styled.header`
  padding: 20px 24px;
`;

const Title = styled(H2SB)`
  display: inline-flex;
  gap: 8px;
  align-items: center;
`;

export default Header;
