// Common Data / Filter Queries
import { GroupedOptionProps, StandardOptionProps } from "ui/forms/Filter";

import api from "API";

import * as Params from "../../params";
import * as Activities from "./payloads/Activity";
import * as Entities from "./payloads/Entity";
import * as EntityLocations from "./payloads/EntityLocations";
import * as GHGScope from "./payloads/GHGScope";
import * as LegacySubmissionSlots from "./payloads/LegacySubmissionSlots";
import * as ReportingYears from "./payloads/ReportingYear";
import * as SubmissionGroup from "./payloads/SubmissionGroup";
import * as SubmissionProgressTimePeriods from "./payloads/SubmissionProgressTimePeriod";
import { tagsToOptions } from "./utils";

const baseUrl = `/organisations/:org/`;
const hubBaseUrl = `/hub/`;
const submissionGroupsBaseUrl = baseUrl + `submission-groups/`;
const submissionSlotsBaseUrl = baseUrl + `submission-slots/`;
const submissionProgressBaseUrl = baseUrl + `submission-progress/`;
const activityMethodologies = baseUrl + `reporting-years/`;

export const urls = {
  reportingYears: baseUrl + `reporting-years`,
  entityLocations: baseUrl + `entity-locations`,
  ghgScopes: baseUrl + `ghg-scopes`,
  activities: baseUrl + `activities`,
  entities: baseUrl + `entities`,
  activityTags: baseUrl + `activity-tags`,
  submissionProgressTimePeriods: submissionProgressBaseUrl + `time-periods/`,
  emissionOutputTags: baseUrl + `emission-output-tags`,
  legacySubmissionSlotStatuses: baseUrl + `legacy-submission-slot-statuses`,
  transactionAuditStatuses: baseUrl + `transaction-audit-statuses`,
  legacySubmissionSlotLabels: baseUrl + `legacy-submission-slots/labels`,
  taskAssignees: hubBaseUrl + `data-task-assignees`,
  taskSubmissionGroups: hubBaseUrl + `data-task-submission-groups`,
  taskSubmissionSlots: hubBaseUrl + `data-task-submission-slots`,
  submissionCadence: submissionGroupsBaseUrl + `cadences`,
  submissionGroupsStatus: submissionGroupsBaseUrl + `statuses`,
  submissionSlotsStatus: submissionSlotsBaseUrl + `statuses`,
  submissionGroups: submissionGroupsBaseUrl + `filter-options`,
  documentCategories: baseUrl + `document-categories`,
  documentTags: baseUrl + `document-tags`,
  documentUsers: baseUrl + `documents/users`,
  activityDataMetadata: baseUrl + `activity-data-metadata`,
};

const extendedEndpoints = api.injectEndpoints({
  endpoints: (builder) => ({
    reportingYears: builder.query<ReportingYears.GetReportingYears, void>({
      query: () => urls.reportingYears,
      // keepUnusedDataFor: 60,
    }),
    entityLocations: builder.query<StandardOptionProps["options"], void>({
      query: () => urls.entityLocations,
      keepUnusedDataFor: 60,
      transformResponse(original: EntityLocations.GetEntityLocations) {
        return original
          .map(({ city }) => (city ? { label: city, value: city } : null))
          .filter(Boolean) as StandardOptionProps["options"];
      },
    }),
    ghgScopes: builder.query<StandardOptionProps["options"], void>({
      query: () => urls.ghgScopes,
      keepUnusedDataFor: 60,
      transformResponse(original: GHGScope.GetGHGScopes) {
        return original.map((scope) => ({
          label: `Scope ${scope}`,
          value: scope.toString(),
        }));
      },
    }),
    activities: builder.query<StandardOptionProps["options"], void>({
      query: () => urls.activities,
      keepUnusedDataFor: 60,
      transformResponse(original: Activities.GetActivities) {
        return original.map(({ id, name }) => ({ label: name, value: id }));
      },
    }),
    activityMethodologies: builder.query<
      StandardOptionProps["options"],
      string
    >({
      query: (reportingYearId) =>
        `${activityMethodologies}${reportingYearId}/activity-methodologies`,
      keepUnusedDataFor: 60,
      transformResponse(original: { id: string; label: string }[]) {
        return original.map(({ id, label }) => ({ label, value: id }));
      },
    }),
    entities: builder.query<StandardOptionProps["options"], void>({
      query: () => urls.entities,
      keepUnusedDataFor: 60,
      transformResponse(original: Entities.GetEntities) {
        return original.map(({ id, name }) => ({ label: name, value: id }));
      },
    }),
    activityTags: builder.query<GroupedOptionProps["groups"], void>({
      query: () => urls.activityTags,
      keepUnusedDataFor: 60,
      transformResponse: tagsToOptions,
    }),
    emissionOutputTags: builder.query<GroupedOptionProps["groups"], void>({
      query: () => urls.emissionOutputTags,
      keepUnusedDataFor: 60,
      transformResponse: tagsToOptions,
    }),
    legacySubmissionSlotStatuses: builder.query<
      StandardOptionProps["options"],
      void
    >({
      query: () => urls.legacySubmissionSlotStatuses,
      keepUnusedDataFor: 60,
    }),
    transactionAuditStatuses: builder.query<
      StandardOptionProps["options"],
      void
    >({
      query: () => urls.transactionAuditStatuses,
      keepUnusedDataFor: 60,
    }),
    legacySubmissionSlotLabels: builder.query<
      StandardOptionProps["options"],
      void
    >({
      query: () => urls.legacySubmissionSlotLabels,
      keepUnusedDataFor: 60,
      transformResponse(
        original: LegacySubmissionSlots.GetLegacySubmissionSlotLabels
      ) {
        return original.map(({ label }) => ({ label: label, value: label }));
      },
    }),
    timePeriods: builder.query<StandardOptionProps["options"], string>({
      query: (submissionGroupId) =>
        `${submissionGroupsBaseUrl}${submissionGroupId}/submission-slots/time-periods/`,
      keepUnusedDataFor: 60,
      transformResponse(original: string[]) {
        return original.map((timePeriod) => ({
          label: timePeriod,
          value: timePeriod,
        }));
      },
    }),
    taskAssignees: builder.query<StandardOptionProps["options"], void>({
      query: () => urls.taskAssignees,
      keepUnusedDataFor: 60,
      transformResponse(original: string[]) {
        return original.map((assignee) => {
          return { label: assignee, value: assignee };
        });
      },
    }),
    taskSubmissionGroups: builder.query<StandardOptionProps["options"], void>({
      query: () => urls.taskSubmissionGroups,
      keepUnusedDataFor: 60,
      transformResponse(original: string[]) {
        return original.map((submissionGroup) => {
          return { label: submissionGroup, value: submissionGroup };
        });
      },
    }),
    taskSubmissionSlots: builder.query<StandardOptionProps["options"], void>({
      query: () => urls.taskSubmissionSlots,
      keepUnusedDataFor: 60,
      transformResponse(original: string[]) {
        return original.map((submissionSlot) => {
          return { label: submissionSlot, value: submissionSlot };
        });
      },
    }),
    taskReportingYears: builder.query<
      ReportingYears.GetReportingYears,
      Params.GenericParamMap
    >({
      query: ({ organisationId }) =>
        `/organisations/${organisationId}/reporting-years/`,
    }),
    submissionCandence: builder.query<StandardOptionProps["options"], void>({
      query: () => urls.submissionCadence,
      keepUnusedDataFor: 60,
      transformResponse(original: SubmissionGroup.GetSubmissionGroup) {
        return original.map(({ label, value }) => {
          return { label: label, value: value };
        });
      },
    }),
    submissionGroupsStatus: builder.query<StandardOptionProps["options"], void>(
      {
        query: () => urls.submissionGroupsStatus,
        keepUnusedDataFor: 60,
        transformResponse(original: SubmissionGroup.GetSubmissionGroup) {
          return original.map(({ label, value }) => {
            return { label: label, value: value };
          });
        },
      }
    ),
    submissionSlotsStatus: builder.query<StandardOptionProps["options"], void>({
      query: () => urls.submissionSlotsStatus,
      keepUnusedDataFor: 60,
      transformResponse(original: SubmissionGroup.GetSubmissionGroup) {
        return original.map(({ label, value }) => {
          return { label: label, value: value };
        });
      },
    }),
    submissionPeriods: builder.query<StandardOptionProps["options"], void>({
      query: () => urls.submissionProgressTimePeriods,
      keepUnusedDataFor: 60,
      transformResponse(
        original: SubmissionProgressTimePeriods.GetSubmissionProgressTimePeriods
      ) {
        return original.map((timePeriod) => ({
          label: timePeriod.label,
          value: timePeriod.id,
        }));
      },
    }),
    submissionGroupsFilter: builder.query<StandardOptionProps["options"], void>(
      {
        query: () => urls.submissionGroups,
        keepUnusedDataFor: 60,
        transformResponse(original: SubmissionGroup.GetSubmissionGroup) {
          return original.map(({ label, value }) => {
            return { label: label, value: value };
          });
        },
      }
    ),
    documentCategories: builder.query<StandardOptionProps["options"], void>({
      query: () => urls.documentCategories,
      keepUnusedDataFor: 60,
      transformResponse(original: { id: string; name: string }[]) {
        return original.map(({ id, name }) => ({ label: name, value: id }));
      },
    }),
    documentTags: builder.query<StandardOptionProps["options"], void>({
      query: () => urls.documentTags,
      keepUnusedDataFor: 0,
      transformResponse(original: { value: string; label: string }[]) {
        return original.map(({ value, label }) => ({ label, value }));
      },
    }),
    documentUsers: builder.query<StandardOptionProps["options"], void>({
      query: () => urls.documentUsers,
      keepUnusedDataFor: 60,
      transformResponse(original: { value: string; label: string }[]) {
        return original.map(({ value, label }) => ({ label, value }));
      },
    }),
    activityDataMetadata: builder.query<
      { [key: string]: string[] },
      Params.GenericParamMap
    >({
      query: (query) => ({
        url: urls.activityDataMetadata,
        params: {
          activity_methodology: Params.toString(query.activity_methodology),
        },
      }),
    }),
  }),
});

export const {
  useEntityLocationsQuery,
  useGhgScopesQuery,
  useActivityTagsQuery,
  useEmissionOutputTagsQuery,
  useActivitiesQuery,
  useActivityMethodologiesQuery,
  useEntitiesQuery,
  useTransactionAuditStatusesQuery,
  useReportingYearsQuery,
  useLegacySubmissionSlotStatusesQuery,
  useLegacySubmissionSlotLabelsQuery,
  useSubmissionPeriodsQuery,
  useSubmissionGroupsFilterQuery,
  useTimePeriodsQuery,
  useTaskAssigneesQuery,
  useTaskSubmissionGroupsQuery,
  useTaskSubmissionSlotsQuery,
  useTaskReportingYearsQuery,
  useSubmissionCandenceQuery,
  useSubmissionGroupsStatusQuery,
  useSubmissionSlotsStatusQuery,
  useDocumentCategoriesQuery,
  useDocumentTagsQuery,
  useDocumentUsersQuery,
  useActivityDataMetadataQuery,
} = extendedEndpoints;
