import { AtlasGraphOrganisation } from "types/Atlas";
import { Entity } from "types/Entity";

const organisationIncludesId = (
  organisation: AtlasGraphOrganisation,
  searchId: string
): AtlasGraphOrganisation[] | null => {
  if (organisation.entity.id === searchId) {
    return [organisation];
  } else {
    let childContainingId: AtlasGraphOrganisation[] | null = null;
    organisation.children.forEach((childOrganisation) => {
      const searchResult = organisationIncludesId(childOrganisation, searchId);
      if (searchResult) {
        childContainingId = searchResult;
      }
    });
    return childContainingId ? [organisation, ...childContainingId] : null;
  }
};

export const getSelectedRoute = (
  graph: AtlasGraphOrganisation,
  selectedEntry?: Entity
): AtlasGraphOrganisation[] => {
  return organisationIncludesId(graph, selectedEntry?.id ?? "") || [];
};
