import React from "react";

import { Filter } from "ui/forms";

import { EntityCustomAttribute as EntityCustomAttributeType } from "API/Endpoints/EntityCustomAttributes/payloads";

import useFilterParam from "./useFilterParam";

const EntityCustomAttribute: React.FC<{
  customAttribute: EntityCustomAttributeType;
}> = ({ customAttribute }) => {
  const data = customAttribute.attributeValues.map((attribute) => {
    return {
      label: attribute.value,
      value: attribute.value,
    };
  });
  const [selected, setSelected] = useFilterParam(customAttribute.value, data, {
    defaultToFirst: false,
    multi: true,
    ready: true,
  });

  return (
    <Filter
      items={{ options: data }}
      title={customAttribute.label}
      label={customAttribute.label}
      selected={!!selected.length}
      count={selected.length ? selected.length : void 0}
      onChange={setSelected}
      disabled={!data.length || !selected}
      value={selected}
      multi={true}
      showReset={true}
      showApply={true}
      searchable={true}
    />
  );
};

export default EntityCustomAttribute;
