import React, { ChangeEvent, useEffect, useState } from "react";
import { H4M } from "typography/headers";
import styled from "styled-components";
import cssVar from "theme/vars";
import { useDebouncedCallback } from "use-debounce";

export interface TextAreaProps {
  label?: string;
  onChange?: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  value?: string;
  placeholder?: string;
  className?: string;
  rows?: number;
  disabled?: boolean;
  debounce?: boolean;
  debounceTime?: number;
}

const TextArea: React.FC<TextAreaProps> = ({
  rows,
  label,
  value,
  onChange,
  placeholder,
  disabled,
  debounce,
  debounceTime,
}) => {
  const [internalValue, setInternalValue] = useState<string>(value || "");

  useEffect(() => {
    setInternalValue(value || "");
  }, [value]);

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInternalValue(e.target.value);
    //if the input doesn't have a debounce then we should call onchange instantly
    if (onChange && !debounce) {
      onChange(e);
    }
  };

  const debouncedOnChange = useDebouncedCallback(() => {
    if (onChange && internalValue !== value) {
      // @ts-ignore
      onChange({ target: { value: internalValue } });
    }
  }, debounceTime || 500);

  useEffect(() => {
    if (debounce) {
      debouncedOnChange();
    }
  }, [debounce, debouncedOnChange, internalValue]);

  return (
    <div>
      {label && <Label> {label}</Label>}
      <Component
        rows={rows || 5}
        value={internalValue}
        onChange={handleChange}
        placeholder={placeholder}
        disabled={disabled}
      />
    </div>
  );
};

const Label = styled(H4M)`
  color: ${cssVar("color/gray/60")};
  margin-bottom: 6px;
`;

const Component = styled.textarea`
  width: 100%;
  padding: 12px 14px;
  background-color: ${cssVar("color/primary/white")};
  color: ${cssVar("color/gray/100")};
  border: 1px solid ${cssVar("color/gray/30")};
  outline: none;
  border-radius: 8px;

  font-family: "InterVariable", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;

  &:active {
    outline: 1px solid ${cssVar("color/primary/blue")};
  }

  &:focus {
    outline: 1px solid ${cssVar("color/primary/blue")};
  }

  &:disabled {
    background-color: ${cssVar("color/primary/superSilverGrey")};
    box-shadow: none;
    border-color: ${cssVar("color/primary/grey")};
    cursor: not-allowed;
    color: ${cssVar("color/primary/darkGrey")};
  }
`;

export default TextArea;
