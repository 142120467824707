import api from "API";

import * as Params from "../../params";
import { Document, DocumentFile, GetDocument, GetDocuments } from "./payloads";

export const documentsEndpoint = "/organisations/:org/documents/";

const extendedEndpoints = api.injectEndpoints({
  endpoints: (builder) => ({
    documents: builder.query<GetDocuments, Params.GenericParamMap>({
      query: (query) => ({
        url: documentsEndpoint,
        method: "GET",
        params: {
          page: query.page,
          page_size: query.page_size,
          category: Params.toArray(query.doc_category),
          entities: Params.toArray(query.entity),
          tags: Params.toArray(query.doc_tag),
          uploaded_by: Params.toArray(query.doc_uploaded_by),
          uploaded_at: Params.toString(query.doc_uploaded_at),
          valid_from: Params.toString(query.valid_from),
          valid_to: Params.toString(query.valid_to),
        },
      }),
    }),
    document: builder.query<GetDocument, Params.GenericParamMap>({
      query: ({ documentId }) => ({
        url: documentsEndpoint + `${documentId}`,
        method: "GET",
      }),
      keepUnusedDataFor: 0,
    }),
    documentDownload: builder.query<DocumentFile, Params.GenericParamMap>({
      query: ({ documentId }) => {
        return {
          url: documentsEndpoint + `${documentId}/download`,
        };
      },
    }),
    documentDelete: builder.mutation<DocumentFile, Params.GenericParamMap>({
      query: ({ documentId }) => {
        return {
          url: documentsEndpoint + `${documentId}`,
          method: "DELETE",
        };
      },
    }),
    documentUpload: builder.mutation<Document, Params.GenericParamMap>({
      query: ({
        file,
        description,
        category,
        entities,
        tags,
        validFrom,
        validTo,
      }) => {
        return {
          url: documentsEndpoint,
          method: "POST",
          body: {
            file,
            description,
            category,
            entities,
            tags,
            valid_from: validFrom,
            valid_to: validTo,
          },
        };
      },
    }),
    documentUpdate: builder.mutation<Document, Params.GenericParamMap>({
      query: ({
        documentId,
        description,
        category,
        entities,
        tags,
        validFrom,
        validTo,
      }) => {
        return {
          url: documentsEndpoint + `${documentId}`,
          method: "PATCH",
          body: {
            description,
            category,
            entities,
            tags,
            valid_from: validFrom,
            valid_to: validTo,
          },
        };
      },
    }),
  }),
});

export const {
  useDocumentsQuery,
  useDocumentQuery,
  useLazyDocumentDownloadQuery,
  useDocumentDeleteMutation,
  useDocumentUploadMutation,
  useDocumentUpdateMutation,
} = extendedEndpoints;
