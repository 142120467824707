import api from "API";

import * as Params from "../../params";
import * as Emissions from "./payloads";

export const emissionsEndpoint = `/organisations/:org/emissions/`;
export const emissionsDetailsEndpoint = `/organisations/:org/emission-outputs/`;
export const emissionsExportEndpoint = emissionsDetailsEndpoint + `export/`;

const extendedEndpoints = api.injectEndpoints({
  endpoints: (builder) => ({
    emissions: builder.query<
      Emissions.GetEmissions,
      Params.GenericParamMap<Emissions.GetEmissionsParams>
    >({
      query: (query) => ({
        url: emissionsEndpoint,
        params: {
          page: query.page,
          date_from: Params.toString(query.date_from),
          date_to: Params.toString(query.date_to),
          group_by: Params.toString(query.group_by),
          ghg_scope: Params.toList(query.scope),
          tags: Params.toJSONArray(query.tags),
          location: Params.toArray(query.location),
          ordering: Params.toString(query.ordering),
          emission_output_type: Params.toString(query.emission_output_type),
          activity: Params.toArray(query.activity),
          entity: Params.toArray(query.entity),
        },
      }),
      providesTags: ["Emissions"],
    }),
    emissionDetails: builder.query<Emissions.GetEmissionDetails, string>({
      query: (id) => ({
        url: emissionsDetailsEndpoint + `${id}`,
      }),
      providesTags: (result) => [{ type: "Emissions", id: result?.id }],
    }),
    emissionExport: builder.query<Blob, void>({
      query: () => ({
        url: emissionsExportEndpoint,
        responseHandler: (response: Response) => response.blob(),
      }),
    }),
    submissionLog: builder.query<
      Emissions.getSubmissionLog,
      Params.GenericParamMap
    >({
      query: (query) => ({
        url: emissionsEndpoint + `${query.id}` + "/submission-log",
        method: "GET",
      }),
    }),
  }),
});

export const {
  useEmissionsQuery,
  useEmissionDetailsQuery,
  useLazyEmissionExportQuery,
  useSubmissionLogQuery,
} = extendedEndpoints;
