import React from "react";

import SvgCalendar from "icons/lib/Calendar";
import styled from "styled-components";
import cssVar from "theme/vars";
import { H4M } from "typography/headers";
import { Filter } from "ui/forms";

import { useReportingYearsQuery } from "API";

import useFilterParam from "./useFilterParam";

const ReportingYear: React.FC = () => {
  const {
    data = [],
    isSuccess,
    isError,
  } = useReportingYearsQuery(void 0, {
    selectFromResult: (result) => ({
      ...result,
      data: result.data?.map((item) => ({
        value: item.id,
        label: item.label ?? "Unknown",
        startDate: item.startDate ?? "Unknown",
        endDate: item.endDate ?? "Unknown",
      })),
    }),
  });
  const [selected, setSelected] = useFilterParam("reporting_year", data, {
    defaultToFirst: true,
    ready: isSuccess || isError,
  });
  const selectedReportingYear = data.find((item) => item.value === selected);

  return (
    <ReportingYearContainer>
      <ReportingYearLabel>
        <Icon>
          <SvgCalendar width={12} height={13} />
        </Icon>
        <Title>Reporting year:</Title>
      </ReportingYearLabel>
      <ReportingYearFilter
        items={{ options: data }}
        title="Reporting year"
        label={selectedReportingYear?.label || ""}
        onChange={setSelected}
        value={selected ?? ""}
        disabled={!isSuccess || !selected}
        selected={!!selected}
      />
    </ReportingYearContainer>
  );
};

const ReportingYearContainer = styled.div`
  display: inline-flex;
  align-items: center;
  height: 32px;
  border-radius: 4px;
`;

const ReportingYearLabel = styled.div`
  display: inline-flex;
  align-items: center;
  padding: 12px;
  height: 32px;
  border-radius: 4px 0px 0px 4px;
  border-width: 1px 0px 1px 1px;
  border-style: solid;
  border-color: ${cssVar("color/primary/skinnyBlue")};
`;

const Title = styled(H4M)`
  padding-left: 4px;
  color: ${cssVar("color/primary/darkGrey")};
`;

const Icon = styled.div`
  padding-top: 1px;
  color: ${cssVar("color/primary/darkGrey")};
`;

const ReportingYearFilter = styled(Filter)`
  height: 32px;
  border-radius: 0px 4px 4px 0px;
  background-color: ${cssVar("color/primary/superSilverGrey")};
  border: 1px solid ${cssVar("color/primary/skinnyBlue")};

  &:hover {
    background-color: ${cssVar("color/primary/silverGrey")};
    border-color: ${cssVar("color/gray/40")};
  }
`;

export default ReportingYear;
