// https://stackoverflow.com/questions/58434389/typescript-deep-keyof-of-a-nested-object
// Helpers to create a string literal type from an object
export type Join<K, P> = K extends string | number
  ? P extends string | number
    ? `${K}${"" extends P ? "" : "/"}${P}`
    : never
  : never;

type Prev = [
  never,
  0,
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
  ...0[]
];

export type Paths<T, D extends number = 10> = [D] extends [never]
  ? never
  : T extends object
  ? {
      [K in keyof T]-?: K extends string | number
        ? `${K}` | Join<K, Paths<T[K], Prev[D]>>
        : never;
    }[keyof T]
  : "";

export type Leaves<T, D extends number = 10> = [D] extends [never]
  ? never
  : T extends object
  ? { [K in keyof T]-?: Join<K, Leaves<T[K], Prev[D]>> }[keyof T]
  : "";

interface Flattenable {
  [key: string]: string | Flattenable;
}
export function flatten(obj: Flattenable, prefix = ""): [string, string][] {
  return Array.from(Object.entries(obj)).reduce(
    (acc: [string, string][], [key, value]) => {
      const theKey = `${prefix ? `${prefix}/` : ""}${key}`;
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
      const next = (
        typeof value === "string" ? [[theKey, value]] : flatten(value, theKey)
      ) as [string, string][];
      return [...acc, ...next];
    },
    []
  );
}

export function extract(obj: Flattenable, path: string): string {
  const keys = path.split("/");
  let result: string | Flattenable = obj;

  for (const key of keys) {
    if (typeof result === "string") {
      return result;
    }

    result = result[key] ?? "";
  }

  if (typeof result !== "string") {
    throw new Error(`Path "${path}" not specific enough`);
  }

  // Due to the type-safety of ColorKey we will ALWAYS get a string
  return result;
}

/**
 * Utility method to apply an "as" prop to a parent component but not override
 * any further parent settings
 */
export function composed(tag: string) {
  return ({ as: override }: { as?: string }) => ({
    as: override ?? tag,
  });
}
