import React from "react";
import styled from "styled-components";

export interface SpinnerProps {
  className?: string;
}

const LINE_COUNT = 8;
const LINE_ARRAY = Array.from(new Array(LINE_COUNT));
const ANIMATION_DURATION = 1.5;
const ANIMATION_VALUES = [2, 3, 4, 5, 6, 6, 5, 4, 3, 2, 2];

const Spinner: React.FC<SpinnerProps> = ({ className }) => {
  return (
    <SpinnerWrapper className={className}>
      <svg viewBox="0 0 24 24" role="progressbar">
        {LINE_ARRAY.map((_value, index) => {
          // Re-order values to stagger as delay/start on <animate />
          // causes all items to freeze until they can start
          const values = [
            ...ANIMATION_VALUES.slice(index),
            ...ANIMATION_VALUES.slice(0, index),
            ANIMATION_VALUES[index] ?? 2,
          ];
          return (
            <rect
              key={index}
              x="18"
              y="11"
              width="6"
              height="2.35"
              fill="currentColor"
              ry="1"
              rx="1"
              stroke="0"
              transform={`rotate(${(-360 / LINE_COUNT) * index}, 12, 12)`}
            >
              <animate
                attributeName="width"
                values={values.join(";")}
                dur={`${ANIMATION_DURATION}s`}
                repeatCount="indefinite"
                fill="freeze"
              />
            </rect>
          );
        })}
      </svg>
    </SpinnerWrapper>
  );
};

const SpinnerWrapper = styled.div`
  display: inline-block;
  width: 24px;
  height: 24px;

  svg {
    width: 100%;
    height: 100%;
    display: block;
  }
`;

export default Spinner;
