import { SVGProps } from "react";
interface SvgBarChartProps extends SVGProps<SVGSVGElement> {
  /**
   * Width of icon, uses size if not given
   */
  width?: number;
  /**
   * Height of icon, uses size if not given
   */
  height?: number;
  /**
   * Size of icon, applies to width & height if not supplied
   * @default 24
   */
  size?: number;
}
export const SvgBarChart = (props: SvgBarChartProps) => (
  <svg
    {...props}
    width={props.width ?? props.size ?? 24}
    height={props.height ?? props.size ?? 24}
    viewBox="0 0 48 48"
    fill="currentColor"
  >
    <path d="M11.5 40q-1.45 0-2.475-1.025Q8 37.95 8 36.5v-15q0-1.45 1.025-2.475Q10.05 18 11.5 18q1.45 0 2.475 1.025Q15 20.05 15 21.5v15q0 1.45-1.025 2.475Q12.95 40 11.5 40ZM24 40q-1.45 0-2.475-1.025Q20.5 37.95 20.5 36.5v-25q0-1.45 1.025-2.475Q22.55 8 24 8q1.45 0 2.475 1.025Q27.5 10.05 27.5 11.5v25q0 1.45-1.025 2.475Q25.45 40 24 40Zm12.5 0q-1.45 0-2.475-1.025Q33 37.95 33 36.5v-7q0-1.45 1.025-2.475Q35.05 26 36.5 26q1.45 0 2.475 1.025Q40 28.05 40 29.5v7q0 1.45-1.025 2.475Q37.95 40 36.5 40Z" />
  </svg>
);
export default SvgBarChart;
