import { ChevronRight } from "icons";
import styled, { css, keyframes } from "styled-components";
import { scrollbars } from "theme/mixins";
import cssVar from "theme/vars";
import { H3R, H4M, H4R } from "typography/headers";
import { Modal, Table, Tabs, Tooltip } from "ui/core";

import Filters from "Common/Filters";

export const NavContainer = styled.div`
  position: sticky;
  top: 0;
  background-color: ${cssVar("color/primary/superSilverGrey")};
  border-bottom: 1px solid ${cssVar("color/gray/30")};
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 48px;
  isolation: isolate;
  z-index: 99;
`;

export const Logo = styled.img`
  width: 126px;
  height: 18px;
  line-height: 0;
  margin-right: 36px;
  cursor: pointer;
`;

export const NavLine = styled.div`
  border-left: 1px solid ${cssVar("color/gray/50")};
  height: 16px;
  margin: 0px 16px;
`;

export const SwitcherButton = styled(H4R)<{
  $open: boolean;
}>`
  display: flex;
  align-items: center;
  color: ${cssVar("color/gray/60")};

  &:hover {
    color: ${cssVar("color/primary/black")};
    text-decoration: underline;
    cursor: pointer;
  }

  ${(props) =>
    props.$open &&
    css`
      color: ${cssVar("color/primary/black")};
    `}
`;

export const SwitcherChevron = styled(ChevronRight).attrs({
  "aria-hidden": true,
})<{
  $open: boolean;
}>`
  display: block;
  width: 20px;
  height: 20px;
  color: ${cssVar("color/gray/60")};
  transform: rotate(${(props) => (props.$open ? -90 : 90)}deg);
  transition: transform 0.2s;
  margin-left: 8px;
`;

const reveal = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
`;

export const SwitcherDropdownMenuWrapper = styled.div.attrs({
  role: "dialog",
})<{
  $open: boolean;
}>`
  position: fixed;
  z-index: 99;
  top: var(--top);
  right: 48px;
  margin-top: 8px;
  animation: ${reveal} 0.3s ease backwards;

  width: 280px;
  max-height: min(576px, 69vh);
  background: ${cssVar("color/primary/white")};
  border: 1px solid ${cssVar("color/primary/disabledGrey")};
  box-shadow: 0px 4px 8px rgba(0, 0, 8, 0.08);
  border-radius: 4px;

  display: ${(props) => (props.$open ? "flex" : "none")};
  flex-direction: column;
  overflow: hidden;
  padding: 8px 0px;
`;

export const SwitcherDropdownOptionsList = styled.div`
  max-height: 440px;
  overflow: auto;
  ${scrollbars()}
  // Offset to allow padding on scroll bar
  margin-right: -12px;
  &:empty {
    display: none;
  }
`;

export const SubTabs = styled(Tabs)`
  background-color: transparent;
  border-bottom: 1px solid ${cssVar("color/gray/30")};
`;

export const ExpandableFilterWrapper = styled(Filters)`
  border: none;
  margin-bottom: 0;

  &.fullWidth {
    margin-left: -48px;
    margin-right: -48px;
    padding: 16px 48px 12px;
    border-bottom: 1px solid #eaecf0;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.04);
    border-radius: 0;
  }
`;

export const FilterText = styled(H4M)`
  color: ${cssVar("color/gray/60")};
  margin-right: 4px;
`;

export const HeadCell = styled(Table.HeadCell)<{
  $borderTopNone?: boolean;
  $noWrap?: boolean;
  $right?: boolean;
}>`
  color: ${cssVar("color/gray/80")};
  background-color: ${cssVar("color/primary/white")};

  ${(props) =>
    props.$borderTopNone &&
    css`
      border-top: none;
    `}

  ${(props) =>
    props.$noWrap &&
    css`
      padding: 5px;
      justify-content: center;
      border-top: none;

      > span {
        white-space: nowrap;
      }
    `}

  ${(props) =>
    props.$right &&
    css`
      justify-content: flex-end;
      text-align: right;
    `}
`;

export const ModifiedHeadCell = styled(HeadCell)`
  justify-content: start !important;
`;

export const ModifiedCondensedCell = styled(Table.CondensedCell)<{
  $isPositive?: boolean;
}>`
  padding: 5px;
  justify-content: center;
  height: 54px;

  ${(props) =>
    props.$isPositive &&
    css`
      border: 1px solid ${cssVar("color/success/60")};
    `};
`;

export const ListEntry = styled(Table.CondensedCell)`
  color: ${cssVar("color/primary/black")};
  border-left: 1px solid ${cssVar("color/gray/30")};
  border-right: 1px solid ${cssVar("color/gray/30")};

  &:first-of-type {
    border-top: 1px solid ${cssVar("color/gray/30")};
    border-radius: 8px 8px 0px 0px;
  }

  &:last-of-type {
    border-radius: 0px 0px 8px 8px;
    margin-bottom: 24px;
  }

  &:only-of-type {
    border-radius: 8px;
  }
`;

export const DetailWrapper = styled.div`
  margin: 20px 0;
`;

export const DetailItem = styled("div")`
  display: flex;
  align-items: center;
  margin: 8px 0;
  flex-wrap: wrap;
`;

export const DetailItemName = styled(H3R)`
  color: ${cssVar("color/gray/60")};
  padding-right: 4px;
`;

export const DownloadFileSection = styled("div")<{ $disabled?: boolean }>`
  color: ${cssVar("color/primary/blue")};
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }

  ${(props) =>
    props.$disabled &&
    css`
      color: ${cssVar("color/primary/grey")};
      cursor: not-allowed;

      &:hover {
        text-decoration: none;
      }
    `}
`;
export const PreviewContainer = styled("div")<{ $disabled?: boolean }>`
  margin-top: 8px;
  margin-right: 8px;
  color: ${cssVar("color/primary/blue")};
  cursor: pointer;

  ${(props) =>
    props.$disabled &&
    css`
      color: ${cssVar("color/primary/grey")};
      cursor: not-allowed;
    `}
`;
export const ActionableCellWrapper = styled.div`
  color: ${cssVar("color/primary/blue")};
  text-decoration: underline;
  cursor: pointer;
`;
export const ActionableIconCell = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;
export const CellIcon = styled.div`
  display: flex;
  align-items: center;
  margin-right: 8px;
`;
export const InfoTooltip = styled(Tooltip)`
  display: block;
  height: 16px;
`;

export const RightAlignedFilter = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
`;

export const ActionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

export const FieldWrapper = styled.div<{ $flex?: boolean }>`
  margin: 20px 0;

  ${({ $flex }) =>
    $flex &&
    css`
      display: flex;

      > div {
        width: 50%;
      }
      > div:first-child {
        margin-right: 16px;
      }
    `}
`;

export const NavHeaderBackground = styled.div`
  position: sticky;
  z-index: 1;
  top: 0;
  width: 100%;
  height: 52px;
  border-bottom: 1px solid ${cssVar("color/gray/30")};
  background-color: ${cssVar("color/primary/superSilverGrey")};
`;

export const FilterVerticalLine = styled.div`
  border-left: 1px solid ${cssVar("color/gray/50")};
  height: 16px;
`;

export const Indicator = styled.div<{ $open: boolean }>`
  position: absolute;
  cursor: pointer;
  width: 24px;
  height: 24px;
  border: 0;
  padding: 0;
  margin: 0;
  left: 12px;
  // top: 51.5%;
  margin-top: -12px;
  overflow: hidden;
  font-size: 10px;
  line-height: 24px;
  transform: rotate(0deg);
  transition: 0.4s ease;

  ${(props) =>
    props.$open &&
    css`
      transform: rotate(180deg);
    `}

  svg {
    width: 24px;
    height: 24px;
    color: ${cssVar("color/primary/blue")};
    transform: rotate(-90deg);
  }
`;

export const ShowMoreNumber = styled.span<{
  $textColor?: string;
  $cursorText?: boolean;
}>`
  color: ${cssVar("color/primary/blue")};
  text-decoration: underline;
  cursor: pointer;

  ${(props) =>
    props.$textColor &&
    css`
      color: ${props.$textColor};
    `}

  ${(props) =>
    props.$cursorText &&
    css`
      cursor: text;
    `}
`;

export const FilePreviewModalBody = styled(Modal.Body)`
  table,
  th,
  td {
    border: 1px solid ${cssVar("color/gray/30")};
    border-collapse: collapse;
  }

  #msdoc-renderer {
    height: 100%;
  }

  #proxy-renderer {
    //unfortunately this is a bit of a 'magic' number as the underlying CSS collapses so we cant work with %'s etc
    min-height: 58vh;
  }
`;
