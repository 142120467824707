import { rem } from "polished";
import { createGlobalStyle } from "styled-components";
import cssVar from "theme/vars";

const DatePickerStyles = createGlobalStyle`
  .react-datepicker {
    position: relative;
  }
  .react-datepicker__week,
  .react-datepicker__day-names {
    display: grid;
    grid-auto-rows: 40px;
    grid-template-columns: repeat(7, 40px);
    margin-bottom: 4px;
    /* grid-gap: 16px; */
    &:last-child {
      margin-bottom: 0;
    }
  }

  .react-datepicker__day,
  .react-datepicker__day-name {
    display: grid;
    width: 40px;
    place-content: center;
    font-weight: 400;
    font-size: ${rem(14)};
    line-height: ${rem(20)};
    color: ${cssVar("color/gray/60")};
    cursor: pointer;
    border-radius: 50%;
  }

  .react-datepicker__aria-live {
    display: none;
  }

  .react-datepicker__month-container {
    box-sizing: border-box;
    padding: 24px;
    width: 50%;
    float: left;
    clear: left;
    &:last-child {
      clear: right;
      border-left: 1px solid ${cssVar("color/gray/20")};
    }
  }
  .react-datepicker__day {
    &:hover {
      background-color: ${cssVar("color/gray/30")};
      color: ${cssVar("color/gray/80")};
    }

    &--outside-month {
      color: ${cssVar("color/gray/40")};
      cursor: default;
      pointer-events: none;
    }

    &--in-range {
      font-weight: 500;
      &:not(.react-datepicker__day--outside-month) {
        background-color: ${cssVar("color/gray/10")};
        color: ${cssVar("color/gray/80")};
        // Show appearance of a long continous strip
        border-radius: 0;

        &:last-child:not(.react-datepicker__day--range-end) {
          border-radius: 0 50% 50% 0;
          .react-datepicker__day--outside-month + & {
            border-radius: 50%;
          }
        }
        &:first-child:not(.react-datepicker__day--range-end) {
          border-radius: 50% 0 0 50%;
        }

        // First item that appears mid-row if prefixed by outside month days
        .react-datepicker__day--outside-month + & {
          border-radius: 50% 0 0 50%;
        }

        // Last item that appears mid-row if appended by outside month days
        & + .react-datepicker__day--outside-month {
          position: relative;
          background-color: ${cssVar("color/gray/10")};
          z-index: -1;
          left: -30px;
        }
      }
    }

    &--selected,
    &--range-start,
    &--selecting-range-start,
    &--range-end {
      &:not(.react-datepicker__day--outside-month) {
        // Reset in-range styles
        border-radius: 50% !important;
        background-color: ${cssVar("color/primary/blue")} !important;
        color: ${cssVar("color/primary/white")} !important;


        // Apply a second background color to complete the range
        position: relative;
        &::before {
          content: "";
          position: absolute;
          width: 100%;
          height: 100%;
          background-color: ${cssVar("color/gray/10")};
          z-index: -1;
          border-radius: 50%;
        }
      }
    }

    // Round off inner highlight for range ends
    &--range-end::before {
      border-radius: 0 50% 50% 0 !important;
    }
    &--range-start::before {
      border-radius: 50% 0 0 50% !important;
    }
  }

  .react-datepicker__day-name {
    cursor: default;
    color: ${cssVar("color/gray/100")};
    font-weight: 500;
  }
`;

export default DatePickerStyles;
