import React from "react";

import styled from "styled-components";
import cssVar from "theme/vars";
import { H4M } from "typography/headers";
import { Filter } from "ui/forms";
import { GroupByOption, Option } from "ui/forms/Filter/Menu";

import useFilterParam from "./useFilterParam";

interface GroupProps {
  options: Option[] | GroupByOption[];
}

const Group: React.FC<GroupProps> = ({ options }) => {
  const [selected, setSelected] = useFilterParam("group_by", options, {
    defaultToFirst: true,
  });
  const selectedLabel = options.find((o) => o.value === selected)?.label ?? "";
  return (
    <RightAlignedFilter>
      <Label>Group by</Label>
      <Filter
        items={{ options }}
        title="Group by"
        label={`${selectedLabel}`}
        onChange={setSelected}
        value={selected ?? ""}
        showReset={true}
        showApply={true}
        anchor="right"
      />
    </RightAlignedFilter>
  );
};

const Label = styled(H4M)`
  margin-right: 8px;
  color: ${cssVar("color/gray/60")};
`;

const RightAlignedFilter = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
`;

export default Group;
