import React from "react";

import { Filter } from "ui/forms";

import { useTaskSubmissionGroupsQuery } from "API";

import useFilterParam from "./useFilterParam";

const TaskSubmissionGroup: React.FC = () => {
  const { data = [], isSuccess, isError } = useTaskSubmissionGroupsQuery();
  const [selected, setSelected] = useFilterParam("submissionGroup", data, {
    defaultToFirst: false,
    ready: isSuccess || isError,
  });
  const selectedLabel =
    data.find((o) => o.value === selected)?.label ?? "Submission Group";
  return (
    <Filter
      items={{ options: data }}
      title="Submission Group"
      label={selectedLabel}
      onChange={setSelected}
      selected={!!selected}
      disabled={!isSuccess}
      value={selected ?? ""}
      showReset={true}
      showApply={true}
      searchable={true}
    />
  );
};

export default TaskSubmissionGroup;
