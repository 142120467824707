import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import { DatePicker } from "ui/forms";

import { formattedDate } from "utilities/general";

import { QUERY_PARAM as PAGINATION_QUERY_PARAM } from "../Pagination";
import { useFilterUpdate } from "./slice";

const DocumentUploadedOn: React.FC = () => {
  const [query, setQuery] = useSearchParams();
  const updateFilters = useFilterUpdate();

  const uploadedAt = query.get("doc_uploaded_at");

  useEffect(() => {
    uploadedAt && updateFilters("doc_uploaded_at", uploadedAt);
    return () => {
      updateFilters("doc_uploaded_at", null);
    };
  }, [uploadedAt, updateFilters]);

  const onChange = (date: Date | null) => {
    const start = formattedDate(date);
    updateFilters("doc_uploaded_at", start);
    query.delete("doc_uploaded_at");
    start && query.set("doc_uploaded_at", start);
    // A change in filter means we should reset pagination
    query.delete(PAGINATION_QUERY_PARAM);
    setQuery(query, { replace: true });
  };

  return (
    <DatePicker
      placeholderText="Uploaded on"
      startDate={uploadedAt ? new Date(uploadedAt) : null}
      onSingleChange={onChange}
      single
    />
  );
};

export default DocumentUploadedOn;
