import api from "API";

import * as OutputApi from "./payloads";

const extendedEndpoints = api.injectEndpoints({
  endpoints: (builder) => ({
    apiClientSecret: builder.query<OutputApi.RequestSecret, void>({
      query: () => ({
        url: `auth/organisations/:org/api-client-secret/`,
        method: "POST",
      }),
    }),
  }),
});

export const { useLazyApiClientSecretQuery } = extendedEndpoints;
