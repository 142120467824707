import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { getSelectedRoute } from "Atlas/Utils";
import { AtlasGraphOrganisation } from "types/Atlas";

import { thunks } from "Atlas/store/Thunks";

export type AtlasSliceState = {
  loading: boolean;
  graph: AtlasGraphOrganisation | null;
  selectedOrganisation: AtlasGraphOrganisation | null;
  selectedRoute: AtlasGraphOrganisation[];
};

const initialState: AtlasSliceState = {
  graph: null,
  selectedOrganisation: null,
  loading: false,
  selectedRoute: [],
};

export const slice = createSlice({
  name: "atlas",
  initialState: initialState,
  reducers: {
    setSelectedEntity: (
      state,
      action: PayloadAction<AtlasGraphOrganisation>
    ) => {
      state.selectedOrganisation = action.payload;
      if (action.payload && state.graph) {
        state.selectedRoute = getSelectedRoute(
          state.graph,
          action.payload.entity
        );
      }
    },
    setRoute: (state, action: PayloadAction<AtlasGraphOrganisation[]>) => {
      state.selectedRoute = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(thunks.fetchGraph.pending, (state) => {
      state.loading = true;
      state.graph = null;
      state.selectedOrganisation = null;
    });
    builder.addCase(thunks.fetchGraph.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(thunks.fetchGraph.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.graph = payload;
      state.selectedOrganisation = payload;
      state.selectedRoute = getSelectedRoute(payload, payload.entity);
    });
  },
});

export default slice.reducer;
