import parseHTML, { Element, domToReact } from "html-react-parser";
import { Text } from "@react-pdf/renderer";

export function html2react(htmlString: string, whitelist: string[] = []) {
  whitelist = whitelist.map((s) => s.toLowerCase());
  const replace = (node: any) => {
    if (node instanceof Element) {
      if (!whitelist.includes(node.tagName.toLowerCase())) {
        return <>{domToReact(node.children, { replace })}</>;
      }
    }
  };
  return parseHTML(htmlString, {
    replace,
  });
}

export function html2pdf(
  htmlString: string,
  styles: Record<string, Record<string, string>>
) {
  let currentList = "";
  const replace = (node: any) => {
    if (node instanceof Element) {
      const baseStyles = styles.base ?? {};
      const tag = node.tagName.toLowerCase();
      const styleRules = styles[tag] ?? {};
      let prefix = "",
        suffix = "";

      let listWasSet = false;
      switch (tag) {
        case "ul":
          currentList = "ul";
          listWasSet = true;
          break;
        case "ol":
          currentList = "ol";
          listWasSet = true;
          break;
        case "li":
          if (currentList === "ul") {
            prefix = "• ";
          } else if (currentList === "ol") {
            const siblings =
              node.parent?.children.filter((t) => "name" in t) ?? [];
            const position = siblings.indexOf(node) ?? 0;
            prefix = `${position + 1}. `;
          }
          suffix = "\n";
          break;
      }

      try {
        return (
          <Text style={{ ...baseStyles, ...styleRules }}>
            <Text>{prefix}</Text>
            {domToReact(node.children, { replace })}
            <Text>{suffix}</Text>
          </Text>
        );
      } finally {
        if (listWasSet) {
          currentList = "";
        }
      }
    }
  };

  return parseHTML(htmlString, {
    replace,
  });
}
