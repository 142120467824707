import React, { ReactNode, useCallback, useContext, useEffect } from "react";
import { useSelector } from "react-redux";

import { AnalyticsBrowser } from "@segment/analytics-next";
import { AnalyticsEvent } from "Tracking/eventTypes";

import config from "config";

import { organisationSelectors } from "store/organisation/organisationSelectors";
import { userSelectors } from "store/user/userSelectors";

import { isDevelopment } from "utilities/utils";

type AnalyticsTrackPageProperties = { pageCategory?: string } & {
  [key: string]: string;
};

type AnalyticsContextState = {
  analytics: AnalyticsBrowser | null;
  trackPage: (
    pageName: string,
    properties?: AnalyticsTrackPageProperties
  ) => void;
  trackEvent: (
    _eventName: AnalyticsEvent,
    properties?: { [key: string]: string }
  ) => void;
};

const initialState: AnalyticsContextState = {
  analytics: null,
  trackPage: () => void 0,
  trackEvent: () => void 0,
};

export const AnalyticsContext =
  React.createContext<AnalyticsContextState>(initialState);

const AnalyticsProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const userId = useSelector(userSelectors.userId);
  const isInternalUser = useSelector(userSelectors.isInternalUser);
  const currentOrgId = useSelector(organisationSelectors.currentOrganisationId);
  const currentReportingYearId = useSelector(
    organisationSelectors.selectedReportingYearId
  );

  const analytics = React.useMemo(
    () =>
      config.segment.writeKey && !isDevelopment
        ? AnalyticsBrowser.load({
            writeKey: config.segment.writeKey,
            cdnURL: config.segment.cdn,
          })
        : null,
    []
  );

  const trackPage = useCallback(
    (pageName: string, properties?: AnalyticsTrackPageProperties) => {
      void analytics?.page(properties?.pageCategory, pageName, {
        currentOrgId,
        currentReportingYearId,
      });
    },
    [analytics, currentOrgId, currentReportingYearId]
  );

  const trackEvent = useCallback(
    (eventName: AnalyticsEvent, properties: { [key: string]: string } = {}) => {
      void analytics?.track(eventName, {
        currentOrgId,
        currentReportingYearId,
        ...properties,
      });
    },
    [analytics, currentOrgId, currentReportingYearId]
  );

  useEffect(() => {
    if (userId) {
      void analytics?.identify(userId, { isInternalUser });
    }
  }, [analytics, userId, isInternalUser]);

  return (
    <AnalyticsContext.Provider value={{ analytics, trackPage, trackEvent }}>
      {children}
    </AnalyticsContext.Provider>
  );
};

export const useAnalytics = () => useContext(AnalyticsContext);

export default AnalyticsProvider;
