import React, { useCallback, useRef, useState } from "react";
import { useSelector } from "react-redux";

import { userSelectors } from "store/user/userSelectors";

import { useElementBox } from "ui/hooks";
import { isAnyNonHubFeatureFlagEnabled } from "utilities/utils";

import { SwitcherButton, SwitcherChevron } from "Common/styles";

import { AppSwitcherDropdown } from "./AppSwitcherDropdown";

export const AppSwitcher: React.FC = () => {
  const [open, setOpen] = useState(false);
  const onToggle = useCallback(() => {
    setOpen((o) => !o);
  }, []);
  const onClose = useCallback(() => {
    setOpen(false);
  }, []);

  const nav = useRef<HTMLDivElement>(null);
  const position = useElementBox(nav, open);
  const flags = useSelector(userSelectors.featureFlags);
  const isNonHubFeatureFlagEnabled = isAnyNonHubFeatureFlagEnabled(flags);

  return (
    <>
      <SwitcherButton onClick={onToggle} ref={nav} $open={open}>
        Hub
        {isNonHubFeatureFlagEnabled && <SwitcherChevron $open={open} />}
      </SwitcherButton>
      {isNonHubFeatureFlagEnabled && (
        <AppSwitcherDropdown
          open={open}
          onClose={onClose}
          parent={nav}
          top={position?.bottom ?? 0}
          right={position?.right ?? 0}
        />
      )}
    </>
  );
};
