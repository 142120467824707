import { RefObject, useCallback, useLayoutEffect, useState } from "react";

export type Options = {
  scroll: boolean;
  resize: boolean;
  debounce: number;
};

const DEFAULT_OPTIONS: Options = {
  scroll: true,
  resize: true,
  debounce: 30,
};

/**
 * Grabs an elements bound rect and saves it to state
 * Updates on window resize and scroll, only triggering a change if a dimension
 * changes
 */
function useElementBox(
  element: RefObject<HTMLElement | null>,
  enabled = true,
  options: Partial<Options> = {}
): DOMRect | null {
  const { scroll, resize, debounce } = { ...DEFAULT_OPTIONS, ...options };
  const getBox = useCallback(() => {
    return element.current?.getBoundingClientRect() ?? null;
  }, [element]);
  const updateBox = useCallback(
    () =>
      setBox((current) => {
        const next = getBox();
        // If either the current or previous value is null, return it as it
        // react will not trigger an update
        if (!current || !next) {
          return next;
        }

        if (
          next.top === current.top &&
          next.left === current.left &&
          next.right === current.right &&
          next.bottom === current.bottom
        ) {
          // There hasn't been any change, so return the existing value,
          // preventing a render update
          return current;
        }

        return next;
      }),
    [getBox]
  );
  const [box, setBox] = useState(getBox);

  useLayoutEffect(() => {
    if (!enabled) {
      return;
    }

    updateBox();
    let timer: NodeJS.Timer;
    function update() {
      if (debounce > 0) {
        clearTimeout(timer);
        timer = setTimeout(updateBox, debounce);
      } else {
        updateBox();
      }
    }

    if (resize) {
      window.addEventListener("resize", update);
    }
    if (scroll) {
      window.addEventListener("scroll", update);
    }

    return () => {
      window.removeEventListener("resize", update);
      window.removeEventListener("scroll", update);
    };
  }, [enabled, debounce, scroll, resize, updateBox]);

  return box;
}

export default useElementBox;
