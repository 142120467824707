import { useSelector } from "react-redux";

import { PayloadAction, createSlice } from "@reduxjs/toolkit";

type LoadingError = "chunk_missing" | "unknown";
type State = {
  error?: LoadingError;
};
const initialState: State = {
  error: void 0,
};
const slice = createSlice({
  name: "chunkLoader",
  initialState,
  reducers: {
    chunkFailed(state, action: PayloadAction<LoadingError>) {
      state.error = action.payload;
    },
  },
});

export const useChunkError = () => {
  return useSelector(
    (state: { [slice.name]: State }) => state.chunkLoader.error
  );
};

export { slice };
export const { reducer, actions } = slice;
