import { SVGProps } from "react";

interface SvgSendProps extends SVGProps<SVGSVGElement> {
  /**
   * Width of icon, uses size if not given
   */
  width?: number;
  /**
   * Height of icon, uses size if not given
   */
  height?: number;
  /**
   * Size of icon, applies to width & height if not supplied
   * @default 16
   */
  size?: number;
}
export const SvgSend = (props: SvgSendProps) => (
  <svg
    {...props}
    width={props.width ?? props.size ?? 16}
    height={props.height ?? props.size ?? 16}
    viewBox="0 0 16 16"
    fill="currentColor"
  >
    <mask
      id="mask0_4027_996"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width={props.width ?? props.size ?? 16}
      height={props.height ?? props.size ?? 16}
    >
      <rect
        width={props.width ?? props.size ?? 16}
        height={props.height ?? props.size ?? 16}
        fill="#D9D9D9"
      />
    </mask>
    <g mask="url(#mask0_4027_996)">
      <path
        d="M2 12.3333V3.66666C2 3.42222 2.1 3.236 2.3 3.108C2.5 2.98044 2.71111 2.96111 2.93333 3.05L13.2 7.38333C13.4778 7.50555 13.6167 7.71111 13.6167 8C13.6167 8.28889 13.4778 8.49444 13.2 8.61666L2.93333 12.95C2.71111 13.0389 2.5 13.0193 2.3 12.8913C2.1 12.7638 2 12.5778 2 12.3333ZM3.33333 11.3333L11.2333 8L3.33333 4.66666V7L7.33333 8L3.33333 9V11.3333Z"
        fill="#1961E7"
      />
    </g>
  </svg>
);
export default SvgSend;
