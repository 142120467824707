import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { AppSwitcher } from "Hub/components/AppSwitcher";
import styled from "styled-components";
import cssVar from "theme/vars";
import { Tabs } from "ui/core";
import { Avatar } from "ui/core";
import { Scroller } from "ui/core/Tabs";

import { userSelectors } from "store/user/userSelectors";

import {
  Navlink,
  useNavigationLinks,
} from "utilities/hooks/useNavigationLinks";
import { hubUrls } from "utilities/urls";

import { Logo, NavContainer, NavLine } from "Common/styles";

export const Links: Navlink[] = [
  {
    label: "Tasks",
    getUrl: hubUrls.tasks,
  },
];

export const Navigation = () => {
  const navigate = useNavigate();
  const flags = useSelector(userSelectors.featureFlags);
  const currentOrg = useSelector(userSelectors.currentOrganisation);
  const name = useSelector(userSelectors.name);
  const email = useSelector(userSelectors.email);

  const links = useNavigationLinks(Links, flags);

  return (
    <HubNavContainer>
      <Logo
        src={currentOrg?.minimumLogoUrl}
        onClick={() => {
          navigate(hubUrls.tasks());
        }}
      />
      <NavLinks tabs={links} />

      <StyledAvatar
        label={name || email}
        size={32}
        onClick={() => {
          navigate(hubUrls.settings());
        }}
      />
      <NavLine />
      <AppSwitcher />
    </HubNavContainer>
  );
};

const HubNavContainer = styled(NavContainer)`
  background-color: ${cssVar("color/accents/purpleBg")};
  border-bottom: 1px solid ${cssVar("color/gray/30")};
`;

const NavLinks = styled(Tabs)`
  border-bottom: none;
  margin-top: auto;
  background-color: transparent;

  ${Scroller} {
    background-color: ${cssVar("color/primary/lightBlue")};
    margin-bottom: 5px;
  }
`;

const StyledAvatar = styled(Avatar)`
  margin-left: auto;
  cursor: pointer;
`;
