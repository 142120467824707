import React from "react";

import styled from "styled-components";
import { scrollbars } from "theme/mixins";

const Body: React.FC<{ className?: string }> = ({ children, className }) => {
  return <ModalBody className={className}>{children}</ModalBody>;
};

const ModalBody = styled.div`
  overflow-y: auto;
  padding: 16px 32px;
  line-height: 1.5;
  ${scrollbars()}
`;

export default Body;
