import React, { useMemo } from "react";

import { Filter } from "ui/forms";

import { useEmissionOutputTagsQuery } from "API";

import useFilterParam from "./useFilterParam";

const EmissionOutputTags: React.FC = () => {
  const { data = [], isSuccess, isError } = useEmissionOutputTagsQuery();
  const queryOptions = useMemo(() => {
    return data.reduce(
      (arr, group) => [...arr, ...group.options],
      [] as { value: string }[]
    );
  }, [data]);
  const [selected, setSelected] = useFilterParam(
    "emission_tags",
    queryOptions,
    {
      defaultToFirst: false,
      multi: true,
      ready: isSuccess || isError,
    }
  );
  return (
    <Filter
      items={{ groups: data }}
      title="Tags"
      label="Tags"
      selected={!!selected.length}
      count={selected.length ? selected.length : void 0}
      onChange={setSelected}
      disabled={!data.length || !selected}
      value={selected}
      multi={true}
      searchable={true}
      showReset={true}
      showApply={true}
    />
  );
};

export default EmissionOutputTags;
