import { SVGProps } from "react";
interface SvgDownloadProps extends SVGProps<SVGSVGElement> {
  /**
   * Width of icon, uses size if not given
   */
  width?: number;
  /**
   * Height of icon, uses size if not given
   */
  height?: number;
  /**
   * Size of icon, applies to width & height if not supplied
   * @default 24
   */
  size?: number;
}
export const SvgDownload = (props: SvgDownloadProps) => (
  <svg
    {...props}
    width={props.width ?? props.size ?? 24}
    height={props.height ?? props.size ?? 24}
    viewBox="0 0 48 48"
    fill="currentColor"
  >
    <path d="M24 31.75q-.3 0-.55-.1-.25-.1-.5-.35l-7.55-7.55q-.45-.45-.425-1.075.025-.625.475-1.075.45-.45 1.075-.45t1.075.45l4.9 4.95V9.5q0-.65.425-1.075Q23.35 8 24 8q.65 0 1.075.425.425.425.425 1.075v17.05l4.95-4.95q.45-.45 1.075-.45t1.075.45q.45.45.45 1.075t-.45 1.075l-7.55 7.55q-.25.25-.5.35-.25.1-.55.1ZM11 40q-1.2 0-2.1-.9Q8 38.2 8 37v-5.65q0-.65.425-1.075.425-.425 1.075-.425.65 0 1.075.425Q11 30.7 11 31.35V37h26v-5.65q0-.65.425-1.075.425-.425 1.075-.425.65 0 1.075.425Q40 30.7 40 31.35V37q0 1.2-.9 2.1-.9.9-2.1.9Z" />
  </svg>
);
export default SvgDownload;
