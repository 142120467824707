import styled, { css } from "styled-components";
import cssVar from "theme/vars";
import { CloudUpload } from "icons";
import { H4SB2 } from "typography/headers";
import { H4R2 } from "typography/headers";
import { H6R } from "typography/captions";
import { v4 as uuidv4 } from "uuid";
import React, { useState, useRef } from "react";

const defaultMaxFileSize = 10 * 1000 * 1000 * 10; //100MB
const defaultFileTypes = [
  ".csv",
  ".doc",
  ".docx",
  ".eml",
  ".gif",
  ".jpg",
  ".jpeg",
  ".msg",
  ".pdf",
  ".png",
  ".txt",
  ".xls",
  ".xlsx",
];

type FileSelectProps = {
  onChange?: (file: File) => void;
  disabled?: boolean;
  acceptedFileTypes?: string[];
  maxSize?: number;
  className?: string;
};
const FileSelect: React.FC<FileSelectProps> = ({
  onChange,
  disabled,
  maxSize = defaultMaxFileSize,
  acceptedFileTypes = defaultFileTypes,
  className,
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [errors, setErrors] = useState<string[]>([]);
  const id = "file-upload--" + uuidv4();

  const handleFilesDropped = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleFileChanged(e.dataTransfer.files[0]);
    }
  };

  const handleFileChanged = (file: File) => {
    if (!disabled) {
      setErrors([]);
      const fileParts = file.name.split(".");
      const fileExtension = fileParts[fileParts.length - 1] ?? "";
      const fileErrors = [];
      if (file.size > maxSize) {
        fileErrors.push(
          `File is too large, max size is ${maxSize / 1000 / 1000}MB`
        );
      }
      if (!acceptedFileTypes.find((ext) => ext === `.${fileExtension.toLowerCase()}`)) {
        fileErrors.push(
          `This file type (.${fileExtension}) is not supported. Please choose a different file.`
        );
      }
      if (fileErrors.length > 0) {
        setErrors(fileErrors);
      } else {
        onChange && onChange(file);
      }
    }
  };
  const handleFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.target.files && e.target.files[0]) {
      handleFileChanged(e.target.files[0]);
    }
  };
  return (
    <DropArea
      className={className}
      $disabled={disabled}
      onClick={() => {
        fileInputRef?.current?.click();
      }}
      onDrop={handleFilesDropped}
      onDragOver={(e) => {
        e.preventDefault();
      }}
    >
      <IconContainer $disabled={disabled}>
        <UploadIcon size={18} $disabled={disabled} />
      </IconContainer>
      <HiddenInput
        ref={fileInputRef}
        type="file"
        role="input"
        name="file-upload"
        id={id}
        disabled={disabled}
        onChange={handleFileSelect}
        accept={acceptedFileTypes.join(",")}
      />
      <CTAWrapper>
        <UploadLink $disabled={disabled}>Click to upload</UploadLink>
        <DNDInstructions>or drag and drop</DNDInstructions>
      </CTAWrapper>
      <H6R>{acceptedFileTypes.join(", ")} files</H6R>
      {errors.length > 0 && (
        <ErrorWrapper>
          {errors.map((error) => (
            <li key={error}>
              <Error>{error} </Error>
            </li>
          ))}
        </ErrorWrapper>
      )}
    </DropArea>
  );
};

const HiddenInput = styled.input`
  width: 0;
  visibility: hidden;
  height: 0;
`;

const DropArea = styled.div<{ $disabled?: boolean }>`
  ${({ $disabled }) => css`
    padding: 16px 24px;
    min-height: 124px;
    background-color: ${cssVar(
      $disabled ? "color/primary/silverGrey" : "color/gray/5"
    )};
    border: 1px dashed
      ${cssVar($disabled ? "color/primary/disabledGrey" : "color/primary/blue")};
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: background-color 0.5s;

    ${!$disabled &&
    css`
      cursor: pointer;

      &:hover {
        background-color: ${cssVar("color/action/superLightBlue")};
      }
    `}
  `}
`;

const UploadIcon = styled(CloudUpload)<{ $disabled?: boolean }>`
  color: ${cssVar("color/gray/70")};
  transition: color 0.5s;

  ${DropArea}:hover & {
    ${({ $disabled }) =>
      !$disabled &&
      css`
        color: ${cssVar("color/primary/blue")};
      `}
  }
`;

const IconContainer = styled.div<{ $disabled?: boolean }>`
  ${({ $disabled }) => css`
    min-width: 40px;
    min-height: 40px;
    background-color: ${cssVar("color/gray/20")};
    border-radius: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 0.5s;

    ${DropArea}:hover & {
      ${!$disabled && `background-color: ${cssVar("color/primary/lightBlue")}`};
    }
  `}
`;

const CTAWrapper = styled.div`
  margin-top: 12px;
`;

const UploadLink = styled(H4SB2)<{ $disabled?: boolean }>`
  ${({ $disabled }) => css`
    color: ${cssVar(
      $disabled ? "color/primary/disabledGrey" : "color/primary/blue"
    )};
    display: inline;
  `}
`;

const DNDInstructions = styled(H4R2)`
  display: inline;
  color: ${cssVar("color/gray/60")};
  margin-left: 4px;
`;

const ErrorWrapper = styled.ul`
  list-style: none;
  padding: 0;
  margin: 16px 0 0 0;
`;

const Error = styled(H6R)`
  color: ${cssVar("color/error/60")};
`;

export default FileSelect;
