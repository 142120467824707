import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { DataTrackerEntity } from "types/DataTracker";
import { SubmissionProgress } from "types/SubmittedData";

import { thunks } from "DataTracker/store/Thunks";

export type DataTrackerSliceState = {
  loading: boolean;
  entities: DataTrackerEntity[];
  categoriesProgress: SubmissionProgress | null;
  entitiesProgress: SubmissionProgress | null;
  selectedEntity: DataTrackerEntity | null;
};

const initialState: DataTrackerSliceState = {
  loading: true,
  entities: [],
  categoriesProgress: null,
  entitiesProgress: null,
  selectedEntity: null,
};

export const slice = createSlice({
  name: "dataTracker",
  initialState,
  reducers: {
    setSelectedEntity: (state, action: PayloadAction<DataTrackerEntity>) => {
      state.selectedEntity = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(thunks.getEntities.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(thunks.getEntities.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(thunks.getEntities.fulfilled, (state, { payload }) => {
      state.entities = payload.entities;
      state.categoriesProgress = payload.categoriesProgress;
      state.entitiesProgress = payload.entitiesProgress;
      state.loading = false;
    });
  },
});

export default slice.reducer;
