import React from "react";

import { rem } from "polished";
import styled from "styled-components";
import cssVar from "theme/vars";

import { html2react } from "utils/html";

export interface DescriptionProps {
  html?: string;
}

const ALLOWED_TAGS = [
  "h1",
  "h2",
  "h3",
  "h4",
  "h5",
  "h6",
  "strong",
  "em",
  "p",
  "s",
  "u",
  "ol",
  "li",
  "ul",
  "table",
  "thead",
  "tbody",
  "tfoot",
  "tr",
  "th",
  "td",
  "a",
  "img",
  "br",
];
const Description: React.FC<DescriptionProps> = ({ html = "" }) => {
  return (
    <DescriptionWrapper aria-label="Methodology description">
      <p>Description</p>
      {html2react(html.split("&nbsp;").join(""), ALLOWED_TAGS)}
    </DescriptionWrapper>
  );
};

const DescriptionWrapper = styled.div`
  margin: 16px 0 0;
  padding: 16px;
  font-size: ${rem(14)};
  line-height: 1.4;
  font-weight: 400;
  background: ${cssVar("color/primary/superSilverGrey")};
  color: ${cssVar("color/gray/100")};
  --border: 1px solid ${cssVar("color/gray/30")};
  border: var(--border);
  border-radius: 8px;

  h1 {
    font-size: 2em;
    font-weight: 700;
    margin: 0;
    color: ${cssVar("color/gray/100")};
  }

  h2 {
    font-size: 1.5em;
    font-weight: 700;
    margin: 0;
    color: ${cssVar("color/gray/100")};
  }

  h3 {
    font-size: 1.25em;
    font-weight: 700;
    margin: 0;
    color: ${cssVar("color/gray/80")};
  }

  p {
    margin: 0;

    &:empty {
      display: none;
    }

    &:first-of-type {
      color: ${cssVar("color/gray/60")};
      padding-bottom: 8px;
    }

    &:not(:empty) + h1,
    &:not(:empty) + h2,
    &:not(:empty) + h3,
    &:not(:empty) + h4,
    &:not(:empty) + h5,
    &:not(:empty) + h6 {
      margin-top: 16px;
    }
  }

  ol,
  li,
  ul {
    margin: 0;
    padding: 0;
  }

  ol,
  ul {
    padding: 10px 0 10px 32px;
  }

  table {
    background: ${cssVar("color/primary/white")};
    border: none;
    border-spacing: 0;
    border-collapse: collapse;
    margin: 16px 8px;

    td {
      border: 1px solid ${cssVar("color/gray/30")};
      padding: 4px 8px;
    }
  }
`;

export default Description;
