import React from "react";

import styled from "styled-components";
import cssVar from "theme/vars";
import { H4M } from "typography/headers";
import { Alert } from "ui/core";

import { AccountCreationError } from "./ExternalRegistration";

const AccountCreationErrorMessage: React.FC<{
  accountCreationError: AccountCreationError;
}> = ({ accountCreationError }) => {
  return (
    <Alert variant="error">
      {accountCreationError.data &&
        Object.keys(accountCreationError.data).map((key) => {
          const errorContent = accountCreationError.data?.[key];
          if (!errorContent) {
            return null;
          }
          if (key === "invitedUsers") {
            return (
              <ErrorMessage key={key}>
                Enter valid email addresses for all invited users.
              </ErrorMessage>
            );
          }
          return (
            <ErrorMessage key={key}>
              {accountCreationError.data && errorContent}
            </ErrorMessage>
          );
        })}
    </Alert>
  );
};

const ErrorMessage = styled(H4M)`
  text-align: center;
  color: ${cssVar("color/action/red")};
`;

export default AccountCreationErrorMessage;
