import React, { useCallback, useRef, useState } from "react";
import { format } from "date-fns";
import styled, { css } from "styled-components";
import Menu from "./Menu";
import cssVar from "theme/vars";
import { H4M } from "typography/headers";
import SvgCalendar from "icons/lib/Calendar";
import { useElementBox } from "../../hooks";
import SingleMenu from "./SingleMenu";
import SingleFormMenu from "./SingleFormMenu";

type DatePickerCallback = (dates: [Date | null, Date | null]) => void;
type SingleDatePickerCallback = (date: Date | null) => void;

export interface DatePickerProps {
  /**
   * Current start date
   */
  startDate: Date | null;
  /**
   * Current end date
   */
  endDate?: Date | null;
  /**
   * Change handler for range dates
   */
  onChange?: DatePickerCallback;
  /**
   * Change handler for single date
   */
  onSingleChange?: SingleDatePickerCallback;
  /**
   * Alignment on page
   */
  rightAligned?: boolean;
  /**
   * Full width of the parent
   */
  fullWidth?: boolean;
  /**
   * Single date picker (no range)
   */
  single?: boolean;
  /**
   * Single date picker (no range) used in forms
   */
  form?: boolean;
  /**
   * Input field text value
   */
  placeholderText?: string;
}

const formattedDates = (startDate: Date, endDate: Date) => {
  return (
    <H4M>
      {format(startDate, "dd MMM yyyy")} - {format(endDate, "dd MMM yyyy")}
    </H4M>
  );
};

const singleFormattedDates = (startDate: Date) => {
  return <H4M>{format(startDate, "dd MMM yyyy")}</H4M>;
};

const DatePicker: React.FC<DatePickerProps> = ({
  startDate,
  endDate,
  onChange,
  onSingleChange,
  rightAligned = false,
  fullWidth,
  single,
  form,
  placeholderText,
}) => {
  const [isOpen, setOpen] = useState(false);

  const onToggle = useCallback(() => {
    setOpen((o) => !o);
  }, []);

  const onClose = useCallback(() => {
    setOpen(false);
  }, []);

  const onApply = useCallback(
    (dates: [Date | null, Date | null]) => {
      onChange && onChange(dates);
    },
    [onChange]
  );

  const onSingleApply = useCallback(
    (date: Date | null) => {
      onSingleChange && onSingleChange(date);
    },
    [onSingleChange]
  );

  const picker = useRef<HTMLDivElement>(null);
  const position = useElementBox(picker, isOpen);

  return (
    <DatePickerWrapper $rightAligned={rightAligned} $fullWidth={fullWidth}>
      <DatePickerLabel
        $fullWidth={fullWidth}
        $form={form}
        ref={picker}
        onClick={onToggle}
      >
        <Icon>
          <SvgCalendar width={12} height={13} />
        </Icon>
        {!single && (
          <>
            {startDate && endDate ? (
              formattedDates(startDate, endDate)
            ) : (
              <Label>
                {placeholderText ? placeholderText : "Select dates"}
              </Label>
            )}
          </>
        )}
        {single && (
          <>
            {startDate ? (
              singleFormattedDates(startDate)
            ) : (
              <Label>{placeholderText ? placeholderText : "Select date"}</Label>
            )}
          </>
        )}
      </DatePickerLabel>
      {!form && !single && (
        <Menu
          open={isOpen}
          onClose={onClose}
          top={position?.bottom ?? 0}
          left={position?.left ?? 0}
          right={position?.right ?? 0}
          rightAligned={rightAligned}
          onApply={onApply}
          startDate={startDate}
          endDate={endDate}
        />
      )}
      {!form && single && (
        <SingleMenu
          open={isOpen}
          onClose={onClose}
          top={position?.bottom ?? 0}
          left={position?.left ?? 0}
          right={position?.right ?? 0}
          rightAligned={rightAligned}
          onApply={onSingleApply}
          startDate={startDate}
        />
      )}
      {form && single && (
        <SingleFormMenu
          open={isOpen}
          onClose={onClose}
          top={position?.bottom ?? 0}
          left={position?.left ?? 0}
          right={position?.right ?? 0}
          rightAligned={rightAligned}
          onApply={onSingleApply}
          startDate={startDate}
        />
      )}
    </DatePickerWrapper>
  );
};

const DatePickerWrapper = styled.div<{
  $rightAligned: boolean;
  $fullWidth?: boolean;
}>`
  cursor: pointer;
  ${(props) =>
    props.$rightAligned &&
    css`
      margin-left: auto;
    `}
  ${(props) =>
    props.$fullWidth &&
    css`
      width: 100%;
    `}
`;

const DatePickerLabel = styled.div<{
  $fullWidth?: boolean;
  $form?: boolean;
}>`
  display: inline-flex;
  align-items: center;
  background: var(--background, ${cssVar("color/primary/white")});
  border: ${(props) =>
    props.$form
      ? `1px solid ${cssVar("color/gray/30")}`
      : `1px solid ${cssVar("color/primary/disabledGrey")}`};
   {
  }
  min-height: ${(props) => (props.$form ? "38px" : "none")};
  border-radius: 8px;
  padding: 4px 16px 4px 14px;
  gap: 8px;

  ${(props) =>
    props.$fullWidth &&
    css`
      width: 100%;
    `}
`;

const Icon = styled.div`
  padding-top: 1px;
  color: ${cssVar("color/primary/darkGrey")};
`;

const Label = styled(H4M).attrs({ as: "span" })`
  color: var(--text, ${cssVar("color/gray/60")});
  transition: color 0.3s;
`;

export default DatePicker;
