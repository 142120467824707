const getOrg = (orgId: string) => `/organisations/${orgId}/`;
const getEntity = (orgId: string, entityId: string) =>
  getOrg(orgId) + `entities/${entityId}/`;
const getCategories = (orgId: string, entityId: string) =>
  getEntity(orgId, entityId) + "categories/";

const getCategory = (
  orgId: string,
  entityId: string,
  categoryId: string
): string => {
  return getCategories(orgId, entityId) + `${categoryId}/`;
};

export const organisationEndpoints = {
  organisation: getOrg,
  entity: getEntity,
  entities: (orgId: string) => getOrg(orgId) + `entities/`,
  graph: (orgId: string) => getOrg(orgId) + `graph/`,
  reportingYears: (orgId: string) => getOrg(orgId) + `reporting-years/`,
  categories: getCategories,
  category: getCategory,
  submittedData: (orgId: string, entityId: string) =>
    getEntity(orgId, entityId) + `submitted-data/`,
  uploadedFiles: (orgId: string) => getOrg(orgId) + `uploaded-files/`,
  progressReport: (orgId: string, reportingYearId: string) =>
    getOrg(orgId) + `reporting-years/${reportingYearId}/progress-report/`,
};
