import { Suspense } from "react";
import { Route, Routes } from "react-router-dom";

import * as Sentry from "@sentry/react";
import ExternalRegistration from "ExternalRegistration/ExternalRegistration";
import ProtectedRoutes from "ProtectedRoutes";

import PageLoader from "Layout/PageLoader";

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

const AppRoutes = () => {
  return (
    <Suspense fallback={<PageLoader />}>
      <SentryRoutes>
        <Route
          path="organisations/:organisationId/external-registration/:linkId/invite/"
          element={<ExternalRegistration />}
        />
        <Route path="*" element={<ProtectedRoutes />} />
      </SentryRoutes>
    </Suspense>
  );
};

export default AppRoutes;
