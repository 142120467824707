import React, { useState } from "react";

import { Methodology } from "types/Methodology";
import { Hyperlink } from "typography/body";

import MethodologyDetails from "./Details";

const Link: React.FC<{
  methodology: Methodology | null;
  label?: string;
}> = ({ methodology, label }) => {
  const [showDetails, setShowDetails] = useState<boolean>(false);

  if (!methodology) {
    return <span>-</span>;
  }

  return (
    <>
      <Hyperlink onClick={() => setShowDetails(true)}>
        {label || methodology.methodologyLabel || methodology.name}
      </Hyperlink>
      <MethodologyDetails
        open={showDetails}
        onClose={() => {
          setShowDetails(false);
        }}
        methodology={methodology}
      />
    </>
  );
};

export default Link;
