import React, { useCallback } from "react";

import {
  ChevronSelectorVerticalDown,
  ChevronSelectorVerticalUp,
  HelpOutline,
} from "icons";
import { rem } from "polished";
import styled, { css } from "styled-components";
import cssVar from "theme/vars";

import Tooltip from "../Tooltip";
import { SortDirection, useTableContext } from "./Context";

export interface HeadCellProps {
  sortKey?: string;
  hint?: string;
  className?: string;
  $sticky?: boolean;
  $stickyOffset?: number;
  $right?: boolean;
}

const HeadCell: React.FC<HeadCellProps> = ({
  children,
  sortKey,
  hint,
  className,
  $sticky,
  $stickyOffset,
  $right,
}) => {
  const { onSort, sort } = useTableContext() ?? {};
  const handleSort = useCallback(() => {
    if (sortKey) {
      onSort?.(sortKey);
    }
  }, [onSort, sortKey]);

  const activeSort = sort?.field === sortKey;
  const currentSortDirection: SortDirection = activeSort
    ? sort?.direction ?? "asc"
    : "asc";

  let sortLabel = `Cancel sorting on '${sortKey}'`;
  if (!activeSort) {
    sortLabel = `Sort on '${sortKey}' ascending`;
  } else if (currentSortDirection === "asc") {
    sortLabel = `Sort on '${sortKey}' descending`;
  }

  return (
    <HeadCellWrapper
      className={className}
      $sticky={$sticky}
      $stickyOffset={$stickyOffset}
      $right={$right}
    >
      <Label>{children}</Label>
      {hint && (
        <InfoTooltip content={hint} initialPosition="bottom">
          <Info />
        </InfoTooltip>
      )}
      {sortKey && (
        <Sorter
          onClick={handleSort}
          $dir={currentSortDirection}
          $active={activeSort}
          aria-label={sortLabel}
        >
          <Asc $active={activeSort && currentSortDirection === "asc"} />
          <Desc $active={activeSort && currentSortDirection === "desc"} />
        </Sorter>
      )}
    </HeadCellWrapper>
  );
};

export const HeadCellWrapper = styled.div<{
  $sticky?: boolean;
  $stickyOffset?: number;
  $right?: boolean;
}>`
  display: flex;
  align-items: center;
  padding: 13px 24px;
  font-size: ${rem(12)};
  line-height: ${rem(18)};
  font-weight: 500;
  color: ${cssVar("color/gray/60")};
  gap: 4px;
  text-align: left;
  background: ${cssVar("color/gray/10")};
  border-top: 1px solid ${cssVar("color/gray/30")};
  border-bottom: 1px solid ${cssVar("color/gray/30")};

  ${(props) =>
    props.$sticky &&
    css`
      background: ${cssVar("color/gray/10")};
      border-left: 1px solid ${cssVar("color/gray/30")};
      position: sticky;
      right: 0;
      box-shadow: -5px 0 10px -1px ${cssVar("color/gray/20")};
    `}

  ${(props) =>
    props.$stickyOffset &&
    css`
      right: ${props.$stickyOffset}px;
    `}

  ${(props) =>
    props.$right &&
    css`
      justify-content: flex-end;
      text-align: right;
    `}
`;

const Info = styled(HelpOutline)`
  width: 16px;
  height: 16px;
  color: ${cssVar("color/gray/60")};
`;
const InfoTooltip = styled(Tooltip)`
  display: block;
  height: 16px;
`;

const Asc = styled(ChevronSelectorVerticalUp)<{ $active: boolean }>`
  display: block;
  width: 17px;
  height: 16px;
  position: absolute;
  top: 0;
  left: 0;
  color: ${cssVar("color/primary/disabledGrey")};
  ${(props) =>
    props.$active &&
    css`
      color: ${cssVar("color/gray/60")};
    `}
`;
const Desc = styled(Asc).attrs({ as: ChevronSelectorVerticalDown })``;
const Sorter = styled.button.attrs({ type: "button" })<{
  $dir: SortDirection;
  $active: boolean;
}>`
  background: none;
  border: 0;
  cursor: pointer;
  position: relative;
  width: 17px;
  height: 16px;
  padding: 0;
  position: relative;
`;
const Label = styled.span`
  display: block;
`;

export default HeadCell;
