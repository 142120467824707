import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import SvgCalendar from "icons/lib/Calendar";
import styled from "styled-components";
import cssVar from "theme/vars";
import { H4M } from "typography/headers";
import { Filter } from "ui/forms";

import { useReportingYearsQuery } from "../../API";

import { organisationSelectors } from "../../store/organisation/organisationSelectors";

import { QUERY_PARAM as PAGINATION_QUERY_PARAM } from "../Pagination";
import { useCurrentFilters, useFilterUpdate } from "./slice";

type ReportingYearFilterItem = {
  value: string;
  label: string;
  startDate: string;
  endDate: string;
};
const DateRangeDropdown = () => {
  const updateFilters = useFilterUpdate();
  const [{ ...filters }] = useCurrentFilters();
  const [query, setQuery] = useSearchParams();
  const defaultReportingYear = useSelector(
    organisationSelectors.selectedReportingYearId
  );
  const [currentValue, setCurrentValue] =
    useState<ReportingYearFilterItem | null>(null);
  const { data, isSuccess } = useReportingYearsQuery();
  const [reportingYears, setReportingYears] = useState<
    ReportingYearFilterItem[]
  >([]);

  useEffect(() => {
    if (data) {
      const reportingYears = data.map((item) => {
        return {
          value: item.id,
          label: item.label ?? "Unknown",
          startDate: item.startDate ?? "Unknown",
          endDate: item.endDate ?? "Unknown",
        };
      });
      setReportingYears(reportingYears);
    }
  }, [data]);

  useEffect(() => {
    if (filters.date_from && filters.date_to) {
      const matchingReportingYear = reportingYears.find(
        (item) => item.startDate === filters.date_from
      );
      if (matchingReportingYear) {
        handleChange(matchingReportingYear.value);
      }
      return;
    }
    if (defaultReportingYear) {
      const matchingReportingYear = reportingYears.find(
        (item) => item.value === defaultReportingYear
      );
      if (matchingReportingYear) {
        handleChange(matchingReportingYear.value);
      }
    }
    // We are specifically not including handle change to stop a loop condition
    // If we change handle change to be a callback we cause an infinite loop
    // so we need a mechanism to update this value without triggering a change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultReportingYear, reportingYears]);

  const handleChange = (value: string) => {
    const selected = reportingYears.find((item) => item.value === value);
    if (selected) {
      updateFilters("date_from", selected.startDate);
      updateFilters("date_to", selected.endDate);
      updateFilters("reporting_year_id", selected.value);
      query.delete("date_from");
      query.delete("date_to");
      selected.startDate && query.set("date_from", selected.startDate);
      selected.endDate && query.set("date_to", selected.endDate);
      // A change in filter means we should reset pagination
      query.delete(PAGINATION_QUERY_PARAM);
      setQuery(query, { replace: true });
      setCurrentValue(selected);
    }
  };
  //when the query params change, update the current value
  const start = query.get("date_from");
  const end = query.get("date_to");
  if (currentValue) {
    if (currentValue.startDate !== start || currentValue.endDate !== end) {
      setCurrentValue(null);
    }
  }
  return (
    <ReportingYearContainer>
      <ReportingYearLabel>
        <Icon>
          <SvgCalendar width={12} height={13} />
        </Icon>
        <Title>Reporting year:</Title>
      </ReportingYearLabel>
      <ReportingYearFilter
        items={{ options: reportingYears }}
        title="Reporting year"
        label={currentValue?.label ?? "Custom"}
        onChange={handleChange}
        value={currentValue?.value}
        disabled={!isSuccess}
        anchor="right"
      />
    </ReportingYearContainer>
  );
};

const ReportingYearContainer = styled.div`
  display: inline-flex;
  align-items: center;
  height: 32px;
  border-radius: 4px;
`;

const ReportingYearLabel = styled.div`
  display: inline-flex;
  align-items: center;
  padding: 12px;
  height: 32px;
  border-radius: 4px 0px 0px 4px;
  border-width: 1px 0px 1px 1px;
  border-style: solid;
  border-color: ${cssVar("color/primary/skinnyBlue")};
`;

const Title = styled(H4M)`
  padding-left: 4px;
  color: ${cssVar("color/primary/darkGrey")};
`;

const Icon = styled.div`
  padding-top: 1px;
  color: ${cssVar("color/primary/darkGrey")};
`;
const ReportingYearFilter = styled(Filter)`
  height: 32px;
  border-radius: 0px 4px 4px 0px;
  background-color: ${cssVar("color/primary/superSilverGrey")};
  border: 1px solid ${cssVar("color/primary/skinnyBlue")};

  &:hover {
    background-color: ${cssVar("color/primary/silverGrey")};
    border-color: ${cssVar("color/gray/40")};
  }
`;
export default DateRangeDropdown;
