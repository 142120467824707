import React, { ReactElement, useCallback } from "react";
import styled, { css } from "styled-components";
import { H4R } from "typography/headers";
import Checkbox from "../../atoms/Checkbox";
import Radio from "../../atoms/Radio";
import cssVar from "theme/vars";

export interface OptionProps {
  value: string;
  label: string;
  multi: boolean;
  selected: boolean;
  onSelect: (value: string) => void;
  bold?: boolean;
  basic?: boolean;
  disabled?: boolean;
  icon?: ReactElement | "";
}

const Option: React.FC<OptionProps> = ({
  value,
  label,
  multi,
  selected,
  onSelect,
  bold = false,
  basic = false,
  disabled = false,
  icon,
}) => {
  const handleChange = useCallback(() => {
    onSelect(value);
  }, [value, onSelect]);

  const UI = multi ? Checkbox : Radio;
  return basic ? (
    <BasicWrapper $selected={selected} $bold={false} onClick={handleChange}>
      <Label $disabled={disabled} as="span">
        {label}
      </Label>
      {icon && icon}
      {selected && <CurrentLabel>Current</CurrentLabel>}
    </BasicWrapper>
  ) : (
    <OptionWrapper $bold={bold}>
      <UI active={selected} />
      <Control
        type={multi ? "checkbox" : "radio"}
        onChange={handleChange}
        checked={selected}
      />
      <Label as="span">{label}</Label>
    </OptionWrapper>
  );
};

const Control = styled.input`
  position: absolute;
  opacity: 0;
  left: -200px;
  height: -200px;
`;
const OptionWrapper = styled.label<{ $bold: boolean }>`
  cursor: pointer;
  position: relative;
  display: flex;
  height: 40px;
  align-items: center;
  gap: 16px;
  padding: 0px 24px;
  white-space: nowrap;
  text-overflow: ellipsis;

  ${(props) =>
    props.$bold &&
    css`
      ${H4R} {
        font-weight: 600;
      }
    `}
`;
const BasicWrapper = styled(OptionWrapper)<{
  $selected: boolean;
}>`
  background: ${cssVar("color/primary/white")};

  &:hover {
    background: ${cssVar("color/gray/10")};
  }

  ${(props) =>
    props.$selected &&
    css`
      background: ${cssVar("color/gray/20")};
      border-left: 2px solid ${cssVar("color/primary/blue")};
      &:hover {
        background: ${cssVar("color/gray/20")};
      }
    `};
`;
const CurrentLabel = styled(H4R)`
  color: ${cssVar("color/gray/60")};
  font-size: 12px;
  margin-left: auto;
`;

const Label = styled(H4R)<{ $disabled?: boolean }>`
  padding-right: 12px;

  ${(props) =>
    props.$disabled &&
    css`
      --border: ${cssVar("color/primary/disabledGrey")} !important;
      --background: ${cssVar("color/primary/silverGrey")} !important;
      --badge: ${cssVar("color/primary/disabledGrey")} !important;
      --text: ${cssVar("color/primary/disabledGrey")} !important;
      --icon: ${cssVar("color/primary/disabledGrey")} !important;
      cursor: not-allowed;
      opacity: 0.5;
    `}
`;

export default Option;
