import styled, { css } from "styled-components";
import cssVar from "theme/vars";
import Button from "./Button";

export type ToggleSegmentOption = {
  label?: string;
  value: string;
};

type ToggleSegmentProps = {
  options: ToggleSegmentOption[];
  value?: string | null;
  onChange?: (newVal: string) => void;
  className?: string;
};

const ToggleSegment: React.FC<ToggleSegmentProps> = ({
  options,
  value,
  onChange,
  className,
}) => {
  return (
    <Container className={className}>
      {options.map((opt) => {
        return (
          <ToggleButton
            dataTestId={`${opt.value}-based-button`}
            size="small"
            key={opt.value}
            $selected={opt.value === value}
            onClick={() => {
              onChange?.(opt.value);
            }}
          >
            {opt.label || opt.value}
          </ToggleButton>
        );
      })}
    </Container>
  );
};

const Container = styled.div`
  background-color: ${cssVar("color/gray/5")};
  border: 1px solid ${cssVar("color/gray/30")};
  border-radius: 4px;
  padding: 4px;
  display: inline-flex;
  flex-direction: row;
  gap: 8px;
`;

const ToggleButton = styled(Button)<{ $selected?: boolean }>`
  border-radius: 4px;
  background-color: transparent;
  border: 1px solid transparent;
  --color: ${cssVar("color/gray/60")};
  white-space: nowrap;

  ${({ $selected }) =>
    $selected &&
    css`
      --color: ${cssVar("color/gray/100")};
      background-color: ${cssVar("color/gray/20")};
      border-color: ${cssVar("color/gray/50")};
    `}
  &:hover {
    --color: ${cssVar("color/gray/100")};
    background-color: ${cssVar("color/gray/10")};
    border-color: ${cssVar("color/gray/40")};
  }
`;

export default ToggleSegment;
