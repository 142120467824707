import React from "react";

import styled from "styled-components";
import { Methodology } from "types/Methodology";
import { DefinitionList, Modal } from "ui/core";

import Description from "./Description";

const MethodologyDetails: React.FC<{
  open: boolean;
  onClose: () => void;
  methodology: Methodology | null;
}> = ({ open, onClose, methodology }) => {
  if (!open) {
    return null;
  }

  return (
    <Window open={open} onClose={onClose}>
      <Modal.Header title="Methodology details" />
      <Body>
        <DefinitionList>
          {methodology?.version && (
            <>
              <DefinitionList.Term>Version</DefinitionList.Term>
              <DefinitionList.Desc>
                {methodology?.version || "-"}
              </DefinitionList.Desc>
            </>
          )}
          <DefinitionList.Term>Name</DefinitionList.Term>
          <DefinitionList.Desc>
            {methodology?.methodologyLabel || methodology?.name}
          </DefinitionList.Desc>
        </DefinitionList>
        <Description html={methodology?.description} />
      </Body>
    </Window>
  );
};

const Window = styled(Modal.Window)`
  max-width: 90vw;
  width: 900px;
`;

const Body = styled(Modal.Body)`
  padding: 0 32px 24px;
`;

export default MethodologyDetails;
