import React, { useState } from "react";

import { Close } from "icons";
import { rem } from "polished";
import styled from "styled-components";
import cssVar from "theme/vars";
import { H4M } from "typography/headers";
import { Badge, Button } from "ui/core";

import {
  RegistrationFormData,
  RegistrationFormGetHandler,
} from "./ExternalRegistration";
import { InputField } from "./RegistrationFormPersonalInfo";

const RegistrationFormCompanyInfo: React.FC<{
  registrationFormData: RegistrationFormData;
  countries: string[];
  getOnChangeHandler: RegistrationFormGetHandler;
  updateInviteEmails: (members: string[]) => void;
  updateCountry: (country: string) => void;
}> = ({
  registrationFormData,
  countries,
  getOnChangeHandler,
  updateInviteEmails,
  updateCountry,
}) => {
  const [membersToAdd, setMembersToAdd] = useState<string | null>(null);
  const handleMembersInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMembersToAdd(e.target.value || null);
  };
  const addMembersToInvite = () => {
    if (membersToAdd) {
      let members = membersToAdd.split(",");
      // remove any that already exist
      members = members
        .map((member) => member.trim())
        .filter(
          (member) => registrationFormData.invitedUsers.indexOf(member) === -1
        );
      updateInviteEmails([...registrationFormData.invitedUsers, ...members]);
      setMembersToAdd(null);
    }
  };
  const removeMemberFromInvite = (user: string) => {
    const userIndex = registrationFormData.invitedUsers.indexOf(user);
    if (userIndex > -1) {
      registrationFormData.invitedUsers.splice(userIndex, 1);
    }
    updateInviteEmails([...registrationFormData.invitedUsers]);
  };

  return (
    <>
      <InputField
        value={registrationFormData.company_name}
        onChange={getOnChangeHandler("company_name")}
        label="Company name"
        type="text"
      />
      <InputField
        value={registrationFormData.city}
        onChange={getOnChangeHandler("city")}
        label="City"
        type="text"
      />
      <Label>Country</Label>
      <Select
        onChange={(e) => {
          updateCountry(e.currentTarget.value);
        }}
      >
        <option value="" selected disabled hidden>
          United Kingdom
        </option>
        {countries.map((country, i) => (
          <option value={country} key={i}>
            {country}
          </option>
        ))}
      </Select>
      <InputField
        onChange={handleMembersInputChange}
        value={membersToAdd ?? ""}
        label="Invite other users"
        type="text"
      />
      <AddEmailButton variant="text-primary" onClick={addMembersToInvite}>
        Add email addresses
      </AddEmailButton>
      <EmailWrapper>
        {registrationFormData.invitedUsers.map((user) => {
          return (
            <Email
              key={user}
              color="gray"
              size="small"
              rightIcon={Close}
              onClick={() => removeMemberFromInvite(user)}
            >
              {user}
            </Email>
          );
        })}
      </EmailWrapper>
    </>
  );
};

const Label = styled(H4M).attrs({ as: "span" })`
  display: block;
  color: ${cssVar("color/gray/60")};
  margin: 0 0 8px;
`;

const Select = styled.select`
  border-radius: 8px;
  background: ${cssVar("color/primary/white")};
  border: 1px solid ${cssVar("color/primary/disabledGrey")};
  height: 40px;
  padding: 8px 12px;
  font-size: ${rem(14)};
  font-family: ${cssVar("text/family/regular")};
  color: ${cssVar("color/gray/100")};
  transition: border-color 0.3s ease, background-color 0.3s ease,
    box-shadow 0.3s ease;
  outline: 0;
  box-shadow: 0px 0px 0px 4px rgba(124, 130, 135, 0);
  margin-bottom: 8px;
`;

const AddEmailButton = styled(Button)`
  max-width: 50%;
  margin-left: auto;
  margin-bottom: 4px;
`;

const EmailWrapper = styled.div`
  display: flex;
  gap: 4px;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 16px;
`;

const Email = styled(Badge)`
  max-width: 100%;
`;

export default RegistrationFormCompanyInfo;
