import { createGlobalStyle } from "styled-components";

import InterCyrillicVariableFullNormal from "@fontsource/inter/files/inter-cyrillic-variable-full-normal.woff2";
import InterCyrillicExtVariableFull from "@fontsource/inter/files/inter-cyrillic-ext-variable-full-normal.woff2";
import InterGreekVariableFullNormal from "@fontsource/inter/files/inter-greek-variable-full-normal.woff2";
import InterGreekExtVariableFull from "@fontsource/inter/files/inter-greek-ext-variable-full-normal.woff2";
import InterLatinVariableFullNormal from "@fontsource/inter/files/inter-latin-variable-full-normal.woff2";
import InterLatinExtVariableFull from "@fontsource/inter/files/inter-latin-ext-variable-full-normal.woff2";
import InterVietnameseVariableFullNormal from "@fontsource/inter/files/inter-vietnamese-variable-full-normal.woff2";

const FontFamilyDeclaration = createGlobalStyle`
  /* inter-cyrillic-variable-full-oblique 0deg 10deg */
  @font-face {
    font-family: 'InterVariable';
    font-style: oblique 0deg 10deg;
    font-display: swap;
    font-weight: 100 900;
    src: url(${InterCyrillicVariableFullNormal}) format('woff2');
    unicode-range: U+0301,U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
  }
  /* inter-cyrillic-ext-variable-full-oblique 0deg 10deg */
  @font-face {
    font-family: 'InterVariable';
    font-style: oblique 0deg 10deg;
    font-display: swap;
    font-weight: 100 900;
    src: url(${InterCyrillicExtVariableFull}) format('woff2');
    unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
  }
  /* inter-greek-variable-full-oblique 0deg 10deg */
  @font-face {
    font-family: 'InterVariable';
    font-style: oblique 0deg 10deg;
    font-display: swap;
    font-weight: 100 900;
    src: url(${InterGreekVariableFullNormal}) format('woff2');
    unicode-range: U+0370-03FF;
  }
  /* inter-greek-ext-variable-full-oblique 0deg 10deg */
  @font-face {
    font-family: 'InterVariable';
    font-style: oblique 0deg 10deg;
    font-display: swap;
    font-weight: 100 900;
    src: url(${InterGreekExtVariableFull}) format('woff2');
    unicode-range: U+1F00-1FFF;
  }
  /* inter-latin-variable-full-oblique 0deg 10deg */
  @font-face {
    font-family: 'InterVariable';
    font-style: oblique 0deg 10deg;
    font-display: swap;
    font-weight: 100 900;
    src: url(${InterLatinVariableFullNormal}) format('woff2');
    unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
  }
  /* inter-latin-ext-variable-full-oblique 0deg 10deg */
  @font-face {
    font-family: 'InterVariable';
    font-style: oblique 0deg 10deg;
    font-display: swap;
    font-weight: 100 900;
    src: url(${InterLatinExtVariableFull}) format('woff2');
    unicode-range: U+0100-024F,U+0259,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
  }
  /* inter-vietnamese-variable-full-oblique 0deg 10deg */
  @font-face {
    font-family: 'InterVariable';
    font-style: oblique 0deg 10deg;
    font-display: swap;
    font-weight: 100 900;
    src: url(${InterVietnameseVariableFullNormal}) format('woff2');
    unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+1EA0-1EF9,U+20AB;
  }

  html, button, *::after, *::before {
    font-family: InterVariable;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  /* ignoring the font-family for Formio icons */
  i[class^='fa']::after, i[class*=' fa']::before {
    font-family: 'FontAwesome';
  }
`;

export default FontFamilyDeclaration;
