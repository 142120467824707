import React, {
  Fragment,
  HTMLAttributes,
  useEffect,
  useRef,
  useState,
} from "react";

import { Listbox } from "@headlessui/react";

import { ReactComponent as IconCaret } from "../../assets/icon-dropdown-caret.svg";

import "./dropdown.scss";

export type DropdownOption = {
  label: string;
  value: any;
};

export type DropdownProps = {
  options: DropdownOption[];
  label?: string;
  onChange?: (newVal: any) => void;
  value?: any;
  fullWidth?: boolean;
  className?: string;
  disabled?: boolean;
};

const findValueInOptions = (
  value: any,
  options: DropdownOption[]
): null | DropdownOption => {
  return options.find((option) => option.value === value) || null;
};

const Dropdown: React.FC<
  DropdownProps & Omit<HTMLAttributes<HTMLSelectElement>, "onChange">
> = ({
  className,
  disabled,
  fullWidth,
  options,
  label,
  onChange,
  ...props
}) => {
  const [selectedOption, setSelectedOption] = useState<DropdownOption | null>(
    findValueInOptions(props.value || props.defaultValue, options)
  );
  const [entriesWidth, setEntriesWidth] = useState(100);
  const triggerRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (triggerRef.current) {
      setEntriesWidth(triggerRef.current.clientWidth);
    }
  }, [triggerRef]);

  useEffect(() => {
    function handleResize() {
      if (triggerRef.current) {
        setEntriesWidth(triggerRef.current.clientWidth);
      }
    }

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleChange = (newVal: any) => {
    setSelectedOption(findValueInOptions(newVal, options));
    if (onChange) {
      onChange(newVal);
    }
  };

  useEffect(() => {
    if (options) {
      setSelectedOption(findValueInOptions(props.value, options));
    }
  }, [props.value, options]);

  const fullWidthClassName = fullWidth ? "min-ui__dropdown--full-width" : "";

  return (
    <div
      className={`min-ui__dropdown ${fullWidthClassName} ${className || ""}`}
    >
      <div className="min-ui__dropdown__header">
        <span className="min-ui__dropdown__header__label">{label}</span>
      </div>

      <Listbox
        value={selectedOption?.value}
        disabled={disabled}
        onChange={handleChange}
      >
        <Listbox.Button ref={triggerRef} className="min-ui__dropdown__body">
          <div
            className={`min-ui__dropdown__body__value
            ${
              !selectedOption
                ? "min-ui__dropdown__body__value--placeholder"
                : ""
            }`}
          >
            {selectedOption?.label || props.placeholder || "Select an option"}
          </div>
          <div className="min-ui__dropdown__body__caret">
            <IconCaret />
          </div>
        </Listbox.Button>
        <Listbox.Options
          style={{ width: entriesWidth }}
          className="min-ui__dropdown__entries min-ui__depth--24 min-ui__scroll"
        >
          {options.map((option, i) => {
            return (
              <Listbox.Option key={i} value={option.value} as={Fragment}>
                {({ active, selected }) => {
                  const activeClass = active
                    ? "min-ui__dropdown__entries__item--active"
                    : "";
                  const selectedClass = selected
                    ? "min-ui__dropdown__entries__item--selected"
                    : "";
                  return (
                    <li
                      className={`min-ui__dropdown__entries__item ${activeClass} ${selectedClass}`}
                    >
                      {option.label}
                    </li>
                  );
                }}
              </Listbox.Option>
            );
          })}
        </Listbox.Options>
      </Listbox>
    </div>
  );
};

export default Dropdown;
