import React, { ComponentType } from "react";

import { motion } from "framer-motion";
import * as Icons from "icons";
import styled, { keyframes } from "styled-components";
import cssVar from "theme/vars";
import { H3SB, H4R } from "typography/headers";

import { SnackNotification, SnackVariant } from "./slice";

const iconVariantColours: Record<SnackVariant, string> = {
  info: ` color: ${cssVar("color/primary/white")};`,
  success: `color: ${cssVar("color/success/80")};`,
  warning: `color: ${cssVar("color/warning/60")};`,
  error: `color: ${cssVar("color/error/60")};`,
};
const variantStyles: Record<SnackVariant, Record<string, string>> = {
  info: {
    "--background": cssVar("color/primary/lightBlue"),
    "--border": cssVar("color/primary/blue"),
  },
  success: {
    "--background": cssVar("color/success/20"),
    "--border": cssVar("color/success/60"),
  },
  warning: {
    "--background": cssVar("color/warning/20"),
    "--border": cssVar("color/warning/60"),
  },
  error: {
    "--background": cssVar("color/error/20"),
    "--border": cssVar("color/error/60"),
  },
};

const DefaultIcons: Record<SnackVariant, keyof typeof Icons> = {
  error: "HighlightOff",
  info: "Info",
  success: "CheckCircle",
  warning: "WarningAmberRounded",
};

const Snack: React.FC<{ notification: SnackNotification }> = ({
  notification,
}) => {
  const iconType: ComponentType | null = notification.icon
    ? Icons[notification.icon]
    : notification.variant
    ? Icons[DefaultIcons[notification.variant]]
    : null;

  return (
    // layout is a framer-motion prop to automate the animation when its
    // position in the layout changes
    <Wrapper $variant={notification.variant || "info"} layout>
      {iconType && (
        <IconWrapper>
          <Icon $variant={notification.variant || "info"} as={iconType} />
        </IconWrapper>
      )}
      <div>
        {notification.headerMessage ? (
          <MessageTitle>{notification.headerMessage}</MessageTitle>
        ) : null}
        <MessageBody>{notification.message}</MessageBody>
      </div>
    </Wrapper>
  );
};

const revealNotification = keyframes`
  0% {
    transform: translateX(35px);
  }
  100% {
    transform: translateX(0px);
  }
`;

const Wrapper = styled(motion.div)<{ $variant: SnackVariant }>`
  ${(props) => variantStyles[props.$variant]};
  display: flex;
  position: absolute;
  right: 0;
  top: 0;
  padding: 16px;
  width: 368px;
  background-color: var(--background);
  border: 1px solid var(--border);
  border-radius: 4px;
  pointer-events: auto;
  animation: 0.4s ${revealNotification} backwards;
`;

const MessageTitle = styled(H3SB)`
  color: ${cssVar("color/gray/100")};
`;
const MessageBody = styled(H4R)`
  color: ${cssVar("color/gray/100")};
`;
const IconWrapper = styled.div`
  width: 24px;
  margin-right: 8px;
`;
const Icon = styled.svg<{ $variant: SnackVariant }>`
  width: 24px;
  height: 24px;

  ${({ $variant }) => `${iconVariantColours[$variant]}`}
`;

export default Snack;
