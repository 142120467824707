import React from "react";

import { Filter } from "ui/forms";

import { useSubmissionGroupsStatusQuery } from "API";

import useFilterParam from "./useFilterParam";

const SubmissionGroupsStatus: React.FC = () => {
  const { data = [], isSuccess, isError } = useSubmissionGroupsStatusQuery();
  const [selected, setSelected] = useFilterParam(
    "submission_groups_status",
    data,
    {
      defaultToFirst: false,
      multi: true,
      ready: isSuccess || isError,
    }
  );
  return (
    <Filter
      items={{ options: data }}
      title="Status"
      label="Status"
      selected={!!selected.length}
      count={selected.length ? selected.length : void 0}
      onChange={setSelected}
      disabled={!data.length || !selected}
      value={selected}
      multi={true}
      showReset={true}
      showApply={true}
      searchable={true}
    />
  );
};

export default SubmissionGroupsStatus;
