import React from "react";
import styled from "styled-components";

const Actions: React.FC = ({ children }) => {
  return <ActionsWrapper>{children}</ActionsWrapper>;
};

const ActionsWrapper = styled.div`
  float: right;
`;

export default Actions;
