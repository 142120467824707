import { SVGProps } from "react";
interface SvgTriangleArrowProps extends SVGProps<SVGSVGElement> {
  /**
   * Width of icon, uses size if not given
   */
  width?: number;
  /**
   * Height of icon, uses size if not given
   */
  height?: number;
  /**
   * Size of icon, applies to width & height if not supplied
   * @default 20
   */
  size?: number;
}
export const SvgTriangleArrow = (props: SvgTriangleArrowProps) => (
  <svg
    {...props}
    width={props.width ?? props.size ?? 20}
    height={props.height ?? props.size ?? 20}
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M5.83337 7.91669L10 12.0834L14.1667 7.91669H5.83337Z"
      fill="#101828"
    />
  </svg>
);
export default SvgTriangleArrow;
