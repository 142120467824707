import React, { CSSProperties, RefObject, useRef } from "react";
import ReactDOM from "react-dom";
import { useNavigate } from "react-router-dom";

import Option from "ui/forms/Filter/Option";

import { useClickOutside, useRootElement } from "ui/hooks";
import urls from "utilities/urls";

import {
  SwitcherDropdownMenuWrapper,
  SwitcherDropdownOptionsList,
} from "Common/styles";

type DropdownProps = {
  open: boolean;
  top: number;
  right: number;
  onClose: () => void;
  parent: RefObject<HTMLElement>;
};

export const AppSwitcherDropdown: React.FC<DropdownProps> = ({
  open,
  top,
  right,
  onClose,
  parent,
}) => {
  const portal = useRootElement("switcher");
  const menu = useRef<HTMLDivElement>(null);
  useClickOutside(menu, onClose, parent);

  const navigate = useNavigate();

  return ReactDOM.createPortal(
    <SwitcherDropdownMenuWrapper
      ref={menu}
      style={
        {
          "--top": `${top}px`,
          "--right": `${right}px`,
        } as CSSProperties
      }
      $open={open}
      role="dialog"
      aria-labelledby="App switcher"
      aria-describedby="Select app."
      aria-label="app switcher menu"
    >
      <SwitcherDropdownOptionsList>
        <Option
          value="platform-value"
          label="Platform"
          basic={true}
          multi={false}
          selected={false}
          onSelect={() => {
            navigate(urls.home(), {
              replace: true,
            });
          }}
        />
      </SwitcherDropdownOptionsList>
    </SwitcherDropdownMenuWrapper>,
    portal
  );
};
