import { useSearchParams } from "react-router-dom";

import SvgCalendar from "icons/lib/Calendar";
import styled from "styled-components";
import cssVar from "theme/vars";
import { H4M } from "typography/headers";
import { Filter } from "ui/forms";

import { skipToken, useActivityMethodologiesQuery } from "API";

import { useFilterUpdate } from "./slice";
import useFilterParam from "./useFilterParam";

interface SwitchActivitiesProps {
  reportingYearId: string;
}

const SwitchActivities: React.FC<SwitchActivitiesProps> = ({
  reportingYearId,
}) => {
  const [query, setQuery] = useSearchParams();
  const updateFilters = useFilterUpdate();
  const {
    data = [],
    isSuccess,
    isError,
  } = useActivityMethodologiesQuery(
    reportingYearId ? reportingYearId : skipToken
  );
  const [selected, _] = useFilterParam("activity_methodology", data, {
    defaultToFirst: true,
    ready: isSuccess || isError,
  });
  const selectedLabel =
    data.find((o) => o.value === selected)?.label ?? "Activity";

  const handleChange = (value: string) => {
    updateFilters("group_by", "none");

    updateFilters("activity_methodology", value);
    query.set("group_by", "none");
    query.set("activity_methodology", value);
    setQuery(query, { replace: true });
  };

  return (
    <DropdownFilterContainer>
      <DropdownFilterLabel>
        <Icon>
          <SvgCalendar width={12} height={13} />
        </Icon>
        <Title>Activity:</Title>
      </DropdownFilterLabel>
      <DropdownFilter
        items={{ options: data }}
        title="Activity"
        label={selectedLabel}
        onChange={handleChange}
        value={selected ?? ""}
        disabled={!isSuccess}
        anchor="left"
        searchable={true}
      />
    </DropdownFilterContainer>
  );
};

const DropdownFilterContainer = styled.div`
  display: inline-flex;
  align-items: center;
  height: 32px;
  border-radius: 4px;
`;

const DropdownFilterLabel = styled.div`
  display: inline-flex;
  align-items: center;
  padding: 12px;
  height: 32px;
  border-radius: 4px 0px 0px 4px;
  border-width: 1px 0px 1px 1px;
  border-style: solid;
  border-color: ${cssVar("color/primary/skinnyBlue")};
`;

const Title = styled(H4M)`
  padding-left: 4px;
  color: ${cssVar("color/primary/darkGrey")};
`;

const Icon = styled.div`
  padding-top: 1px;
  color: ${cssVar("color/primary/darkGrey")};
`;
const DropdownFilter = styled(Filter)`
  height: 32px;
  border-radius: 0px 4px 4px 0px;
  background-color: ${cssVar("color/primary/superSilverGrey")};
  border: 1px solid ${cssVar("color/primary/skinnyBlue")};

  &:hover {
    background-color: ${cssVar("color/primary/silverGrey")};
    border-color: ${cssVar("color/gray/40")};
  }
`;
export default SwitchActivities;
