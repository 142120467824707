import { createApi, skipToken } from "@reduxjs/toolkit/query/react";

import config from "config";

import * as ReportingYears from "./Endpoints/Filters/payloads/ReportingYear";
import { baseQuery } from "./fetch";
import * as Params from "./params";

const api = createApi({
  baseQuery: baseQuery,
  tagTypes: [
    "EntityReport",
    "ProgressReport",
    "Emissions",
    "Transaction",
    "Submission",
    "SubmissionData",
  ],
  refetchOnMountOrArgChange: config.api.disableCache,
  keepUnusedDataFor: 5,
  endpoints: (builder) => ({
    progressReport: builder.query<
      ReportingYears.ProgressReport,
      Params.GenericParamMap
    >({
      query: (query) => ({
        url: `/organisations/:org/reporting-years/${query.reporting_year}/progress-report/`,
        params: {
          ordering: Params.toString(query.ordering),
        },
      }),
      providesTags: ["ProgressReport"],
    }),
    progressReportCSV: builder.query<Blob, string>({
      query: (reporting_year) => ({
        url: `/organisations/:org/reporting-years/${reporting_year}/export-progress-report/`,
        responseHandler: (response: Response) => response.blob(),
      }),
    }),
  }),
});

export { skipToken, baseQuery };

export const { useProgressReportQuery, useProgressReportCSVQuery } = api;
export default api;
