import { ReportingYear } from "types/ReportingYear";

import { RootState } from "store/store";

export const organisationSelectors = {
  currentOrganisationId: (state: RootState): string | null =>
    state.organisation.currentOrganisation?.id || null,
  reportingYears: (state: RootState): ReportingYear[] =>
    state.organisation.reportingYears,
  selectedReportingYear: (state: RootState): ReportingYear | null =>
    state.organisation.selectedReportingYear,
  selectedReportingYearId: (state: RootState): string | null =>
    state.organisation.selectedReportingYear?.id || null,
  currentOrganisationName: (state: RootState): string | null =>
    state.organisation.currentOrganisation?.name || null,
};
