import React, {
  ChangeEvent,
  CSSProperties,
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import ReactDOM from "react-dom";
import styled, { css, keyframes } from "styled-components";
import { useClickOutside, useRootElement } from "../../hooks";
import cssVar from "theme/vars";
import { scrollbars } from "theme/mixins";
import Input from "../Input";
import { Alert, Button } from "../../core";
import OptionGroup from "./OptionGroup";
import Opt from "./Option";

export type Option = {
  label: string;
  value: string;
};

export type GroupByOption = {
  label: string;
  value: string;
  position?: number;
};

export type StandardOptionProps = {
  options: Option[];
};
export type GroupedOptionProps = {
  groups: {
    label: string;
    options: Option[];
  }[];
};
export type MenuProps = {
  open: boolean;
  left: number;
  right: number;
  anchor: "left" | "right";
  top: number;
  multi: boolean;
  onClose: () => void;
  onApply: (values: string[]) => void;
  value: string[];
  searchable?: boolean;
  title?: string;
  subtitle?: string;
  showApply?: boolean;
  showReset?: boolean;
  items: StandardOptionProps | GroupedOptionProps;
  parent: RefObject<HTMLElement>;
};

const Menu: React.FC<MenuProps> = ({
  open,
  top,
  left,
  right,
  anchor,
  onClose,
  items,
  onApply,
  multi,
  searchable = false,
  title = "Filters",
  subtitle = "Select filter to apply.",
  showApply = false,
  showReset = false,
  value,
  parent,
}) => {
  const portal = useRootElement("filter");
  const menu = useRef<HTMLDivElement>(null);
  useClickOutside(menu, onClose, parent);

  const [term, setTerm] = useState("");
  const handleSearch = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setTerm(e.target.value);
  }, []);
  const searchTerm = (searchable ? term : "").trim().toLowerCase();
  const filteredOptions = useMemo(() => {
    if (!searchTerm) {
      return "groups" in items ? items.groups : items.options;
    }

    if ("groups" in items) {
      // Filter out each sub-group, then filter out any empty groups
      return items.groups
        .map((group) => {
          return {
            ...group,
            options: group.options.filter(({ label }) =>
              label.toLowerCase().includes(searchTerm)
            ),
          };
        })
        .filter((group) => group.options.length);
    }

    return items.options.filter(({ label }) =>
      label.toLowerCase().includes(searchTerm)
    );
  }, [searchTerm, items]);

  // State for interrim value if the apply button is available
  const [updatedValue, setUpdatedValue] = useState(value);

  // Keep our state in-sync with provided value
  useEffect(() => {
    setUpdatedValue(value);
  }, [value, open]);

  const currentValue = showApply ? updatedValue : value;
  const handleSelect = useCallback(
    (value: string) => {
      let nextValue: string[];
      if (multi) {
        let set = new Set(currentValue);
        if (set.has(value)) {
          set.delete(value);
        } else {
          set = set.add(value);
        }
        nextValue = Array.from(set);
      } else {
        nextValue = [value];
      }
      (showApply ? setUpdatedValue : onApply)(nextValue);
    },
    [currentValue, showApply, onApply, multi]
  );
  const onReset = useCallback(() => {
    onApply([]);
    setUpdatedValue([]);
  }, [onApply]);

  const handleSelectAll = useCallback(() => {
    if (!("groups" in items)) {
      const change = showApply ? setUpdatedValue : onApply;
      change(
        currentValue.length === items.options.length
          ? []
          : items.options.map((o) => o.value)
      );
    }
  }, [items, showApply, onApply, currentValue]);

  const handleApply = useCallback(() => {
    onApply(updatedValue);
    onClose();
  }, [onApply, updatedValue, onClose]);

  const lengthIncludingGroups = (
    items: StandardOptionProps | GroupedOptionProps
  ) => {
    if (!("groups" in items)) {
      return items.options.length;
    }
    return items.groups.reduce(
      (runningTotal, group) => runningTotal + group.options.length,
      0
    );
  };
  const showSearch = lengthIncludingGroups(items) > 5;

  return ReactDOM.createPortal(
    <MenuWrapper
      ref={menu}
      style={
        {
          "--top": `${top}px`,
          "--left": `${left}px`,
          "--right": `${right}px`,
        } as CSSProperties
      }
      $open={open}
      $right={anchor === "right"}
      $searchable={showSearch}
      $showReset={showReset}
      $showApply={showApply}
      role="dialog"
      aria-labelledby={title}
      aria-describedby={subtitle}
      aria-label="filter menu"
    >
      {showSearch && (
        <Search
          placeholder="Search something"
          value={term}
          onChange={handleSearch}
          role="searchbox"
        />
      )}
      {!!searchTerm && !filteredOptions.length && (
        <NoResults>No results found for &quot;{searchTerm}&quot;</NoResults>
      )}
      <OptionsList
        $grouped={"groups" in items}
        $searchable={showSearch}
        $showReset={showReset}
        $showApply={showApply}
      >
        {"groups" in items &&
          (filteredOptions as GroupedOptionProps["groups"]).map((group) => (
            <OptionGroup
              key={group.label}
              label={group.label}
              options={group.options}
              value={currentValue}
              onSelect={handleSelect}
              multi={multi}
            />
          ))}
        {!("groups" in items) && (
          <>
            {multi && !searchTerm && (
              <Opt
                multi={true}
                key="all"
                bold={true}
                label={`Select all (${filteredOptions.length})`}
                value="all"
                selected={currentValue.length === filteredOptions.length}
                onSelect={handleSelectAll}
              />
            )}
            {(filteredOptions as StandardOptionProps["options"]).map(
              (option) => (
                <Opt
                  key={option.value}
                  value={option.value}
                  label={option.label}
                  multi={multi}
                  selected={currentValue.includes(option.value)}
                  onSelect={handleSelect}
                />
              )
            )}
          </>
        )}
      </OptionsList>
      <Actions>
        {showReset && (
          <ResetButton
            size="large"
            variant="text-primary"
            onClick={onReset}
            disabled={!currentValue.length}
          >
            Reset
          </ResetButton>
        )}
        {showApply && (
          <Button size="large" variant="primary" onClick={handleApply}>
            Show results
          </Button>
        )}
      </Actions>
    </MenuWrapper>,
    portal
  );
};

const Actions = styled.div`
  padding: 16px 16px 0px 16px;
  margin-left: auto;
  &:empty {
    display: none;
  }
`;
const ResetButton = styled(Button)`
  padding: 12px 16px;
  margin-right: 16px;
`;
const OptionsList = styled.div<{
  $grouped: boolean;
  $searchable?: boolean;
  $showReset: boolean;
  $showApply: boolean;
}>`
  width: 320px;
  max-height: 440px;
  overflow: auto;
  ${scrollbars()}
  padding: 8px 12px 8px 0;
  // Offset to allow padding on scroll bar
  margin-right: -12px;
  &:empty {
    display: none;
  }

  ${(props) =>
    props.$searchable &&
    css`
      border-top: 1px solid ${cssVar("color/gray/30")};
    `}

  // Force scrollbar when showing groups to prevent flicker
  ${(props) =>
    props.$grouped &&
    css`
      overflow-y: scroll;
    `}

  ${(props) =>
    (props.$showReset || props.$showApply) &&
    css`
      border-bottom: 0.5px solid ${cssVar("color/primary/disabledGrey")};
    `}
`;
const NoResults = styled(Alert).attrs({ variant: "empty" })`
  // Protect ourselves if the user types something mad
  h4 {
    word-break: break-all;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }
`;
const Search = styled(Input).attrs({ icon: "Search" })`
  padding: 0px 16px 16px 16px;
`;
const reveal = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
`;
const revealRight = keyframes`
  0% {
    opacity: 0;
    transform: translate(-100%, -20px);
  }
  100% {
    opacity: 1;
    transform: translate(-100%, 0px);
  }
`;
export const MenuWrapper = styled.div.attrs({ role: "dialog" })<{
  $open: boolean;
  $right: boolean;
  $searchable?: boolean;
  $showReset: boolean;
  $showApply: boolean;
  $form?: boolean;
}>`
  position: fixed;
  z-index: 200;
  top: var(--top);
  margin-top: 8px;
  animation: ${reveal} 0.3s ease backwards;

  ${(props) =>
    props.$right
      ? css`
          left: var(--right);
          transform: translateX(-100%);
          animation-name: ${revealRight};
        `
      : css`
          left: var(--left);
        `}

  width: 320px;
  max-height: min(576px, 52vh);
  background: ${cssVar("color/primary/white")};
  border: 1px solid ${cssVar("color/primary/disabledGrey")};
  border: ${(props) =>
    props.$form
      ? `1px solid ${cssVar("color/gray/30")}`
      : `1px solid ${cssVar("color/primary/disabledGrey")}`};
  box-shadow: 0px 4px 8px rgba(0, 0, 8, 0.08);
  border-radius: ${(props) => (props.$form ? "8px" : "4px")};

  display: ${(props) => (props.$open ? "flex" : "none")};
  flex-direction: column;
  overflow: hidden;
  padding: ${(props) =>
    !props.$searchable && !props.$showReset && !props.$showApply
      ? "0"
      : props.$searchable
      ? "16px 0px"
      : "0px 0px 16px 0px"};
`;

export default Menu;
