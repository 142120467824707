import * as Sentry from "@sentry/react";
import AppRoutes from "AppRoutes";
import ErrorPage from "Error/ErrorPage";
import TimeoutProvider from "SessionTimeout/TimeoutProvider";
import AnalyticsProvider from "Tracking/AnalyticsProvider";

import { ChunkErrorDialogue } from "Common/ChunkLoader";
import { UIFlagsProvider } from "Common/FeatureFlags";
import definitions from "Common/FeatureFlags/definitions";
import Snackbar from "Common/Snackbar";
import Application from "Layout/Application";

function App() {
  return (
    <UIFlagsProvider features={definitions}>
      <TimeoutProvider>
        <Application>
          <Sentry.ErrorBoundary fallback={<ErrorPage />}>
            <AnalyticsProvider>
              <AppRoutes />
              <Snackbar />
            </AnalyticsProvider>
          </Sentry.ErrorBoundary>
          <ChunkErrorDialogue />
        </Application>
      </TimeoutProvider>
    </UIFlagsProvider>
  );
}

export default App;
