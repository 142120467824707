import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

import ErrorPage from "Error/ErrorPage";
import styled from "styled-components";
import { Spinner } from "ui/core";

import { userSelectors } from "store/user/userSelectors";
import { userThunks } from "store/user/userThunks";

// import { HUB_FEATURE_FLAG } from "utilities/consts";
import { useAppDispatch, useAppSelector } from "utilities/hooks/general";
import urls, { hubUrls } from "utilities/urls";
import { isAnyNonHubFeatureFlagEnabled } from "utilities/utils";

const Redirect: React.FC = () => {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const userID = useAppSelector(userSelectors.userId);
  const flags = useAppSelector(userSelectors.featureFlags);
  const [errorPage, setErrorPage] = useState(false);
  const navigate = useNavigate();

  const isHubFeatureFlagEnabled = useSelector(
    userSelectors.someOrganisationsContainHubFeatureFlag
  );

  useEffect(() => {
    if (userID) {
      const isNonHubFeatureFlagEnabled = isAnyNonHubFeatureFlagEnabled(flags);

      // State is ready, redirect

      // If user has Hub feature and no other feature enabled
      if (isHubFeatureFlagEnabled && !isNonHubFeatureFlagEnabled) {
        navigate(hubUrls.tasks(), {
          replace: true,
        });
      } else {
        navigate(urls.home(), {
          replace: true,
        });
      }

      // Data already retrieved no reason to re-fetch
      return;
    }
    async function getDetails() {
      try {
        await dispatch(userThunks.fetchUserDetails()).unwrap();
      } catch (e) {
        if (e === "ERR_NETWORK") {
          // handling network error
          setErrorPage(true);
        } else {
          // User is not authenticated
          navigate(`${urls.login()}?${searchParams.toString()}`, {
            replace: true,
          });
        }
      }
    }

    void getDetails();
  }, [
    userID,
    flags,
    dispatch,
    navigate,
    searchParams,
    isHubFeatureFlagEnabled,
  ]);

  if (userID) {
    // Prevent flicker of spinner
    return null;
  }

  return (
    <RedirectWrapper>{errorPage ? <ErrorPage /> : <Spinner />}</RedirectWrapper>
  );
};

const RedirectWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default Redirect;
