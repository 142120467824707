import React, { CSSProperties, RefObject, useRef } from "react";
import ReactDOM from "react-dom";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import styled from "styled-components";
import cssVar from "theme/vars";
import { Entity } from "types/Entity";
import Option from "ui/forms/Filter/Option";

import { userSelectors } from "store/user/userSelectors";
import { userSlice } from "store/user/userSlice";
import { userThunks } from "store/user/userThunks";

import { useClickOutside, useRootElement } from "ui/hooks";
import { useAppDispatch } from "utilities/hooks/general";
import urls, { hubUrls } from "utilities/urls";

import {
  SwitcherDropdownMenuWrapper,
  SwitcherDropdownOptionsList,
} from "Common/styles";

type StandardOptionProps = {
  options: Entity[];
};

type DropdownProps = {
  open: boolean;
  top: number;
  right: number;
  onClose: () => void;
  parent: RefObject<HTMLElement>;
};

const isCurrentOrg = (
  org: Entity,
  currentOrganisation: Entity | null
): boolean => {
  return org.id === currentOrganisation?.id;
};

const OrgSwitcherDropdown: React.FC<DropdownProps> = ({
  open,
  top,
  right,
  onClose,
  parent,
}) => {
  const portal = useRootElement("switcher");
  const menu = useRef<HTMLDivElement>(null);
  useClickOutside(menu, onClose, parent);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const currentOrganisation = useSelector(userSelectors.currentOrganisation);
  const organisations = useSelector(userSelectors.organisations);
  const isHubFeatureFlagEnabled = useSelector(
    userSelectors.someOrganisationsContainHubFeatureFlag
  );

  const handleSetNewOrganisation = (id: string) => {
    void dispatch(
      userThunks.updateCurrentOrganisation({
        organisationId: id,
      })
    )
      .unwrap()
      .then(
        (_) => {
          dispatch(userSlice.actions.resetOrganisation());
          navigate(urls.default(), { replace: true });
        },
        (_) => void 0
      );
  };

  const handleSelect = (id: string) => {
    handleSetNewOrganisation(id);
  };

  return ReactDOM.createPortal(
    <SwitcherDropdownMenuWrapper
      ref={menu}
      style={
        {
          "--top": `${top}px`,
          "--right": `${right}px`,
        } as CSSProperties
      }
      $open={open}
      role="dialog"
      aria-labelledby="Organisation switcher"
      aria-describedby="Select organisation."
      aria-label="organisation switcher menu"
    >
      <SwitcherDropdownOptionsList>
        {(organisations as StandardOptionProps["options"]).map((option) => (
          <Option
            key={option.id}
            value={option.id}
            label={option.name}
            basic={true}
            multi={false}
            selected={isCurrentOrg(option, currentOrganisation)}
            onSelect={handleSelect}
          />
        ))}
        {isHubFeatureFlagEnabled && (
          <>
            <SwitcherDropdownOptionsDivider />
            <Option
              value="hub-value"
              label="Hub"
              basic={true}
              multi={false}
              selected={false}
              onSelect={() => {
                navigate(hubUrls.tasks(), {
                  replace: true,
                });
              }}
            />
          </>
        )}
      </SwitcherDropdownOptionsList>
    </SwitcherDropdownMenuWrapper>,
    portal
  );
};

const SwitcherDropdownOptionsDivider = styled.div`
  border-top: 1px solid ${cssVar("color/primary/disabledGrey")};
`;

export default OrgSwitcherDropdown;
