import { Button } from "@minimum.eco/min-ui";
import styled from "styled-components";
import cssVar from "theme/vars";

import config from "config";

import BasePage from "Layout/Page";

const ErrorPage = () => {
  return (
    <Page name="Error">
      <Title>Sorry! Something went wrong</Title>
      <Body>
        Please
        <Link
          as={Button}
          onClick={() => {
            window.location.reload();
          }}
          variant="link"
        >
          refresh
        </Link>
        the page and try again or if the problem persists
        <EmailLink href={`mailto:${config.supportEmail}`}>
          contact support.
        </EmailLink>
      </Body>
    </Page>
  );
};

const Page = styled(BasePage)`
  align-items: center;
  padding-top: 10%;
`;

const Title = styled.h1`
  color: ${cssVar("color/slate/800")};
  margin-bottom: ${cssVar("spacing/2")};
  font-family: ${cssVar("text/family/regular")};
  font-size: ${cssVar("text/legacy/xl/size")};
  line-height: ${cssVar("text/legacy/xl/lineHeight")};
  letter-spacing: ${cssVar("text/legacy/xl/letterSpacing")};
  font-weight: bold;
`;

const Body = styled.p`
  font-family: ${cssVar("text/family/regular")};
  font-size: ${cssVar("text/legacy/m/size")};
  line-height: ${cssVar("text/legacy/m/lineHeight")};
  letter-spacing: ${cssVar("text/legacy/m/letterSpacing")};
  font-weight: 400;
  color: ${cssVar("color/slate/600")};
`;

const Link = styled.a`
  display: inline;
  margin-left: ${cssVar("spacing/2")};
  margin-right: ${cssVar("spacing/2")};
  font-weight: bold;
`;

const EmailLink = styled(Link)`
  color: ${cssVar("color/emerald/600")};
  text-decoration: none;
`;

export default ErrorPage;
