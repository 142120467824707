import React, { useCallback } from "react";

import { Button, Modal } from "ui/core";

import { useChunkError } from "./slice";

const ChunkErrorDialogue: React.FC = () => {
  const handleReload = useCallback(() => {
    window.location.reload();
  }, []);

  const error = useChunkError();
  if (!error) {
    return null;
  }

  const title =
    error === "chunk_missing" ? "Application Updated" : "Could not load page";
  return (
    <Modal.Window open={true} closeOnClickOutside={false}>
      <Modal.Header withCloseButton={false} title={title} />
      <Modal.Body>
        <p>
          {error === "chunk_missing" ? (
            <>
              Our application has been updated. To continue using Minimum please
              reload this page in your browser.
            </>
          ) : (
            <>
              Something went wrong when trying to load this page, please reload
              your browser and try again.
            </>
          )}
        </p>
      </Modal.Body>
      <Modal.FooterWithActions>
        <Button onClick={handleReload}>Reload</Button>
      </Modal.FooterWithActions>
    </Modal.Window>
  );
};

export { ChunkErrorDialogue };
