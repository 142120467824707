import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { DataCaptureCategory } from "types/Category";
import { SubmittedData, SubmittedDataSource } from "types/SubmittedData";

import { dataCaptureThunks } from "store/dataCapture/dataCaptureThunks";

import { getNewSubmittedData } from "utilities/factories/submittedDataFactory";

type CaptureOriginPage = `atlas` | `projectManage` | `submissions` | null;
export type DataCaptureSliceState = {
  loadingCategories: boolean;
  loadingData: boolean;
  saving: boolean;
  loadingCategory: boolean;
  selectedCategoryId: string | null;
  categories: DataCaptureCategory[];
  arrivedVia: CaptureOriginPage;
  submittedData: {
    [key: string]: SubmittedData;
  };
};

const initialState: DataCaptureSliceState = {
  loadingCategories: false,
  loadingData: false,
  loadingCategory: false,
  selectedCategoryId: null,
  categories: [],
  submittedData: {},
  arrivedVia: null,
  saving: false,
};

export const dataCaptureSlice = createSlice({
  name: "dataCapture",
  initialState,
  reducers: {
    setActiveCategoryId: (state, action: PayloadAction<string>) => {
      state.selectedCategoryId = action.payload;
    },
    setArrival: (state, action: PayloadAction<CaptureOriginPage>) => {
      state.arrivedVia = action.payload;
    },
    setSelectedCategoryToShowErrors: (state) => {
      const data = state.submittedData[state.selectedCategoryId ?? ""];
      if (state.selectedCategoryId && data) {
        data.showErrors = true;
      }
    },
    setCategoryData: (
      state,
      action: PayloadAction<{
        categoryId: string;
        data: any;
        errors?: any;
      }>
    ) => {
      const payload = state.submittedData[action.payload.categoryId];
      if (payload) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        payload.data = action.payload.data;
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        payload.errors = action.payload.errors;
      }
    },

    addCategoryFile: (
      state,
      action: PayloadAction<{ categoryId: string; fileId: string }>
    ) => {
      const submittedData = state.submittedData[action.payload.categoryId];
      if (submittedData) {
        submittedData.files = [...submittedData.files, action.payload.fileId];
      }
    },
    removeCategoryFile: (
      state,
      action: PayloadAction<{ categoryId: string; fileId: string }>
    ) => {
      const submittedData = state.submittedData[action.payload.categoryId];
      if (submittedData) {
        submittedData.files = [...submittedData.files].filter(
          (fileId) => fileId !== action.payload.fileId
        );
      }
    },

    setCategorySource: (
      state,
      action: PayloadAction<{
        categoryId: string;
        source: SubmittedDataSource | null;
        clearData?: boolean;
      }>
    ) => {
      const { categoryId, source, clearData } = action.payload;
      const submittedData = state.submittedData[categoryId];
      if (submittedData) {
        submittedData.source = source;
        if (clearData) {
          submittedData.data = {};
          submittedData.errors = {};
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(dataCaptureThunks.fetchCategories.pending, (state) => {
      state.loadingCategories = true;
    });
    builder.addCase(
      dataCaptureThunks.fetchCategories.fulfilled,
      (state, { payload }) => {
        state.loadingCategories = false;
        state.categories = payload;
        const firstItem = payload[0];
        if (firstItem) {
          if (!state.selectedCategoryId) {
            state.selectedCategoryId = firstItem.id;
          }
          // create empty data objects for any without
          const newSubmittedData = payload.reduce((acc, category) => {
            const catId = category.id;
            const newData = getNewSubmittedData(catId);
            const cat = category.sources[0];
            if (cat) {
              newData.source = cat;
            }
            return {
              ...acc,
              [catId]: newData,
            };
          }, {});
          state.submittedData = newSubmittedData;
        }
      }
    );
    builder.addCase(dataCaptureThunks.fetchCategories.rejected, (state) => {
      state.loadingCategories = false;
    });

    builder.addCase(dataCaptureThunks.fetchCategory.pending, (state) => {
      state.loadingCategory = true;
    });
    builder.addCase(dataCaptureThunks.fetchCategory.fulfilled, (state) => {
      state.loadingCategory = false;
    });
    builder.addCase(dataCaptureThunks.fetchCategory.rejected, (state) => {
      state.loadingCategory = false;
    });
    builder.addCase(dataCaptureThunks.fetchData.pending, (state) => {
      state.loadingData = true;
    });
    builder.addCase(dataCaptureThunks.fetchData.rejected, (state) => {
      state.loadingData = false;
    });
    builder.addCase(
      dataCaptureThunks.fetchData.fulfilled,
      (state, { payload }) => {
        state.loadingData = false;
        const key = state.selectedCategoryId;
        const data = payload[0];
        if (key) {
          state.submittedData[key] = data ? data : getNewSubmittedData(key);
        }
      }
    );

    builder.addCase(dataCaptureThunks.fetchAllData.pending, (state) => {
      state.loadingData = true;
    });
    builder.addCase(dataCaptureThunks.fetchAllData.rejected, (state) => {
      state.loadingData = false;
    });
    builder.addCase(
      dataCaptureThunks.fetchAllData.fulfilled,
      (state, { payload }) => {
        state.loadingData = false;
        const remappedData = payload.reduce((acc, current) => {
          return { ...acc, [current.category]: current };
        }, {});
        state.submittedData = {
          ...state.submittedData,
          ...remappedData,
        };
      }
    );
    builder.addCase(dataCaptureThunks.saveCategoryData.rejected, (state) => {
      state.saving = false;
    });
    builder.addCase(dataCaptureThunks.saveCategoryData.pending, (state) => {
      state.saving = true;
    });
    builder.addCase(
      dataCaptureThunks.saveCategoryData.fulfilled,
      (state, { payload }) => {
        state.saving = false;
        const { errors } = state.submittedData[payload.category] || {
          errors: {},
        };
        state.submittedData[payload.category] = {
          ...payload,
          errors,
          showErrors: true,
        };
      }
    );
  },
});

export default dataCaptureSlice.reducer;
