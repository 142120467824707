import { useEffect } from "react";
import { NavLink, Outlet, useLocation, useNavigate } from "react-router-dom";

import styled from "styled-components";
import cssVar from "theme/vars";
import { H4M } from "typography/headers";

export type NestedTab = {
  label: string;
  url: string;
  badgeValue?: number;
};

type NestedTabsProps = {
  tabs: NestedTab[];
  redirectToFirst?: boolean;
};

const NestedTabs: React.FC<NestedTabsProps> = ({ tabs, redirectToFirst }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const activeIndex = tabs.findIndex((tab) => pathname.startsWith(tab.url));
  const firstUrl = tabs[0]?.url;

  useEffect(() => {
    if (redirectToFirst && firstUrl && activeIndex === -1) {
      // Select first tab
      navigate(firstUrl, { replace: true });
    }
  }, [navigate, firstUrl, redirectToFirst, activeIndex]);

  return (
    <>
      <TabsWrapper>
        {tabs.map((item, index) => {
          return (
            <TabLink key={`${item.label} + ${index}`} to={item.url}>
              <H4M as="span">{item.label}</H4M>
              {item.badgeValue && <Badge>{item.badgeValue}</Badge>}
            </TabLink>
          );
        })}
      </TabsWrapper>
      <Outlet />
    </>
  );
};

const TabsWrapper = styled.div`
  max-width: 100%;
  display: flex;
  overflow: hidden;
  position: relative;
  min-height: 32px;
  border-bottom: 1px solid ${cssVar("color/slate/300")};
`;
const TabLink = styled(NavLink)`
  text-decoration: none;
  display: flex;
  align-items: center;
  padding: 4px 12px 12px;
  color: ${cssVar("color/gray/60")};
  text-decoration: none;
  transition: color 0.2s ease;
  text-align: center;
  white-space: nowrap;
  flex-shrink: 0;
  min-width: 0;

  &:hover {
    color: ${cssVar("color/gray/80")};
  }

  ${H4M} {
    color: inherit;
  }

  &.active {
    color: ${cssVar("color/slate/800")};
    border-bottom: 2px solid ${cssVar("color/gray/80")};
  }
`;
const Badge = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 4px;
  height: 16px;
  width: 16px;
  font-size: 11px;
  color: ${cssVar("color/primary/white")};
  background-color: ${cssVar("color/error/80")};
  border-radius: 50%;
`;

export default NestedTabs;
