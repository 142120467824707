import { useContext } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import OrganisationSwitcher from "OrganisationSwitcher";
import { AnalyticsEvent } from "Tracking";
import { AnalyticsContext } from "Tracking/AnalyticsProvider";
import styled from "styled-components";
import cssVar from "theme/vars";
import { FeatureFlag } from "types/Entity";
import { Tabs } from "ui/core";
import { Avatar } from "ui/core";
import { Scroller } from "ui/core/Tabs";

import { userSelectors } from "store/user/userSelectors";

import { HUB_FEATURE_FLAG } from "utilities/consts";
import {
  Navlink,
  useNavigationLinks,
} from "utilities/hooks/useNavigationLinks";
import urls from "utilities/urls";

import { Logo, NavContainer, NavLine } from "Common/styles";

export const Links: Navlink[] = [
  {
    label: "Home",
    getUrl: urls.home,
  },
  {
    label: "Atlas",
    getUrl: urls.atlas,
    flags: [FeatureFlag.ATLAS_VIEW],
  },
  {
    label: "Data submission",
    getUrl: urls.submissionGroup,
    flags: [FeatureFlag.DATA_COLLECTION_VIEW],
  },
  {
    label: "Data submission (legacy)",
    getUrl: urls.legacySubmissions,
    flags: [FeatureFlag.LEGACY_SUBMISSIONS_VIEW],
  },
  {
    label: "Data submission (legacy)",
    getUrl: urls.dataSubmission,
    flags: [FeatureFlag.SUBMISSIONS_VIEW],
  },
  {
    label: "Project management",
    getUrl: urls.dataTracker,
    flags: [FeatureFlag.DATA_TRACKER_VIEW],
  },
  {
    label: "Submission progress",
    getUrl: urls.submissionProgress,
    flags: [FeatureFlag.SUBMISSION_PROGRESS_VIEW],
  },
  {
    label: "Emissions",
    getUrl: urls.emissionsEmissionsData,
    parentUrl: urls.emissions,
    flags: [FeatureFlag.EMISSIONS_VIEW],
  },
  {
    label: "Analytics",
    getUrl: urls.analytics,
    flags: [
      FeatureFlag.EXPLO_DASHBOARDS_VIEW,
      FeatureFlag.BESPOKE_EXPLO_DASHBOARDS_VIEW,
      FeatureFlag.STANDARD_EXPLO_DASHBOARDS_VIEW,
    ],
  },
  {
    label: "Reports",
    getUrl: urls.requestReport,
    flags: [FeatureFlag.REQUEST_REPORT_VIEW],
  },
  {
    label: "Documents",
    getUrl: urls.documents,
    flags: [FeatureFlag.DOCUMENTS_VIEW],
  },
  {
    label: "Transactions",
    getUrl: urls.transactions,
    flags: [FeatureFlag.TRANSACTIONS_VIEW],
  },
  {
    label: "Methodologies",
    getUrl: urls.methodologies,
    flags: [FeatureFlag.METHODOLOGIES_LIST_VIEW],
  },
];

const Navigation = () => {
  const navigate = useNavigate();
  const { trackEvent } = useContext(AnalyticsContext);
  const flags = useSelector(userSelectors.featureFlags);
  const currentOrg = useSelector(userSelectors.currentOrganisation);
  const userOrganisations = useSelector(userSelectors.organisations);
  const name = useSelector(userSelectors.name);
  const email = useSelector(userSelectors.email);

  const links = useNavigationLinks(Links, flags);
  const isHubFeatureFlagEnabled = Boolean(flags[HUB_FEATURE_FLAG]);

  const handleNavClick = (destination: string, origin: string) => {
    trackEvent(AnalyticsEvent.NAVIGATION_BAR_TAB_VIEWED, {
      "Destination Navigation Bar Tab": destination,
      "Origin Navigation Bar Tab": origin,
    });
  };

  return (
    <NavContainer>
      <Logo
        src={currentOrg?.minimumLogoUrl}
        onClick={() => {
          navigate(urls.home());
        }}
      />
      <NavLinks tabs={links} onClickCallback={handleNavClick} />

      <StyledAvatar
        label={name || email}
        size={32}
        onClick={() => {
          navigate(urls.settings());
        }}
      />
      {(userOrganisations?.length !== 1 || isHubFeatureFlagEnabled) &&
        currentOrg && (
          <>
            <NavLine />
            <OrganisationSwitcher currentOrg={currentOrg} />
          </>
        )}
    </NavContainer>
  );
};

const NavLinks = styled(Tabs)`
  border-bottom: none;
  margin-top: auto;
  background-color: ${cssVar("color/primary/superSilverGrey")};

  ${Scroller} {
    background-color: ${cssVar("color/primary/superSilverGrey")};
    margin-bottom: 5px;
  }
`;

const StyledAvatar = styled(Avatar)`
  margin-left: auto;
  cursor: pointer;
`;

export default Navigation;
