import { useCallback } from "react";
import { useSearchParams } from "react-router-dom";

import { Pagination as Pages } from "ui/core";

export const QUERY_PARAM = "p";

export const usePage = () => {
  const [params] = useSearchParams();
  const current = parseInt(params.get(QUERY_PARAM) ?? "1", 10);
  return isNaN(current) || !current ? 1 : current;
};

interface PaginationParams {
  total?: number;
  onPageChange?: () => void;
}
const Pagination: React.FC<PaginationParams> = ({
  total = 1,
  onPageChange,
}) => {
  const page = usePage();
  const [params, setParams] = useSearchParams();

  const handlePageChange = useCallback(
    (page: number) => {
      params.set(QUERY_PARAM, page.toString());
      setParams(params);

      if (onPageChange) {
        onPageChange();
      }
    },
    [params, setParams, onPageChange]
  );

  return <Pages current={page} total={total} onPage={handlePageChange} />;
};

export default Pagination;
