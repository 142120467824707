import api from "API";

import * as Params from "../../params";
import * as EmissionsActivityData from "./payloads";

export const emissionsActivityDataEndpoint = `/organisations/:org/activity-data/`;
export const emissionsActivityDataAsyncExportEndpoint =
  emissionsActivityDataEndpoint + `async-export/`;

const extendedEndpoints = api.injectEndpoints({
  endpoints: (builder) => ({
    emissionsActivityData: builder.query<
      EmissionsActivityData.GetEmissionsActivityData,
      Params.GenericParamMap
    >({
      query: (query) => {
        const params = {
          page: query.page,
          page_size: query.page_size,
          group_by: Params.toString(query.group_by),
          reporting_year: query.reporting_year,
          date_from: Params.toString(query.date_from),
          date_to: Params.toString(query.date_to),
          entity: Params.toArray(query.entity),
          location: Params.toArray(query.location),
          activity_methodology: query.activity_methodology,
        };

        return {
          url: emissionsActivityDataEndpoint,
          params: Params.enrichParamsWithCustomAttributes({ params, query }),
        };
      },
    }),
    emissionsActivityDataAsyncExport: builder.query<
      null,
      Params.GenericParamMap
    >({
      query: (query) => {
        const params = {
          reporting_year: query.reporting_year,
          date_from: Params.toString(query.date_from),
          date_to: Params.toString(query.date_to),
          entity: Params.toArray(query.entity),
          location: Params.toArray(query.location),
        };

        return {
          url: emissionsActivityDataAsyncExportEndpoint,
          params,
        };
      },
    }),
  }),
});

export const {
  useEmissionsActivityDataQuery,
  useLazyEmissionsActivityDataAsyncExportQuery,
} = extendedEndpoints;
