import DL, { Term, Desc } from "./DefinitionList";

type Exports = typeof DL & {
  Term: typeof Term;
  Desc: typeof Desc;
};

const exported = DL as Exports;
exported.Term = Term;
exported.Desc = Desc;

export default exported;
