import React, { ReactNode } from "react";

import "./tooltip.scss";

export type TooltipPosition = "above" | "below" | "left" | "right";

export interface TooltipProps {
  text?: ReactNode;
  children: ReactNode;
  delay?: number;
  spaced?: boolean;
  position?: TooltipPosition;
}

const Tooltip: React.FC<TooltipProps> = ({
  children,
  text,
  position,
  spaced,
}) => {
  const spacedClassName = spaced ? "min-ui__tooltip__content--spaced" : "";
  const positionClassName =
    "min-ui__tooltip__content--" + (position || "above");

  return (
    <div className="min-ui__tooltip__container">
      {children}
      {text && (
        <div
          className={`min-ui__tooltip__content ${positionClassName} ${spacedClassName}`}
        >
          <span className="min-ui__tooltip__content__text">{text}</span>
        </div>
      )}
    </div>
  );
};

export default Tooltip;
