import { Route, Routes } from "react-router-dom";

import AccountCheck from "Authentication/AccountCheck";
import Guard from "Authentication/Guard";
import HubOnlyAccessCheck from "Authentication/HubOnlyAccessCheck";
import OrgRestrictedRoute from "Authentication/OrgRestrictedRoute";
import UserRedirect from "User/Redirect";

import urls, { hubUrlParts, urlParts } from "utilities/urls";

import { lazy } from "Common/ChunkLoader";

const TasksPage = lazy(() => import("Hub/pages/Tasks"));
const HubSettingsPage = lazy(() => import("Hub/pages/Settings"));
const TaskDetails = lazy(() => import("Hub/pages/TaskDetails"));
const ViewSubmittedSurvey = lazy(
  () => import("Hub/pages/TaskDetails/components/ViewSubmittedSurvey")
);

const LoginPage = lazy(() => import("Authentication/Login"));
const SupplierInvitation = lazy(
  () => import("Authentication/SupplierInvitation")
);
const CallbackHandler = lazy(() => import("Authentication/CallbackHandler"));
const HomePage = lazy(() => import("Home"));
const SettingsPage = lazy(() => import("pages/Settings"));
const AtlasPage = lazy(() => import("Atlas"));
const DataTrackerPage = lazy(() => import("DataTracker"));
const SubmissionProgress = lazy(
  () => import("pages/SubmissionProgress/SubmissionProgress")
);
const SubmissionProgressCadenceDetails = lazy(
  () => import("pages/SubmissionProgress/SubmissionProgressCadenceDetails")
);
const SubmissionProgressActiveSubmissions = lazy(
  () => import("pages/SubmissionProgress/SubmissionProgressActiveSubmissions")
);
const SubmissionProgressSubmittedItemsDetails = lazy(
  () =>
    import("pages/SubmissionProgress/SubmissionProgressSubmittedItemsDetails")
);
const SubmissionProgressFileUploads = lazy(
  () => import("pages/SubmissionProgress/SubmissionProgressFileUploads")
);
const SuppliersPage = lazy(() => import("DataTracker/Supplier"));
const DownloadExportedFilePage = lazy(
  () => import("pages/DownloadExportedFile")
);
const LegacyDataCapturePage = lazy(() => import("LegacyDataCapture"));
const Analytics = lazy(() => import("Analytics"));
const RequestReport = lazy(() => import("RequestReport"));
const Emissions = lazy(() => import("pages/Emissions/Emissions"));
const EmissionsActivityData = lazy(
  () => import("pages/Emissions/EmissionsActivityData")
);
const EmissionDetails = lazy(() => import("EmissionDetails"));
const AuditLogIndex = lazy(() => import("EmissionDetails/AuditLogIndex"));
const ConsolidationDetails = lazy(
  () => import("EmissionDetails/ConsolidationDetails")
);
const CalculationLog = lazy(() => import("EmissionDetails/CalculationLog"));
const SubmissionLog = lazy(() => import("EmissionDetails/SubmissionLog"));
const EstimationDetails = lazy(
  () => import("EmissionDetails/EstimationDetails")
);
const Transactions = lazy(() => import("Transactions"));
const TransactionDetails = lazy(() => import("TransactionDetails"));
const DataSubmission = lazy(() => import("LegacySubmissions"));
const LegacySubmissionSlot = lazy(
  () => import("LegacySubmissionSlot/LegacySubmissionSlotPage")
);
const Methodologies = lazy(() => import("Methodology/Page"));
const SubmissionGroups = lazy(() => import("DataCollection/SubmissionGroups"));
const SubmissionGroupIndex = lazy(
  () => import("DataCollection/SubmissionGroups/SubmissionGroupIndex")
);
const SubmissionGroupUpload = lazy(
  () => import("DataCollection/SubmissionGroupUpload")
);
const SubmissionGroupActionRequired = lazy(
  () => import("DataCollection/SubmissionGroups/SubmissionGroupActionRequired")
);
const SubmissionGroupVarianceCheck = lazy(
  () => import("DataCollection/SubmissionGroups/SubmissionGroupVarianceCheck")
);
const SubmissionGroupVarianceChecks = lazy(
  () =>
    import("DataCollection/SubmissionGroups/SubmissionGroupVarianceChecksTab")
);
const SubmissionSlots = lazy(() => import("DataCollection/SubmissionSlots"));
const SubmissionSlotUpload = lazy(
  () => import("DataCollection/SubmissionSlotDetails")
);
const Documents = lazy(() => import("pages/Documents"));
const DocumentUpload = lazy(() => import("pages/DocumentUpload"));
const DocumentUpdate = lazy(() => import("pages/DocumentUpdate"));

const ProtectedRoutes = () => {
  return (
    <AccountCheck>
      <Routes>
        <Route path={urlParts.auth}>
          <Route
            path={urls.supplierInvite()}
            element={<SupplierInvitation />}
          />
          <Route path={urlParts.login} element={<LoginPage />} />
          <Route path={urlParts.callback} element={<CallbackHandler />} />
          <Route path="*" element={<LoginPage />} />
        </Route>

        {/* Hub pages */}
        <Route
          path={hubUrlParts.root}
          element={<Guard withNav={false} withHubNav={true} />}
        >
          <Route path={hubUrlParts.settings} element={<HubSettingsPage />} />
          <Route path={hubUrlParts.tasks} element={<TasksPage />} />
        </Route>
        <Route
          path={hubUrlParts.root}
          element={<Guard withNav={false} withHubNav={false} />}
        >
          <Route path={hubUrlParts.tasks}>
            <Route path={`${hubUrlParts.organisationId}/${hubUrlParts.taskId}`}>
              <Route index element={<TaskDetails />} />
            </Route>
            <Route
              path={`${hubUrlParts.organisationId}/${hubUrlParts.taskId}/${hubUrlParts.activityId}`}
            >
              <Route index element={<ViewSubmittedSurvey />} />
            </Route>
          </Route>
        </Route>

        {/* Platform pages */}
        {/*We cant nest this in submissions as it has to not show the nav*/}
        <Route path={urlParts.org} element={<Guard withNav={false} />}>
          <Route path={urlParts.orgId} element={<OrgRestrictedRoute />}>
            <Route
              path={urlParts.downloadExportedFile}
              element={<DownloadExportedFilePage />}
            />

            <Route element={<HubOnlyAccessCheck />}>
              <Route path={`${urlParts.dataSubmission}/${urlParts.slotId}`}>
                <Route index element={<LegacySubmissionSlot />} />
              </Route>
              <Route path={urlParts.submissionGroup}>
                <Route
                  path={`${urlParts.submissionGroupId}/${urlParts.submissionGroupUpload}`}
                  element={<SubmissionGroupUpload />}
                />
                <Route
                  path={`${urlParts.submissionGroupId}/${urlParts.submissionSlot}`}
                >
                  <Route path={urlParts.submissionSlotId}>
                    <Route index element={<SubmissionSlotUpload />} />
                  </Route>
                </Route>
                <Route
                  path={`${urlParts.submissionGroupId}/${urlParts.submissionGroupActionRequired}/${urlParts.submissionSlotId}`}
                >
                  <Route index element={<SubmissionGroupVarianceCheck />} />
                </Route>
              </Route>
              <Route
                path={`${urlParts.documents}/${urlParts.documentUpload}`}
                element={<DocumentUpload />}
              />
              <Route
                path={`${urlParts.documents}/${urlParts.documentUpdate}/${urlParts.documentId}`}
                element={<DocumentUpdate />}
              />
            </Route>
          </Route>
        </Route>

        <Route path={urlParts.org} element={<Guard />}>
          <Route index element={<UserRedirect />} />
          <Route path={urlParts.orgId} element={<OrgRestrictedRoute />}>
            <Route index element={<UserRedirect />} />
            <Route element={<HubOnlyAccessCheck />}>
              <Route path={urlParts.home} element={<HomePage />} />
              <Route
                path={urlParts.methodologies}
                element={<Methodologies />}
              />
              <Route path={urlParts.settings} element={<SettingsPage />} />
              <Route path={urlParts.atlas}>
                <Route path={urlParts.selectedEntity} element={<AtlasPage />} />
                <Route index element={<AtlasPage />} />
              </Route>
              {/* Start of Data Collection Pages */}
              <Route path={urlParts.submissionGroup}>
                <Route index element={<SubmissionGroups />} />
                <Route
                  path={urlParts.submissionGroupId}
                  element={<SubmissionGroupIndex />}
                >
                  <Route path={urlParts.submissionSlot}>
                    <Route index element={<SubmissionSlots />} />
                  </Route>
                  <Route
                    path={urlParts.submissionGroupActionRequired}
                    element={<SubmissionGroupActionRequired />}
                  />
                  <Route
                    path={urlParts.submissionGroupVarianceChecks}
                    element={<SubmissionGroupVarianceChecks />}
                  />
                </Route>
              </Route>
              {/* End of Data Collection Pages */}

              <Route path={urlParts.dataTracker}>
                <Route
                  path={urlParts.selectedEntity}
                  element={<DataTrackerPage />}
                />
                <Route index element={<DataTrackerPage />} />
              </Route>
              {/* Submission Progress */}
              <Route path={urlParts.submissionProgress}>
                <Route index element={<SubmissionProgress />} />
                <Route
                  path={urlParts.submissionProgressCadenceId}
                  element={<SubmissionProgressCadenceDetails />}
                >
                  <Route path={urlParts.submissionProgressActiveSubmissions}>
                    <Route
                      index
                      element={<SubmissionProgressActiveSubmissions />}
                    />
                  </Route>
                  <Route
                    path={urlParts.submissionProgressNeedsAction}
                    element={<SubmissionProgressActiveSubmissions />}
                  />
                  <Route
                    path={urlParts.submissionProgressAwaitingData}
                    element={<SubmissionProgressActiveSubmissions />}
                  />
                </Route>
                <Route path={urlParts.submissionProgressSubmittedItems}>
                  <Route element={<SubmissionProgressSubmittedItemsDetails />}>
                    <Route path={urlParts.submissionProgressFileUploads}>
                      <Route
                        index
                        element={<SubmissionProgressFileUploads />}
                      />
                    </Route>
                  </Route>
                </Route>
              </Route>

              <Route
                path={urlParts.legacySubmissions}
                element={<SuppliersPage />}
              />
              <Route path={urlParts.analytics}>
                <Route index element={<Analytics />} />
                <Route path={urlParts.dashboard} element={<Analytics />} />
              </Route>
              {/*This is for category based legacy data capture*/}
              <Route path={urlParts.dataCapture}>
                <Route path={urlParts.selectedEntity}>
                  <Route path={urlParts.category}>
                    <Route
                      path={urlParts.selectedCategory}
                      element={<LegacyDataCapturePage />}
                    />
                  </Route>
                  <Route index element={<LegacyDataCapturePage />} />
                </Route>
                <Route index element={<UserRedirect />} />
              </Route>
              <Route
                path={urlParts.requestReport}
                element={<RequestReport />}
              />

              {/* Emissions */}
              <Route path={urlParts.emissions}>
                <Route
                  path={urlParts.emissionsEmissionsData}
                  element={<Emissions />}
                />
                <Route
                  path={urlParts.emissionsActivityData}
                  element={<EmissionsActivityData />}
                />
                <Route
                  path={`${urlParts.emissionId}/${urlParts.consolidationId}`}
                >
                  <Route index element={<EmissionDetails />} />
                  <Route path={urlParts.auditLog} element={<AuditLogIndex />}>
                    <Route
                      path={urlParts.consolidationDetails}
                      element={<ConsolidationDetails />}
                    />
                    <Route
                      path={urlParts.calculationLog}
                      element={<CalculationLog />}
                    />
                    <Route
                      path={urlParts.submissionLog}
                      element={<SubmissionLog />}
                    />
                    <Route
                      path={urlParts.estimationDetails}
                      element={<EstimationDetails />}
                    />
                  </Route>
                </Route>
              </Route>

              {/* Transactions */}
              <Route path={urlParts.transactions}>
                <Route index element={<Transactions />} />
                <Route
                  path={urlParts.transactionId}
                  element={<TransactionDetails />}
                />
              </Route>
              <Route
                path={urlParts.dataSubmission}
                element={<DataSubmission />}
              />
              <Route path={urlParts.documents} element={<Documents />} />
            </Route>
            <Route path="*" element={<UserRedirect />} />
          </Route>
        </Route>

        <Route path="*" element={<UserRedirect />} />
      </Routes>
    </AccountCheck>
  );
};

export default ProtectedRoutes;
