import { Filter } from "ui/forms";

import { useLegacySubmissionSlotStatusesQuery } from "API";

import useFilterParam from "Common/Filters/useFilterParam";

const LegacySubmissionStatus = () => {
  const {
    data = [],
    isSuccess,
    isError,
  } = useLegacySubmissionSlotStatusesQuery();
  const [selected, setSelected] = useFilterParam("submission_status", data, {
    defaultToFirst: false,
    multi: true,
    ready: isSuccess || isError,
  });
  return (
    <Filter
      items={{ options: data }}
      title="Status"
      label="Status"
      selected={!!selected.length}
      count={selected.length ? selected.length : void 0}
      onChange={setSelected}
      disabled={!selected}
      value={selected}
      multi={true}
      showReset={true}
      showApply={true}
      searchable={false}
    />
  );
};

export default LegacySubmissionStatus;
