import { createContext, useContext } from "react";

interface ModalContextState {
  open: boolean;
  onClose?: () => void;
}

const initialState: ModalContextState = {
  open: false,
};

export const ModalContext = createContext<ModalContextState>(initialState);

export const useModalContext = () => useContext(ModalContext);
