import { useCallback, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { QUERY_PARAM as PAGINATION_QUERY_PARAM } from "Common/Pagination";

import { setReady, useFilterUpdate } from "./slice";

type ReturnValue =
  | [string | null, (value: string) => void]
  | [string[], (value: string[]) => void];

/**
 * Hook to automatically sync a query string item with an array of options
 */
function useFilterParam(
  name: string,
  options: { value: string }[],
  config: {
    initialValue?: string;
    defaultToFirst?: boolean;
    multi?: false;
    ready?: boolean;
  }
): [string | null, (value: string) => void];
function useFilterParam(
  name: string,
  options: { value: string }[],
  config: {
    initialValue?: string;
    defaultToFirst?: boolean;
    multi: true;
    ready?: boolean;
  }
): [string[], (value: string[]) => void];
function useFilterParam(
  name: string,
  options: { value: string }[],
  config: {
    initialValue?: string;
    defaultToFirst?: boolean;
    multi?: boolean;
    ready?: boolean;
  }
): ReturnValue {
  const [query, setQuery] = useSearchParams();
  const values = useMemo(() => options.map((o) => o.value), [options]);
  const updateFilters = useFilterUpdate();
  const dispatch = useDispatch();
  const { ready: readyState } = config;

  const availableOptions = options.length;
  useEffect(() => {
    dispatch(setReady({ key: name, value: readyState ?? !!availableOptions }));
    return () => {
      dispatch(setReady({ key: name, value: null }));
    };
  }, [availableOptions, dispatch, name, readyState]);

  const onChangeFilter = useCallback(
    (value: string | string[]) => {
      // A change in filter means we should reset pagination
      query.delete(PAGINATION_QUERY_PARAM);

      // Remove the existing filter
      query.delete(name);

      if (Array.isArray(value)) {
        // Multi mode
        const filtered = value.filter((v) => values.includes(v));
        if (filtered.length) {
          // Add each valid value as a separate query parameter
          filtered.forEach((v) => query.append(name, v));
        }
      } else if (values.includes(value)) {
        // Valid single value
        query.append(name, value);
      }

      // Update the query parameters in the URL
      setQuery(query, { replace: true });
    },
    [setQuery, query, name, values]
  );

  let currentValue: string | string[] | null;
  if (config.multi) {
    // Use getAll to retrieve all instances of the query parameter
    currentValue = query.getAll(name).filter((v) => values.includes(v));
  } else {
    const queryValues = query.getAll(name);
    const queryValue = queryValues.length > 0 ? queryValues[0] : null; // Use the first value if it exists

    const defaultValue = config.initialValue
      ? config.initialValue
      : config.defaultToFirst
      ? values[0]
      : null;

    const isValid = queryValue && values.includes(queryValue);
    currentValue = isValid ? queryValue : defaultValue ?? null;
  }

  const memoID = Array.isArray(currentValue)
    ? currentValue.join(",")
    : currentValue ?? "";

  const memoized = useMemo(() => {
    return currentValue;
    // memoID is determined by currentValue so this is safe.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memoID]);

  useEffect(() => {
    updateFilters(name, memoized);
    return () => updateFilters(name, null);
  }, [name, memoized, updateFilters]);

  return [memoized, onChangeFilter] as ReturnValue;
}

export default useFilterParam;
