import api from "API";

import * as Params from "../../params";
import {
  GetSubmissionProgressCadenceDetails,
  GetSubmissionProgressFiles,
  GetSubmissionProgressOverview,
  GetSubmissionProgressReminderEmailAddresses,
  GetSubmissionProgressSubmissionSlots,
} from "./payloads";

export const SubmissionProgressEndpoint =
  "/organisations/:org/submission-progress/";
export const SubmissionProgressSubmissionsEndpoint =
  "/organisations/:org/submission-progress/submissions/";

const extendedEndpoints = api.injectEndpoints({
  endpoints: (builder) => ({
    submissionProgressOverview: builder.query<
      GetSubmissionProgressOverview,
      Params.GenericParamMap
    >({
      query: (query) => ({
        url: SubmissionProgressEndpoint + "overview",
        method: "GET",
        params: {
          reporting_year: query.reporting_year,
        },
      }),
    }),
    submissionProgressSubmissionSlots: builder.query<
      GetSubmissionProgressSubmissionSlots,
      Params.GenericParamMap
    >({
      query: (query) => {
        const params = {
          page: query.page,
          page_size: query.page_size,
          ordering: Params.toString(query.ordering),
          reporting_year: query.reporting_year,
          entity: Params.toArray(query.entity),
          activity: Params.toArray(query.activity),
          status: Params.toArray(query.status),
          time_period: Params.toArray(query.submissionPeriod),
          cadence: Params.toString(query.cadence),
          submission_group: Params.toArray(query.submissionGroup),
        };

        return {
          url: SubmissionProgressSubmissionsEndpoint,
          params: Params.enrichParamsWithCustomAttributes({ params, query }),
        };
      },
    }),
    submissionProgressFiles: builder.query<
      GetSubmissionProgressFiles,
      Params.GenericParamMap
    >({
      query: (query) => {
        const params = {
          page: query.page,
          page_size: query.page_size,
          ordering: Params.toString(query.ordering),
          reporting_year: query.reporting_year,
          entities: Params.toArray(query.entity),
          activities: Params.toArray(query.activity),
          status: Params.toArray(query.status),
          time_period: Params.toArray(query.submissionPeriod),
          submission_group: Params.toArray(query.submissionGroup),
          submitted_on: Params.toString(query.submitted_on),
          cadence: Params.toArray(query.cadence),
        };

        return {
          url: SubmissionProgressEndpoint + "files",
          params,
        };
      },
    }),
    submissionProgressCadenceDetails: builder.query<
      GetSubmissionProgressCadenceDetails,
      Params.GenericParamMap
    >({
      query: (query) => {
        return {
          url:
            SubmissionProgressSubmissionsEndpoint +
            `reporting-years/${query.reporting_year}/cadence-details/${query.cadence}`,
        };
      },
    }),
    submissionProgressReminderEmailAddresses: builder.query<
      GetSubmissionProgressReminderEmailAddresses,
      Params.GenericParamMap
    >({
      query: (query) => {
        return {
          url:
            SubmissionProgressSubmissionsEndpoint + "reminder-email-addresses",
          method: "GET",
          params: {
            page: query.page,
            page_size: query.page_size,
            reporting_year: query.reporting_year,
            entities: Params.toArray(query.entity),
            activities: Params.toArray(query.activity),
            status: Params.toArray(query.status),
            time_period: Params.toArray(query.submissionPeriod),
            cadence: Params.toString(query.cadence),
            id_in: Params.toList(query.id_in),
          },
        };
      },
    }),
    submissionProgressSendEmailReminders: builder.mutation<
      GetSubmissionProgressReminderEmailAddresses,
      Params.GenericParamMap
    >({
      query: (query) => {
        return {
          url: SubmissionProgressSubmissionsEndpoint + "send-email-reminders",
          method: "POST",
          params: {
            page: query.page,
            page_size: query.page_size,
            reporting_year: query.reporting_year,
            entities: Params.toArray(query.entity),
            activities: Params.toArray(query.activity),
            status: Params.toArray(query.status),
            time_period: Params.toArray(query.submissionPeriod),
            cadence: Params.toString(query.cadence),
            id_in: Params.toList(query.id_in),
          },
        };
      },
    }),
    submissionProgressExportToExcel: builder.query<
      Blob,
      Params.GenericParamMap
    >({
      query: (query) => {
        const params = {
          page: query.page,
          page_size: query.page_size,
          reporting_year: query.reporting_year,
          entity: Params.toArray(query.entity),
          activity: Params.toArray(query.activity),
          status: Params.toArray(query.status),
          time_period: Params.toArray(query.submissionPeriod),
          id_in: Params.toList(query.id_in),
        };

        return {
          url: SubmissionProgressSubmissionsEndpoint + "export",
          params: Params.enrichParamsWithCustomAttributes({ params, query }),
          responseHandler: (response: Response) => response.blob(),
        };
      },
    }),
  }),
});

export const {
  useSubmissionProgressOverviewQuery,
  useSubmissionProgressSubmissionSlotsQuery,
  useSubmissionProgressFilesQuery,
  useSubmissionProgressCadenceDetailsQuery,
  useLazySubmissionProgressReminderEmailAddressesQuery,
  useSubmissionProgressSendEmailRemindersMutation,
  useLazySubmissionProgressExportToExcelQuery,
} = extendedEndpoints;
