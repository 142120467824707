import { ReactNode } from "react";
import { Helmet } from "react-helmet";

// import { AnalyticsContext } from "Tracking/AnalyticsProvider";
import styled from "styled-components";
import cssVar from "theme/vars";

export type PageProps = {
  className?: string;
  children?: ReactNode;
  name: string;
  tabName?: string;
};

const Page: React.FC<PageProps> = ({ children, className, name, tabName }) => {
  return (
    <Container className={className}>
      <Helmet title={tabName ?? name} />
      {children}
    </Container>
  );
};

const Container = styled.div`
  padding: 24px 48px 32px;
  background-color: ${cssVar("color/primary/white")};
  overflow: visible;
  min-height: 100%;
`;

export default Page;
