import Window from "./Window";
import Body from "./Body";
import Footer, { FooterWithActions } from "./Footer";
import Header from "./Header";

export default {
  Window,
  Body,
  Header,
  Footer,
  FooterWithActions,
};
