import {
  FileStructure,
  SubmissionGroupData,
  SubmissionGroups,
} from "types/SubmissionGroups";

import api from "API";

import * as Params from "../../params";
import { GetSubmissionGroupParams, GetSubmissionGroups } from "./payloads";

export const SubmissionGroupsEndpoint =
  "/organisations/:org/submission-groups/";

const extendedEndpoints = api.injectEndpoints({
  endpoints: (builder) => ({
    submissionGroups: builder.query<
      GetSubmissionGroups,
      Params.GenericParamMap<GetSubmissionGroupParams>
    >({
      query: (query) => ({
        url: SubmissionGroupsEndpoint,
        method: "GET",
        params: {
          page: query.page,
          page_size: query.page_size,
          reporting_year: query.reporting_year,
          entities: Params.toArray(query.entity),
          activities: Params.toArray(query.activity),
          status: Params.toArray(query.submission_groups_status),
          ordering: Params.toString(query.ordering),
          submission_cadence: Params.toArray(query.cadence),
        },
      }),
    }),
    submissionGroupDetails: builder.query<
      SubmissionGroups,
      Params.GenericParamMap
    >({
      query: (query) => ({
        url: SubmissionGroupsEndpoint + `${query.submissionGroupId}`,
        method: "GET",
      }),
    }),
    uploadSubmissionGroupData: builder.mutation<
      SubmissionGroupData,
      { submissionGroupId: string; files: FileStructure }
    >({
      query: ({ submissionGroupId, ...files }) => ({
        url: SubmissionGroupsEndpoint + `${submissionGroupId}` + "/submit",
        method: "POST",
        body: files,
      }),
    }),
  }),
});

export const {
  useSubmissionGroupsQuery,
  useSubmissionGroupDetailsQuery,
  useLazySubmissionGroupDetailsQuery,
  useUploadSubmissionGroupDataMutation,
} = extendedEndpoints;
