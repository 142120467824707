import { extract, Leaves } from "./utilities";

const Spacing = {
  regular: "var(--spacing-3)",
  0: "0",
  1: "calc(var(--spacing-3) * 0.25)",
  2: "calc(var(--spacing-3) * 0.5)",
  3: "8px",
  4: "calc(var(--spacing-3) * 1.5)",
  5: "calc(var(--spacing-3) * 2)",
  6: "calc(var(--spacing-3) * 2.5)",
  7: "calc(var(--spacing-3) * 3)",
  8: "calc(var(--spacing-3) * 4)",
  9: "calc(var(--spacing-3) * 5)",
  10: "calc(var(--spacing-3) * 6)",
  11: "calc(var(--spacing-3) * 7)",
  12: "calc(var(--spacing-3) * 8)",
  13: "calc(var(--spacing-3) * 9)",
  14: "calc(var(--spacing-3) * 10)",
};

export type SpacingKey = Leaves<typeof Spacing>;
export default function getSpacing(key: SpacingKey): string {
  return extract(Spacing, key);
}

export { Spacing };
