import { SVGProps } from "react";
interface SvgCalendarProps extends SVGProps<SVGSVGElement> {
  /**
   * Width of icon, uses size if not given
   */
  width?: number;
  /**
   * Height of icon, uses size if not given
   */
  height?: number;
  /**
   * Size of icon, applies to width & height if not supplied
   * @default 20
   */
  size?: number;
}
export const SvgCalendar = (props: SvgCalendarProps) => (
  <svg
    {...props}
    width={props.width ?? props.size ?? 18}
    height={props.height ?? props.size ?? 20}
    viewBox="0 0 18 20"
    fill="currentColor"
  >
    <path d="M2 20C1.45 20 0.979 19.8043 0.587 19.413C0.195667 19.021 0 18.55 0 18V4C0 3.45 0.195667 2.97933 0.587 2.588C0.979 2.196 1.45 2 2 2H3V0.975C3 0.691667 3.09567 0.458333 3.287 0.275C3.479 0.0916668 3.71667 0 4 0C4.28333 0 4.521 0.0956666 4.713 0.287C4.90433 0.479 5 0.716667 5 1V2H13V0.975C13 0.691667 13.096 0.458333 13.288 0.275C13.4793 0.0916668 13.7167 0 14 0C14.2833 0 14.5207 0.0956666 14.712 0.287C14.904 0.479 15 0.716667 15 1V2H16C16.55 2 17.021 2.196 17.413 2.588C17.8043 2.97933 18 3.45 18 4V18C18 18.55 17.8043 19.021 17.413 19.413C17.021 19.8043 16.55 20 16 20H2ZM2 18H16V8H2V18ZM2 6H16V4H2V6Z" />
  </svg>
);
export default SvgCalendar;
