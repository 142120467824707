import { SVGProps } from "react";
interface SvgFactoryProps extends SVGProps<SVGSVGElement> {
  /**
   * Width of icon, uses size if not given
   */
  width?: number;
  /**
   * Height of icon, uses size if not given
   */
  height?: number;
  /**
   * Size of icon, applies to width & height if not supplied
   * @default 24
   */
  size?: number;
}
export const SvgFactory = (props: SvgFactoryProps) => (
  <svg
    {...props}
    width={props.width ?? props.size ?? 24}
    height={props.height ?? props.size ?? 24}
    viewBox="0 0 48 48"
    fill="currentColor"
  >
    <path d="M7 44q-1.25 0-2.125-.875T4 41V22q0-.9.475-1.675T5.8 19.2l10.1-4.3q.75-.3 1.425.1T18 16.25V18l7.95-3.2q.75-.3 1.4.15.65.45.65 1.25V20h16v21q0 1.25-.875 2.125T41 44Zm34-21H25v-4.6l-10 4v-3.95L7 22v19h34ZM24 36q.65 0 1.075-.425.425-.425.425-1.075v-5q0-.65-.425-1.075Q24.65 28 24 28q-.65 0-1.075.425-.425.425-.425 1.075v5q0 .65.425 1.075Q23.35 36 24 36Zm-8 0q.65 0 1.075-.425.425-.425.425-1.075v-5q0-.65-.425-1.075Q16.65 28 16 28q-.65 0-1.075.425-.425.425-.425 1.075v5q0 .65.425 1.075Q15.35 36 16 36Zm16 0q.65 0 1.075-.425.425-.425.425-1.075v-5q0-.65-.425-1.075Q32.65 28 32 28q-.65 0-1.075.425-.425.425-.425 1.075v5q0 .65.425 1.075Q31.35 36 32 36Zm12-16h-9l1.85-14.7q.1-.55.5-.925T38.3 4h2.4q.55 0 .95.375.4.375.5.925Zm-3 21H7h34Z" />
  </svg>
);
export default SvgFactory;
