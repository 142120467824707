import React, { ReactNode, useEffect, useState } from "react";

import { useDebouncedCallback } from "use-debounce";
import { v4 as uuidv4 } from "uuid";

import "./text-area.scss";

export type InputProps = {
  label?: string;
  value?: string;
  error?: string;
  headerContent?: ReactNode;
  debounce?: boolean;
  debounceTime?: number;
};

type fullInputProps = InputProps &
  Omit<React.HTMLProps<HTMLTextAreaElement>, "css" | "ref" | "value">;

export const TextArea = React.forwardRef<HTMLTextAreaElement, fullInputProps>(
  (
    { value, className, label, onChange, debounce, debounceTime, ...props },
    ref
  ) => {
    const id = "min-ui__text-area--" + uuidv4();

    const [internalValue, setInternalValue] = useState<string>(value || "");

    useEffect(() => {
      setInternalValue(value || "");
    }, [value]);

    const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      setInternalValue(e.target.value);
      //if the input doesn't have a debounce then we should call onchange instantly
      if (onChange && !debounce) {
        onChange(e);
      }
    };

    const debouncedOnChange = useDebouncedCallback(() => {
      if (onChange && internalValue !== value) {
        // @ts-ignore
        onChange({ target: { value: internalValue } });
      }
    }, debounceTime || 500);

    useEffect(() => {
      if (debounce) {
        debouncedOnChange();
      }
    }, [debounce, debouncedOnChange, internalValue]);

    return (
      <div className={`min-ui__text-area ${className || ""}`}>
        <label htmlFor={id} className="min-ui__text-area__label">
          {label}
        </label>
        <div className="min-ui__text-area__target-container">
          <textarea
            ref={ref}
            id={id}
            value={internalValue}
            className="min-ui__text-area__target min-ui__scroll"
            onChange={handleChange}
            {...props}
          />
        </div>
      </div>
    );
  }
);

export default TextArea;
