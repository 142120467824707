import React, { ReactNode } from "react";

import { Close } from "icons";
import { rem } from "polished";
import styled, { css } from "styled-components";
import cssVar from "theme/vars";
import { H4R } from "typography/headers";

import { useModalContext } from "./Context";

type ModalHeaderProps = {
  title?: string;
  titleContent?: ReactNode;
  subtitle?: ReactNode;
  shadowed?: boolean;
  centered?: boolean;
  withCloseButton?: boolean;
  className?: string;
  noBorder?: boolean;
};
const Header: React.FC<ModalHeaderProps> = ({
  title,
  titleContent,
  subtitle,
  shadowed = false,
  centered = false,
  withCloseButton = true,
  className,
  noBorder = false,
}) => {
  const { onClose } = useModalContext();
  return (
    <Wrapper
      $shadow={shadowed}
      $centered={centered}
      $noBorder={noBorder}
      className={className}
    >
      {title && (
        <Title>
          {title}
          {titleContent}
        </Title>
      )}
      {subtitle && <Subtitle>{subtitle}</Subtitle>}
      {withCloseButton && (
        <CloseButton onClick={onClose}>
          <CloseIcon size={16} />
        </CloseButton>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div<{
  $shadow: boolean;
  $centered: boolean;
  $noBorder: boolean;
}>`
  position: relative;
  padding: 32px 32px 8px 32px;
  ${({ $shadow }) =>
    $shadow
      ? css`
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
        `
      : css`
          :after {
            content: "";
            position: absolute;
            height: 1px;
            background-color: ${cssVar("color/gray/20")};
            bottom: 0;
            left: 32px;
            right: 32px;
            width: auto;
          }
        `}

  ${({ $centered }) =>
    $centered &&
    css`
      text-align: center;
    `}
  ${({ $noBorder }) =>
    $noBorder &&
    css`
      :after {
        background-color: transparent;
      }
    `}
}`;
const CloseButton = styled.button.attrs({
  type: "button",
  "aria-label": "Close",
})`
  position: absolute;
  right: 16px;
  top: 16px;
  padding: 8px;
  color: ${cssVar("color/gray/70")};
  cursor: pointer;
  border: 0;
  background: transparent;

  :hover {
    color: ${cssVar("color/gray/70")};
    background: ${cssVar("color/primary/silverGrey")};
    border: 4px ${cssVar("color/primary/silverGrey")};
    border-radius: 24px;
  }
`;

const CloseIcon = styled(Close)`
  vertical-align: middle;
`;

const Title = styled.h1`
  display: flex;
  margin: 0;
  font-size: ${rem(18)};
  line-height: ${rem(32)};
  font-weight: 600;
  color: ${cssVar("color/gray/100")};
  max-width: 90%;
  word-break: break-word;
`;

const Subtitle = styled(H4R)`
  margin: 0;
  color: ${cssVar("color/gray/60")};
`;

export default Header;
