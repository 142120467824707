const processVals = {};

export const ENV = {
  DEMO: "demo",
  STG: "staging",
  PROD: "production",
  DEV: "development",
};

export const HUB_FEATURE_FLAG = "HUB_VIEW";

export default processVals;
