import React, { ComponentType } from "react";

import { CheckCircle, Edit, Info, Send, WarningAmber } from "icons";
import styled, { css } from "styled-components";
import cssVar from "theme/vars";
import { H4R2 } from "typography/headers";

import Spinner from "./Spinner";

export type Variant =
  | "error"
  | "warning"
  | "success"
  | "info"
  | "shiny"
  | "lightBlue"
  | "blueInfo"
  | "blue"
  | "yellow"
  | "gray"
  | "empty";

export interface AlertProps {
  /**
   * Defines colors, borders, and icons
   */
  variant?: Variant;
  /**
   * Show loading indicator on the right?
   */
  loading?: boolean;
  /**
   * Allow overloading of styles
   */
  className?: string;
  /**
   * If true any children passed will not be wrapped in a <h4> tag. leave false if you are just passing text
   */
  complexChildren?: boolean;
}

const Alert: React.FC<AlertProps> = ({
  loading = false,
  variant = "info",
  children,
  className,
  complexChildren,
}) => {
  const icon = VariantIcons[variant];
  return (
    <AlertWrapper className={className} $variant={variant}>
      <Icon as={icon} />
      {complexChildren ? <div>{children}</div> : <Copy>{children}</Copy>}
      {loading && <Loader />}
    </AlertWrapper>
  );
};

export const VariantIcons: Record<Variant, ComponentType> = {
  error: WarningAmber,
  warning: WarningAmber,
  success: CheckCircle,
  info: Info,
  shiny: Info,
  empty: Info,
  lightBlue: Info,
  blueInfo: Info,
  blue: Send,
  yellow: Edit,
  gray: Info,
};

export const VariantStyles: Record<Variant, Record<string, string>> = {
  error: {
    "--background": cssVar("color/error/10"),
    "--icon": cssVar("color/error/60"),
  },
  warning: {
    "--background": cssVar("color/warning/10"),
    "--icon": cssVar("color/warning/60"),
  },
  success: {
    "--background": cssVar("color/success/10"),
    "--icon": cssVar("color/success/60"),
  },
  info: {
    "--icon": cssVar("color/gray/100"),
    "--background": cssVar("color/gray/5"),
    "--border": `1px solid ${cssVar("color/gray/40")}`,
  },
  shiny: {
    "--icon": cssVar("color/gray/60"),
    "--background": cssVar("color/action/superLightBlue"),
    "--border": `1px solid ${cssVar("color/primary/blue")}`,
  },
  empty: {
    "--background": cssVar("color/primary/superSilverGrey"),
  },
  lightBlue: {
    "--background": cssVar("color/action/superLightBlue"),
    "--icon": cssVar("color/gray/100"),
    "--border": `none`,
    "border-radius": "0px",
  },
  blueInfo: {
    "--background": cssVar("color/background/info"),
    "--icon": cssVar("color/foreground/info"),
    "--border": `1px solid ${cssVar("color/blue/02")}`,
  },
  blue: {
    "--background": cssVar("color/background/info"),
    "--icon": cssVar("color/foreground/info"),
    "--border": `1px solid ${cssVar("color/blue/02")}`,
  },
  yellow: {
    "--background": cssVar("color/background/warning"),
    "--icon": cssVar("color/foreground/warning"),
    "--border": `1px solid ${cssVar("color/yellow/02")}`,
  },
  gray: {
    "--background": cssVar("color/background/default"),
    "--icon": cssVar("color/neutral/04"),
    "--border": `1px solid ${cssVar("color/border/default")}`,
  },
};

export const AlertWrapper = styled.div<{ $variant: Variant }>`
  background: var(--background, transparent);
  border-radius: 8px;
  padding: 12px;
  border: var(--border, 1px solid transparent);
  ${(props) => VariantStyles[props.$variant]};
  display: grid;
  grid-template-columns: 20px 1fr 20px;
  gap: 6px;

  ${({ $variant }) =>
    ($variant === "blueInfo" ||
      $variant === "blue" ||
      $variant === "yellow" ||
      $variant === "gray") &&
    css`
      > svg {
        position: relative;
        top: 3px;
        width: 16px;
        height: 16px;
      }
    `};
`;

export const Icon = styled.svg.attrs({ role: "img" })`
  width: 20px;
  height: 20px;
  flex: 0;
  color: var(--icon);
`;

export const Loader = styled(Spinner)`
  color: ${cssVar("color/primary/blue")};
  justify-self: flex-end;
  width: 20px;
  height: 20px;
`;

export const Copy = styled(H4R2)`
  color: ${cssVar("color/gray/60")};
`;

export default Alert;
