import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { Button } from "ui/core";

import { selectors } from "./slice";

const ResetAll: React.FC<{ ignoreParams?: string }> = ({ ignoreParams }) => {
  const [query, setQuery] = useSearchParams();
  const hasFilters = useSelector(selectors.hasFilters);

  const handleReset = () => {
    if (ignoreParams) {
      const selectParams = query.get(ignoreParams);
      const newParams = new URLSearchParams();

      if (selectParams) {
        newParams.set(ignoreParams, selectParams);
      }
      setQuery(newParams);
    } else {
      setQuery({});
    }
  };
  return hasFilters ? <Button onClick={handleReset}> Reset</Button> : null;
};

export default ResetAll;
