import React, { ReactNode } from "react";

import { v4 as uuidv4 } from "uuid";

import { ReactComponent as IconChecked } from "../../assets/icon-checmark.svg";
import { ReactComponent as IconIndeterminate } from "../../assets/icon-indeterminate.svg";

import "./checkbox.scss";

export interface CheckboxProps {
  children?: ReactNode;
  label?: ReactNode;
  className?: string;
  indeterminate?: boolean;
  checked: boolean | null;
  rounded?: boolean;
}

const Checkbox: React.FC<
  CheckboxProps & Omit<React.InputHTMLAttributes<HTMLInputElement>, "checked">
> = ({
  className,
  children,
  rounded,
  label,
  checked,
  indeterminate,
  ...props
}) => {
  const id: string = "min-ui__checkbox--" + uuidv4();
  const additionalClassName = className || "";
  const indeterminateClassName =
    indeterminate && checked === null
      ? "min-ui__checkbox__label--indeterminate"
      : "";
  const roundedClassName = rounded ? "min-ui__checkbox--rounded" : "";
  const labelClassName = label ? "" : "min-ui__checkbox--no-label";

  return (
    <div
      className={`min-ui__checkbox ${roundedClassName} ${additionalClassName} ${labelClassName}`}
    >
      <input
        className="min-ui__checkbox__input"
        id={id}
        type="checkbox"
        checked={!!checked}
        {...props}
      />

      <label
        className={`min-ui__checkbox__label ${indeterminateClassName}`}
        htmlFor={id}
      >
        <span className="min-ui__checkbox__label__content">
          {label || children}
        </span>
      </label>
      <div className="min-ui__checkbox__icons">
        {checked && <IconChecked />}
        {indeterminate && checked === null && <IconIndeterminate />}
      </div>
    </div>
  );
};
export default Checkbox;
