import config from "config";

import { selectors } from "DataTracker/store/Selectors";
import { RootState, store } from "store/store";

export const buildUrl = (parts: string[]): string => {
  return "/" + parts.join("/");
};

const getUrlBuilder = (parts: string[]): (() => string) => {
  return () => {
    return buildUrl(parts);
  };
};

const getUrlBuilderWithOrg = (parts: string[]): (() => string) => {
  return () => {
    const currentOrg = (store.getState() as RootState).organisation
      .currentOrganisation?.id;
    return buildUrl([urlParts.org, currentOrg || "-1", ...parts]);
  };
};

const getUrlBuilderWithHub = (parts: string[]): (() => string) => {
  return () => {
    return buildUrl([hubUrlParts.root, ...parts]);
  };
};

export const urlParts = {
  home: "home",
  auth: "auth",
  login: "login",
  callback: "callback",
  supplier: "supplier",
  downloadExportedFile: "download-exported-file",
  organisation: ":organisation",
  invite: ":invite",
  uid: ":uid",
  token: ":token",
  account: "account",
  profile: "profile",
  org: "o",
  orgId: ":orgId",
  atlas: "atlas",
  selectedEntity: ":entityId",
  category: "category",
  dataCapture: "data-capture",
  selectedCategory: ":categoryId",
  dataTracker: "manage",
  submissionProgress: "submission-progress",
  submissionProgressCadenceId: ":cadenceId",
  submissionProgressActiveSubmissions: "active-submissions",
  submissionProgressNeedsAction: "needs-action",
  submissionProgressAwaitingData: "awaiting-data",
  submissionProgressSubmittedItems: "submitted-items",
  submissionProgressFileUploads: "file-uploads",
  analytics: "analytics",
  legacySubmissions: "submissions",
  dashboard: ":dashboard",
  settings: "settings",
  requestReport: "request-report",
  emissions: "emissions",
  emissionId: ":emissionId",
  emissionsEmissionsData: "emissions-data",
  emissionsActivityData: "activity-data",
  auditLog: "audit-log",
  consolidationId: ":consolidationId",
  consolidationDetails: "consolidation-details",
  calculationLog: "calculation-log",
  submissionLog: "submission-log",
  estimationDetails: "estimation-details",
  transactions: "transactions",
  transactionId: ":transactionId",
  dataSubmission: "data-submission",
  slotId: ":slotId",
  dataSourceDefinitionId: ":dataSourceDefinitionId",
  methodologies: "methodologies",
  submissionGroup: "submission-groups",
  submissionGroupId: ":submissionGroupId",
  submissionGroupUpload: "submit",
  submissionSlot: "submission-slots",
  submissionSlotId: ":slotId",
  submissionGroupActionRequired: "action-required",
  submissionGroupVarianceChecks: "variance-checks",
  documents: "documents",
  documentUpload: "submit",
  documentUpdate: "edit",
  documentId: ":documentId",
};

export const hubUrlParts = {
  root: "hub",
  tasks: "tasks",
  taskId: ":taskId",
  organisationId: ":organisationId",
  activityId: ":activityId",
  settings: "settings",
};

const urls = {
  default: getUrlBuilderWithOrg([urlParts.home]),
  root: () => "/",
  home: getUrlBuilderWithOrg([urlParts.home]),
  methodologies: getUrlBuilderWithOrg([urlParts.methodologies]),
  login: getUrlBuilder([urlParts.auth, urlParts.login]),
  supplierInvite: getUrlBuilder([
    urlParts.auth,
    urlParts.supplier,
    urlParts.organisation,
    urlParts.invite,
  ]),
  account: getUrlBuilderWithOrg([urlParts.account]),
  settings: getUrlBuilderWithOrg([urlParts.settings]),
  atlas: getUrlBuilderWithOrg([urlParts.atlas]),
  atlasWithSelectedEntity: (selectedEntity: string) =>
    getUrlBuilderWithOrg([urlParts.atlas, selectedEntity])(),
  dataTracker: () => {
    const selected = selectors.selectedEntity(store.getState() as RootState);
    if (selected) {
      return getUrlBuilderWithOrg([urlParts.dataTracker, selected.entity.id])();
    } else {
      return getUrlBuilderWithOrg([urlParts.dataTracker])();
    }
  },
  submissionProgress: getUrlBuilderWithOrg([urlParts.submissionProgress]),
  submissionProgressCadenceDetails: (cadenceId: string) =>
    getUrlBuilderWithOrg([urlParts.submissionProgress, cadenceId])(),
  submissionProgressCadenceActiveSubmissions: (cadenceId: string) =>
    getUrlBuilderWithOrg([
      urlParts.submissionProgress,
      cadenceId,
      urlParts.submissionProgressActiveSubmissions,
    ])(),
  submissionProgressCadenceNeedsAction: (cadenceId: string) =>
    getUrlBuilderWithOrg([
      urlParts.submissionProgress,
      cadenceId,
      urlParts.submissionProgressNeedsAction,
    ])(),
  submissionProgressCadenceAwaitingData: (cadenceId: string) =>
    getUrlBuilderWithOrg([
      urlParts.submissionProgress,
      cadenceId,
      urlParts.submissionProgressAwaitingData,
    ])(),
  submissionProgressSubmittedItems: () =>
    getUrlBuilderWithOrg([
      urlParts.submissionProgress,
      urlParts.submissionProgressSubmittedItems,
    ])(),
  submissionProgressFileUploads: () =>
    getUrlBuilderWithOrg([
      urlParts.submissionProgress,
      urlParts.submissionProgressSubmittedItems,
      urlParts.submissionProgressFileUploads,
    ])(),
  legacySubmissions: getUrlBuilderWithOrg([urlParts.legacySubmissions]),
  getSubmission: (submissionId?: string) =>
    getUrlBuilderWithOrg(
      submissionId
        ? [urlParts.legacySubmissions, submissionId]
        : [urlParts.legacySubmissions]
    )(),
  dataTrackerWithSelectedEntity: (selectedEntity: string) =>
    getUrlBuilderWithOrg([urlParts.dataTracker, selectedEntity])(),
  analytics: getUrlBuilderWithOrg([urlParts.analytics]),
  dataCapture: getUrlBuilderWithOrg([urlParts.dataCapture]),
  dataCaptureWithSelectedEntity: (selectedEntity: string) =>
    getUrlBuilderWithOrg([urlParts.dataCapture, selectedEntity])(),
  dataCaptureWithEntityAndCategory: (
    selectedEntity: string,
    categoryId: string
  ) =>
    getUrlBuilderWithOrg([
      urlParts.dataCapture,
      selectedEntity,
      urlParts.category,
      categoryId,
    ])(),
  analyticsDashboard: (dashboard: string) =>
    getUrlBuilderWithOrg([urlParts.analytics, dashboard])(),
  requestReport: getUrlBuilderWithOrg([urlParts.requestReport]),
  emissions: () => getUrlBuilderWithOrg([urlParts.emissions])(),
  emissionDetails: (emissionId: string, consolidationId: string) =>
    getUrlBuilderWithOrg([urlParts.emissions, emissionId, consolidationId])(),
  emissionsEmissionsData: () =>
    getUrlBuilderWithOrg([
      urlParts.emissions,
      urlParts.emissionsEmissionsData,
    ])(),
  emissionsActivityData: () =>
    getUrlBuilderWithOrg([
      urlParts.emissions,
      urlParts.emissionsActivityData,
    ])(),
  transactions: getUrlBuilderWithOrg([urlParts.transactions]),
  dataSubmission: getUrlBuilderWithOrg([urlParts.dataSubmission]),
  submissionGroup: getUrlBuilderWithOrg([urlParts.submissionGroup]),
  selectedSubmissionGroup: (submissionGroupId: string) =>
    getUrlBuilderWithOrg([
      urlParts.submissionGroup,
      submissionGroupId,
      urlParts.submissionSlot,
    ])(),
  selectedSubmissionGroupView: (submissionGroupId: string) =>
    getUrlBuilderWithOrg([urlParts.submissionGroup, submissionGroupId])(),
  submissionGroupUpload: (submissionGroupId: string) =>
    getUrlBuilderWithOrg([
      urlParts.submissionGroup,
      submissionGroupId,
      urlParts.submissionGroupUpload,
    ])(),
  selectedSubmissionSlot: (submissionGroupId: string, slotId: string) =>
    getUrlBuilderWithOrg([
      urlParts.submissionGroup,
      submissionGroupId,
      urlParts.submissionSlot,
      slotId,
    ])(),
  selectedSubmissionGroupActionRequired: (submissionGroupId: string) =>
    getUrlBuilderWithOrg([
      urlParts.submissionGroup,
      submissionGroupId,
      urlParts.submissionGroupActionRequired,
    ])(),
  selectedSubmissionGroupVarianceChecks: (submissionGroupId: string) =>
    getUrlBuilderWithOrg([
      urlParts.submissionGroup,
      submissionGroupId,
      urlParts.submissionGroupVarianceChecks,
    ])(),
  selectedSubmissionGroupVarianceCheck: (
    submissionGroupId: string,
    slotId: string
  ) =>
    getUrlBuilderWithOrg([
      urlParts.submissionGroup,
      submissionGroupId,
      urlParts.submissionGroupActionRequired,
      slotId,
    ])(),
  getDataSubmission: (submissionId?: string) =>
    getUrlBuilderWithOrg(
      submissionId
        ? [urlParts.dataSubmission, submissionId]
        : [urlParts.dataSubmission]
    )(),
  auditLog: getUrlBuilderWithOrg([urlParts.auditLog]),
  getConsolidationDetails: (emissionId: string, consolidationId: string) =>
    getUrlBuilderWithOrg([
      urlParts.emissions,
      emissionId,
      consolidationId,
      urlParts.auditLog,
      urlParts.consolidationDetails,
    ])(),
  getCalculationLog: (emissionId: string, consolidationId: string) =>
    getUrlBuilderWithOrg([
      urlParts.emissions,
      emissionId,
      consolidationId,
      urlParts.auditLog,
      urlParts.calculationLog,
    ])(),
  getSubmissionLog: (emissionId: string, consolidationId: string) =>
    getUrlBuilderWithOrg([
      urlParts.emissions,
      emissionId,
      consolidationId,
      urlParts.auditLog,
      urlParts.submissionLog,
    ])(),
  getEstimationDetails: (emissionId: string, consolidationId: string) =>
    getUrlBuilderWithOrg([
      urlParts.emissions,
      emissionId,
      consolidationId,
      urlParts.auditLog,
      urlParts.estimationDetails,
    ])(),
  documents: getUrlBuilderWithOrg([urlParts.documents]),
  documentUpload: getUrlBuilderWithOrg([
    urlParts.documents,
    urlParts.documentUpload,
  ]),
  documentUpdate: (documentId: string) =>
    getUrlBuilderWithOrg([
      urlParts.documents,
      urlParts.documentUpdate,
      documentId,
    ])(),
};

export const hubUrls = {
  tasks: getUrlBuilderWithHub([hubUrlParts.tasks]),
  settings: getUrlBuilderWithHub([urlParts.settings]),
  getTask: (organisationId: string, taskId: string) =>
    getUrlBuilderWithHub([hubUrlParts.tasks, organisationId, taskId])(),
  getTaskActivity: (
    organisationId: string,
    taskId: string,
    activityId: string
  ) =>
    getUrlBuilderWithHub([
      hubUrlParts.tasks,
      organisationId,
      taskId,
      activityId,
    ])(),
};

//this is not in RTK query because its needed for handing to minforms

export const fileUploadEndpoint: () => string = () => {
  return `${config.api.endpoint}/organisations/${
    store.getState().organisation.currentOrganisation?.id
  }/files`;
};

export const getRedirectPage = () => {
  const redirect = sessionStorage.getItem("postAuthRedirect");
  if (redirect) {
    sessionStorage.removeItem("postAuthRedirect");
  }
  return redirect || urls.default();
};

export const redirectToLogin = () => {
  if (window.location.pathname.indexOf("/auth/") < 0) {
    sessionStorage.setItem("postAuthRedirect", window.location.pathname);
    window.location.href = urls.login();
  }
};

export default urls;
