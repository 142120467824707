/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";

import config from "config";

axios.defaults.baseURL = config.api.endpoint;

export interface AxiosOptions extends AxiosRequestConfig {
  credentials?: string;
}

export const APIRequest = async <T>(
  options: AxiosOptions
): Promise<AxiosResponse<T>> => {
  const data = options.data ? JSON.stringify(options.data) : {};

  return axios({
    data,
    credentials: "include",
    withCredentials: true,
    ...options,
  });
};

export const APIPost = async <T>(
  url: string,
  data?: any,
  options: AxiosOptions = {}
): Promise<AxiosResponse<T>> => {
  return APIRequest({
    url,
    ...options,
    data,
    method: "POST",
  });
};

export const APIPatch = async <T>(
  url: string,
  data?: any,
  options: AxiosOptions = {}
): Promise<AxiosResponse<T>> => {
  return APIRequest({
    url,
    ...options,
    data,
    method: "PATCH",
  });
};

export const APIPut = async <T>(
  url: string,
  data?: any,
  options: AxiosOptions = {}
): Promise<AxiosResponse<T>> => {
  return APIRequest({
    url,
    ...options,
    data,
    method: "PUT",
  });
};

export const APIGet = async <T>(
  url: string,
  options: AxiosOptions = {}
): Promise<AxiosResponse<T>> => {
  return APIRequest({
    url,
    ...options,
    method: "GET",
  });
};

export const APIDelete = async <T>(
  url: string,
  options: AxiosOptions = {}
): Promise<AxiosResponse<T>> => {
  return APIRequest({
    url,
    ...options,
    method: "DELETE",
  });
};
