import config from "config";

import { ENV, HUB_FEATURE_FLAG } from "./consts";

export const isDemo = config.environment === ENV.DEMO;
export const isProduction = config.environment === ENV.PROD;
export const isDevelopment = config.environment === ENV.DEV;

export const isAnyNonHubFeatureFlagEnabled = (flags: any) => {
  for (const key in flags) {
    if (key !== HUB_FEATURE_FLAG && flags[key] === true) {
      return true; // Return true as soon as a flag (other than HUB_FEATURE_FLAG) is true
    }
  }
  return false; // Return false if no other flag is set to true
};
