import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";

import { Spinner } from "ui/core";

import { organisationSelectors } from "store/organisation/organisationSelectors";
import { organisationThunks } from "store/organisation/organisationThunks";
import { userThunks } from "store/user/userThunks";

import { useAppDispatch } from "utilities/hooks/general";
import { urlParts } from "utilities/urls";

const OrgRestrictedRoute = () => {
  const dispatch = useAppDispatch();
  const { orgId } = useParams();
  const currentOrgId = useSelector(organisationSelectors.currentOrganisationId);
  const navigate = useNavigate();
  const location = useLocation();

  //Make sure we find the default when the orgId is -1;
  useEffect(() => {
    if (orgId === "-1") {
      if (currentOrgId) {
        const url = location.pathname.replace(
          `/${urlParts.org}/-1/`,
          `/${urlParts.org}/${currentOrgId}/`
        );
        navigate(url, { replace: true });
      } else {
        void dispatch(userThunks.fetchUserDetails()).unwrap();
      }
    }
  }, [currentOrgId, dispatch, location.pathname, navigate, orgId]);

  //Fetch the new organisation when it differs from the saved one or default one
  useEffect(() => {
    if (currentOrgId) {
      void dispatch(
        organisationThunks.getOrganisation({ orgId: currentOrgId })
      );
      void dispatch(
        organisationThunks.getReportingYears({ orgId: currentOrgId })
      );
    }
  }, [currentOrgId, dispatch]);

  return orgId && orgId !== "-1" ? <Outlet /> : <Spinner />;
};

export default OrgRestrictedRoute;
