import styled from "styled-components";
import { rem } from "polished";
import Base from "./base";
import { composed } from "theme/utilities";
import cssVar from "theme/vars";

/**
 * font-size: 14px, line-height: 24px, letter-spacing: 0.005em, font-weight: 400
 */
export const P = styled(Base).attrs(composed("p"))`
  font-size: ${rem(14)};
  line-height: ${rem(24)};
  letter-spacing: 0.005em;
  font-weight: 400;
`;

export const Hyperlink = styled(Base)`
  font-size: 1em;
  line-height: inherit;
  letter-spacing: inherit;
  font-weight: 400;
  color: ${cssVar("color/primary/blue")};
  cursor: pointer;
  text-decoration: none;
  &:hover {
    color: ${cssVar("color/action/activeBlue")};
    text-decoration: underline;
  }
`;
