import { SVGProps } from "react";
interface SvgAssignmentAddProps extends SVGProps<SVGSVGElement> {
  /**
   * Width of icon, uses size if not given
   */
  width?: number;
  /**
   * Height of icon, uses size if not given
   */
  height?: number;
  /**
   * Size of icon, applies to width & height if not supplied
   * @default 24
   */
  size?: number;
}
export const SvgAssignmentAdd = (props: SvgAssignmentAddProps) => (
  <svg
    {...props}
    width={props.width ?? props.size ?? 24}
    height={props.height ?? props.size ?? 24}
    viewBox="0 0 48 48"
    fill="currentColor"
  >
    <path d="M9 42q-1.25 0-2.125-.875T6 39V9q0-1.25.875-2.125T9 6h10.25q.25-1.75 1.6-2.875T24 2q1.8 0 3.15 1.125Q28.5 4.25 28.75 6H39q1.25 0 2.125.875T42 9v16.45q-.7-.4-1.475-.65-.775-.25-1.525-.4V9H9v30h15.45q.2.8.45 1.55t.6 1.45Zm0-5.35V39 9v15.4-.2 12.45ZM15.5 34h9.05q.2-.8.45-1.55t.65-1.45H15.5q-.65 0-1.075.425Q14 31.85 14 32.5q0 .65.425 1.075Q14.85 34 15.5 34Zm0-8.5h15.7q.7-.35 1.35-.575.65-.225 1.45-.425v-2H15.5q-.65 0-1.075.425Q14 23.35 14 24q0 .65.425 1.075.425.425 1.075.425Zm0-8.5h17q.65 0 1.075-.425Q34 16.15 34 15.5q0-.65-.425-1.075Q33.15 14 32.5 14h-17q-.65 0-1.075.425Q14 14.85 14 15.5q0 .65.425 1.075Q14.85 17 15.5 17ZM24 8.15q.7 0 1.225-.525.525-.525.525-1.225 0-.7-.525-1.225Q24.7 4.65 24 4.65q-.7 0-1.225.525-.525.525-.525 1.225 0 .7.525 1.225.525.525 1.225.525Zm12.65 37.8q-3.9 0-6.65-2.775-2.75-2.775-2.75-6.575 0-3.9 2.75-6.675t6.65-2.775q3.85 0 6.625 2.775T46.05 36.6q0 3.8-2.775 6.575Q40.5 45.95 36.65 45.95Zm-.75-8.5v4.7q0 .35.25.575.25.225.6.225t.575-.25q.225-.25.225-.6v-4.65h4.7q.35 0 .575-.25.225-.25.225-.6t-.225-.575q-.225-.225-.575-.225h-4.7v-4.7q0-.35-.225-.575-.225-.225-.575-.225t-.6.225q-.25.225-.25.575v4.7h-4.7q-.35 0-.575.225-.225.225-.225.575t.25.6q.25.25.6.25Z" />
  </svg>
);
export default SvgAssignmentAdd;
