import { SVGProps } from "react";
interface SvgChevronSelectorVerticalUpProps extends SVGProps<SVGSVGElement> {
  /**
   * Width of icon, uses size if not given
   */
  width?: number;
  /**
   * Height of icon, uses size if not given
   */
  height?: number;
  /**
   * Size of icon, applies to width & height if not supplied
   * @default 24
   */
  size?: number;
}
export const SvgChevronSelectorVerticalUp = (
  props: SvgChevronSelectorVerticalUpProps
) => (
  <svg
    {...props}
    width={props.width ?? props.size ?? 24}
    height={props.height ?? props.size ?? 24}
    viewBox="0 0 17 16"
    fill="currentColor"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.10082 2.10098C8.41324 1.78856 8.91977 1.78856 9.23219 2.10098L12.5655 5.43432C12.8779 5.74674 12.8779 6.25327 12.5655 6.56569C12.2531 6.87811 11.7466 6.87811 11.4342 6.56569L8.66651 3.79804L5.89886 6.56569C5.58644 6.87811 5.07991 6.87811 4.76749 6.56569C4.45507 6.25327 4.45507 5.74674 4.76749 5.43432L8.10082 2.10098Z"
    />
  </svg>
);
export default SvgChevronSelectorVerticalUp;
