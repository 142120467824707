import React from "react";
import styled from "styled-components";
import { ReactDatePickerCustomHeaderProps } from "react-datepicker";
import { H3R } from "typography/headers";
import { format } from "date-fns";
import cssVar from "theme/vars";
import { ChevronLeft, ChevronRight } from "icons";

const Header: React.FC<ReactDatePickerCustomHeaderProps> = ({
  increaseMonth,
  decreaseMonth,
  monthDate,
  customHeaderCount,
}) => {
  const leftSide = customHeaderCount === 0;
  return (
    <HeaderWrapper>
      {leftSide && (
        <Navigate onClick={decreaseMonth}>
          <ChevronLeft />
        </Navigate>
      )}
      <Month>{format(monthDate, leftSide ? "MMMM yyyy" : "MMMM")}</Month>
      {!leftSide && (
        <Navigate onClick={increaseMonth}>
          <ChevronRight />
        </Navigate>
      )}
    </HeaderWrapper>
  );
};

const Navigate = styled.button.attrs({ type: "button" })`
  border: 0;
  background: none;
  width: 36px;
  height: 100%;
  cursor: pointer;
  color: ${cssVar("color/primary/darkGrey")};
  position: absolute;
  top: 0;
  left: 0;
  display: grid;
  place-content: center;
  &:hover {
    color: ${cssVar("color/primary/black")};
  }
  &:last-child {
    right: 0;
    left: auto;
  }

  svg {
    width: 20px;
    height: 20px;
  }
`;
const Month = styled(H3R).attrs({ as: "h5" })`
  font-weight: 600;
  color: ${cssVar("color/gray/100")};
  text-align: center;
  margin: 0;
  padding: 0;
`;
const HeaderWrapper = styled.header`
  position: relative;
  padding: 6px;
  margin-bottom: 22px;
`;

export default Header;
