import React, {
  CSSProperties,
  useCallback,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import ReactDOM from "react-dom";

import { rem } from "polished";
import styled, { css } from "styled-components";
import cssVar from "theme/vars";

import { useRootElement } from "../hooks";

type AnchorPoint = "left" | "right" | "top" | "bottom";

export interface TooltipProps {
  /**
   * Preferred location to anchor the tooltip to the content. This will be
   * overwritten if it cannot fit on the screen
   */
  initialPosition?: AnchorPoint;
  /**
   * Textual content of the tooltip
   */
  content: string;
  /**
   * Styling overrides for the anchoring element
   */
  className?: string;
}

type FailingAxis = "top" | "left" | "right" | "bottom";

function calculatePosition(
  anchor: AnchorPoint,
  target: DOMRect,
  wrapper: DOMRect
): [number, number, FailingAxis[]] {
  let x = 0,
    y = 0,
    l = 0,
    r = 0,
    b = 0,
    t = 0;
  switch (anchor) {
    case "left":
      x = target.left;
      y = target.top + target.height / 2;
      l = x - wrapper.width;
      t = y - wrapper.height / 2;
      b = y + wrapper.height / 2;
      r = x;
      break;
    case "right":
      x = target.right;
      y = target.top + target.height / 2;
      l = x;
      t = y - wrapper.height / 2;
      b = y + wrapper.height / 2;
      r = x + wrapper.width;
      break;
    case "top":
      x = target.left + target.width / 2;
      y = target.top;
      l = x - wrapper.width / 2;
      r = x + wrapper.width / 2;
      t = y - wrapper.height;
      b = y;
      break;
    case "bottom":
      x = target.left + target.width / 2;
      y = target.bottom;
      l = x - wrapper.width / 2;
      r = x + wrapper.width / 2;
      t = y;
      b = y + wrapper.height;
      break;
  }

  const failure: FailingAxis[] = [];
  if (t <= 0) {
    failure.push("top");
  }
  if (l <= 0) {
    failure.push("left");
  }
  if (b >= window.innerHeight) {
    failure.push("bottom");
  }
  if (r >= window.innerWidth) {
    failure.push("right");
  }

  return [x, y, failure];
}

/**
 * Generic tooltip component that utilizes react portals to place itself outside
 * of the stacking context of the associated element.
 */
const Tooltip: React.FC<TooltipProps> = ({
  children,
  initialPosition = "right",
  content,
  className,
}) => {
  const repositionAttempts = useRef<AnchorPoint[]>([]);
  const wrapper = useRef<HTMLDivElement>(null);
  const target = useRef<HTMLSpanElement>(null);
  const [anchor, setAnchor] = useState<AnchorPoint>(initialPosition);
  const [position, setPosition] = useState<[number, number] | null>(null);
  const portalTarget = useRootElement("tooltips");
  const [visible, setVisible] = useState(false);

  const [x, y] = position || [];

  useLayoutEffect(() => {
    // side effects
    function recalculate() {
      if (!wrapper.current || !target.current) {
        return;
      }

      let [x, y, failingAxis] = calculatePosition(
        initialPosition,
        target.current.getBoundingClientRect(),
        wrapper.current.getBoundingClientRect()
      );

      if (!failingAxis.length && initialPosition !== anchor) {
        // We have gone from not being able to fit to fitting after a resize/scroll
        repositionAttempts.current = [];
        setAnchor(initialPosition);
        return;
      }

      // Check updated anchor point and use that if needed
      const [initialX, initialY] = [x, y];
      if (failingAxis.length && initialPosition !== anchor) {
        [x, y, failingAxis] = calculatePosition(
          anchor,
          target.current.getBoundingClientRect(),
          wrapper.current.getBoundingClientRect()
        );
      }

      // If we still don't fit, determine a new path
      if (failingAxis.length) {
        // Check for an alternative method of rendering
        if (repositionAttempts.current.length <= 4) {
          if (
            failingAxis.includes("top") &&
            !repositionAttempts.current.includes("bottom")
          ) {
            setAnchor("bottom");
            repositionAttempts.current.push("bottom");
            return;
          } else if (
            failingAxis.includes("left") &&
            !repositionAttempts.current.includes("right")
          ) {
            setAnchor("right");
            repositionAttempts.current.push("right");
            return;
          } else if (
            failingAxis.includes("bottom") &&
            !repositionAttempts.current.includes("top")
          ) {
            setAnchor("top");
            repositionAttempts.current.push("top");
            return;
          } else if (
            failingAxis.includes("right") &&
            !repositionAttempts.current.includes("left")
          ) {
            setAnchor("left");
            repositionAttempts.current.push("left");
            return;
          }
        } else {
          // We've tried everything so just load our original position
          setAnchor(initialPosition);
          setPosition([initialX, initialY]);
          return;
        }
      } else {
        repositionAttempts.current = [];
        setPosition([x, y]);
      }
    }

    // It's a waste of time to calculate anything if we're not visible
    if (!visible) {
      return;
    }

    let timer: NodeJS.Timeout;
    const debounced = () => {
      clearTimeout(timer);
      timer = setTimeout(recalculate, 50);
    };

    window.addEventListener("scroll", debounced);
    window.addEventListener("resize", debounced);

    recalculate();
    // cleanup
    return () => {
      clearTimeout(timer);
      window.removeEventListener("scroll", debounced);
      window.removeEventListener("resize", debounced);
    };
  }, [anchor, initialPosition, visible]);

  const onShow = useCallback(() => {
    content && setVisible(true);
  }, [content]);

  const onHide = useCallback(() => {
    setVisible(false);
  }, []);
  return (
    <>
      <Anchor
        ref={target}
        className={className}
        onMouseEnter={onShow}
        onMouseLeave={onHide}
      >
        {children}
      </Anchor>
      {ReactDOM.createPortal(
        <TooltipWrapper
          $visible={visible}
          $anchor={anchor}
          $placed={!!position}
          ref={wrapper}
          style={
            {
              "--top": `${y}px`,
              "--left": `${x}px`,
            } as CSSProperties
          }
        >
          <Content>{content}</Content>
        </TooltipWrapper>,
        portalTarget
      )}
    </>
  );
};

const Anchor = styled.span`
  position: relative;
  overflow: visible;
`;
const TooltipWrapper = styled.div<{
  $anchor: AnchorPoint;
  $visible: boolean;
  $placed: boolean;
}>`
  position: fixed;
  top: var(--top);
  left: var(--left);
  z-index: 100;

  pointer-events: none;

  opacity: ${(props) => (props.$visible ? "1" : "0")};
  transition: opacity 0.3s ease;

  ${(props) =>
    props.$visible &&
    css`
      transition-delay: 0.2s;
    `}
  ${(props) => {
    switch (props.$anchor) {
      case "left":
        return {
          transform: "translate(-100%, -50%)",
          paddingRight: "12px",
        };
      case "right":
        return {
          transform: "translate(0%, -50%)",
          paddingLeft: "12px",
        };
      case "top":
        return {
          transform: "translate(-50%, -100%)",
          paddingBottom: "12px",
        };
      case "bottom":
        return {
          transform: "translate(-50%, 0%)",
          paddingTop: "12px",
        };
    }
  }}
    // Re-positioning ourselves can get chaotic if padding is thrown in
  ${(props) =>
    !props.$placed &&
    css`
      padding: 0 !important;

      &::after {
        content: none !important;
      }
    `}
  &::after {
    content: "";
    position: absolute;
    width: 12px;
    height: 12px;
    transform: translate(-50%, -50%) rotate(45deg);
    background: ${cssVar("color/brand/nightGreen")};
    z-index: -1;
    border-radius: 1px;
    ${(props) => {
      switch (props.$anchor) {
        case "left":
          return {
            top: "50%",
            right: 3,
          };
        case "right":
          return {
            top: "50%",
            left: 14,
          };
        case "top":
          return {
            left: "50%",
            bottom: 3,
          };
        case "bottom":
          return {
            left: "50%",
            top: 14,
          };
      }
    }}
  }
`;

const Content = styled.div`
  border-radius: 8px;
  background-color: ${cssVar("color/brand/nightGreen")};
  padding: 8px 12px;
  max-width: 320px;
  font-size: ${rem(12)};
  line-height: ${rem(16)};
  color: ${cssVar("color/primary/white")};
  position: relative;
  z-index: 1;
  width: max-content;
`;

export default Tooltip;
