import React from "react";
import styled from "styled-components";
import cssVar from "theme/vars";
import Header from "./Header";
import Subheader from "./Subheader";
import Actions from "./Actions";

type CardExports = React.FC<CardProps> & {
  Header: typeof Header;
  Subheader: typeof Subheader;
  Actions: typeof Actions;
};

export interface CardProps {
  className?: string;
}

const Card: React.FC<CardProps> = ({ className, children }) => {
  return <CardWrapper className={className}>{children}</CardWrapper>;
};

const CardWrapper = styled.section`
  border: 1px solid ${cssVar("color/gray/30")};
  border-radius: 12px;
  background: ${cssVar("color/primary/white")};
  padding-bottom: 24px;
`;

const Exports = Card as CardExports;
Exports.Header = Header;
Exports.Subheader = Subheader;
Exports.Actions = Actions;

export default Exports;
