import React, { useMemo } from "react";

import { Filter } from "ui/forms";

import { EntityCustomAttribute } from "API/Endpoints/EntityCustomAttributes/payloads";

import useFilterParam from "./useFilterParam";

const EntityCustomAttributes: React.FC<{
  customAttributes: EntityCustomAttribute[];
  metadata?: { [key: string]: string[] };
}> = ({ customAttributes, metadata }) => {
  let data = customAttributes.map((customAttribute) => {
    const label = customAttribute.label;
    const value = customAttribute.value;
    const options = customAttribute.attributeValues.map((attributeName) => {
      return {
        label: attributeName.value,
        value: `{"${value}":"${attributeName.value}"}`,
      };
    });

    return {
      label,
      options,
    };
  });

  if (metadata) {
    const metadataData = Object.entries(metadata).map((metaObject) => {
      const label = metaObject[0];
      const value = metaObject[0];
      const metaOptions = metaObject[1];
      const options = metaOptions.map((attributeName) => {
        return {
          label: attributeName,
          value: `{"activity_data_metadata__${value}":"${attributeName}"}`,
        };
      });

      return {
        label,
        options,
      };
    });

    data = [...data, ...metadataData];
  }

  const queryOptions = useMemo(() => {
    return data.reduce(
      (arr, group) => [...arr, ...group.options],
      [] as { value: string }[]
    );
  }, [data]);

  const [selected, setSelected] = useFilterParam(
    "customAttributes",
    queryOptions,
    {
      defaultToFirst: false,
      multi: true,
      ready: true,
    }
  );

  return (
    <Filter
      items={{ groups: data }}
      title="More Filters"
      label="More Filters"
      selected={!!selected.length}
      count={selected.length ? selected.length : void 0}
      onChange={setSelected}
      disabled={!data.length || !selected}
      value={selected}
      multi={true}
      searchable={false}
      showReset={true}
      showApply={true}
    />
  );
};

export default EntityCustomAttributes;
