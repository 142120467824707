import { GenericError } from "@auth0/auth0-spa-js";

import client from "./client";

export enum TokenResponse {
  SUCCESS = "SUCCESS",
  ERROR = "ERROR",
  UNAUTHORIZED = "UNAUTHORIZED",
}

type Response =
  | [TokenResponse.SUCCESS, string]
  | [Exclude<TokenResponse, TokenResponse.SUCCESS>, null];

export async function getToken(): Promise<Response> {
  try {
    const token = await client.getTokenSilently({
      detailedResponse: true,
    });

    return [TokenResponse.SUCCESS, token.id_token];
  } catch (error) {
    if (error instanceof GenericError && error.error === "login_required") {
      return [TokenResponse.UNAUTHORIZED, null];
    }
    return [TokenResponse.ERROR, null];
  }
}
