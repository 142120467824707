import { ChevronLeft, ChevronRight } from "icons";
import React, { Fragment, MouseEvent, useCallback, useMemo } from "react";
import styled, { css } from "styled-components";
import cssVar from "theme/vars";
import Button, { ButtonProps } from "./Button";

export interface PaginationProps {
  current?: number;
  total?: number;
  primaryAction?: Omit<ButtonProps, "variant" | "size">;
  secondaryAction?: Omit<ButtonProps, "variant" | "size">;
  onPage?: (page: number) => void;
}

const Pagination: React.FC<PaginationProps> = ({
  current = 1,
  total = 0,
  onPage,
}) => {
  const handlePage = useCallback(
    (e: MouseEvent<HTMLElement>, page?: number) => {
      e.preventDefault();
      const goTo =
        page ?? parseInt(e.currentTarget.dataset.page ?? "0", 10) ?? 0;
      if (!goTo || isNaN(goTo)) {
        return;
      }
      onPage?.(goTo);
    },
    [onPage]
  );

  const handleNext = useCallback(
    (e: MouseEvent<HTMLElement>) => {
      handlePage(e, Math.min(current + 1, total));
    },
    [handlePage, current, total]
  );
  const handlePrev = useCallback(
    (e: MouseEvent<HTMLElement>) => {
      handlePage(e, Math.max(current - 1, 1));
    },
    [handlePage, current]
  );

  const show = useMemo(() => {
    const list = new Set<number>();
    // Always show the first page
    list.add(Math.min(1, total));

    list.add(Math.max(1, current - 2));
    list.add(Math.max(1, current - 1));
    list.add(current);
    list.add(Math.min(total, current + 1));
    list.add(Math.min(total, current + 2));

    // Always show the last  page
    list.add(Math.max(1, total));

    return Array.from(list);
  }, [current, total]);

  if (total < 2) {
    return null;
  }

  return (
    <PaginationWrapper>
      <Previous onClick={handlePrev} disabled={current <= 1}>
        <ChevronLeft />
        Previous
      </Previous>
      {show.map((page, index) => (
        <Fragment key={index}>
          <Link
            onClick={handlePage}
            data-page={page}
            $active={current === page}
          >
            {page}
          </Link>
          {/* If the next link is more than 1 away, render ... */}
          {Math.abs(page - (show[index + 1] ?? page)) > 1 && (
            <Link as="span">...</Link>
          )}
        </Fragment>
      ))}
      <Next onClick={handleNext} disabled={current >= total}>
        Next
        <ChevronRight />
      </Next>
    </PaginationWrapper>
  );
};

const Previous = styled(Button).attrs({
  variant: "text-primary",
  size: "large",
})`
  justify-content: start;
  width: 150px;
  margin-right: auto;
  svg {
    width: 24px;
    height: 24px;
    margin-right: 8px;
  }
`;
const Next = styled(Previous)`
  justify-content: end;
  justify-self: flex-end;
  margin-right: 0;
  margin-left: auto;
  svg {
    margin-left: 8px;
    margin-right: 0;
  }
`;

const Link = styled.a<{ $active?: boolean }>`
  display: grid;
  place-content: center;
  border-radius: 8px;
  width: 40px;
  height: 40px;
  color: ${cssVar("color/gray/60")};
  text-decoration: none;
  font-weight: 500;
  cursor: pointer;
  ${(props) =>
    props.$active &&
    css`
      color: ${cssVar("color/gray/100")};
      background-color: ${cssVar("color/gray/10")};
      cursor: default;
    `}
  &:hover {
    color: ${cssVar("color/gray/100")};
  }
`;
const PaginationWrapper = styled.nav`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px;
`;

export default Pagination;
