import { StrictMode, useEffect } from "react";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import {
  BrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";

import "@minimum.eco/min-ui";
import * as Sentry from "@sentry/react";
import { setAxiosInterceptors } from "net/inteceptors";
import { StyleSheetManager } from "styled-components";
import { GlobalVariableStyles } from "theme/vars";
import FontFamilyDeclaraton from "typography/install";

import config from "config";

import { store } from "store/store";

import ScrollToTop from "utilities/hooks/scrollToTop";
import { isDevelopment } from "utilities/utils";

import App from "./App";
import GlobalStyles from "./GlobalStyle";
import "./index.scss";
import reportWebVitals from "./reportWebVitals";

setAxiosInterceptors();

if (!isDevelopment) {
  Sentry.init({
    dsn: config.sentry.dsn,
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
    ],
    normalizeDepth: 10,
    tracesSampleRate: 1,
    release: `${config.app.name}@${config.app.sem_ver}`,
  });
}

const StyleSheets = React.memo(() => {
  return (
    <StyleSheetManager target={document.body}>
      <>
        <GlobalStyles />
        <FontFamilyDeclaraton />
        <GlobalVariableStyles />
      </>
    </StyleSheetManager>
  );
});

ReactDOM.render(
  <StrictMode>
    <StyleSheets />
    <Provider store={store}>
      <BrowserRouter>
        <ScrollToTop />
        <App />
      </BrowserRouter>
    </Provider>
  </StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
