import styled from "styled-components";
import { rem } from "polished";
import Base from "./base";
import { composed } from "theme/utilities";

/**
 * font-size: 32px, line-height: 40px, letter-spacing: -0.5px, font-weight: 700
 */
export const H1B = styled(Base).attrs(composed("h1"))`
  font-size: ${rem(32)};
  line-height: ${rem(40)};
  letter-spacing: ${rem(-0.5)};
  font-weight: 700;
`;

/**
 * font-size: 32px, line-height: 40px, letter-spacing: -0.5px, font-weight: 600
 */
export const H1SB = styled(H1B)`
  font-weight: 600;
`;

/**
 * font-size: 32px, line-height: 40px, letter-spacing: -0.5px, font-weight: 400
 */
export const H1R = styled(H1B)`
  font-weight: 400;
`;

/**
 * font-size: 20px, line-height: 28px, letter-spacing: -0.5px, font-weight: 600
 */
export const H2SB = styled(Base).attrs(composed("h2"))`
  font-size: ${rem(20)};
  line-height: ${rem(28)};
  letter-spacing: ${rem(-0.5)};
  font-weight: 600;
`;

/**
 * font-size: 20px, line-height: 28px, letter-spacing: -0.5px, font-weight: 400
 */
export const H2R = styled(H2SB)`
  font-weight: 400;
`;

/**
 * font-size: 32px, line-height: 32px, letter-spacing: 0, font-weight: 500
 */
export const H2M = styled(H2SB)`
  font-weight: 500;
  line-height: ${rem(32)};
  letter-spacing: ${rem(0)};
`;

/**
 * font-size: 16px, line-height: 24px, letter-spacing: -0.5%, font-weight: 400
 */
export const H3R = styled(Base).attrs(composed("h3"))`
  font-size: ${rem(16)};
  line-height: ${rem(24)};
  letter-spacing: -0.5%;
  font-weight: 400;
`;

/**
 * font-size: 16px, line-height: 24px, letter-spacing: -0.5%, font-weight: 500
 */
export const H3M = styled(H3R).attrs(composed("h3"))`
  font-weight: 500;
`;

/**
 * font-size: 16px, line-height: 24px, letter-spacing: -0.5%, font-weight: 600
 */
export const H3SB = styled(H3R).attrs(composed("h3"))`
  font-weight: 600;
`;

/**
 * font-size: 14px, line-height: 24px, letter-spacing: -0.005em, font-weight: 600
 */
export const H4SB = styled(Base).attrs(composed("h4"))`
  font-size: ${rem(14)};
  line-height: ${rem(24)};
  letter-spacing: -0.005em;
  font-weight: 600;
`;

/**
 * font-size: 14px, line-height: 20px, letter-spacing: -0.005em, font-weight: 600
 */
export const H4SB2 = styled(H4SB)`
  line-height: 20px;
`;

/**
 * font-size: 14px, line-height: 24px, letter-spacing: -0.005em, font-weight: 500
 */
export const H4M = styled(H4SB)`
  font-weight: 500;
`;

/**
 * font-size: 14px, line-height: 24px, letter-spacing: -0.005em, font-weight: 400
 */
export const H4R = styled(H4SB)`
  font-weight: 400;
`;

/**
 * font-size: 14px, line-height: 20px, letter-spacing: -0.005em, font-weight: 400
 */
export const H4R2 = styled(H4SB)`
  font-weight: 400;
  line-height: 20px;
`;
