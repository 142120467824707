import styled from "styled-components";
import { rem } from "polished";
import Base from "./base";
import { composed } from "theme/utilities";

/**
 * font-size: 16px, line-height: 32px, letter-spacing: 0rem, font-weight: 500
 */
export const H3 = styled(Base).attrs(composed("h3"))`
  font-size: ${rem(16)};
  line-height: ${rem(32)};
  letter-spacing: ${rem(0)};
  font-weight: 500;
`;

/**
 * font-size: 14px, line-height: 32px, letter-spacing: 0rem, font-weight: 500
 */
export const H4 = styled(Base).attrs(composed("h4"))`
  font-size: ${rem(14)};
  line-height: ${rem(32)};
  letter-spacing: ${rem(0)};
  font-weight: 500;
`;
