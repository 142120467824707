import React, { CSSProperties, useCallback, useMemo } from "react";
import styled, { css } from "styled-components";

import cssVar from "theme/vars";
import { ContextInfo, SortDirection, TableContext } from "./Context";
import { HeadCellWrapper } from "./HeadCell";

export interface TableProps {
  /**
   * Specifies the ratios of column sizes. Numbers indicate ratios, strings represent fixed CSS values
   */
  columns: (number | string)[];
  sort?: {
    field?: string;
    direction?: SortDirection;
  };
  onSort?: (field: string, dir: SortDirection) => void;
  className?: string;
  /** Smallest size the table contents can be before needing to scroll */
  minWidth?: number;
  loading?: boolean;
  expandableRows?: boolean;
  /**
   * Provide a different order for columns
   */
  reorder?: number[];
  rounded?: boolean;
}

const Table: React.FC<TableProps> = ({
  className,
  children,
  sort,
  onSort,
  columns,
  minWidth = 0,
  loading,
  expandableRows = false,
  reorder,
  rounded,
}) => {
  const sortField = sort?.field,
    sortDir = sort?.direction;
  const handleSort = useCallback(
    (field: string) => {
      if (field === sortField) {
        if (sortDir === "asc") {
          onSort?.(field, "desc");
        } else {
          onSort?.("", "asc");
        }
      } else {
        onSort?.(field, "asc");
      }
    },
    [onSort, sortField, sortDir]
  );

  const ctx: ContextInfo = {
    onSort: handleSort,
    sort: sort ?? {},
  };

  if (
    reorder &&
    columns.length !== reorder.length &&
    process.env.NODE_ENV === "development"
  ) {
    throw new Error("Reordering array must match length of columns array");
  }

  const columnSizes = useMemo(() => {
    if (!reorder) {
      return columns;
    }

    return reorder.map((index) => columns[index] ?? 1);
  }, [reorder, columns]);

  return (
    <TableContext.Provider value={ctx}>
      <TableWrapper
        className={className}
        $loading={loading}
        $columns={columns.length}
        $reorder={reorder ?? []}
        $rounded={!!rounded}
        style={
          {
            "--min-width": `${minWidth}px`,
            "--columns": columnSizes
              .map((c) => (typeof c === "string" ? c : `${c}fr`))
              .join(" "),
            "--header-color": expandableRows
              ? cssVar("color/gray/20")
              : cssVar("color/primary/superSilverGrey"),
          } as CSSProperties
        }
      >
        {children}
      </TableWrapper>
    </TableContext.Provider>
  );
};

const TableWrapper = styled.div.attrs({ role: "table" })<{
  $loading?: boolean;
  $columns: number;
  $reorder: number[];
  $rounded: boolean;
}>`
  // None of that table layout- thank you!
  display: grid;
  grid-template-columns: var(--columns);
  grid-auto-rows: max-content;
  // Ensure re-ordered content fills in the top left
  grid-auto-flow: dense;
  background: ${cssVar("color/primary/white")};
  min-width: var(--min-width);
  position: relative;
  opacity: ${(props) => (props.$loading ? "0.6" : "1")};
  max-width: 100%;

  ${(props) =>
    props.$reorder.map(
      (n, order) => css`
        > :nth-child(${props.$reorder.length}n + ${n + 1}) {
          grid-column: ${order + 1} / span 1;
        }
      `
    )}

  ${({ $rounded, $columns }) =>
    $rounded &&
    css`
      border-radius: 12px;
      border-left: 1px solid ${cssVar("color/gray/30")};
      border-right: 1px solid ${cssVar("color/gray/30")};
      overflow: hidden;

      ${HeadCellWrapper}:nth-child(${$columns}n) {
        border-top-right-radius: 12px;
      }

      ${HeadCellWrapper}:first-of-type {
        border-top-left-radius: 12px;
      }
    `}
`;

export default Table;
