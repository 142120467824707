import api from "API";

import * as Params from "../../params";
import * as EntityReports from "./payloads";

export const entityReportsEndpoint = `/organisations/:org/entity-reports`;

const extendedEndpoints = api.injectEndpoints({
  endpoints: (builder) => ({
    entityReports: builder.query<
      EntityReports.GetReports,
      Params.GenericParamMap
    >({
      query: (query) => ({
        url: entityReportsEndpoint,
        params: {
          reporting_year: Params.toString(query.reporting_year),
          entity: Params.toArray(query.entity),
        },
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: "EntityReport" as const,
                id,
              })),
              { type: "EntityReport", id: "LIST" },
            ]
          : [{ type: "EntityReport", id: "LIST" }],
    }),
    requestEntityReport: builder.mutation<
      EntityReports.RequestReport,
      EntityReports.RequestReportParams
    >({
      query: ({ id }) => ({
        url: `/organisations/:org/entity-reports/${id}/request/`,
        method: "POST",
      }),
      invalidatesTags: (_result, _error, { id }) => [
        { type: "EntityReport", id },
      ],
    }),
    cancelEntityReport: builder.mutation<
      EntityReports.RequestReport,
      EntityReports.RequestReportParams
    >({
      query: ({ id }) => ({
        url: `/organisations/:org/entity-reports/${id}/request/`,
        method: "DELETE",
      }),
      invalidatesTags: (_result, _error, { id }) => [
        { type: "EntityReport", id },
      ],
    }),
    downloadEntityReport: builder.query<EntityReports.DownloadReport, string>({
      query: (id) => ({
        url: `/organisations/:org/entity-reports/${id}/download`,
      }),
    }),
  }),
});

export const {
  useEntityReportsQuery,
  useRequestEntityReportMutation,
  useCancelEntityReportMutation,
  useDownloadEntityReportQuery,
  useLazyDownloadEntityReportQuery,
} = extendedEndpoints;
