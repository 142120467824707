import React from "react";

import { Filter } from "ui/forms";

import { useDocumentTagsQuery } from "API";

import useFilterParam from "./useFilterParam";

const DocumentTags: React.FC = () => {
  const { data = [], isSuccess, isError } = useDocumentTagsQuery();
  const [selected, setSelected] = useFilterParam("doc_tag", data, {
    defaultToFirst: false,
    multi: true,
    ready: isSuccess || isError,
  });
  return (
    <Filter
      items={{ options: data }}
      title="Tag"
      label="Tag"
      selected={!!selected.length}
      count={selected.length ? selected.length : void 0}
      onChange={setSelected}
      disabled={!data.length || !selected}
      value={selected}
      multi={true}
      searchable={true}
      showReset={true}
      showApply={true}
    />
  );
};

export default DocumentTags;
