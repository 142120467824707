import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import { DatePicker } from "ui/forms";

import { formattedDate } from "utilities/general";

import { QUERY_PARAM as PAGINATION_QUERY_PARAM } from "../Pagination";
import { useFilterUpdate } from "./slice";

const SubmittedOn = () => {
  const [query, setQuery] = useSearchParams();
  const updateFilters = useFilterUpdate();

  const submittedOn = query.get("submitted_on");

  useEffect(() => {
    submittedOn && updateFilters("submitted_on", submittedOn);
    return () => {
      updateFilters("submitted_on", null);
    };
  }, [submittedOn, updateFilters]);

  const onChange = (date: Date | null) => {
    const start = formattedDate(date);
    updateFilters("submitted_on", start);
    query.delete("submitted_on");
    start && query.set("submitted_on", start);
    // A change in filter means we should reset pagination
    query.delete(PAGINATION_QUERY_PARAM);
    setQuery(query, { replace: true });
  };

  return (
    <DatePicker
      placeholderText="Submitted On"
      startDate={submittedOn ? new Date(submittedOn) : null}
      onSingleChange={onChange}
      single
    />
  );
};

export default SubmittedOn;
