import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

import { Auth0Client, RedirectLoginResult } from "@auth0/auth0-spa-js";

import config from "config";

import client from "./client";
import { getToken } from "./token";

/**
 * Client is statically created and used here, would prefer to move away from
 * this, however since our current net code is not executed within the react
 * state tree we can't call the token method without it being available outside
 * of react.
 */
const context = createContext<Auth0Client | null>(client);

export const AuthProvider = context.Provider;

export const useAuthClient = () => {
  return useContext(context);
};

/**
 * Hook to get an easily usable function for execution in a React Element to
 * trigger the login flow of Auth0
 */
export const useLogin = () => {
  const client = useAuthClient();
  return useCallback(() => {
    return client?.loginWithRedirect() ?? Promise.resolve(void 0);
  }, [client]);
};

/**
 * Hook to get an easily usable function for execution in a React Element to
 * trigger the logout flow of Auth0
 */
export const useLogout = () => {
  const client = useAuthClient();
  return useCallback(async () => {
    let refreshToken = "";

    function getRefreshToken() {
      const storage = localStorage;
      const authTokenKey = Object.keys(storage).filter((key) => {
        if (key.includes("auth0spa")) {
          return key;
        }
      })[0];

      const localStorageAuthItem = storage.getItem(authTokenKey || "");
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const parsedAuthToken: { body: { refresh_token: string } } = JSON.parse(
        localStorageAuthItem as string
      );

      const token: string = parsedAuthToken?.body?.refresh_token || "";
      refreshToken = token;
    }

    async function revoke() {
      try {
        await fetch(`https://${config.auth0.domain}/oauth/revoke`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            client_id: config.auth0.clientId,
            token: refreshToken,
          }),
        });
      } catch (e) {
        // handle error or ignore
      }
    }

    async function runLogout() {
      getRefreshToken();

      if (refreshToken) {
        await revoke();
      }
    }

    await runLogout(); // Wait for runLogout to complete

    return client?.logout() ?? Promise.resolve(void 0);
  }, [client]);
};

/**
 * Hook to get an easily usable function to process the oauth callback of
 * Auth0. Always returns a promise.
 *
 */
export const useHandleCallback = () => {
  const client = useAuthClient();
  /**
   * @param idToken If supplied token is given use for passwordless
   */
  return useCallback<(idToken?: string) => Promise<null | RedirectLoginResult>>(
    (idToken?: string) => {
      if (idToken) {
        // Auth0 is an enigma, and despite sending us an ID token, we don't
        // get a refresh token... but the cookie is set. So if run login here,
        // it'll set us up as normal- no secondary auth route to maintain!
        void client?.loginWithRedirect();
        return Promise.resolve(null);
      }

      if (!client) {
        return Promise.resolve(null);
      }

      return client.handleRedirectCallback();
    },
    [client]
  );
};

export enum UserStatus {
  UNKNOWN = "UNKNOWN",
  LOGGED_IN = "LOGGED_IN",
  LOGGED_OUT = "LOGGED_OUT",
}

/**
 * Simple state hook to determine user's current authentication status via Auth0
 * initial value is set to UNKNOWN until response is returned by Auth0
 *
 * Potential improvements: cache value if hook is used in many places
 */
export const useUserSession = () => {
  const client = useAuthClient();
  const [state, setState] = useState<UserStatus>(UserStatus.UNKNOWN);
  useEffect(() => {
    if (!client) {
      return;
    }

    // Guard against state change on unmount
    let shouldRun = true;
    async function run() {
      if (!shouldRun) {
        return;
      }

      setState(
        (await client?.isAuthenticated())
          ? UserStatus.LOGGED_IN
          : UserStatus.LOGGED_OUT
      );
    }

    void run();

    return () => {
      shouldRun = false;
    };
  }, [client]);

  return state;
};

export function useToken() {
  const [token, setToken] = useState("");
  useEffect(() => {
    let running = true;
    async function fetch() {
      try {
        const [, fetchedToken] = await getToken();
        if (running && fetchedToken) {
          setToken(fetchedToken);
        }
      } catch (e) {
        // ignore
      }
    }

    void fetch();

    return () => {
      running = false;
    };
  }, []);
  return token;
}
