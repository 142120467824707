import React, { ReactNode } from "react";

import { ReactComponent as IconLink } from "../../assets/icon-link.svg";

import "./button.scss";

export type ButtonVariant = "primary" | "secondary" | "link" | "hyperlink";

export interface ButtonProps {
  variant?: ButtonVariant;
  disabled?: boolean;
  children?: ReactNode;
  linkIcon?: boolean;
  block?: boolean;
  reverse?: boolean;
}

const Button: React.FC<
  ButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>
> = ({
  children,
  disabled,
  className,
  variant = "primary",
  block,
  reverse,
  ...props
}) => {
  const variantClassName = "min-ui__btn--" + variant;
  const blockName = block ? "min-ui__btn--block" : "";
  const reverseName = reverse ? "min-ui__btn--reverse" : "";
  return (
    <button
      className={`min-ui__btn ${variantClassName} ${blockName} ${reverseName} ${
        className || ""
      }`}
      disabled={disabled}
      {...props}
    >
      <span>{children}</span>
      {variant === "hyperlink" && <IconLink />}
    </button>
  );
};

export default Button;
