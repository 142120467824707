import styled from "styled-components";
import { rem } from "polished";
import Base from "./base";
import cssVar from "theme/vars";

/**
 * font-size: 12px, line-height: 16px, letter-spacing: 0.005em, font-weight: 500
 */
export const Large = styled(Base)`
  font-size: ${rem(12)};
  line-height: ${rem(16)};
  letter-spacing: 0.005em;
  font-weight: 500;
  color: ${cssVar("color/primary/darkGrey")};
`;

/**
 * font-size: 10, line-height: 16px, letter-spacing: 0.005em, font-weight: 600
 */
export const Small = styled(Large)`
  font-size: ${rem(10)};
  font-weight: 600;
`;
