import { ChevronLeft } from "icons";
import React, { useCallback, useState } from "react";
import styled, { css } from "styled-components";
import cssVar from "theme/vars";
import { H4M } from "typography/headers";
import Option from "./Option";

export interface OptionGroupProps {
  options: {
    value: string;
    label: string;
  }[];
  label: string;
  onSelect: (value: string) => void;
  value: string[];
  multi: boolean;
}

const OptionGroup: React.FC<OptionGroupProps> = ({
  options,
  label,
  value,
  ...optProps
}) => {
  const [open, setOpen] = useState(false);
  const handleToggle = useCallback(() => {
    setOpen((o) => !o);
  }, []);
  return (
    <>
      <OptionGroupWrapper
        aria-expanded={open}
        role="button"
        onClick={handleToggle}
        $open={open}
        aria-label={`${label} (${options.length})`}
      >
        <H4M as="span">
          {label} ({options.length})
        </H4M>
        <Arrow $open={open} />
      </OptionGroupWrapper>
      {open &&
        options.map((option) => (
          <Option
            key={option.value}
            selected={value.includes(option.value)}
            {...option}
            {...optProps}
          />
        ))}
    </>
  );
};

const Arrow = styled(ChevronLeft)<{ $open: boolean }>`
  margin-left: auto;
  transform: rotate(${(props) => (props.$open ? "90" : "-90")}deg);
  transition: transform 0.4s ease;
  color: ${cssVar("color/primary/blue")};
  width: 20px;
  height: 20px;
`;
const OptionGroupWrapper = styled.div<{ $open: boolean }>`
  background: ${cssVar("color/primary/superSilverGrey")};
  border: 1px solid ${cssVar("color/gray/30")};
  ${(props) =>
    props.$open &&
    css`
      background: ${cssVar("color/primary/silverGrey")};
      border: 1px solid ${cssVar("color/gray/40")};
    `}
  transition: background-color 0.2s ease, border-color 0.2s ease;
  border-radius: 8px;
  padding: 18px 16px;
  margin: 16px 8px 8px 16px;
  &:first-child {
    margin-top: 0;
  }
  cursor: pointer;
  display: flex;
  align-items: center;
`;

export default OptionGroup;
