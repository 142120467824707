import React from "react";

import styled from "styled-components";
import cssVar from "theme/vars";

import Activities from "./Activities";
import ActivityTags from "./ActivityTags";
import AuditStatus from "./AuditStatus";
import Cadences from "./Cadences";
import DateRange from "./DateRange";
import DateRangeDropdown from "./DateRangeDropdown";
import DocumentCategories from "./DocumentCategories";
import DocumentTags from "./DocumentTags";
import DocumentUploadedOn from "./DocumentUploadedOn";
import DocumentUsers from "./DocumentUsers";
import DocumentValidDateRange from "./DocumentValidDateRange";
import EmissionOutputTags from "./EmissionOutputTags";
import Entities from "./Entities";
import EntityCustomAttribute from "./EntityCustomAttribute";
import EntityCustomAttributes from "./EntityCustomAttributes";
import Group from "./Group";
import LegacySubmissionSlotLabels from "./LegacySubmissionSlotLabels";
import LegacySubmissionStatus from "./LegacySubmissionStatus";
import Location from "./Location";
import MultiReportingYear from "./MultiReportingYear";
import Organisation from "./Organisation";
import ReportingYear from "./ReportingYear";
import Scope from "./Scope";
import Scope3Category from "./Scope3Category";
import Status from "./Status";
import SubmissionGroup from "./SubmissionGroup";
import SubmissionGroupsStatus from "./SubmissionGroupsStatus";
import SubmissionPeriod from "./SubmissionPeriod";
import SubmissionSlotsStatus from "./SubmissionSlotsStatus";
import SubmittedOn from "./SubmittedOn";
import SwitchActivities from "./SwitchActivities";
import TaskAssignee from "./TaskAssignee";
import TaskListStatus from "./TaskListStatus";
import TaskReportingYear from "./TaskReportingYear";
import TaskSubmissionGroup from "./TaskSubmissionGroup";
import TaskSubmissionSlot from "./TaskSubmissionSlot";
import TimePeriod from "./TimePeriod";

export interface FiltersProps {
  sticky?: boolean;
  children?: React.ReactNode;
  className?: string;
}

const Filters = React.forwardRef<HTMLElement, FiltersProps>(
  ({ children, className }, ref) => {
    return (
      <FilterWrapper ref={ref} className={className}>
        {children}
      </FilterWrapper>
    );
  }
);

const FilterWrapper = styled.nav`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 12px 16px;
  gap: 12px;

  background: ${cssVar("color/primary/white")};
  border: 1px solid ${cssVar("color/primary/skinnyBlue")};
  border-radius: 8px;
  margin: 0 0 16px;

  position: sticky;
  top: 52px;
  z-index: 1;
`;

type Exports = typeof Filters & {
  ReportingYear: typeof ReportingYear;
  Cadences: typeof Cadences;
  SubmissionGroupsStatus: typeof SubmissionGroupsStatus;
  SubmissionSlotsStatus: typeof SubmissionSlotsStatus;
  SubmissionPeriod: typeof SubmissionPeriod;
  SubmissionGroup: typeof SubmissionGroup;
  TimePeriod: typeof TimePeriod;
  Location: typeof Location;
  Activities: typeof Activities;
  Entities: typeof Entities;
  Scope: typeof Scope;
  ActivityTags: typeof ActivityTags;
  EmissionOutputTags: typeof EmissionOutputTags;
  Group: typeof Group;
  LegacySubmissionStatus: typeof LegacySubmissionStatus;
  MultiReportingYear: typeof MultiReportingYear;
  AuditStatus: typeof AuditStatus;
  DateRange: typeof DateRange;
  DateRangeDropdown: typeof DateRangeDropdown;
  SubmittedOn: typeof SubmittedOn;
  LegacySubmissionSlotLabels: typeof LegacySubmissionSlotLabels;
  EntityCustomAttribute: typeof EntityCustomAttribute;
  EntityCustomAttributes: typeof EntityCustomAttributes;
  Status: typeof Status;
  DocumentCategories: typeof DocumentCategories;
  DocumentTags: typeof DocumentTags;
  DocumentUsers: typeof DocumentUsers;
  DocumentUploadedOn: typeof DocumentUploadedOn;
  DocumentValidDateRange: typeof DocumentValidDateRange;
  TaskListStatus: typeof TaskListStatus;
  TaskSubmissionGroup: typeof TaskSubmissionGroup;
  TaskSubmissionSlot: typeof TaskSubmissionSlot;
  TaskAssignee: typeof TaskAssignee;
  TaskReportingYear: typeof TaskReportingYear;
  SwitchActivities: typeof SwitchActivities;
  Scope3Category: typeof Scope3Category;
  Organisation: typeof Organisation;
};

const exported = Filters as Exports;
exported.ReportingYear = ReportingYear;
exported.Cadences = Cadences;
exported.SubmissionGroupsStatus = SubmissionGroupsStatus;
exported.SubmissionSlotsStatus = SubmissionSlotsStatus;
exported.SubmissionPeriod = SubmissionPeriod;
exported.SubmissionGroup = SubmissionGroup;
exported.TimePeriod = TimePeriod;
exported.Location = Location;
exported.Activities = Activities;
exported.Entities = Entities;
exported.Scope = Scope;
exported.ActivityTags = ActivityTags;
exported.EmissionOutputTags = EmissionOutputTags;
exported.Group = Group;
exported.LegacySubmissionStatus = LegacySubmissionStatus;
exported.MultiReportingYear = MultiReportingYear;
exported.AuditStatus = AuditStatus;
exported.DateRange = DateRange;
exported.DateRangeDropdown = DateRangeDropdown;
exported.SubmittedOn = SubmittedOn;
exported.LegacySubmissionSlotLabels = LegacySubmissionSlotLabels;
exported.EntityCustomAttribute = EntityCustomAttribute;
exported.EntityCustomAttributes = EntityCustomAttributes;
exported.Status = Status;
exported.DocumentCategories = DocumentCategories;
exported.DocumentTags = DocumentTags;
exported.DocumentUsers = DocumentUsers;
exported.DocumentUploadedOn = DocumentUploadedOn;
exported.DocumentValidDateRange = DocumentValidDateRange;
exported.SwitchActivities = SwitchActivities;
exported.Scope3Category = Scope3Category;
// Hub filters
exported.TaskListStatus = TaskListStatus;
exported.TaskSubmissionGroup = TaskSubmissionGroup;
exported.TaskSubmissionSlot = TaskSubmissionSlot;
exported.TaskAssignee = TaskAssignee;
exported.Organisation = Organisation;
exported.TaskReportingYear = TaskReportingYear;

export * from "./slice";
export default exported;
