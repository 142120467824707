import styled from "styled-components";
import cssVar from "theme/vars";

const Base = styled.span`
  color: ${cssVar("color/primary/black")};
  margin: 0;
  padding: 0;
`;

export default Base;
