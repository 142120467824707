import { SVGProps } from "react";
interface SvgCategoryProps extends SVGProps<SVGSVGElement> {
  /**
   * Width of icon, uses size if not given
   */
  width?: number;
  /**
   * Height of icon, uses size if not given
   */
  height?: number;
  /**
   * Size of icon, applies to width & height if not supplied
   * @default 24
   */
  size?: number;
}
export const SvgCategory = (props: SvgCategoryProps) => (
  <svg
    {...props}
    width={props.width ?? props.size ?? 24}
    height={props.height ?? props.size ?? 24}
    viewBox="0 0 48 48"
    fill="currentColor"
  >
    <path d="M15.75 21.7q-.9 0-1.35-.775-.45-.775.05-1.525L22.8 6.05q.45-.7 1.25-.7t1.25.7l8.35 13.35q.5.75.05 1.525-.45.775-1.35.775ZM35.3 44q-3.7 0-6.2-2.5t-2.5-6.2q0-3.7 2.5-6.2t6.2-2.5q3.7 0 6.2 2.5t2.5 6.2q0 3.7-2.5 6.2T35.3 44ZM7.5 42.75q-.65 0-1.075-.425Q6 41.9 6 41.25v-12.2q0-.65.425-1.075.425-.425 1.075-.425h12.2q.65 0 1.075.425.425.425.425 1.075v12.2q0 .65-.425 1.075-.425.425-1.075.425ZM35.3 41q2.4 0 4.05-1.65T41 35.3q0-2.4-1.65-4.05T35.3 29.6q-2.4 0-4.05 1.65T29.6 35.3q0 2.4 1.65 4.05T35.3 41ZM9 39.75h9.2v-9.2H9Zm9.45-21.05h11.2l-5.6-9.05Zm5.6 0ZM18.2 30.55Zm17.1 4.75Z" />
  </svg>
);
export default SvgCategory;
