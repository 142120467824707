import HeadCell from "./HeadCell";
import ExpandableRow from "./ExpandableRow";
import Cell, { CondensedCell, FooterCell } from "./Cell";
import Table, { TableProps } from "./Table";

type TableExports = React.FC<TableProps> & {
  HeadCell: typeof HeadCell;
  ExpandableRow: typeof ExpandableRow;
  Cell: typeof Cell;
  CondensedCell: typeof CondensedCell;
  FooterCell: typeof FooterCell;
};

const Exports = Table as TableExports;
Exports.HeadCell = HeadCell;
Exports.ExpandableRow = ExpandableRow;
Exports.Cell = Cell;
Exports.CondensedCell = CondensedCell;
Exports.FooterCell = FooterCell;

export default Exports;
