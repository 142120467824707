import { asArray } from "utils/types";

interface DefaultParams {
  [key: string]: GenericParam;
}

export type GenericParam = (string | number)[] | string | null | void | number;
export type GenericParamMap<T extends object = object> = DefaultParams & T;

/**
 * Takes an array of already stringified objects and converts into a JSON string
 */
export function toJSONArray(value: GenericParam) {
  if (!value) {
    return void 0;
  }

  if (Array.isArray(value) && !value.length) {
    return void 0;
  }

  return `[${asArray(value).join(",")}]`;
}

export function toArray(value: GenericParam) {
  if (!value) {
    return void 0;
  }

  if (Array.isArray(value) && !value.length) {
    return void 0;
  }

  return asArray(value);
}

export function toList(value: GenericParam) {
  if (!value) {
    return void 0;
  }

  return asArray(value).join(",");
}

export function toString(value: GenericParam): string | void {
  if (!value) {
    return void 0;
  }

  return asArray(value)[0]?.toString();
}

export function toRequiredString(value: GenericParam): string {
  return toString(value) ?? "";
}

export function convertArrayToObject(
  array: string[]
): { name: string; values: string[] }[] {
  const results: { [key: string]: string[] } = {};

  array.forEach((jsonString) => {
    try {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const parsedObj = JSON.parse(jsonString);
      for (const key in parsedObj) {
        if (!results[key]) {
          results[key] = [];
        }
        const parsedValue = parsedObj[key] as string;
        if (typeof parsedValue === "string") {
          results[key]!.push(parsedValue);
        }
      }
    } catch {
      // Handle error or log if needed
    }
  });

  return Object.keys(results).map((key) => ({
    name: key,
    values: results[key] || [], // Ensure values is always an array
  }));
}

// Enrich params with Entity custom attributes from query
export const enrichParamsWithCustomAttributes = ({
  params,
  query,
}: {
  params: DefaultParams;
  query: GenericParamMap<DefaultParams>;
}): DefaultParams => {
  const updatedParams = { ...params };

  for (const key in query) {
    if (
      Object.prototype.hasOwnProperty.call(query, key) &&
      !Object.prototype.hasOwnProperty.call(params, key)
    ) {
      const attributesArray = query[key] as [];
      // Convert and add the new key and value to params
      if (key === "customAttributes") {
        const convertedObjects = convertArrayToObject(attributesArray);
        convertedObjects.map((convertedObject) => {
          updatedParams[convertedObject.name] = convertedObject.values;
        });
      } else {
        // This would add all of the query params into the request
        // updatedParams[key] = toArray(attributesArray);

        // This will update only the params which already exist or were added as params
        if (updatedParams[key] !== undefined) {
          updatedParams[key] = toArray(attributesArray);
        } else {
          // Dynamically assign keys and values from the query object to params for Entity Custom attributes less than 2
          // i.e. which is not under `More filters`
          updatedParams[key] = toArray(query[key]);
        }
      }
    }
  }

  return updatedParams;
};
