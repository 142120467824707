import {
  Action,
  AnyAction,
  PreloadedState,
  Reducer,
  ThunkAction,
  combineReducers,
  configureStore,
} from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query/react";
import { slice as methodologies } from "Methodology";

import api from "API";

import atlasReducer from "Atlas/store/Slice";
import DataTrackerReducer from "DataTracker/store/Slice";
import dataCaptureReducer from "store/dataCapture/dataCaptureSlice";
import organisationReducer from "store/organisation/organisationSlice";
import userReducer from "store/user/userSlice";

import { slice as chunkLoader } from "Common/ChunkLoader";
import { slice as filters } from "Common/Filters";
import { slice as snackbar } from "Common/Snackbar";

const allReducers = combineReducers({
  user: userReducer,
  atlas: atlasReducer,
  dataCapture: dataCaptureReducer,
  organisation: organisationReducer,
  dataTracker: DataTrackerReducer,
  [filters.name]: filters.reducer,
  [snackbar.name]: snackbar.reducer,
  [methodologies.name]: methodologies.reducer,
  [chunkLoader.name]: chunkLoader.reducer,
  [api.reducerPath]: api.reducer,
});

const rootReducer: Reducer = (
  state: ReturnType<typeof allReducers>,
  action: AnyAction
) => {
  if (action.type === "user/resetOrganisation") {
    state = {} as RootState;
  }
  return allReducers(state, action);
};

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(api.middleware),
});

// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch);

export const setupStore = (preloadedState?: PreloadedState<RootState>) => {
  const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({ serializableCheck: false }).concat(api.middleware),
    preloadedState,
  });
  setupListeners(store.dispatch);
  return store;
};

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof allReducers>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
