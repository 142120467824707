/**
 * Shows a failure screen when a user's state is not valid
 */
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

import styled from "styled-components";
import { H4M } from "typography/headers";
import { Button } from "ui/core";

import { userSelectors } from "store/user/userSelectors";

import { useAppSelector } from "utilities/hooks/general";
import urls from "utilities/urls";

import Page from "Layout/Page/Auth";

import Response from "../Response";
import { useLogout } from "../react";

const AccountCheck: React.FC = ({ children }) => {
  const invalidLogin = useAppSelector(userSelectors.loginInvalid);
  const logout = useLogout();
  const navigate = useNavigate();

  const handleLogout = useCallback(() => {
    async function run() {
      await logout();
      navigate(urls.login());
    }

    void run();
  }, [navigate, logout]);

  if (!invalidLogin) {
    return <>{children}</>;
  }

  return (
    <Page name="">
      <Response
        message="We couldn't find a Minimum account matching that email address."
        isError={true}
      >
        <H4M>
          Please email <strong>support@minimum.com</strong> if you are facing
          any issues.
        </H4M>
        <Logout onClick={handleLogout}>Log out</Logout>
      </Response>
    </Page>
  );
};

const Logout = styled(Button).attrs({ variant: "secondary", size: "large" })`
  margin-top: 24px;
  width: 100%;
`;

export default AccountCheck;
