import { createGlobalStyle, css } from "styled-components";
import { ColorKey, Colors } from "./colors";
import { Spacing, SpacingKey } from "./spacing";
import { Typography, TypographyKey } from "./typography";
import { flatten } from "./utilities";

type VariableKey =
  | `color/${ColorKey}`
  | `text/${TypographyKey}`
  | `spacing/${SpacingKey}`;

function cssVar(key: VariableKey): string {
  return `var(--${key.split("/").join("-")})`;
}

const AllVars: [string, string][] = [
  ...flatten(Colors, "color"),
  ...flatten(Typography, "text"),
  ...flatten(Spacing, "spacing"),
];

export function styles() {
  return css`
    :root {
      ${AllVars.map(
        ([name, value]) => `--${name.split("/").join("-")}: ${value};`
      ).join("\n  ")}
    }
  `;
}

export const GlobalVariableStyles = createGlobalStyle`${styles()}`;

export default cssVar;
