import { SVGProps } from "react";
interface SvgAssignmentProps extends SVGProps<SVGSVGElement> {
  /**
   * Width of icon, uses size if not given
   */
  width?: number;
  /**
   * Height of icon, uses size if not given
   */
  height?: number;
  /**
   * Size of icon, applies to width & height if not supplied
   * @default 24
   */
  size?: number;
}
export const SvgAssignment = (props: SvgAssignmentProps) => (
  <svg
    {...props}
    width={props.width ?? props.size ?? 24}
    height={props.height ?? props.size ?? 24}
    viewBox="0 0 48 48"
    fill="currentColor"
  >
    <path d="M15.5 34h10.65q.65 0 1.075-.425.425-.425.425-1.075 0-.65-.425-1.075Q26.8 31 26.15 31H15.5q-.65 0-1.075.425Q14 31.85 14 32.5q0 .65.425 1.075Q14.85 34 15.5 34Zm0-8.5h17q.65 0 1.075-.425Q34 24.65 34 24q0-.65-.425-1.075-.425-.425-1.075-.425h-17q-.65 0-1.075.425Q14 23.35 14 24q0 .65.425 1.075.425.425 1.075.425Zm0-8.5h17q.65 0 1.075-.425Q34 16.15 34 15.5q0-.65-.425-1.075Q33.15 14 32.5 14h-17q-.65 0-1.075.425Q14 14.85 14 15.5q0 .65.425 1.075Q14.85 17 15.5 17ZM9 39h30V9H9v30ZM9 9v30V9Zm0 33q-1.25 0-2.125-.875T6 39V9q0-1.25.875-2.125T9 6h10.25q.25-1.75 1.6-2.875T24 2q1.8 0 3.15 1.125Q28.5 4.25 28.75 6H39q1.25 0 2.125.875T42 9v30q0 1.25-.875 2.125T39 42ZM24 8.15q.7 0 1.225-.525.525-.525.525-1.225 0-.7-.525-1.225Q24.7 4.65 24 4.65q-.7 0-1.225.525-.525.525-.525 1.225 0 .7.525 1.225.525.525 1.225.525Z" />
  </svg>
);
export default SvgAssignment;
