import { TokenResponse, getToken } from "Authentication";
import axios from "axios";

export const setAxiosInterceptors = () => {
  axios.interceptors.request.use(
    async (config) => {
      const [result, token] = await getToken();

      if (result === TokenResponse.UNAUTHORIZED) {
        return config;
      }

      if (token) {
        config.headers["Authorization"] = `Bearer ${token}`;
      }

      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  axios.interceptors.request.use((config) => {
    if (config && config.headers) {
      config.headers["X-MIN-CAMEL-CASE"] = "true";
    }

    return config;
  });

  axios.interceptors.request.use((config) => {
    // Append / to all net calls
    if (config.url && config.url[config.url.length - 1] !== "/") {
      config.url += "/";
    }
    return config;
  });
};
