import React, { ComponentType, LazyExoticComponent } from "react";

import fetch from "isomorphic-fetch";

import { store } from "store/store";

import { actions } from "./slice";

const NotLoaded: React.FC = () => <div />;

async function checkConnection() {
  try {
    const timeout = new AbortController();
    setTimeout(() => timeout.abort(), 5000);
    const response = await fetch("/index.html", {
      signal: timeout.signal,
    });
    return response.ok;
  } catch (e) {
    return false;
  }
}

export function lazy(
  promiseFn: () => Promise<{ default: ComponentType<any> }>
): LazyExoticComponent<ComponentType<any>> {
  return React.lazy(async function () {
    try {
      return await promiseFn();
    } catch (e) {
      if (e instanceof Error) {
        if (e.name === "ChunkLoadError") {
          // Check if a general issue connecting to the server is the cause
          // i.e. our server is down, or the  user's internet
          const connectionOkay = await checkConnection();

          store.dispatch(
            actions.chunkFailed(connectionOkay ? "chunk_missing" : "unknown")
          );
          return { default: NotLoaded };
        }
      }
      throw e;
      // ignore
    }
  });
}
