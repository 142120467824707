import styled from "styled-components";
import { rem } from "polished";
import Base from "./base";

/**
 * font-size: 13px, line-height: 16px, letter-spacing: 0%, font-weight: 400
 */
export const Menu = styled(Base)`
  font-size: ${rem(13)};
  line-height: ${rem(16)};
  letter-spacing: 0;
  font-weight: 400;
`;

/**
 * font-size: 13px, line-height: 16px, letter-spacing: 0%, font-weight: 500
 */
export const SmallM = styled(Menu)`
  font-size: ${rem(13)};
  font-weight: 500;
`;

/**
 * font-size: 14px, line-height: 16px, letter-spacing: 0%, font-weight: 600
 */
export const LargeM = styled(SmallM)`
  font-size: ${rem(14)};
  font-weight: 600;
`;
