import { ReactNode, useMemo } from "react";
import styled, { css } from "styled-components";
import cssVar from "theme/vars";
import { Subtitles } from "typography";
import { Close as X } from "icons";

export interface AvatarProps {
  size?: number;
  label?: string;
  image?: string;
  className?: string;
  onClick?: () => void;
  action?: {
    onClick?: () => void;
    icon?: ReactNode;
    alignBottom?: boolean;
  };
}

const Avatar: React.FC<AvatarProps> = ({
  className,
  label,
  size,
  image,
  action,
  onClick,
}) => {
  const initials = useMemo(() => {
    if (label?.length) {
      return label
        .split(" ")
        .map((n: string) => n.substring(0, 1).toUpperCase())
        .join("");
    }

    return "";
  }, [label]);

  return (
    <AvatarContainer
      $size={size}
      $image={image}
      className={className}
      data-testid="avatar"
      onClick={onClick}
    >
      {label && <AvatarLabel as="p">{initials}</AvatarLabel>}
      {action && (
        <AvatarAction
          $bottomAlign={action.alignBottom}
          onClick={action.onClick}
        >
          {action.icon}
        </AvatarAction>
      )}
    </AvatarContainer>
  );
};

export type DismissibleAvatarProps = {
  onDismiss: () => void;
  alignBottom?: boolean;
} & Pick<AvatarProps, "size" | "label" | "image">;
export const DismissibleAvatar: React.FC<DismissibleAvatarProps> = ({
  onDismiss,
  alignBottom,
  ...props
}) => {
  return (
    <Avatar
      {...props}
      action={{
        onClick: onDismiss,
        alignBottom,
        icon: <X size={12} color={cssVar("color/primary/blue")} />,
      }}
    />
  );
};

const AvatarAction = styled.button<{ $bottomAlign?: boolean }>`
  position: absolute;
  top: -4px;
  right: -4px;
  width: 16px;
  height: 16px;
  max-height: 16px;
  max-width: 16px;
  min-height: 16px;
  min-width: 16px;
  background-color: ${cssVar("color/primary/superSilverGrey")};
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.25);
  outline: none;
  border: none;
  cursor: pointer;
  border-radius: 100%;
  transition: background 0.3s ease;
  display: flex;
  justify-content: center;
  padding: 0;
  align-items: center;

  &:hover {
    background-color: ${cssVar("color/primary/lightBlue")};
  }

  &:active {
    background-color: ${cssVar("color/primary/riverBlue")};
  }

  ${({ $bottomAlign }) => {
    if ($bottomAlign) {
      return css`
        bottom: -4px;
      `;
    }
  }}
`;

const AvatarLabel = styled(Subtitles.H3)`
  color: inherit;
  font-weight: 400;
  font-size: 14px;
`;

const AvatarContainer = styled.div<{ $size?: number; $image?: string }>`
  position: relative;
  border-radius: 100%;
  background-color: ${cssVar("color/grayModern/marble")};
  color: ${cssVar("color/primary/white")};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  user-select: none;

  ${({ $image }) => {
    if ($image) {
      return css`
        background-image: url("${$image}");
        background-size: cover;
      `;
    }
  }}

  ${({ $size }) => {
    if (!$size) {
      $size = 40;
    }
    return css`
      width: ${$size}px;
      height: ${$size}px;
      max-width: ${$size}px;
      max-height: ${$size}px;
    `;
  }}
`;

export default Avatar;
