import React, { useCallback, useRef, useState } from "react";

import { Entity } from "types/Entity";

import { useElementBox } from "ui/hooks";

import { SwitcherButton, SwitcherChevron } from "Common/styles";

import OrganisationSwitcherDropdown from "./OrganisationSwitcherDropdown";

interface OrgSwitcherProps {
  currentOrg: Entity;
}

const OrganisationSwitcher: React.FC<OrgSwitcherProps> = ({ currentOrg }) => {
  const [open, setOpen] = useState(false);
  const onToggle = useCallback(() => {
    setOpen((o) => !o);
  }, []);
  const onClose = useCallback(() => {
    setOpen(false);
  }, []);

  const nav = useRef<HTMLDivElement>(null);
  const position = useElementBox(nav, open);

  return (
    <>
      <SwitcherButton onClick={onToggle} ref={nav} $open={open}>
        {currentOrg.name}
        <SwitcherChevron $open={open} />
      </SwitcherButton>
      <OrganisationSwitcherDropdown
        open={open}
        onClose={onClose}
        parent={nav}
        top={position?.bottom ?? 0}
        right={position?.right ?? 0}
      />
    </>
  );
};

export default OrganisationSwitcher;
