import { useLayoutEffect } from "react";

export const SCROLL_LOCK_CLASSNAME = "lock-scroll-on-page";
export default function useScrollLock(enabled: boolean) {
  useLayoutEffect(() => {
    if (!enabled) {
      return;
    }
    document.documentElement.classList.add(SCROLL_LOCK_CLASSNAME);
    return () =>
      document.documentElement.classList.remove(SCROLL_LOCK_CLASSNAME);
  }, [enabled]);
}
