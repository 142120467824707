import api from "API";

import * as Params from "../../params";
import { GetLegacySubmissions } from "./payloads";

export const legacySubmissionsEndpoint = `/organisations/:org/legacy-submission-slots/`;

const extendedEndpoints = api.injectEndpoints({
  endpoints: (builder) => ({
    legacySubmissions: builder.query<
      GetLegacySubmissions,
      Params.GenericParamMap
    >({
      query: (query) => ({
        url: legacySubmissionsEndpoint,
        params: {
          page: query.page,
          page_size: query.page_size,
          reporting_year: Params.toArray(query.reporting_years),
          entity: Params.toArray(query.entity),
          activity: Params.toArray(query.activity),
          status: Params.toArray(query.submission_status),
          ordering: Params.toString(query.ordering),
          label: Params.toArray(query.label),
        },
      }),
      providesTags: (response) =>
        response && response.results
          ? [
              ...response.results.map(({ id }) => ({
                type: "Submission" as const,
                id,
              })),
              { type: "Submission", id: "LIST" },
            ]
          : [{ type: "Submission", id: "LIST" }],
    }),
  }),
});

export const { useLegacySubmissionsQuery } = extendedEndpoints;
