import api from "API";

import { Transaction } from "../../../types/Transaction";
import * as Params from "../../params";
import * as Transactions from "./payloads";

export const transactionsEndpoint = `/organisations/:org/transactions`;

const extendedEndpoints = api.injectEndpoints({
  endpoints: (builder) => ({
    transactions: builder.query<
      Transactions.GetTransactions,
      Params.GenericParamMap<Transactions.GetTransactionsParams>
    >({
      query: (query) => ({
        url: transactionsEndpoint,
        params: {
          page: query.page,
          date_from: Params.toString(query.date_from),
          date_to: Params.toString(query.date_to),
          tags: Params.toJSONArray(query.emission_tags),
          methodology: Params.toArray(query.methodology),
          entity: Params.toArray(query.entity),
          activity: Params.toArray(query.activity),
          ordering: Params.toString(query.ordering),
          emission_output_type: Params.toString(query.emission_output_type),
          audit_status: Params.toString(query.audit_status),
        },
      }),
      providesTags: ["Transaction"],
    }),
    transactionDetails: builder.query<
      Transaction,
      Params.GenericParamMap<Transactions.GetTransactionsParams> & {
        transactionId: string;
      }
    >({
      query: (query) => ({
        url: `/organisations/:org/transactions/${query.transactionId}`,
        params: {
          emission_output_type: Params.toString(query.emission_output_type),
        },
      }),
      providesTags: (result) => [{ type: "Transaction", id: result?.id }],
    }),
    transactionsCSV: builder.query<Blob, void>({
      query: () => ({
        url: `/organisations/:org/emission-outputs/`,
        responseHandler: (response: Response) => response.blob(),
      }),
    }),
  }),
});

export const {
  useTransactionsQuery,
  useTransactionDetailsQuery,
  useTransactionsCSVQuery,
} = extendedEndpoints;
