import React from "react";

import { Filter } from "ui/forms";

import { useTimePeriodsQuery } from "API";

import useFilterParam from "./useFilterParam";

interface TimePeriodProps {
  submissionGroupId: string;
}

const TimePeriod: React.FC<TimePeriodProps> = ({ submissionGroupId }) => {
  const {
    data = [],
    isSuccess,
    isError,
  } = useTimePeriodsQuery(submissionGroupId);
  const [selected, setSelected] = useFilterParam("timePeriod", data, {
    defaultToFirst: false,
    ready: isSuccess || isError,
  });
  const selectedLabel =
    data.find((o) => o.value === selected)?.label ?? "Time period";
  return (
    <Filter
      items={{ options: data }}
      title="Time period"
      label={selectedLabel}
      onChange={setSelected}
      selected={!!selected}
      disabled={!isSuccess}
      value={selected ?? ""}
      showReset={true}
      showApply={true}
      searchable={true}
    />
  );
};

export default TimePeriod;
