import styled from "styled-components";
import cssVar from "theme/vars";
import { H6R } from "typography/captions";
import { H4M } from "typography/headers";
import { ComponentType } from "react";

type subtitleProps = {
  label: string;
  additionalInfo?: string;

  labelType?: ComponentType;
  light?: boolean;
};

const Subtitle: React.FC<subtitleProps> = ({
  label,
  additionalInfo,
  light = false,
  labelType = H4M,
  children,
}) => {
  return (
    <Wrapper>
      <div>
        <Title as={labelType} $light={light}>
          {label}
        </Title>
        {additionalInfo && <H6R>{additionalInfo}</H6R>}
      </div>
      <div>{children}</div>
    </Wrapper>
  );
};

const Title = styled.h3<{ $light: boolean }>`
  ${({ $light }) => `color: ${cssVar(`color/gray/${$light ? "60" : "100"}`)};`}
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export default Subtitle;
