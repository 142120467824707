import styled from "styled-components";
import { rem } from "polished";
import Base from "./base";
import { composed } from "theme/utilities";
import cssVar from "theme/vars";

/**
 * font-size: 14px, line-height: 16px, letter-spacing: -0.005em, font-weight: 400
 */
export const H5R = styled(Base).attrs(composed("h5"))`
  font-size: ${rem(14)};
  line-height: ${rem(16)};
  letter-spacing: -0.005em;
  font-weight: 400;
  color: ${cssVar("color/primary/darkGrey")};
`;

/**
 * font-size: 12px, line-height: 16px, letter-spacing: -0.005em, font-weight: 400
 */
export const H6R = styled(Base).attrs(composed("h6"))`
  font-size: ${rem(12)};
  line-height: ${rem(16)};
  letter-spacing: -0.005em;
  font-weight: 400;
  color: ${cssVar("color/primary/darkGrey")};
`;
