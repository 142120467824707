import { Check } from "icons";
import { rem } from "polished";
import styled, { css } from "styled-components";
import cssVar from "theme/vars";

interface CheckboxProps {
  active?: boolean;
  checked?: boolean;
  onChange?: () => void;
}
const Checkbox: React.FC<CheckboxProps> = ({
  active = false,
  checked = false,
  onChange,
}) => {
  const isChecked = active || checked;

  return (
    <CheckboxWrapper
      $checked={isChecked}
      onClick={() => {
        onChange && onChange();
      }}
      data-testid="checkbox"
    >
      {isChecked && <Check size={18} />}
    </CheckboxWrapper>
  );
};

const CheckboxWrapper = styled.span.attrs({ "aria-hidden": true })<{
  $checked: boolean;
}>`
  cursor: pointer;
  display: inline-grid;
  place-content: center;
  border-radius: 4px;
  min-width: 20px;
  height: 20px;
  background: ${cssVar("color/primary/white")};
  color: ${cssVar("color/primary/blue")};
  border: 1px solid ${cssVar("color/gray/50")};
  font-size: ${rem(14)};
  font-family: ${cssVar("text/family/regular")};
  transition: border-color 0.3s ease, background-color 0.3s ease,
    box-shadow 0.3s ease;
  outline: 0;

  box-shadow: 0px 0px 0px 4px rgba(124, 130, 135, 0);

  &[disabled] {
    background: ${cssVar("color/primary/superSilverGrey")};
    border: 1px solid ${cssVar("color/primary/disabledGrey")};
  }

  *:hover > & {
    box-shadow: 0px 0px 0px 4px rgba(44, 113, 241, 0.1);
  }

  :focus {
    box-shadow: 0px 0px 0px 4px rgba(44, 113, 241, 0.2) !important;
  }

  :active {
    box-shadow: 0px 0px 0px 4px rgba(44, 113, 241, 0.35) !important;
  }

  ${(props) =>
    props.$checked &&
    css`
      color: ${cssVar("color/primary/white")};
      background: ${cssVar("color/primary/blue")};
      border: 1px solid ${cssVar("color/primary/blue")};
    `}
`;

export default Checkbox;
