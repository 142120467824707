import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { PayloadAction, createSelector, createSlice } from "@reduxjs/toolkit";

import { RootState } from "../../store/store";

type State = {
  currentValues: Record<string, string | string[]>;
  ready: Record<string, boolean>;
};
const initialState: State = {
  currentValues: {},
  ready: {},
};

export const slice = createSlice({
  name: "filters",
  initialState,
  reducers: {
    setReady(
      state,
      action: PayloadAction<{ key: string; value: boolean | null }>
    ) {
      if (action.payload.value === null) {
        delete state.ready[action.payload.key];
      } else {
        state.ready[action.payload.key] = action.payload.value;
      }
    },
    setValue(
      state,
      action: PayloadAction<{ key: string; value: string | string[] | null }>
    ) {
      if (!action.payload.value) {
        delete state.currentValues[action.payload.key];
      } else {
        if (state.currentValues[action.payload.key] === action.payload.value) {
          return;
        }
        state.currentValues[action.payload.key] = action.payload.value;
      }
    },
  },
});

const currentValues = (state: { [slice.name]: State }) =>
  state.filters.currentValues;

const readyValues = (state: { [slice.name]: State }) => state.filters.ready;
const isReady = createSelector([readyValues], (ready) => {
  const values = Array.from(Object.values(ready));
  return !!values.length && values.every(Boolean);
});

const currentFilters = createSelector(
  [currentValues, isReady],
  (values, isReady): [Record<string, string | string[]>, boolean] => [
    values,
    isReady,
  ]
);

export const useCurrentFilters = () => {
  return useSelector(currentFilters);
};

export const useCurrentFilterValues = () => {
  return useCurrentFilters()[0];
};

export const useFilterUpdate = () => {
  const dispatch = useDispatch();
  return useCallback(
    (key: string, value: string | string[] | null) => {
      dispatch(slice.actions.setValue({ key, value }));
    },
    [dispatch]
  );
};

export const selectors = {
  hasFilters: (state: RootState): boolean => {
    //We don't want a generic approach to non array filters as group by will always be set
    if (
      state.filters.currentValues.date_from ||
      state.filters.currentValues.date_to
    ) {
      return true;
    }
    //If any of the array values have a length greater than 0 then we have a filter
    return Object.values(state.filters.currentValues).some((filter) => {
      return Array.isArray(filter) && filter.length > 0;
    });
  },
};

export const { setValue, setReady } = slice.actions;
export const { reducer } = slice;
