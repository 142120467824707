import { createAsyncThunk } from "@reduxjs/toolkit";
import { APIGet, APIPost } from "net/api";
import { organisationEndpoints } from "net/endpoints/organisations";
import { DataCaptureCategory } from "types/Category";
import { SubmittedData } from "types/SubmittedData";

const fetchCategories = createAsyncThunk(
  "dataCapture/categories",
  async (
    params: { orgId: string; entityId: string; reportingId: string },
    thunkApi
  ) => {
    try {
      const response = await APIGet<DataCaptureCategory[]>(
        organisationEndpoints.categories(params.orgId, params.entityId),
        { params: { reporting_year: params.reportingId } }
      );
      return response.data;
    } catch (err: any) {
      return thunkApi.rejectWithValue(err.response.data);
    }
  }
);

const saveCategoryData = createAsyncThunk(
  "dataCapture/category/save",
  async (
    params: {
      categoryId: string;
      reportingId: string;
      source: string;
      status: string;
      data: any;
      entityId: string;
      orgId: string;
      files: string[];
    },
    thunkAPI
  ) => {
    try {
      const response = await APIPost<SubmittedData>(
        organisationEndpoints.submittedData(params.orgId, params.entityId),
        {
          category: params.categoryId,
          source: params.source,
          status: params.status,
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          data: params.data,
          files: params.files,
        },
        { params: { reporting_year: params.reportingId } }
      );
      return response.data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

const fetchCategory = createAsyncThunk(
  "dataCapture/category",
  async (
    params: {
      orgId: string;
      entityId: string;
      categoryId: string;
      reportingId: string;
    },
    thunkAPI
  ) => {
    try {
      const response = await APIGet<DataCaptureCategory>(
        organisationEndpoints.category(
          params.orgId,
          params.entityId,
          params.categoryId
        ),
        { params: { reporting_year: params.reportingId } }
      );
      return response.data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

const fetchData = createAsyncThunk(
  "dataCapture/fetchData",
  async (
    params: {
      orgId: string;
      entityId: string;
      reportingId: string;
      categoryId: string;
    },
    thunkAPI
  ) => {
    try {
      const response = await APIGet<SubmittedData[]>(
        organisationEndpoints.submittedData(params.orgId, params.entityId),
        {
          params: {
            reporting_year: params.reportingId,
            category: params.categoryId,
          },
        }
      );
      return response.data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

const fetchAllData = createAsyncThunk(
  "dataCapture/fetchAllData",
  async (
    params: {
      orgId: string;
      entityId: string;
      reportingId: string;
    },
    thunkAPI
  ) => {
    try {
      const response = await APIGet<SubmittedData[]>(
        organisationEndpoints.submittedData(params.orgId, params.entityId),
        {
          params: {
            reporting_year: params.reportingId,
          },
        }
      );
      return response.data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const dataCaptureThunks = {
  fetchCategories,
  fetchCategory,
  fetchData,
  fetchAllData,
  saveCategoryData,
};
