import { createGlobalStyle } from "styled-components";

import { SCROLL_LOCK_CLASSNAME } from "ui/hooks/useScrollLock";

const GlobalStyles = createGlobalStyle`
  html {
    overflow-y: scroll;
    overflow-x: hidden;

    &.${SCROLL_LOCK_CLASSNAME} {
      overflow-y: hidden;
    }

    sub, sup {
      font-size: 58.3%;
      position: relative;
      vertical-align: baseline;
      line-height: 1em;
    }

    sub {
      top: 2px;
    }

    sup {
      top: -2px;
    }

  }

`;

export default GlobalStyles;
