import React from "react";

import styled from "styled-components";
import { Alert } from "ui/core";
import { Input } from "ui/forms";

import {
  RegistrationFormData,
  RegistrationFormGetHandler,
} from "./ExternalRegistration";

const RegistrationFormPersonalInfo: React.FC<{
  registrationFormData: RegistrationFormData;
  getOnChangeHandler: RegistrationFormGetHandler;
}> = ({ registrationFormData, getOnChangeHandler }) => {
  return (
    <>
      <InputField
        value={registrationFormData.email}
        onChange={getOnChangeHandler("email")}
        label="Email Address"
        type="email"
      />
      <InputField
        value={registrationFormData.password}
        onChange={getOnChangeHandler("password")}
        label="Password"
        type="password"
      />
      <Alert variant="lightBlue">
        Your password must contain at least
        <List>
          <li>8 characters</li>
          <li>one of lower case (a-z), upper case (A-Z) and numbers (0-9)</li>
          <li>one special character (!@#$%^&*)</li>
        </List>
      </Alert>
    </>
  );
};

export const InputField = styled(Input)`
  margin-bottom: 8px;
`;
const List = styled.ul`
  margin: 4px 0;
  padding-left: 16px;
`;

export default RegistrationFormPersonalInfo;
