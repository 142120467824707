import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";

import { userSelectors } from "store/user/userSelectors";

import { useAppSelector } from "utilities/hooks/general";
import { hubUrls } from "utilities/urls";
import { isAnyNonHubFeatureFlagEnabled } from "utilities/utils";

const HubOnlyAccessCheck = () => {
  const navigate = useNavigate();
  const userID = useAppSelector(userSelectors.userId);
  const flags = useAppSelector(userSelectors.featureFlags);

  const isHubFeatureFlagEnabled = useSelector(
    userSelectors.someOrganisationsContainHubFeatureFlag
  );

  useEffect(() => {
    if (userID) {
      const isNonHubFeatureFlagEnabled = isAnyNonHubFeatureFlagEnabled(flags);

      // If user has Hub feature and no other feature enabled
      if (isHubFeatureFlagEnabled && !isNonHubFeatureFlagEnabled) {
        navigate(hubUrls.tasks(), {
          replace: true,
        });
      }

      return;
    }
  }, [userID, flags, navigate, isHubFeatureFlagEnabled]);

  return <Outlet data-testid="hub-only-outlet" />;
};

export default HubOnlyAccessCheck;
