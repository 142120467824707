import React, { ChangeEvent, useEffect, useRef } from "react";
import styled from "styled-components";
import cssVar from "theme/vars";
import { Headers } from "typography";
import { rem } from "polished";
import * as Icons from "icons";

export interface InputProps {
  /**
   * Optional label to display above text input
   */
  label?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  value?: string;
  /**
   * Optional placeholder text to show in text input
   */
  placeholder?: string;
  type?: "email" | "password" | "text";
  /**
   * Optional icon to show in left of control
   */
  icon?: keyof typeof Icons;
  /**
   * For re-styling
   */
  className?: string;

  /**
   * Aria Role
   */
  role?: string;
  /**
   * Auto focus on show
   */
  focus?: boolean;
  disabled?: boolean;
}

const Input: React.FC<InputProps> = ({
  label,
  onChange,
  value,
  placeholder,
  type = "text",
  icon,
  className,
  role,
  focus,
  disabled,
}) => {
  const control = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (focus) {
      control.current?.focus();
    }
  }, [focus]);
  return (
    <InputWrapper className={className}>
      {label && <Label>{label}</Label>}
      <ControlWrapper>
        {icon && <Icon as={Icons[icon]} />}
        <Control
          onChange={onChange}
          value={value}
          placeholder={placeholder}
          type={type}
          role={role}
          ref={control}
          disabled={disabled}
        />
      </ControlWrapper>
    </InputWrapper>
  );
};

const Icon = styled.svg.attrs({ "aria-hidden": true })`
  position: absolute;
  top: 10px;
  left: 12px;
  width: 20px;
  height: 20px;
  color: ${cssVar("color/primary/grey")};
  pointer-events: none;
`;
const InputWrapper = styled.label`
  overflow: visible;
  position: relative;
`;
const Label = styled(Headers.H4M).attrs({ as: "span" })`
  display: block;
  color: ${cssVar("color/gray/60")};
  margin: 0 0 8px;
`;

const ControlWrapper = styled.div`
  position: relative;
`;
const Control = styled.input`
  display: block;
  width: 100%;
  border-radius: 8px;
  background: ${cssVar("color/primary/white")};
  border: 1px solid ${cssVar("color/primary/disabledGrey")};
  height: 40px;
  padding: 8px 12px;
  font-size: ${rem(14)};
  font-family: ${cssVar("text/family/regular")};
  color: ${cssVar("color/gray/100")};
  transition: border-color 0.3s ease, background-color 0.3s ease,
    box-shadow 0.3s ease;
  outline: 0;

  box-shadow: 0px 0px 0px 4px rgba(124, 130, 135, 0);

  &[disabled] {
    background: ${cssVar("color/primary/superSilverGrey")};
  }
  &:focus {
    border-color: ${cssVar("color/primary/blue")};
  }
  &:hover {
    box-shadow: 0px 0px 0px 4px rgba(124, 130, 135, 0.15);
  }

  &::placeholder {
    color: ${cssVar("color/primary/grey")};
  }

  // Move over content when icon is present
  ${Icon} + & {
    padding-left: 40px;
  }
`;

export default Input;
