import React from "react";

import { Filter } from "ui/forms";
import { Option } from "ui/forms/Filter/Menu";

import useFilterParam from "./useFilterParam";

interface StatusProps {
  options: Option[];
  disabled?: boolean;
}

const TaskListStatus: React.FC<StatusProps> = ({
  options,
  disabled = false,
}) => {
  const [selected, setSelected] = useFilterParam("status", options, {
    defaultToFirst: false,
  });
  const selectedLabel =
    options.find((o) => o.value === selected)?.label ?? "Status";
  return (
    <Filter
      items={{ options }}
      title="Status"
      label={selectedLabel}
      selected={!!selected}
      disabled={options.length === 0 || disabled}
      onChange={setSelected}
      value={selected ?? ""}
      showReset={true}
      showApply={true}
      searchable={true}
    />
  );
};

export default TaskListStatus;
