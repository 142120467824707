export enum AnalyticsEvent {
  DATA_CAPTURE_SAVE = "DATA_CAPTURE_SAVE",
  DATA_CAPTURE_SUBMIT = "DATA_CAPTURE_SUBMIT",
  LOGGED_IN = "LOGGED_IN",
  LOGGED_OUT = "LOGGED_OUT",
  HOMEPAGE_ACTION = "HOMEPAGE_ACTION",
  HOMEPAGE_BUTTON_CLICKED = "Home Page Button Clicked",
  SUPPORT_REQUEST = "SUPPORT_REQUEST",
  EXPORT_TO_EXCEL_EMISSIONS = "Emissions Export to Excel Clicked",
  EXPORT_TO_EXCEL_ACTIVITY_DATA = "Activity Data Export to Excel Clicked",
  NAVIGATION_BAR_TAB_VIEWED = "Navigation Bar Tab Viewed",
  EMISSIONS_DETAILS_PAGE = "Emissions Details Page Viewed",
  HOME_PAGE_SECTION_VIEWED = "Home Page Section Viewed",
  PAGE_SCROLLED = "Page Scrolled",
  SUBMISSION_COMPLETED = "Submission Completed",
  DATA_TASK_CLAIMED = "Data Task Claimed",
  DATA_TASK_COMPLETED = "Data Task Completed",
  EMISSIONS_COMMITTED = "Emissions Committed",
}
