import { createAsyncThunk } from "@reduxjs/toolkit";
import { APIGet } from "net/api";
import { organisationEndpoints } from "net/endpoints/organisations";
import { DataTrackerEntity } from "types/DataTracker";
import { SubmissionProgress } from "types/SubmittedData";

import { RootState } from "store/store";

import { selectors } from "./Selectors";
import { slice } from "./Slice";

export type GetEntitiesResponseBody = {
  categoriesProgress: SubmissionProgress;
  entitiesProgress: SubmissionProgress;
  entities: DataTrackerEntity[];
};

const getEntities = createAsyncThunk(
  "dataTracker/getEntities",
  async (params: { orgId: string; reportingYearId: string }, thunkApi) => {
    try {
      const response = await APIGet<GetEntitiesResponseBody>(
        organisationEndpoints.progressReport(
          params.orgId,
          params.reportingYearId
        )
      );

      const selected = selectors.selectedEntity(
        thunkApi.getState() as RootState
      );

      const entryToSelect = selected ?? response.data.entities[0];
      if (entryToSelect) {
        thunkApi.dispatch(slice.actions.setSelectedEntity(entryToSelect));
      }

      return response.data;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.response.data);
    }
  }
);

export const thunks = {
  getEntities,
};
