import api from "API";

import * as ConsolidationSettings from "./payloads";

export const consolidationSettingsEndpoint = `/organisations/:org/consolidation-settings/`;

const extendedEndpoints = api.injectEndpoints({
  endpoints: (builder) => ({
    consolidationSettings: builder.query<
      ConsolidationSettings.GetConsolidationSettings,
      void
    >({
      query: () => {
        return {
          url: consolidationSettingsEndpoint,
        };
      },
    }),
  }),
});

export const { useConsolidationSettingsQuery } = extendedEndpoints;
