import api from "API";

import * as Params from "../../params";
import * as Emissions from "./payloads";

export const consolidatedEmissionsEndpoint = `/organisations/:org/consolidated-emissions/`;
export const consolidatedEmissionsExportEndpoint =
  consolidatedEmissionsEndpoint + `export/`;
export const consolidatedEmissionsAsyncExportEndpoint =
  consolidatedEmissionsEndpoint + `async-export/`;

const extendedEndpoints = api.injectEndpoints({
  endpoints: (builder) => ({
    consolidatedEmissions: builder.query<
      Emissions.GetConsolidatedEmissions,
      Params.GenericParamMap<Emissions.GetConsolidatedEmissionsParams>
    >({
      query: (query) => {
        const params: Emissions.ConsolidatedEmissionsEndpointParamsType = {
          page: query.page,
          date_from: Params.toString(query.date_from),
          date_to: Params.toString(query.date_to),
          group_by: Params.toString(query.group_by),
          ghg_scope: Params.toList(query.scope),
          ghg_category: Params.toList(query.scope3Category),
          tags: Params.toJSONArray(query.tags),
          location: Params.toArray(query.location),
          ordering: Params.toString(query.ordering),
          emission_output_type: Params.toString(query.emission_output_type),
          activity: Params.toArray(query.activity),
          activity_data: Params.toArray(query.activity_data),
          entity: Params.toArray(query.entity),
          reporting_year: Params.toString(query.reporting_year),
        };

        return {
          url: consolidatedEmissionsEndpoint,
          params: Params.enrichParamsWithCustomAttributes({ params, query }),
        };
      },
    }),
    consolidatedEmissionDetails: builder.query<
      Emissions.GetConsolidatedEmissionDetails,
      Emissions.GetConsolidatedEmissionDetailsParams
    >({
      query: ({ emission_id, consolidation_id, reporting_year }) => ({
        url:
          consolidatedEmissionsEndpoint +
          `${emission_id}/` +
          `${consolidation_id}/`,
        params: {
          reporting_year,
        },
      }),
    }),
    consolidatedEmissionConsolidationDetails: builder.query<
      Emissions.GetConsolidatedEmissionConsolidationDetails,
      Emissions.GetConsolidatedEmissionDetailsParams
    >({
      query: ({ emission_id, consolidation_id, reporting_year }) => ({
        url:
          consolidatedEmissionsEndpoint +
          `${emission_id}/` +
          `${consolidation_id}/calculation-details`,
        params: {
          reporting_year,
        },
      }),
    }),
    consolidatedEmissionCalculationLog: builder.query<
      Emissions.GetConsolidatedEmissionCalculationLogItem[],
      Emissions.GetConsolidatedEmissionDetailsParams
    >({
      query: ({ emission_id, consolidation_id, reporting_year }) => ({
        url:
          consolidatedEmissionsEndpoint +
          `${emission_id}/` +
          `${consolidation_id}/calculation-logs`,
        params: {
          reporting_year,
        },
      }),
    }),
    consolidatedEmissionExport: builder.query<
      Blob,
      Params.GenericParamMap<Emissions.ExportConsolidatedEmissionsParams>
    >({
      query: (query) => {
        const params: Emissions.ConsolidatedEmissionsEndpointGenericParamsType =
          {
            date_from: Params.toString(query.date_from),
            date_to: Params.toString(query.date_to),
            ghg_scope: Params.toList(query.scope),
            location: Params.toArray(query.location),
            activity: Params.toArray(query.activity),
            entity: Params.toArray(query.entity),
            reporting_year: Params.toString(query.reporting_year),
          };

        return {
          url: consolidatedEmissionsExportEndpoint,
          params: Params.enrichParamsWithCustomAttributes({ params, query }),
          responseHandler: (response: Response) => response.blob(),
        };
      },
    }),
    consolidatedEmissionAsyncExport: builder.query<
      Blob,
      Params.GenericParamMap<Emissions.ExportConsolidatedEmissionsParams>
    >({
      query: (query) => {
        const params: Emissions.ConsolidatedEmissionsEndpointGenericParamsType =
          {
            date_from: Params.toString(query.date_from),
            date_to: Params.toString(query.date_to),
            ghg_scope: Params.toList(query.scope),
            location: Params.toArray(query.location),
            activity: Params.toArray(query.activity),
            entity: Params.toArray(query.entity),
            reporting_year: Params.toString(query.reporting_year),
          };

        return {
          url: consolidatedEmissionsAsyncExportEndpoint,
          params: Params.enrichParamsWithCustomAttributes({ params, query }),
          responseHandler: (response: Response) => response.blob(),
        };
      },
    }),
  }),
});

export const {
  useConsolidatedEmissionsQuery,
  useConsolidatedEmissionDetailsQuery,
  useConsolidatedEmissionConsolidationDetailsQuery,
  useConsolidatedEmissionCalculationLogQuery,
  useLazyConsolidatedEmissionExportQuery,
  useLazyConsolidatedEmissionAsyncExportQuery,
} = extendedEndpoints;
