import api from "API";

import * as Analytics from "./payloads";

export const bespokeDashboardsEndpoint = `/organisations/:org/explo-dashboards`;
export const standardDashboardsEndpoint = `/organisations/:org/standard-explo-dashboards/`;

const extendedEndpoints = api.injectEndpoints({
  endpoints: (builder) => ({
    bespokeExploDashboards: builder.query<Analytics.GetBespokeDashboards, void>(
      {
        query: () => ({
          url: bespokeDashboardsEndpoint,
        }),
      }
    ),
    standardExploDashboards: builder.query<
      Analytics.GetStandardDashboards,
      void
    >({
      query: () => ({
        url: standardDashboardsEndpoint,
      }),
    }),
    exploJwtToken: builder.mutation<
      Analytics.JwtTokenResponse,
      Analytics.JwtTokenParams
    >({
      query: ({ dashboardId, ...entityPk }) => ({
        url: standardDashboardsEndpoint + `${dashboardId}/` + "token",
        method: "POST",
        body: entityPk,
      }),
    }),
  }),
});

export const {
  useBespokeExploDashboardsQuery,
  useStandardExploDashboardsQuery,
  useExploJwtTokenMutation,
} = extendedEndpoints;
