import React from "react";

import { scope3CategoryNames } from "EmissionDetails/scope3CategoryNames";
import { Filter } from "ui/forms";

import useFilterParam from "./useFilterParam";

const Scope3Category: React.FC = () => {
  const data = Object.entries(scope3CategoryNames).map(([key, value]) => ({
    label: value,
    value: key,
  }));
  const [selected, setSelected] = useFilterParam("scope3Category", data, {
    defaultToFirst: false,
    multi: true,
    ready: true,
  });
  return (
    <Filter
      items={{ options: data }}
      title="Scope 3 Category"
      label="Scope 3 Category"
      selected={!!selected.length}
      count={selected.length ? selected.length : void 0}
      onChange={setSelected}
      disabled={!data.length || !selected}
      value={selected}
      multi={true}
      showReset={true}
      showApply={true}
    />
  );
};

export default Scope3Category;
