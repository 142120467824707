import cssVar from "theme/vars";
import { H4M } from "typography/headers";
import { rem } from "polished";
import React, { ComponentType } from "react";
import styled, { css } from "styled-components";

export type BadgeColor =
  | "gray"
  | "orange"
  | "rose"
  | "pink"
  | "purple"
  | "darkPurple"
  | "midBlue"
  | "coal"
  | "initial"
  | "success"
  | "warning"
  | "error"
  | "combination";
type Size = "large" | "medium" | "small";

const Badge: React.FC<{
  size?: Size;
  className?: string;
  color: BadgeColor;
  leftIcon?: ComponentType;
  rightIcon?: ComponentType;
  interactable?: boolean;
  onClick?: () => void;
}> = ({
  size = "medium",
  color = "initial",
  leftIcon,
  rightIcon,
  className,
  children,
  interactable,
  onClick,
}) => {
  return (
    <Container
      className={className}
      $color={color}
      $size={size}
      $interactable={interactable}
      onClick={onClick}
    >
      {leftIcon && <LeftIcon as={leftIcon} />}
      <Label as={size === "small" ? TextXS : H4M}>{children}</Label>
      {rightIcon && <RightIcon as={rightIcon} />}
    </Container>
  );
};

const colourVariants: Record<BadgeColor, Record<string, string>> = {
  gray: {
    "--back-color": cssVar("color/gray/20"),
    "--color": cssVar("color/gray/70"),
  },
  purple: {
    "--back-color": cssVar("color/accents/purpleBg"),
    "--color": cssVar("color/accents/purple"),
  },
  coal: {
    "--back-color": cssVar("color/action/superLightBlue"),
    "--color": cssVar("color/grayModern/coal"),
  },
  darkPurple: {
    "--back-color": cssVar("color/accents/darkPurpleBg"),
    "--color": cssVar("color/accents/darkPurple"),
  },
  error: {
    "--back-color": cssVar("color/error/10"),
    "--color": cssVar("color/error/80"),
  },
  initial: {
    "--back-color": cssVar("color/gray/10"),
    "--color": cssVar("color/grayModern/nickel"),
  },
  midBlue: {
    "--back-color": cssVar("color/action/superLightBlue"),
    "--hover-color": cssVar("color/action/lightActiveBlue"),
    "--color": cssVar("color/action/duskBlue"),
  },
  orange: {
    "--back-color": cssVar("color/accents/orangeBg"),
    "--color": cssVar("color/accents/orange"),
  },
  pink: {
    "--back-color": cssVar("color/accents/pinkBg"),
    "--color": cssVar("color/accents/pink"),
  },
  rose: {
    "--back-color": cssVar("color/accents/roseBg"),
    "--color": cssVar("color/accents/rose"),
  },
  success: {
    "--back-color": cssVar("color/success/10"),
    "--color": cssVar("color/success/70"),
  },
  warning: {
    "--back-color": cssVar("color/warning/10"),
    "--color": cssVar("color/warning/80"),
  },
  combination: {
    "--back-color": cssVar("color/accents/orangeBg"),
    "--color": cssVar("color/action/duskBlue"),
  },
};
const sizeVariants: Record<Size, Record<string, string>> = {
  large: {
    padding: "4px 12px",
  },
  medium: {
    padding: "2px 10px",
  },
  small: {
    padding: "2px 8px",
  },
};

const TextXS = styled.span`
  font-size: ${rem("12px")};
  line-height: ${rem("18px")};
  font-weight: 500;

  vertical-align: bottom;
`;

const Label = styled.span`
  display: inline;
  padding: 0;
  margin: 0;
  color: inherit;
`;

export const Icon = styled.svg.attrs({ role: "img" })`
  color: var(--color);
  max-width: 16px;
  max-height: 16px;
`;

export const LeftIcon = styled(Icon)`
  margin-right: 4px;
  margin-left: -6px;
`;

export const RightIcon = styled(Icon)`
  margin-right: -6px;
  margin-left: 4px;
`;

const Container = styled.div<{
  $size: Size;
  $color: BadgeColor;
  $interactable?: boolean;
}>`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 6px;
  border-radius: 16px;
  background-color: var(--back-color, ${cssVar("color/gray/10")});
  color: var(--color, ${cssVar("color/grayModern/nickel")});
  ${({ $color }) => colourVariants[$color]};
  ${({ $size }) => sizeVariants[$size]};

  ${(props) =>
    props.$interactable &&
    css`
      cursor: pointer;

      &:hover {
        transition: background-color 0.2s;
        background-color: var(
          --hover-color,
          var(--back-color, ${cssVar("color/gray/10")})
        );
      }
    `}
`;

export default Badge;
