import { ReactNode } from "react";

import { P } from "typography/body";

import Button from "../Button";
import { ModalWindowProps } from "./Window";
import Modal from "./index";

interface StatusModalProps extends ModalWindowProps {
  title?: string;
  description?: string | ReactNode;
  actions?: ReactNode;
}

const StatusModal: React.FC<StatusModalProps> = ({
  actions,
  title,
  description,
  children,
  ...rest
}) => {
  return (
    <Modal.Window {...rest}>
      {title && <Modal.Header title={title} withCloseButton={false} noBorder />}
      <Modal.Body>
        {description && <P> {description} </P>}
        {children}
      </Modal.Body>
      <Modal.FooterWithActions aligned="right">
        {actions || (
          <Button variant="secondary" onClick={rest.onClose}>
            Ok
          </Button>
        )}
      </Modal.FooterWithActions>
    </Modal.Window>
  );
};

export default StatusModal;
