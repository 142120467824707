import { ChevronLeft } from "icons";
import React, { ReactNode, useCallback, useState } from "react";
import styled, { css } from "styled-components";
import cssVar from "theme/vars";
import Table from "./Table";
import Cell from "./Cell";
import { HeadCellWrapper } from "./HeadCell";

export interface ExpandableRowProps {
  columns: (string | number)[];
  contents: ReactNode;
}

const ExpandableRow: React.FC<ExpandableRowProps> = ({
  columns,
  contents,
  children,
}) => {
  const [open, setOpen] = useState(false);
  const onToggle = useCallback(() => setOpen((o) => !o), []);
  const content = React.Children.toArray(contents);
  return (
    <>
      {content.map((item, index) => (
        <ExpandableRowWrapper key={index} $first={index === 0}>
          {index === 0 && (
            <Indicator
              $open={open}
              onClick={onToggle}
              aria-label={open ? "Collapse" : "Expand"}
            >
              <ChevronLeft />
            </Indicator>
          )}
          {item}
        </ExpandableRowWrapper>
      ))}
      {open && <Content columns={columns}>{children}</Content>}
    </>
  );
};

const Indicator = styled.div<{ $open: boolean }>`
  position: absolute;
  cursor: pointer;
  width: 24px;
  height: 24px;
  border: 0;
  padding: 0;
  margin: 0;
  left: 12px;
  top: 50%;
  margin-top: -12px;
  overflow: hidden;
  font-size: 10px;
  line-height: 24px;
  transform: rotate(0deg);
  transition: 0.4s ease;

  ${(props) =>
    props.$open &&
    css`
      transform: rotate(180deg);
    `}

  svg {
    width: 24px;
    height: 24px;
    color: ${cssVar("color/primary/blue")};
    transform: rotate(-90deg);
  }
`;

const ExpandableRowWrapper = styled.div<{ $first: boolean }>`
  position: relative;
  background: ${cssVar("color/gray/10")};

  --header-color: ${cssVar("color/primary/superSilverGrey")};

  ${(props) =>
    props.$first &&
    css`
      // Allocate space for the expand-toggle
      padding-left: 60px;
      ${Cell} {
        padding-left: 0;
      }
    `}
  ${Cell} {
    color: ${cssVar("color/gray/100")};
    border-bottom: 0;
    height: 100%;
  }

  border-top: 1px solid ${cssVar("color/gray/20")};
  border-bottom: 1px solid ${cssVar("color/gray/20")};
  &:first-of-type {
    border-top: 0;
  }
`;

const Content = styled(Table)`
  grid-column: 1 / -1;
  border-bottom: 0;

  ${HeadCellWrapper} {
    background: ${cssVar("color/primary/white")};
    border-top: 0;
  }
`;

export default ExpandableRow;
