import { createAsyncThunk } from "@reduxjs/toolkit";
import * as Sentry from "@sentry/react";
import { APIGet, APIPatch } from "net/api";

import { usersEndpoint } from "API";
import { GetUser } from "API/Endpoints/Users/payloads";

import { userSlice } from "./userSlice";

const fetchUserDetails = createAsyncThunk("user/me", async (_, thunkApi) => {
  try {
    const response = await APIGet<GetUser>(`${usersEndpoint}/me/`);
    const { id } = response.data;
    Sentry.setUser({ id });
    return response.data;
  } catch (error: any) {
    if ("code" in error && error.code === "ERR_NETWORK") {
      Sentry.captureException(error);
      return thunkApi.rejectWithValue(error.code);
    } else if (
      "code" in error.response.data &&
      error.response.data.code === "user_not_found"
    ) {
      thunkApi.dispatch(userSlice.actions.setInvalidLogin());
    }

    return thunkApi.rejectWithValue(error.response.data);
  }
});

const updateCurrentOrganisation = createAsyncThunk(
  "user/me",
  async (
    params: {
      organisationId: string;
    },
    thunkApi
  ) => {
    try {
      const response = await APIPatch<GetUser>(`${usersEndpoint}/me/`, {
        active_organisation_id: params.organisationId,
      });
      return response.data;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.response.data);
    }
  }
);

export const userThunks = {
  fetchUserDetails,
  updateCurrentOrganisation,
};
