import React, { useCallback, useState, CSSProperties, useEffect } from "react";
import { createPortal } from "react-dom";
import ReactDatePicker from "react-datepicker";
import styled from "styled-components";
import { useRootElement } from "ui/hooks";
import DatePickerStyles from "./Styles";
import Header from "./Header";
import { MenuWrapper } from "../Filter/Menu";
import { scrollbars } from "theme/mixins";

export interface SingleFormMenuProps {
  open: boolean;
  onClose: () => void;
  onApply: (date: Date | null) => void;
  top: number;
  left: number;
  right: number;
  rightAligned: boolean;
  startDate: Date | null;
}

const SingleFormMenu: React.FC<SingleFormMenuProps> = ({
  open,
  onClose,
  onApply,
  top,
  left,
  right,
  rightAligned = false,
  startDate = null,
}) => {
  const portal = useRootElement("date-picker");

  const [startValue, setStartValue] = useState<Date | null>(startDate);

  // Keep our state in-sync with provided value
  useEffect(() => {
    setStartValue(startDate);
  }, [startDate]);

  const handleChange = useCallback(
    (date: Date | null) => {
      setStartValue(date);
      onApply(date);
      onClose();
    },
    [onApply, onClose]
  );

  return createPortal(
    <>
      {open && <Backdrop onClick={onClose} />}
      <DatePicker
        $open={open}
        $right={rightAligned}
        $form={true}
        $showReset={false}
        $showApply={false}
        role="dialog"
        style={
          {
            "--top": `${top}px`,
            "--left": `${left}px`,
            "--right": `${right}px`,
          } as CSSProperties
        }
      >
        <DatePickerStyles />
        <ReactDatePicker
          onChange={handleChange}
          selected={startValue}
          monthsShown={2}
          startDate={startValue}
          calendarStartDay={1}
          renderCustomHeader={Header}
          // renderCustomHeader={(props) => (
          //   <Header {...props} customHeaderCount={2} />
          // )}
          inline
        />
      </DatePicker>
    </>,
    portal
  );
};

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 100;
`;

const DatePicker = styled(MenuWrapper)`
  width: 656px;
  padding: 0;
  overflow-y: auto;
  ${scrollbars()}
`;

export default SingleFormMenu;
