import { RegistrationFormData } from "ExternalRegistration/ExternalRegistration";

import api from "API";

import * as ExternalRegistration from "./payloads";

const extendedEndpoints = api.injectEndpoints({
  endpoints: (builder) => ({
    externalRegistrationInvitation: builder.query<
      ExternalRegistration.GetInvitation,
      ExternalRegistration.InvitationParams
    >({
      query: ({ organisationId, linkId }) => ({
        url: `/organisations/${organisationId}/external-registration/${linkId}/invite/`,
        method: "GET",
      }),
    }),
    externalRegistrationInvite: builder.mutation<
      RegistrationFormData,
      ExternalRegistration.InvitationParams
    >({
      query: ({ organisationId, linkId, ...formData }) => ({
        url: `/organisations/${organisationId}/external-registration/${linkId}/invite/`,
        method: "POST",
        body: formData,
      }),
    }),
  }),
});

export const {
  useExternalRegistrationInvitationQuery,
  useExternalRegistrationInviteMutation,
} = extendedEndpoints;
