import React from "react";

import { Filter } from "ui/forms";

import { useGhgScopesQuery } from "API";

import useFilterParam from "./useFilterParam";

const Scope: React.FC = () => {
  const { data = [], isSuccess, isError } = useGhgScopesQuery();
  const [selected, setSelected] = useFilterParam("scope", data, {
    defaultToFirst: false,
    multi: true,
    ready: isSuccess || isError,
  });
  return (
    <Filter
      items={{ options: data }}
      title="Scope"
      label="Scope"
      selected={!!selected.length}
      count={selected.length ? selected.length : void 0}
      onChange={setSelected}
      disabled={!data.length || !selected}
      value={selected}
      multi={true}
      showReset={true}
      showApply={true}
    />
  );
};

export default Scope;
