import { useSelector } from "react-redux";

import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Methodology } from "types/Methodology";

type State = {
  viewing: string | Methodology | null;
};

const initialState: State = {
  viewing: null,
};
const slice = createSlice({
  name: "methodologies",
  initialState,
  reducers: {
    view(state, action: PayloadAction<string | Methodology>) {
      state.viewing = action.payload;
    },
    close(state, _action: PayloadAction<void>) {
      state.viewing = null;
    },
  },
});

export const useViewingMethology = () =>
  useSelector<{ [slice.name]: State }, string | Methodology | null>(
    (state) => state.methodologies.viewing
  );

export const { reducer, actions } = slice;
export { slice };
